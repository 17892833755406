import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ToastManager } from '@blocks/toast/toast.manager';
import { Reference } from '@interfaces/reference.interface';
import { JobService } from '@services/job.service';
import { ReferenceService } from '@services/reference.service';
import { LIMIT_GET_LIST } from 'src/app/shared/constants';

type JobListType = 'find-job' | 'applied-job' | 'saved-job';
@Component({
  selector: 'job-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() searchBarType: JobListType = 'find-job';
  public keyword!: string;
  public location!: string;
  public isLoading = false;

  public experienceLevelList: Reference[] = [];
  public employmentTypeList: Reference[] = [];
  public workplaceTypeList: Reference[] = [];
  public industries: Reference[] = [];
  public languages: Reference[] = [];

  public selectedOrder!: string;
  public showClosedJob!: string;
  public selectedExperienceList: string[] = [];
  public selectedEmploymentTypeList: string[] = [];
  public selectedWorkplaceList: string[] = [];
  public selectedIndustries: string[] = [];
  public selectedLanguages: string[] = [];
  public filterBar = 'filterbar';
  public showJobAsGridList = false;

  constructor
    (
      private jobService: JobService,
      private referenceService: ReferenceService,
      private toastManager: ToastManager,
    ) {
  }

  public async onTalentListModeClick() {
    const value = !this.showJobAsGridList;
    this.showJobAsGridList = value
    this.jobService.setShowJobAsGridList(value)
    // TODO: harusnya ngambil limit 20
    if (this.showJobAsGridList) {
      await this.onSearch(20)
    }
  }

  async ngOnInit(): Promise<void> {
    try {
      this.isLoading = true;
      this.experienceLevelList = await this.referenceService.getReferenceListByType('EXPERIENCE_LEVEL');
      this.employmentTypeList = await this.referenceService.getReferenceListByType('WORK_TYPE');
      this.workplaceTypeList = await this.referenceService.getReferenceListByType('JOB_TYPE');
      this.industries = await this.referenceService.getReferenceListByType('INDUSTRY');
      this.languages = await this.referenceService.getReferenceListByType('LANGUAGE');
      if (this.searchBarType === 'applied-job') {
        this.filterBar = 'filterbar';
        await this.jobService.getAppliedJobList({});
        return;
      }
      if (this.searchBarType === 'saved-job') {
        this.filterBar = 'filterbar';
        await this.jobService.getSavedJobList({});
        return;
      }
      if (this.searchBarType === 'find-job') {
        this.filterBar = 'filterbar-with-margin';
        await this.jobService.getJobList({});
        return;
      }

    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  onExperienceLevelCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedExperienceList.push(value)
      return
    }
    const indexToRemove = this.selectedExperienceList.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedExperienceList.splice(indexToRemove, 1);
    }
  }
  onEmploymentTypeCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedEmploymentTypeList.push(value)
      return
    }
    const indexToRemove = this.selectedEmploymentTypeList.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedEmploymentTypeList.splice(indexToRemove, 1);
    }
  }
  onWorkplaceCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedWorkplaceList.push(value)
      return
    }
    const indexToRemove = this.selectedWorkplaceList.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedWorkplaceList.splice(indexToRemove, 1);
    }
  }

  onIndustryCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedIndustries.push(value)
      return
    }
    const indexToRemove = this.selectedIndustries.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedIndustries.splice(indexToRemove, 1);
    }
  }

  onLanguageCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedLanguages.push(value)
      return
    }
    const indexToRemove = this.selectedLanguages.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedLanguages.splice(indexToRemove, 1);
    }
  }

  onOrderByOptionClick(event: any) {
    const value = event.target.value;
    this.selectedOrder = value;
  }

  onShowClosedOptionClick(event: any) {
    const value = event.target.value;
    this.showClosedJob = value;
  }

  async onSearch(size?: number) {
    try {
      this.isLoading = true;
      const params = {
        keyword: this.keyword,
        location: this.location,
        industry: this.selectedIndustries.join('|'),
        workplaceType: this.selectedWorkplaceList.join('|'),
        employmentType: this.selectedEmploymentTypeList.join('|'),
        experienceLevel: this.selectedExperienceList.join('|'),
        languages: this.selectedLanguages.join('|'),
        orderBy: this.selectedOrder,
        size: LIMIT_GET_LIST,
        showClosed: this.showClosedJob === 'yes',
      }
      this.jobService.setJobErrorMessage('');
      if (this.searchBarType === 'applied-job') {
        await this.jobService.getAppliedJobList(params);
        return;
      }
      if (this.searchBarType === 'saved-job') {
        await this.jobService.getSavedJobList(params);
        return;
      }
      if (this.searchBarType === 'find-job') {
        await this.jobService.getJobList(params);
        return;
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
