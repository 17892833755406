import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { TalentService } from '@services/talent.service';
import { isEmpty } from 'lodash';
import { ALPHABET_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['../../../talent/about/about.component.scss']
})
export class LanguageFormComponent implements AfterViewInit {

  public formType = 'NEW';
  public isLoading = false;
  public languages: string[] = [];
  public newLanguage: string = '';
  public languageInvalidMessage = '';

  constructor
    (
      private talentService: TalentService,
      private dialogRef: MatDialogRef<LanguageFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data) {
      const languages = modalData?.data.languages.map((item: any) => item.language);
      this.languages = languages
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public addLanguage() {
    const isLanguageLimitExceeded = this.languages.length > MAX_LENGTH.SKILL;
    const isExist = this.languages.includes(this.newLanguage);
    const isAlphabetical = ALPHABET_PATTERN.test(this.newLanguage)
    if (!isEmpty(isAlphabetical) && !isAlphabetical) {
      this.languageInvalidMessage = 'Languages should be alphabets';
      this.cdr.detectChanges();
      return;
    }
    if (!isLanguageLimitExceeded && !isExist && this.newLanguage.trim() !== '') {
      this.languages.push(this.newLanguage.trim());
      this.newLanguage = '';
      this.languageInvalidMessage = ''
    }
  }

  public removeSkill(skill: string, type: string) {

    if (type === 'language') {
      const index = this.languages.indexOf(skill);
      if (index !== -1) {
        this.languages.splice(index, 1);
      }
    }
  }

  public isLanguageValid = () => {
    if (this.languageInvalidMessage) {
      return false
    }
    if (this.languages.length === 0) {
      this.languageInvalidMessage = 'Languages is required'
      return false
    }

    if (this.languages.length > MAX_LENGTH.SKILL) {
      this.languageInvalidMessage = `Languages length maximum ${MAX_LENGTH.SKILL} language`
      return false
    }
    this.languageInvalidMessage = ''
    return true
  }

  public async editLanguage(): Promise<void> {
    try {
      this.isLoading = true;
      await this.talentService.editLanguage(this.languages);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Language saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
