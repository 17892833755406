import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { UserType } from '@enums/login.enum';
import { StorageHelper } from '@helpers/storage.helper';
import { StringHelper } from '@helpers/string.helper';

export const authGuard: CanActivateFn = (route, state) => {
  const olympusToken = StorageHelper.getAccessToken();
  const router = inject(Router)
  if (olympusToken) return true;

  router.navigate(['auth/login'])
  return false
};

export const talentGuard: CanActivateFn = (route, state) => {
  const loggedUser = StorageHelper.getCurrentLoggedUser();
  const router = inject(Router)

  if (loggedUser?.isRegistration) {
    router.navigate(['auth/login'])
    return false
  }

  if (loggedUser?.type === UserType.TALENT) return true;

  router.navigate(['auth/login'])
  return false
};

export const corporateGuard: CanActivateFn = (route, state) => {
  const loggedUser = StorageHelper.getCurrentLoggedUser();
  const router = inject(Router)

  if (loggedUser?.isRegistration) {
    router.navigate(['auth/login'])
    return false
  }

  if (loggedUser?.type === UserType.CORPORATE) return true;

  router.navigate(['auth/login'])
  return false
};

export const backOfficeGuard: CanActivateFn = (route, state) => {
  const loggedUser = StorageHelper.getCurrentLoggedUser();
  const router = inject(Router)
  if (loggedUser?.type === UserType.BACK_OFFICE) return true;

  router.navigate(['auth/login'])
  return false
};

export const hasEmailGuard: CanActivateFn = (route, state) => {
  const signUpData = StorageHelper.getItem('SIGNUP_DATA');
  const router = inject(Router)
  if (signUpData?.email) return true;

  router.navigate(['auth/login'])
  return false
};

export const registrationGuard: CanActivateFn = (route, state) => {
  const loggedUser = StorageHelper.getCurrentLoggedUser();
  const router = inject(Router)
  if (loggedUser?.isRegistration) return true;

  router.navigate(['/'])
  return false
};

export const corporateDetailGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state) => {
  const companyId = route.params['companyId'];
  if (companyId && StringHelper.isUUID(companyId)) {
    return true;
  }
  const router = inject(Router)
  router.navigate(['/corporate/list'])
  return false
};
