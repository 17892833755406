<app-spinner *ngIf="isLoading"></app-spinner>
<div class="dasboard-container" style="margin: 10px 0px;">
  <empty-result style="width: 100%;" [emptyResultType]="'find-talent'"
    *ngIf="talentList && talentList.length === 0"></empty-result>
  <div class="talent-sidebar" style="width: 100%;"
    *ngIf="!showTalentAsGridList && talentList && talentList.length >= 1">
    <div class="talent-section">
      <div class="talent-card-list-section">
        <div *ngIf="talentList" class="row" style="width: 100%; gap:10px">
          <div *ngFor="let talent of talentList;">
            <app-talent-card [talentListType]="talentListType" [talent]="talent"
              [isSelected]="talent === selectedTalent" (cardSelected)="selectTalent(talent)">
            </app-talent-card>
          </div>
        </div>
      </div>
      <div *ngIf="showPagination" class="talent-pagination-section">
        <pagination [maxPageList]="5" [currentPage]="currentPage" [originTotalPages]="originTotalPages"
          [paginationNumberList]="paginationNumberList" (paginationChange)="onPaginationChange($event)"></pagination>
      </div>
    </div>
    <div class="talent-resume">
      <ng-container *ngIf="talentListType === 'on-boarding-talent'">
        <recruitment-steps></recruitment-steps>
        <app-job [jobId]="selectedJobId"></app-job>
      </ng-container>
      <talent-resume *ngIf="detailedTalent" [userProfile]="detailedTalent"
        [applicationStatus]="applicationStatus"></talent-resume>
    </div>
  </div>
  <div *ngIf="showTalentAsGridList && talentList && talentList.length >= 1" style="width: 100%">
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div *ngIf="talentList && !isOnGridViewProfile" class="row">
      <div class="col-sm-6 col-md-4 col-lg-3" style="margin-bottom: 15px" *ngFor="let talent of talentList;">
        <app-talent-card [talent]="talent" [isSelected]="talent === selectedTalent"
          (cardSelected)="selectTalent(talent)">
        </app-talent-card>
      </div>
    </div>
    <div *ngIf="isOnGridViewProfile" class="row">
      <div class="col-sm-7 col-sm-8 col-lg-8 d-md-block">
        <div (click)="goBackGirdViewProfile()" class="tools">
          <div class="back">
            <svg class="chevron-left" width="16" height="17" viewBox="0 0 16 17" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.3536 2.14645C11.5488 2.34171 11.5488 2.65829 11.3536 2.85355L5.70711 8.5L11.3536 14.1464C11.5488 14.3417 11.5488 14.6583 11.3536 14.8536C11.1583 15.0488 10.8417 15.0488 10.6464 14.8536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645L10.6464 2.14645C10.8417 1.95118 11.1583 1.95118 11.3536 2.14645Z"
                fill="#C2C2C2" />
            </svg>
            <div class="return">Go Back</div>
          </div>
        </div>
        <talent-resume *ngIf="detailedTalent" [applicationStatus]="applicationStatus"
          [userProfile]="detailedTalent"></talent-resume>
      </div>
      <div class="col-sm-5 col-md-4 col-lg-4">
        <div class="frame-1679">
          <div class="see-similar-profile">See Similar Profile</div>
          <div *ngIf="similarTalentList" class="row" style="gap:10px">
            <div *ngFor="let talent of similarTalentList;">
              <app-talent-card [talentListType]="talentListType" [talent]="talent"
                [isSelected]="talent === selectedTalent" (cardSelected)="selectTalent(talent)">
              </app-talent-card>
            </div>
          </div>
          <div class="line"></div>
          <div class="explore-button-primary-rounded">
            <div class="explore-more-button">Explore More</div>
            <div class="icn">
              <div class="arrow-right">
                <svg class="arrow" width="14" height="10" viewBox="0 0 14 10" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 5C0 4.72386 0.223858 4.5 0.5 4.5L12.2929 4.5L9.14645 1.35355C8.95118 1.15829 8.95118 0.841708 9.14645 0.646446C9.34171 0.451183 9.65829 0.451183 9.85355 0.646446L13.8536 4.64645C14.0488 4.84171 14.0488 5.15829 13.8536 5.35355L9.85355 9.35355C9.65829 9.54882 9.34171 9.54882 9.14645 9.35355C8.95118 9.15829 8.95118 8.84171 9.14645 8.64645L12.2929 5.5L0.5 5.5C0.223858 5.5 0 5.27614 0 5Z"
                    fill="#233D90" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
