import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { DataHelper } from '@helpers/data.helper';
import { UserProfile } from '@interfaces/talent.interface';
import { JobDetailComponent } from '@modals/job-detail/job-detail.component';
import { BackOfficeService } from '@services/back-office.service';
import { JobService } from '@services/job.service';
import { ModalInterface, ModalService } from '@services/modal.service';
import { OnBoardingService } from '@services/onboarding.service';
import { RegisterService } from '@services/register.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import moment from 'dayjs';
import _ from 'lodash';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() data: any;
  public notification: any;
  public userProfile !: UserProfile | null;
  public isLoading = false;
  private destroy$ = new Subject<void>();
  public loginAs = 'TALENT';
  public isReadNotifation = false

  constructor(
    private router: Router,
    private toastManager: ToastManager,
    private modalService: ModalService,
    private registerService: RegisterService,
    private storeService: StoreService,
    private onBoardingService: OnBoardingService,
    private talentService: TalentService,
    private backOfficeService: BackOfficeService,
    private jobService: JobService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.storeService.loginAs$,
      this.storeService.userProfile$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        loginAs,
        userProfile
      ]: [any, any]) => {
        this.loginAs = loginAs;
        this.userProfile = userProfile;
      })

    this.notification = {
      ...this.data,
      ...this.contructNotificationData()
    }

    if (this.notification.isRead) {
      this.notification.notitificationBorder = 'read'
    }
    this.isReadNotifation = this.notification.isRead;
  }

  private contructNotificationData() {
    if (this.data) {
      if (this.data.extraData) {
        let extraData = this.data.extraData
        extraData = extraData
          .replace(/(\w+):/g, '"$1":')
          .replace(/'([^']*)'/g, '"$1"')
          .replace(/\n\s*/g, '');
        extraData = _.attempt(JSON.parse.bind(null, extraData));
        if (_.isError(extraData)) {
          this.toastManager.showError('Invalid JSON format', JSON.stringify(this.data))
          return {
            needAction: false,
            buttonLabel: null,
            notitificationBorder: 'primary',
            notificationDate: moment(this.data.notificationDate).format('DD MMMM YYYY')
          }
        }
        extraData.notificationDate = moment(this.data.notificationDate).format('DD MMMM YYYY')
        return extraData
      } else {
        let extraData: any = {
          needAction: false,
          buttonLabel: null,
          notitificationBorder: 'primary',
          notificationDate: moment(this.data.notificationDate).format('DD MMMM YYYY')
        }
        return extraData
      }
    }
  }

  public async onClickDetail() {
    this.isReadNotifation = true;
    this.notification.notitificationBorder = 'read'
    if (this.loginAs === 'BACK_OFFICE') {
      await this.backOfficeService.readNotification(this.notification.id);
      await this.backOfficeService.getUnredNotificationCount();
    } else {
      await this.talentService.readNotification(this.notification.id);
      await this.talentService.getUnredNotificationCount();
    }

    if (this.notification.group === 'PLACEMENT') {
      try {
        this.isLoading = true;
        if (this.loginAs === 'BACK_OFFICE' && this.notification.notificationTemplate === 'NEW_TALENT_PLACEMENT_PROCESS') {
          const jobOffer = await this.jobService.getJobOfferById(this.notification.refId);
          this.router.navigate(['/talent/talent/account', jobOffer.talentId])
          return
        }

        const onboardingData = await this.onBoardingService.getOnBoardingDataById(this.notification.refId)
        if (this.loginAs === 'CORPORATE') {
          this.router.navigate(['/corporate/on-boarding/', onboardingData.jobId], {
            queryParams: {
              onboardingId: this.notification.refId,
              status: DataHelper.mapOnBoardingStatus(onboardingData.status)
            }
          })
        }

        if (this.loginAs === 'TALENT') {
          this.router.navigate(['/talent/placements/'], {
            queryParams: {
              onboardingId: this.notification.refId,
              jobId: onboardingData.jobId,
              status: DataHelper.mapOnBoardingStatus(onboardingData.status)
            }
          })
        }

        if (this.loginAs === 'BACK_OFFICE') {
          this.router.navigate(['/talent/placement/all', this.notification.refId])
          return
        }

      } catch (err: any) {
        this.toastManager.showError(null, err.message)
      } finally {
        this.isLoading = false
      }
      return;
    }

    if (this.notification.group === 'REGISTRATION' && this.loginAs === 'BACK_OFFICE') {
      if (this.notification.refId) {
        this.router.navigate(['/talent/talent/account', this.notification.refId]);
        return
      }
    }

    if (this.notification.group === 'JOB_POST') {
      try {
        this.isLoading = true;
        if (this.loginAs === 'CORPORATE') {
          this.jobService.setSearchParams({
            jobId: this.notification.refId
          })
          this.router.navigate(['/corporate/job/'], {
            queryParams: {
              jobId: this.notification.refId,
            }
          })
        }

        if (this.loginAs === 'TALENT' || this.loginAs === 'BACK_OFFICE') {
          const modalConfig: ModalInterface = {
            component: JobDetailComponent,
            customLayout: {
              width: '810px',
            },
            data: this.notification,
          }
          this.modalService.openModal(modalConfig);
          return
        }

      } catch (err: any) {
        this.toastManager.showError(null, err.message)
      } finally {
        this.isLoading = false
      }
      return;
    }

    if (this.notification.notificationTemplate === 'SENT_OFFER') {
      const modalConfig: ModalInterface = {
        component: JobDetailComponent,
        customLayout: {
          width: '810px',
        },
        data: this.notification,
      }
      this.modalService.openModal(modalConfig)
      return
    }

    if (this.notification.navigateTo === 'open-job-modal-by-ref-id') {
      const modalConfig: ModalInterface = {
        component: JobDetailComponent,
        customLayout: {
          width: '810px',
        },
        data: this.notification,
      }
      this.modalService.openModal(modalConfig)
      return
    }
    if (this.notification.navigateTo === "verify-email") {
      try {
        if (this.userProfile) {
          await this.registerService.resendEmailActivation(this.userProfile?.email);
          this.toastManager.showSuccess('Resend email activation successfully')
        }
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      }
    }
  }
}

