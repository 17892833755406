<!-- NOTE Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/talent']">
      <img src="./assets/img/project/enigma-logo-blue.svg" [alt]="appName + ' Logo'" class="img-fluid me-2" />
      {{ appName }}
    </a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0 w-100">

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/talent/']" [routerLinkActive]="['active']">
            {{ 'HOME' | translate}}
          </a>
        </li>

        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" ngbDropdownToggle id="navbarDropdown" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            {{ 'USER' | translate }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" [routerLink]="['/user']" [routerLinkActive]="['active']">{{ 'MY_ACCOUNT' |
                translate }}</a></li>
            <li><a class="dropdown-item cursor-pointer" (click)="onClickLogout()">{{ 'LOGOUT' | translate }}</a></li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
</nav>

<!-- NOTE Content -->
<ng-content></ng-content>