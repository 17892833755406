// Angular modules
import { Injectable, Injector } from '@angular/core';

// Enums
import { Endpoint } from '@enums/endpoint.enum';

// Interfaces
import { Talent } from '@interfaces/talent.interface';

// Services
import { CompanyBasicInfo, CorporateJobStatus, CorporateSearchType, CorporateTalentStatus, Job, JobInfo, JobOffer } from '@interfaces/corporate.interface';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { LIMIT_GET_LIST } from '../constants';
import { APIBaseService } from './api-base.service';
import { StorageHelper } from '@helpers/storage.helper';

@Injectable()
export class JobService extends APIBaseService {

  private readonly _availableJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _corporateJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _savedJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _appliedJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _jobTemplateList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _corporateActiveJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _jobErrorMessage = new BehaviorSubject<string>('');
  private readonly _isLoading = new BehaviorSubject<boolean>(false);
  private readonly _showJobAsGridList = new BehaviorSubject<boolean>(false);
  private readonly _viewedTalentList = new BehaviorSubject<Array<Talent> | null>(null);
  private readonly _appliedTalentList = new BehaviorSubject<Array<Talent> | null>(null);
  private readonly _offeredTalentList = new BehaviorSubject<Array<Talent> | null>(null);
  private readonly _totalJob = new BehaviorSubject<number>(0);

  private readonly _corporateJobParams = new BehaviorSubject<{ status: CorporateJobStatus, pageNumber: number }>({ status: 'all', pageNumber: 1 });
  private readonly _corporateTalentStatus = new BehaviorSubject<CorporateTalentStatus | null>(null);
  private readonly _corporateSearchType = new BehaviorSubject<CorporateSearchType>('find-job');
  private readonly _searchParams = new BehaviorSubject<any>({ size: 10 });

  readonly availableJobList$ = this._availableJobList.asObservable();
  readonly corporateJobList$ = this._corporateJobList.asObservable();
  readonly savedJobList$ = this._savedJobList.asObservable();
  readonly appliedJobList$ = this._appliedJobList.asObservable();
  readonly jobTemplateList$ = this._jobTemplateList.asObservable();
  readonly jobErrorMessage$ = this._jobErrorMessage.asObservable();
  readonly isLoading$ = this._isLoading.asObservable();
  readonly showJobAsGridList$ = this._showJobAsGridList.asObservable();
  readonly corporateActiveJobList$ = this._corporateActiveJobList.asObservable();
  readonly viewedTalentList$ = this._viewedTalentList.asObservable();
  readonly appliedTalentList$ = this._appliedTalentList.asObservable();
  readonly offeredTalentList$ = this._offeredTalentList.asObservable();
  readonly corporateJobParams$ = this._corporateJobParams.asObservable();
  readonly corporateTalentStatus$ = this._corporateTalentStatus.asObservable();
  readonly corporateSearchType$ = this._corporateSearchType.asObservable();
  readonly searchParams$ = this._searchParams.asObservable();
  readonly totalJob$ = this._totalJob.asObservable();

  public setAvailableJobList(state: Array<JobInfo> | null): void {
    this._availableJobList.next(state);
  }

  public setCorporateJobList(state: Array<JobInfo> | null): void {
    this._corporateJobList.next(state);
  }

  public setSavedJobList(state: Array<JobInfo> | null): void {
    this._savedJobList.next(state);
  }

  public setViewedTalentList(state: Array<Talent> | null): void {
    this._viewedTalentList.next(state);
  }

  public setAppliedTalentList(state: Array<Talent> | null): void {
    this._appliedTalentList.next(state);
  }

  public setJobTemplateList(state: Array<JobInfo> | null): void {
    this._jobTemplateList.next(state);
  }

  public setOfferedTalentList(state: Array<Talent> | null): void {
    this._offeredTalentList.next(state);
  }

  public setCorporateJobParams(status: CorporateJobStatus, pageNumber = 1): void {
    this._corporateJobParams.next({ status, pageNumber });
  }

  public setCorporateTalentStatus(state: CorporateTalentStatus): void {
    this._corporateTalentStatus.next(state);
  }

  public setCorporateSearchType(state: CorporateSearchType): void {
    this._corporateSearchType.next(state);
  }

  public setAppliedJobList(state: Array<JobInfo> | null): void {
    this._appliedJobList.next(state);
  }

  public setCorporateActiveJobList(state: Array<JobInfo> | null): void {
    this._corporateActiveJobList.next(state);
  }

  public setJobErrorMessage(state: string): void {
    this._jobErrorMessage.next(state);
  }

  public setLoading(state: boolean): void {
    this._isLoading.next(state);
  }

  public setShowJobAsGridList(state: boolean): void {
    this._showJobAsGridList.next(state);
  }

  public setSearchParams(state: any): void {
    this._searchParams.next(state);
  }

  public resetSubscibedData(): void {
    this._searchParams.next({});
  }

  constructor(private injector: Injector) {
    super(injector);
  }

  public async createJobInitiate(payload: Partial<Job>): Promise<Job> {
    const url = Endpoint.JOB_INITIATE;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createJobTemplateInitiate(payload: Partial<Job>): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.post(`${url}/initiate-template`, payload);
    return data.data;
  }

  public async createJobOfferInitiate(payload: Partial<JobOffer>): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/initiate`, payload);
    return data.data;
  }

  public async getJobList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}`, { params: updatedParams });
    const result = data.data.jobs;
    this.setAvailableJobList(result)
    return result
  }

  public async getAppliedJobList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.APPLY_JOB;
    const updatedParams = _.pickBy(params);
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/list`, { params: updatedParams });
    const result = data.data.jobs;
    this.setAppliedJobList(result)
    return result
  }

  public async getCorporateActiveJobList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params);
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/active/list`, { params: updatedParams });
    const result = data.data.jobs;
    this.setCorporateActiveJobList(result)
    return result
  }

  public async getSavedJobList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.SAVE_JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/list`, { params: updatedParams });
    const result = data.data.jobs;
    this.setSavedJobList(result)
    return result
  }

  public async delegateTask(payload: any) {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.post(`${url}-onboarding/delegate-recruitment-assignee`, payload);
    return data.data;
  }


  public async getJobById(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.get(`${url}/${jobId}`);
    return data.data;
  }

  public async getJobTemplateById(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.get(`${url}/template/${jobId}/detail`);
    return data.data;
  }

  public async getDraftJobById(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.get(`${url}/draft/${jobId}`);
    return data.data;
  }

  public async getTemplateDraftJobById(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.get(`${url}/template-draft/${jobId}`);
    return data.data;
  }

  public async getCorporateJobById(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.get(`${url}/company/${jobId}`);
    return data.data;
  }

  public async getJobOfferById(jobOfferId: string): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.get(`${url}/${jobOfferId}`);
    return data.data;
  }

  public async saveJobById(jobId: string): Promise<Job> {
    // for talent save a job from available jobs
    const url = Endpoint.SAVE_JOB;
    const { data } = await this.baseAPI.post(url, { jobId: jobId });
    return data.data;
  }

  public async applyJobById(jobId: string): Promise<Job> {
    const url = Endpoint.APPLY_JOB;
    const { data } = await this.baseAPI.post(url, { jobId: jobId });
    return data.data;
  }

  public async createJobDetail(payload: any): Promise<Job> {
    const url = Endpoint.JOB_DETAIL;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createTemplateJobDetail(payload: any): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.post(`${url}/job-template-detail`, payload);
    return data.data;
  }

  public async createJobOfferDetail(payload: any): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/description`, payload);
    return data.data;
  }

  public async acceptOffer(offerId: string): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/offer/approve`, { offerId });
    return data.data;
  }

  public async declineOffer(offerId: string): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/offer/reject`, { offerId });
    return data.data;
  }

  public async createJobOffeByActiveJobId(actoveJobId: string, talentId: string): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const payload = {
      jobId: actoveJobId,
      talentId
    }
    const { data } = await this.baseAPI.post(`${url}/offer`, payload);
    return data.data;
  }


  public async createJobApplicantPreference(payload: any): Promise<Job> {
    const url = Endpoint.JOB_APPLICANT_PREFERENCE;
    const { data } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createTemplateJobApplicantPreference(payload: any): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.post(`${url}/applicant-preference-template`, payload);
    return data.data;
  }

  public async createJobOfferPreference(payload: any): Promise<JobOffer> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/detail`, payload);
    return data.data;
  }

  public async submitJob(jobId: string, isSavedAsTemplate: boolean): Promise<Job> {
    const url = Endpoint.JOB_SUBMIT;
    const { data } = await this.baseAPI.post(url, { jobId, isSavedAsTemplate });
    return data.data;
  }

  public async submitJobTemplate(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.post(`${url}/submit-template`, { jobId });
    return data.data;
  }

  public async submitJobOffer(jobOfferId: string): Promise<Job> {
    const url = Endpoint.JOB_OFFER;
    const { data } = await this.baseAPI.post(`${url}/submit`, { offerId: jobOfferId });
    return data.data;
  }

  public async editJob(updatedBasicInfo: Partial<CompanyBasicInfo>): Promise<any> {
    const url = Endpoint.COMPANY_BASIC_INFO;
    const { data } = await this.talentAPI.patch(url, updatedBasicInfo);
    return data.data;
  }

  public async deleteJobFromTalent(jobId: string): Promise<any> {
    const url = 'job/delete-application';
    const { data } = await this.baseAPI.post(url, { jobId });
    return data.data;
  }

  public async withdrawJobFromTalent(jobId: string, reason: string): Promise<any> {
    const url = 'job/withdraw';
    const { data } = await this.baseAPI.post(url, { jobId, reason });
    return data.data;
  }

  public async approveApplicantForJob(jobId: string, talentId: string): Promise<any> {
    const url = 'job/approve';
    const { data } = await this.baseAPI.post(url, { jobId, talentId });
    return data.data;
  }

  public async declineApplicantForJob(jobId: string, talentId: string): Promise<any> {
    const url = 'job/decline';
    const { data } = await this.baseAPI.post(url, { jobId, talentId });
    return data.data;
  }

  public async getCorporateJobList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/company/all`, { params: updatedParams });
    const result = data.data.jobs;
    this.setCorporateJobList(result)
    this._totalJob.next(data.data.totalData)
    return result
  }

  public async getJobTemplateList(params: any): Promise<Array<JobInfo>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/template/list-all`, { params: updatedParams });
    const result = data.data.jobs;
    this.setJobTemplateList(result)
    return result
  }

  public async getViewedTalentList(jobId: string, params: any): Promise<Array<Talent>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/company/${jobId}/viewed`, { params: updatedParams });
    const result = data.data.talents;
    this.setViewedTalentList(result)
    return result
  }

  public async getAppliedTalentList(jobId: string, params: any): Promise<Array<Talent>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/company/${jobId}/applied`, { params: updatedParams });
    const result = data.data.talents;
    this.setAppliedTalentList(result)
    return result
  }

  public async getOfferedTalentList(jobId: string, params: any): Promise<Array<Talent>> {
    const url = Endpoint.JOB;
    const updatedParams = _.pickBy(params)
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/company/${jobId}/offer`, { params: updatedParams });
    const result = data.data.talents;
    this.setOfferedTalentList(result)
    return result
  }

  public async deactivateJob(jobId: string): Promise<Job> {
    const url = Endpoint.JOB;
    const { data } = await this.baseAPI.patch(`${url}/deactive`, { jobId });
    return data.data;
  }

  public async deleteJob(jobId: string): Promise<Job> {
    const { data } = await this.baseAPI.post(`job/job/delete`, { jobId });
    return data.data;
  }

  public async deleteJobTemplate(jobId: string): Promise<Job> {
    const { data } = await this.baseAPI.delete(`job/job/template/${jobId}/delete`);
    return data.data;
  }

  private checkPermission() {
    const currentUser = StorageHelper.getCurrentLoggedUser();
    if (currentUser?.role === 'User') {
      throw new Error('You are not allowed to do this action');
    }
  }
}
