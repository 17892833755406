// Angular modules
import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'graduate-card',
  templateUrl: './graduate.component.html',
  styleUrls: ['./graduate.component.scss']
})
export class GraduateComponent implements OnInit {
  @Input() withLabel: boolean = false;
  longText = 'lorem ipsum dolor sit amet, consectetur adip'
  constructor() { }

  public ngOnInit(): void {
  }

}
