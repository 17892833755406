import { Pipe, PipeTransform } from '@angular/core';
import { MAX_LENGTH } from '../constants';

@Pipe({
  name: 'maxLength'
})
export class MaxLengthPipe implements PipeTransform {

  transform(type: any): number {
    if (type === 'INPUT') {
      return MAX_LENGTH.INPUT
    }

    if (type === 'INPUT_MEDIUM') {
      return MAX_LENGTH.INPUT_MEDIUM
    }

    if (type === 'PHONE') {
      return MAX_LENGTH.PHONE
    }

    if (type === 'TEXT_AREA_SMALL') {
      return MAX_LENGTH.TEXT_AREA_SMALL
    }

    if (type === 'TEXT_AREA_LARGE') {
      return MAX_LENGTH.TEXT_AREA_LARGE
    }

    return MAX_LENGTH.INPUT
  }
}
