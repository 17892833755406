<div class="tools2" *ngIf="paginationNumberList.length > 1">
  <div class="pagination">
    <div class="num" *ngIf="currentPage === 1">
      <div class="_2">
        <svg class="arrow-sm" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.5 4C7.5 4.24163 7.30412 4.4375 7.0625 4.4375H1.99372L3.87186 6.31564C4.04271 6.48649 4.04271 6.76351 3.87186 6.93436C3.70101 7.10521 3.42399 7.10521 3.25314 6.93436L0.62814 4.30936C0.457286 4.13851 0.457286 3.8615 0.62814 3.69064L3.25314 1.06564C3.42399 0.894786 3.70101 0.894786 3.87186 1.06564C4.04271 1.2365 4.04271 1.51351 3.87186 1.68436L1.99372 3.5625L7.0625 3.5625C7.30412 3.5625 7.5 3.75838 7.5 4Z"
            fill="#C2C2C2" />
        </svg>
      </div>
    </div>
    <div *ngIf="currentPage !== 1" class="num2" (click)="onPaginationChange(currentPage - 1)">
      <div class="_2">
        <svg class="arrow-sm" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.5 4C7.5 4.24163 7.30412 4.4375 7.0625 4.4375H1.99372L3.87186 6.31564C4.04271 6.48649 4.04271 6.76351 3.87186 6.93436C3.70101 7.10521 3.42399 7.10521 3.25314 6.93436L0.62814 4.30936C0.457286 4.13851 0.457286 3.8615 0.62814 3.69064L3.25314 1.06564C3.42399 0.894786 3.70101 0.894786 3.87186 1.06564C4.04271 1.2365 4.04271 1.51351 3.87186 1.68436L1.99372 3.5625L7.0625 3.5625C7.30412 3.5625 7.5 3.75838 7.5 4Z"
            fill="#FDFDFD" />
        </svg>
      </div>
    </div>
    <div *ngFor="let pageNumber of paginationNumberList" [ngClass]="currentPage === pageNumber ? 'num2': 'num'"
      (click)="onPaginationChange(pageNumber)">
      <div class="_2">
        <div [ngClass]="currentPage === pageNumber ? '_012': '_013'">{{ pageNumber }}</div>
      </div>
    </div>
    <div *ngIf="originTotalPages === currentPage" class="num">
      <div class="_2">
        <svg class="arrow-sm2" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.5 4C0.5 3.75837 0.695875 3.5625 0.9375 3.5625L6.00628 3.5625L4.12814 1.68436C3.95729 1.51351 3.95729 1.23649 4.12814 1.06564C4.29899 0.894786 4.57601 0.894786 4.74686 1.06564L7.37186 3.69064C7.54271 3.86149 7.54271 4.1385 7.37186 4.30936L4.74686 6.93436C4.57601 7.10521 4.29899 7.10521 4.12814 6.93436C3.95729 6.7635 3.95729 6.48649 4.12814 6.31564L6.00628 4.4375H0.9375C0.695875 4.4375 0.5 4.24162 0.5 4Z"
            fill="#C2C2C2" />
        </svg>
      </div>
    </div>
    <div *ngIf="originTotalPages !== currentPage" class="num2" (click)="onPaginationChange(currentPage + 1)">
      <div class="_2">
        <svg class="arrow-sm2" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.5 4C0.5 3.75837 0.695875 3.5625 0.9375 3.5625L6.00628 3.5625L4.12814 1.68436C3.95729 1.51351 3.95729 1.23649 4.12814 1.06564C4.29899 0.894786 4.57601 0.894786 4.74686 1.06564L7.37186 3.69064C7.54271 3.86149 7.54271 4.1385 7.37186 4.30936L4.74686 6.93436C4.57601 7.10521 4.29899 7.10521 4.12814 6.93436C3.95729 6.7635 3.95729 6.48649 4.12814 6.31564L6.00628 4.4375H0.9375C0.695875 4.4375 0.5 4.24162 0.5 4Z"
            fill="#FDFDFD" />
        </svg>
      </div>
    </div>
  </div>
</div>
