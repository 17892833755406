<!-- For more settings use the AutoHTML plugin tab ... -->
<div *ngIf="!isLoading" class="filterbar">
  <div class="search-section">
    <div class="list-type-option">
      <div class="options">
        <input (click)="onOnboardingStatusChange('on process')" type="radio" name="showAs"
          id="radioCheckedDefaultOnProcess" class="ec-form-check-input-sm" value="on process"
          [checked]="showAsControl.value === 'on process'">
        <label for="radioCheckedDefaultOnProcess" class="ec-form-check-label">On Process</label>
      </div>
      <div class="options">
        <input (click)="onOnboardingStatusChange('accepted')" type="radio" name="showAs"
          id="radioCheckedDefaultRejected" class="ec-form-check-input-sm" value="accepted"
          [checked]="showAsControl.value === 'accepted'">
        <label for="radioCheckedDefaultRejected" class="ec-form-check-label">Completed</label>
      </div>
      <div class="options">
        <input (click)="onOnboardingStatusChange('declined')" type="radio" name="showAs"
          id="radioCheckedDefaultRejected" class="ec-form-check-input-sm" value="declined"
          [checked]="showAsControl.value === 'declined'">
        <label for="radioCheckedDefaultRejected" class="ec-form-check-label">Rejected</label>
      </div>
    </div>
    <div class="list-type-option">
      <div *ngIf="loginAs === 'TALENT'" class="ec-input-group-rounded">
        <span class="ec-input-group-text">
          <i class="bi bi-briefcase-fill"></i>
        </span>
        <input [(ngModel)]="searchValue" type="text" id="searchValue" class="ec-form-control-rounded ec-is-invalid"
          placeholder="Job title, Keyword, Company" (keydown.enter)="onSearch()">
      </div>
      <div *ngIf="loginAs === 'CORPORATE'" class="ec-input-group-rounded">
        <span class="ec-input-group-text">
          <i class="bi bi-person-fill"></i>
        </span>
        <input [(ngModel)]="searchValue" type="text" id="searchValue" class="ec-form-control-rounded ec-is-invalid"
          placeholder="Find talent" (keydown.enter)="onSearch()">
      </div>
      <button (click)="onSearch()" style="display: flex; gap: 5px;"
        class="ec-btn ec-btn-primary ec-btn-rounded default">
        <i class="bi bi-search"></i> Search </button>
    </div>
  </div>
  <div class="line-7">
  </div>
</div>
