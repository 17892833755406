import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { TableHelper } from '@helpers/table.helper';
import { CorporateJobStatus } from '@interfaces/corporate.interface';
import { JobService } from '@services/job.service';
import { StoreService } from '@services/store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-job-template',
  templateUrl: './job-template.component.html',
  styleUrls: ['./job-template.component.scss']
})
export class JobTemplateComponent {
  public isLoading = false;
  public errorMessage = '';
  public selectedJobStatus: CorporateJobStatus = 'all';
  private destroy$ = new Subject<void>();
  public currentPage: number = 1;
  public currentPageSize: number = 10;
  public paginationNumberList !: Array<number>;

  constructor(
    private storeService: StoreService,
    private jobService: JobService,
    private toastManager: ToastManager,
    private router: Router,
  ) {
    this.storeService.hideSubNavbar();
  }

  ngOnDestroy(): void {
    this.jobService.setShowJobAsGridList(false)
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      await this.jobService.getJobTemplateList({});
    } catch (error: any) {
      this.toastManager.showError('Download resume failed', error.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async onPaginationChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.jobService.setCorporateJobParams(this.selectedJobStatus, pageNumber);
  }

  public navigateTo(url: string) {
    this.router.navigate([url]);
  }
}
