import { Component, Input } from '@angular/core';
import { JobService } from '@services/job.service';
import { LIMIT_GET_LIST } from 'src/app/shared/constants';

type SearchBarType = 'job-offer';

@Component({
  selector: 'active-job-search-bar',
  templateUrl: './active-job-search-bar.component.html',
  styleUrls: ['./active-job-search-bar.component.scss']
})
export class ActiveJobSearchBarComponent {
  @Input() searchBarType: SearchBarType = 'job-offer';

  public keyword!: string;
  public isLoading = false;
  public selectedOrder!: string;
  public showJobAsGridList = false;

  constructor
    (
      private jobService: JobService,
    ) {
  }

  async ngOnInit(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.searchBarType === 'job-offer') {
        await this.jobService.getCorporateActiveJobList({});
        return;
      }
    } catch (error: any) {
      this.jobService.setJobErrorMessage(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async onTalentListModeClick() {
    const value = !this.showJobAsGridList;
    this.showJobAsGridList = value
    this.jobService.setShowJobAsGridList(value)
    if(this.showJobAsGridList) {
      // TODO: harsunya reload engan 20 page
      // await this.onSearch()
      // await this.jobService.getCorporateActiveJobList({ size: 20});
    }
  }

  public onOrderByOptionClick(event: any) {
    const value = event.target.value;
    this.selectedOrder = value;
  }

  public async onSearch() {
    try {
      this.isLoading = true;
      const params = {
        keyword: this.keyword,
        orderBy: this.selectedOrder,
        size: LIMIT_GET_LIST
      }
      this.jobService.setJobErrorMessage('');
      if (this.searchBarType === 'job-offer') {
        await this.jobService.getCorporateActiveJobList(params);
        return;
      }
    }
    catch (error: any) {
      this.jobService.setJobErrorMessage(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
