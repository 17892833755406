import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonModalComponent } from '@modals/common-modal/common-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRef: MatDialogRef<CommonModalComponent> | null = null;

  constructor(private dialog: MatDialog) { }

  openModal(modalConfig: ModalInterface): void {
    const { component, customLayout, data, callback } = modalConfig;
    const props: any = {
      data: { data, callback }
    }
    if (customLayout && customLayout.height) {
      props.height = customLayout.height || 'auto'
    }
    if (customLayout && customLayout.width) {
      props.width = customLayout.width || '900px'
    }

    this.dialogRef = this.dialog.open(component, props);
  }

  closeModal(status: string): void {
    if (this.dialogRef) {
      this.dialogRef.close(status);
    }
  }
}

export interface ModalInterface {
  component: any;
  customLayout?: any;
  data?: any;
  callback?: Function;
}
