import { Component, Input } from '@angular/core';
import { CompanyProfile, Job } from '@interfaces/corporate.interface';

@Component({
  selector: 'app-job-benefit',
  templateUrl: './job-benefit.component.html',
  styleUrls: ['./job-benefit.component.scss']
})
export class JobBenefitComponent {
  @Input() job!: Job;
}
