import { Injector } from "@angular/core";
import { ToastManager } from "@blocks/toast/toast.manager";
import { environment } from "@env/environment";
import { StorageHelper } from "@helpers/storage.helper";
import { AuthResponse } from "@interfaces/authentication.interface";
import { TranslateService } from "@ngx-translate/core";
import axios, { AxiosError, AxiosInstance, CreateAxiosDefaults } from "axios";
import get from "lodash/get";
import { v4 as uuidv4 } from 'uuid';
import { StoreService } from "./store.service";

export class APIBaseService {
  private default: CreateAxiosDefaults = {
    withCredentials: true,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  };
  protected authAPI: AxiosInstance = axios.create({
    baseURL: environment.msAuthUrl,
    ...this.default,
  });
  protected talentAPI: AxiosInstance = axios.create({
    baseURL: environment.msTalentUrl,
    ...this.default,
  });
  protected referenceAPI: AxiosInstance = axios.create({
    baseURL: environment.msReferenceUrl,
    ...this.default,
  });
  protected baseAPI: AxiosInstance = axios.create({
    baseURL: environment.baseUrl,
    ...this.default,
  });
  protected storeService!: StoreService;
  protected toastManager!: ToastManager;
  protected translateService!: TranslateService;
  protected isBackOfficePage: boolean = environment.isBackOfficePage;

  constructor(private injectorObj: Injector) {

    this.storeService = this.injectorObj.get(StoreService);
    this.toastManager = this.injectorObj.get(ToastManager);
    this.translateService = this.injectorObj.get(TranslateService);

    this.initRequestInterceptor(this.authAPI);
    this.initResponseInterceptor(this.authAPI);

    this.initRequestInterceptor(this.talentAPI);
    this.initResponseInterceptor(this.talentAPI);

    this.initRequestInterceptor(this.referenceAPI);
    this.initResponseInterceptor(this.referenceAPI);

    this.initRequestInterceptor(this.baseAPI);
    this.initResponseInterceptor(this.baseAPI);
  }

  private initRequestInterceptor(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      const accessToken = StorageHelper.getAccessToken();
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;

      }
      const requestId = uuidv4();
      config.headers['X-Request-ID'] = requestId;
      return config;
    },
      (error) => {
        this.storeService.setIsLoading(false);

        return Promise.reject(error);
      });
  }

  private initResponseInterceptor(instance: AxiosInstance): void {
    instance.interceptors.response.use((response) => {
      this.storeService.setIsLoading(false);

      return response;
    },
      async (error: AxiosError<AuthResponse>) => {
        this.storeService.setIsLoading(false);
        if (error.code === 'ERR_CANCELED') return Promise.resolve(error);

        const translationCode = get(error, 'response.data.translationCode');
        const errorMessageFromBackend = get(error, 'response.data.message');
        let errorMessage = 'Something went wrong!';
        if (translationCode) {
          let translatedMessage = this.translateService.instant(translationCode)
          if (translatedMessage === translationCode) {
            // translationCode hasn't set
            translatedMessage = errorMessageFromBackend;
          }
          return Promise.reject(new Error(translatedMessage));
        }

        if (error.message) {
          return Promise.reject(new Error(error.message));
        }

        return Promise.reject(new Error(errorMessage));
      });
  }

}
