import { Injectable, Injector } from '@angular/core';

import { Endpoint } from '@enums/endpoint.enum';

import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { LIMIT_GET_LIST } from '../constants';
import { APIBaseService } from './api-base.service';
import { JobInfo } from '@interfaces/corporate.interface';
import { Talent } from '@interfaces/talent.interface';
import { Recruitment, TechnicalTest } from '@interfaces/onboarding.interface';

@Injectable()
export class OnBoardingService extends APIBaseService {

  private readonly _onboardingJobList = new BehaviorSubject<Array<JobInfo> | null>(null);
  private readonly _onboardingTalentList = new BehaviorSubject<Array<Talent> | null>(null);
  private readonly _selectedOnBordingId = new BehaviorSubject<string | null>(null);
  private readonly _shouldSendMeeting = new BehaviorSubject(false);
  private readonly _shouldSendOnboarding = new BehaviorSubject(false);

  readonly onboardingJobList$ = this._onboardingJobList.asObservable();
  readonly onboardingTalentList$ = this._onboardingTalentList.asObservable();
  readonly selectedOnBordingId$ = this._selectedOnBordingId.asObservable();
  readonly shouldSendMeeting$ = this._shouldSendMeeting.asObservable();
  readonly shouldSendOnboarding$ = this._shouldSendOnboarding.asObservable();

  constructor(private injector: Injector) {
    super(injector);
  }

  public setOnBoardingJobList(state: Array<JobInfo> | null): void {
    this._onboardingJobList.next(state);
  }

  public setOnBoardingTalentList(state: Array<Talent> | null): void {
    this._onboardingTalentList.next(state);
  }

  public setSelectedOnBoardingId(state: string | null): void {
    this._selectedOnBordingId.next(state);
  }

  public setShouldSendMeeting(shouldSendMeeting: boolean): void {
    this._shouldSendMeeting.next(shouldSendMeeting);
  }

  public setShouldSendOnboarding(shouldSendOnboarding: boolean): void {
    this._shouldSendOnboarding.next(shouldSendOnboarding);
  }

  public async getMyOnBoardingJobList(params: any): Promise<Array<JobInfo>> {
    const url = 'job/job-onboarding';
    const updatedParams = _.pickBy(params);
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/my-onboarding`, { params: updatedParams });
    const result = data.data.onboardings;
    this.setOnBoardingJobList(result)
    return result
  }

  public async getOnBoardingTalentListByJobId(params: any): Promise<Array<JobInfo>> {
    const url = 'job/job-onboarding';
    const updatedParams = _.pickBy(params);
    if (!params.size) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}/list`, { params: updatedParams });
    const result = data.data.onboardings;
    this.setOnBoardingTalentList(result)
    return result
  }

  public async getOnBoardingDataById(onBoardingId: string): Promise<Recruitment> {
    const url = 'job/job-onboarding/detail';
    const { data } = await this.baseAPI.get(`${url}/${onBoardingId}`);
    const result = data.data;
    return result
  }

  public async saveWelcomeData(payload: any): Promise<Recruitment> {
    const url = 'job/job-onboarding/welcome';
    const { data } = await this.baseAPI.post(`${url}`, payload);
    const result = data.data;
    return result
  }


  public async saveAnswerLink(onboardingId: string, link: string): Promise<any> {
    const url = 'job/job-onboarding/answer-test';
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, link });
    return data.data;
  }

  public async submitTechnicalTest(payload: any, result: string): Promise<any> {
    let url = '';
    if (result === 'approved') {
      url = 'job/job-onboarding/approve-test';
    }
    if (result === 'rejected') {
      url = 'job/job-onboarding/reject-test';
    }
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async submitInterviewTest(payload: any, result: string): Promise<any> {
    let url = '';
    if (result === 'approved') {
      url = 'job/job-onboarding/approve-interview';
    }
    if (result === 'rejected') {
      url = 'job/job-onboarding/reject-interview';
    }
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async sendMeetingInvitation(params: any): Promise<any> {
    const url = `job/job-onboarding/interview`;
    const { data }: { data: any } = await this.baseAPI.post(url, params);
    return data.data;
  }

  public async sendOnboardingInvitation(params: any): Promise<any> {
    const url = `job/job-onboarding/on-board/invite`;
    const { data }: { data: any } = await this.baseAPI.post(url, params);
    return data.data;
  }

  public async updateOnboardingInvitation(params: any): Promise<any> {
    const url = `job/job-onboarding/on-board/edit`;
    const { data }: { data: any } = await this.baseAPI.post(url, params);
    return data.data;
  }

  public async onboardingResult(onboardingId: string, type: "APPROVED" | "REJECTED"): Promise<any> {
    if (type === "APPROVED") {
      const url = `job/job-onboarding/on-board/approve`;
      const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId });
      return data.data;
    }
    const url = `job/job-onboarding/on-board/reject`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId });
    return data.data;
  }

  public async approveNDA(onboardingId: string): Promise<any> {
    const url = `job/job-onboarding/nda-approve`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId });
    return data.data;
  }

  public async markAsComplete(onboardingId: string): Promise<any> {
    const url = `job/job-onboarding/complete-placement`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId });
    return data.data;
  }

  public async acceptOnboardingSchedule(onboardingId: string): Promise<any> {
    const url = `job/job-onboarding/accept-schedule`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId });
    return data.data;
  }

  public async acceptMeetingInterview(onboardingId: string, interviewType: string): Promise<any> {
    const url = `job/job-onboarding/accept-reschedule-interview`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, interviewType });
    return data.data;
  }

  public async acceptRescheduledTime(onboardingId: string, interviewType: string): Promise<any> {
    const url = `job/job-onboarding/accept-reschedule-interview`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, interviewType });
    return data.data;
  }

  public async rejectRescheduledTime(onboardingId: string, interviewType: string): Promise<any> {
    const url = `job/job-onboarding/reject-reschedule-interview`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, interviewType });
    return data.data;
    return {}
  }

  public async rescheduleMeetingInterview(payload: any): Promise<any> {
    const url = `job/job-onboarding/reschedule-interview`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async resignApplication(payload: any): Promise<any> {
    const url = `job/job-onboarding/resign-application`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async getTestTemplateById(jobId: string): Promise<TechnicalTest> {
    const url = `job/job/test-template/get`;
    const { data }: { data: any } = await this.baseAPI.get(url, { params: { jobId } });
    return data.data;
  }

  public async setMeetingLink(onboardingId: string, link: string, interviewType: string): Promise<any> {
    const url = `job/job-onboarding/interview-link`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, link, interviewType });
    return data.data;
  }

  public async createTechnicalTest(payload: any): Promise<any> {
    const url = `job/job-onboarding/test`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async createJobTechnicalTest(payload: any): Promise<any> {
    const url = `job/job/test-template`;
    const { data }: { data: any } = await this.baseAPI.post(url, payload);
    return data.data;
  }

  public async uploadTestResult(onboardingId: string, file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      formData.append('onboardingId', onboardingId);
      const url = `job/job-onboarding/answer-upload`;
      const { data } = await this.baseAPI.post(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async uploadNDA(onboardingId: string, file: File | null, type: string): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      formData.append('onboardingId', onboardingId);
      let url = `job/job-onboarding/nda-upload`;
      if (type === 'TALENT') {
        url = `job/job-onboarding/talent-nda-upload`;
      }
      const { data } = await this.baseAPI.post(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async removeNDA(onboardingId: string): Promise<any> {
    const url = `job/job-onboarding/nda`;
    const { data }: { data: any } = await this.baseAPI.post(url, { onboardingId, link: "" });
    return data.data;
  }

  public async uploadTestQuestion(jobId: string, file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      formData.append('jobId', jobId);
      const url = `job/job-onboarding/test-upload`;
      const { data } = await this.baseAPI.post(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }
}
