import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { OnBoardingTalentStatus } from '@interfaces/corporate.interface';
import { PaginatedOnBoardingTalentResponse, OnboardingTalentData } from '@interfaces/onboarding.interface';
import { CorporateService } from '@services/corporate.service';
import { ReferenceService } from '@services/reference.service';
import { StoreService } from '@services/store.service';
import { startCase } from 'lodash';

@Component({
  selector: 'app-wish',
  templateUrl: './wish.component.html',
  styleUrls: [
    './wish.component.scss',
    '../../back-office/talent/registration-list/registration-list.component.scss'
  ]
})
export class WishComponent {

  public isLoading = false;
  public selectedOnBoardingStatus: OnBoardingTalentStatus = 'all';
  public params: any = {
    size: 10,
    page: 1,
    status: null,
    orderBy: 'date',
    orderDirection: 'desc'
  }
  public paginatedOnBoardingTalent !: PaginatedOnBoardingTalentResponse;
  public currentPage: number = 1;
  public currentPageSize: number = 10;
  public paginationNumberList !: Array<number>;
  public talentList: OnboardingTalentData[] = [];
  public isApproveModeVisible: boolean = false;
  public errorPopUpMessage = '';
  public rejectReason = '';
  public isDropsApplicationVisible = false;
  public rotationAngle = 0;
  public paginationSizeList: Array<number> = [];


  constructor(
    private corporateService: CorporateService,
    private router: Router,
    private referenceService: ReferenceService,
    private toastManager: ToastManager,
    private storeService: StoreService,
  ) {

  }
  async ngOnInit(): Promise<void> {
    try {
      this.isLoading = true;
      this.storeService.showSubNavbar();
      this.storeService.setActiveSubNavbar('wish');
      const paginationSizeList = await this.referenceService.getReferenceListByType('PAGINATION_SIZE');
      this.paginationSizeList = paginationSizeList.map(paginationSize => Number(paginationSize.value)).sort();
      await this.getOnBoardingList();
    } catch (error: any) {
      this.toastManager.showError(null, error.message);
    } finally {
      this.isLoading = false
    }
  }

  async getOnBoardingList() {
    try {
      this.isLoading = true;
      this.paginatedOnBoardingTalent = await this.corporateService.getOnBoardingTalents(this.params);
      const length = Math.ceil(this.paginatedOnBoardingTalent.totalData / this.paginatedOnBoardingTalent.size);
      this.paginationNumberList = Array.from({ length }, (_, index) => index + 1);
      this.talentList = this.paginatedOnBoardingTalent.result.map(item => ({
        ...item,
        mappedLastStep: this.mapLastStep(item.lastStep)
      }));
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private mapLastStep(lastStep: string) {
    if (lastStep === 'test') {
      return 'Technical Test'
    }

    return startCase(lastStep)
  }

  public generateNumber(index: number): number {
    return this.currentPage * this.currentPageSize - this.currentPageSize + index + 1;
  }

  public async onStatusChange(status: OnBoardingTalentStatus): Promise<void> {
    this.selectedOnBoardingStatus = status;
    this.params = {
      ...this.params,
      size: this.currentPageSize,
      page: 1,
      status: status !== 'all' ? status : null,
    }
    this.currentPage = 1;
    await this.getOnBoardingList();
  }

  public async toTalentDetail(talent: OnboardingTalentData | null) {
    this.toastManager.showError(null, 'Detail not set up yet')
    // this.router.navigate(['talent/pending', talent?.talentId])
  }

  public async onPaginationChange(pageNumber: number) {
    this.params = {
      ...this.params,
      size: this.currentPageSize,
      page: pageNumber
    }
    this.currentPage = pageNumber;
    await this.getOnBoardingList();
  }

  public async onPaginationSizeChange() {
    this.params = {
      ...this.params,
      size: this.currentPageSize,
      page: 1
    }
    this.currentPage = 1;
    await this.getOnBoardingList();
  }

  public async onSortByChange(orderBy: string) {
    let orderDirection = 'asc';
    if (this.params.orderBy === orderBy) {
      orderDirection = this.params.orderDirection === 'desc' ? 'asc' : 'desc';
    }
    this.params = {
      ...this.params,
      size: this.currentPageSize,
      page: 1,
      orderBy,
      orderDirection
    }
    this.rotationAngle = orderDirection === 'asc' ? 180 : 0;
    this.currentPage = 1;
    await this.getOnBoardingList();
  }

  public isSortByShown(orderBy: string, orderDirection: string): boolean {
    const isSameOrderBy = this.params.orderBy === orderBy;
    const isSameOrderDirection = this.params.orderDirection === orderDirection;
    return isSameOrderBy && isSameOrderDirection
  }
}
