import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[navigateUrl]'
})
export class NavigateDirective {
  @Input() navigateUrl!: string;

  constructor(private router: Router) { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {

    // Check if the user holds Ctrl/Command/Shift key or is using the middle mouse button
    if (event.ctrlKey || event.metaKey || event.shiftKey || event.button === 1) {
      window.open(this.navigateUrl, '_blank');
      return;
    }

    event.preventDefault(); // Prevent default link behavior

    const [path, queryParams] = this.navigateUrl.split('?');
    this.router.navigate([path], { queryParams: this.parseQueryParams(queryParams) });
  }

  private parseQueryParams(queryString: string | undefined): { [key: string]: string } {
    if (!queryString) {
      return {};
    }

    return queryString
      .split('&')
      .reduce((acc: { [key: string]: string }, param: string) => {
        const [key, value] = param.split('=');
        acc[key] = decodeURIComponent(value || '');
        return acc;
      }, {});
  }
}
