<div class="recruitment-step-section">
  <div class="steps">
    <div [ngClass]="activeStep === 'test' ? 'step-card-primary' : getInactiveStepClass('test')"
      (click)="onStepCardClick('test')">
      <div class="illu">
        <div class="on-boarding">
          <recruitment-svg [step]="'test'"></recruitment-svg>
        </div>
      </div>
      <div [ngClass]="activeStep === 'test' ? 'test' : 'test2'">Test</div>
    </div>
    <div [ngClass]="activeStep === 'interview' ? 'step-card-primary' : getInactiveStepClass('interview')"
      (click)="onStepCardClick('interview')">
      <div class="illu">
        <div class="on-boarding">
          <recruitment-svg [step]="'interview'"></recruitment-svg>
        </div>
      </div>
      <div [ngClass]="activeStep === 'interview' ? 'test' : 'test2'">Interview</div>
    </div>
    <div [ngClass]="activeStep === 'on-boarding' ? 'step-card-primary' : getInactiveStepClass('on-boarding')"
      (click)="onStepCardClick('on-boarding')">
      <div class="illu">
        <div class="on-boarding">
          <recruitment-svg [step]="'on-boarding'"></recruitment-svg>
        </div>
      </div>
      <div [ngClass]="activeStep === 'on-boarding' ? 'test' : 'test2'">Onboarding</div>
    </div>
    <div [ngClass]="activeStep === 'nda' ? 'step-card-primary' : getInactiveStepClass('nda')"
      (click)="onStepCardClick('nda')">
      <div class="illu">
        <div class="on-boarding">
          <recruitment-svg [step]="'nda'"></recruitment-svg>
        </div>
      </div>
      <div [ngClass]="activeStep === 'nda' ? 'test' : 'test2'">NDA</div>
    </div>
    <div [ngClass]="activeStep === 'welcome' ? 'step-card-primary' : getInactiveStepClass('welcome')"
      (click)="onStepCardClick('welcome')">
      <div class="illu">
        <div class="on-boarding">
          <recruitment-svg [step]="'welcome'"></recruitment-svg>
        </div>
      </div>
      <div [ngClass]="activeStep === 'welcome' ? 'test' : 'test2'">Welcome</div>
    </div>
  </div>
  <ng-container *ngIf="loginAs === 'TALENT' && recruitmentData">
    <ng-container *ngIf="activeStep === 'test'">
      <div class="test3">Test</div>
      <div class="card">
        <div class="div">
          <div class="card2">
            <ng-container *ngIf="recruitmentData.testData">
              <div class="desc3">
                <div class="tag-wrapper"
                  *ngIf="recruitmentData.testData.answerType === 2 && recruitmentData.testData.testType !== 1">
                  <ng-container *ngIf="recruitmentData.testData.answerLink">
                    <div class="tag-job-status bg-success-soft">
                      <label>Technical test answer has submitted</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!recruitmentData.testData.answerLink">
                    <div class="tag-job-status bg-danger-soft">
                      <label>Waiting to answer technical test</label>
                    </div>
                  </ng-container>
                </div>
                <div class="on-boarding-desc">
                  <div [innerHtml]="recruitmentData.testData.description | safeHtml"></div>
                </div>
                <div class="deadline">
                  <div class="deadline2">Deadline</div>
                  <div class="time">
                    <svg class="calendar-2-week-fill" width="16" height="17" viewBox="0 0 16 17" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.5 0.5C3.77614 0.5 4 0.723858 4 1V1.5H12V1C12 0.723858 12.2239 0.5 12.5 0.5C12.7761 0.5 13 0.723858 13 1V1.5H14C15.1046 1.5 16 2.39543 16 3.5V14.5C16 15.6046 15.1046 16.5 14 16.5H2C0.895431 16.5 0 15.6046 0 14.5V3.5C0 2.39543 0.895431 1.5 2 1.5H3V1C3 0.723858 3.22386 0.5 3.5 0.5ZM13.4545 3.5H2.54545C2.24421 3.5 2 3.72386 2 4V5C2 5.27614 2.24421 5.5 2.54545 5.5H13.4545C13.7558 5.5 14 5.27614 14 5V4C14 3.72386 13.7558 3.5 13.4545 3.5ZM8.5 7.5C8.22386 7.5 8 7.72386 8 8V9C8 9.27614 8.22386 9.5 8.5 9.5H9.5C9.77614 9.5 10 9.27614 10 9V8C10 7.72386 9.77614 7.5 9.5 7.5H8.5ZM11.5 7.5C11.2239 7.5 11 7.72386 11 8V9C11 9.27614 11.2239 9.5 11.5 9.5H12.5C12.7761 9.5 13 9.27614 13 9V8C13 7.72386 12.7761 7.5 12.5 7.5H11.5ZM3 11V12C3 12.2761 3.22386 12.5 3.5 12.5H4.5C4.77614 12.5 5 12.2761 5 12V11C5 10.7239 4.77614 10.5 4.5 10.5H3.5C3.22386 10.5 3 10.7239 3 11ZM6.5 10.5C6.22386 10.5 6 10.7239 6 11V12C6 12.2761 6.22386 12.5 6.5 12.5H7.5C7.77614 12.5 8 12.2761 8 12V11C8 10.7239 7.77614 10.5 7.5 10.5H6.5Z"
                        fill="#757575" />
                    </svg>
                    <div class="_14-05-2024">{{ recruitmentData.testData.deadline | date: 'dd/MM/YYYY'}}</div>
                  </div>

                </div>
                <ng-container *ngIf="recruitmentData.lastStep === 'test'">
                  <ng-container *ngIf="recruitmentData.testData.testType === 1">
                    <div class="btn-rounded bg-primary-soft" (click)="openLink(recruitmentData.testData.link)">
                      <label>
                        <i class="bi bi-download">
                        </i>
                        Download file
                      </label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="recruitmentData.testData.testType === 2">
                    <div *ngIf="!isRejectedApplicant()" class="btn-rounded bg-primary-soft"
                      (click)="openLink(recruitmentData.testData.link)">
                      <label>
                        <i class="bi bi-link-45deg">
                        </i>
                        Take test
                      </label>
                    </div>
                    <div class="desc" style="margin-bottom: 10px;">
                      <div style="display: flex; gap: 10px;">
                        <label>Answer Link : </label>
                        <a target="_blank" *ngIf="recruitmentData.testData.answerLink"
                          [href]="recruitmentData.testData.answerLink">Preview</a>
                        <label *ngIf="!recruitmentData.testData.answerLink"> - </label>
                      </div>
                      <div *ngIf="!isRejectedApplicant()" class="ec-input-group-rounded">
                        <span class="ec-input-group-text">
                          <i class="bi bi-link-45deg"></i>
                        </span>
                        <input [formControl]="answeredLink" type="text" id="answeredLink"
                          class="ec-form-control-rounded ec-is-invalid" placeholder="Input answer link">
                        <div class="ec-invalid-feedback"
                          *ngIf="answeredLink.touched && answeredLink.hasError('required')">
                          Answer Link is required
                        </div>
                        <div class="ec-invalid-feedback"
                          *ngIf="answeredLink.touched && answeredLink.hasError('pattern')">
                          Answer Link should be a valid url
                        </div>
                      </div>
                      <div *ngIf="answeredLink.valid" class="btn-rounded bg-primary-soft" (click)="saveAnswerLink()">
                        <label>
                          <i class="bi bi-save">
                          </i>
                          Save
                        </label>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="recruitmentData.testData.testType === 1">
                <svg class="breaker" width="1" height="179" preserveAspectRatio="none" viewBox="0 0 1 179" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <line x1="0.5" y1="4" x2="0.499993" y2="175" stroke="#C2C2C2" />
                </svg>
                <div class="desc2" *ngIf="recruitmentData.lastStep === 'test'">
                  <div class="tag-wrapper">
                    <ng-container *ngIf="recruitmentData.testData.answerLink">
                      <div class="tag-job-status bg-success-soft">
                        <label>Technical test answer has submitted</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!recruitmentData.testData.answerLink">
                      <div class="tag-job-status bg-danger-soft">
                        <label>Waiting talent to answer technical test</label>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="!recruitmentData.testData.answerLink" class="no-file-uploaded">{{'(No file selected)' |
                    stringCase:
                    'upload-filename'}}
                  </div>
                  <div class="button-upload-preview">
                    <div *ngIf="recruitmentData.testData.answerLink" class="btn-border-rounded btn-border-success-soft"
                      (click)="openLink(recruitmentData.testData.answerLink)">
                      <label>
                        <i class="bi bi-eye"></i>
                        Preview
                      </label>
                    </div>
                    <div *ngIf="recruitmentData.testData.status !== 'REJECT'" class="btn-rounded bg-primary-soft"
                      (click)="uploadTestResult()">
                      <label>
                        <i class="bi bi-upload"></i>
                        {{ isUploadingFile ? 'Uploading ...': 'Upload'}}
                      </label>
                      <span *ngIf="isUploadingFile" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      <input type="file" style="display: none;" id="testResult" class="input-file"
                        (change)="onFileSelected($event, 'testResult')" accept=".pdf, .doc, .docx">
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="!recruitmentData.testData" class="desc3">
              <label>Technical test has not setup yet</label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeStep === 'interview'"> <!-- as talent -->
      <ng-container>
        <div class="test3">1st Interview</div>
        <div class="card">
          <div class="div">
            <div class="card2">
              <div class="desc">
                <ng-container *ngIf="recruitmentData.interviewData">
                  <div class="tag-wrapper">
                    <ng-container *ngIf="isPendingReschedule('INTERVIEW')">
                      <div class="tag-job-status bg-warning-soft">
                        <label>Reschedule time is on pending approval</label>
                      </div>
                    </ng-container>
                  </div>
                  <div class="deadline">
                    <div class="deadline2">{{recruitmentData.interviewData.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                      recruitmentData.interviewData.time }}
                      {{recruitmentData.interviewData.timezone}}
                    </div>
                    <ng-container *ngIf="!recruitmentData.interviewData?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData.scheduleStatus === interviewStatus.accepted">
                        <label>Accepted</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.interviewData.scheduleStatus === interviewStatus.reschedule">
                        <label>Reschedule</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="recruitmentData.interviewData?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData?.status === 'APPROVED'">
                        <label>Passed</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.interviewData?.status === 'REJECTED'">
                        <label>Rejected</label>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="isPendingReschedule('INTERVIEW')" class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.interviewData.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at
                      {{
                      recruitmentData.interviewData.latestTime}} -
                      {{recruitmentData.interviewData.latestTimezone}}
                    </div>
                  </div>
                  <div class="on-boarding-desc">
                    <div [innerHtml]="recruitmentData.interviewData.description | safeHtml"></div>
                  </div>
                  <!-- waiting talent to accept / reschedule -->
                  <div *ngIf="recruitmentData.interviewData.scheduleStatus === interviewStatus.invitationSent"
                    class="button-group">
                    <div class="btn-rounded bg-primary-soft" (click)="openModal('accept-meeting', null)">
                      <label>
                        <i class="bi bi-check-circle">
                        </i>
                        Accept Meeting
                      </label>
                    </div>
                    <div *ngIf="!userProfile?.isEnigmaTalent" class="btn-border-rounded btn-border-primary-soft"
                      (click)="openModal('reschedule-meeting', null)">
                      <label>
                        <i class="bi bi-calendar2-x">
                        </i>
                        Request schedule
                      </label>
                    </div>
                  </div>
                  <ng-container *ngIf="recruitmentData.lastStep === 'interview' && recruitmentData.interviewData.invitationLink &&
                  (!recruitmentData.interviewData.status || recruitmentData.interviewData.status === 'RESCHEDULE')">
                    <div class="flex-row">
                      <div (click)="openLink(recruitmentData.interviewData.invitationLink)"
                        class="btn-rounded bg-primary-soft">
                        <label>
                          <i class="bi bi-link-45deg">
                          </i>
                          Access Meeting
                        </label>
                      </div>
                      <div (click)="copyText(recruitmentData.interviewData.invitationLink, 'Meeting link')"
                        class="btn-border-rounded btn-border-primary-soft">
                        <label>
                          <i class="bi bi-clipboard">
                          </i>
                          Copy link
                        </label>
                      </div>
                    </div>
                  </ng-container>
                  <button
                    *ngIf="!recruitmentData.interviewData.invitationLink && recruitmentData.interviewData.scheduleStatus === interviewStatus.accepted"
                    disabled type="button" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                    Interview link not available yet
                  </button>
                </ng-container>
                <button *ngIf="!recruitmentData.interviewData" disabled type="button"
                  class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                  Interview has not setup yet
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="recruitmentData.interviewData2
      || (recruitmentData.lastStep === 'interview' && recruitmentData.interviewData?.status === 'APPROVED')">
        <div class="test3">2nd Interview</div>
        <div class="card">
          <div class="div">
            <div class="card2">
              <div class="desc">
                <ng-container *ngIf="recruitmentData.interviewData2">
                  <div class="tag-wrapper">
                    <ng-container *ngIf="isPendingReschedule('INTERVIEW-2')">
                      <div class="tag-job-status bg-warning-soft">
                        <label>Reschedule time is on pending approval</label>
                      </div>
                    </ng-container>
                  </div>
                  <div class="deadline">
                    <div class="deadline2">{{recruitmentData.interviewData2.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                      recruitmentData.interviewData2.time }}
                      {{recruitmentData.interviewData2.timezone}}
                    </div>
                    <ng-container *ngIf="!recruitmentData.interviewData2?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData2.scheduleStatus === interviewStatus.accepted">
                        <label>Accepted</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft" *ngIf="isPendingReschedule('INTERVIEW-2')">
                        <label>Reschedule</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="recruitmentData.interviewData2?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData2?.status === 'APPROVED'">
                        <label>Passed</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.interviewData2?.status === 'REJECTED'">
                        <label>Rejected</label>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="recruitmentData.interviewData2.scheduleStatus === interviewStatus.reschedule"
                    class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.interviewData2.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at
                      {{
                      recruitmentData.interviewData2.latestTime}} -
                      {{recruitmentData.interviewData2.latestTimezone}}
                    </div>
                  </div>
                  <div class="on-boarding-desc">
                    <div [innerHtml]="recruitmentData.interviewData2.description | safeHtml"></div>
                  </div>
                  <!-- waiting talent to accept / reschedule -->
                  <div *ngIf="recruitmentData.interviewData2.scheduleStatus === interviewStatus.invitationSent"
                    class="button-group">
                    <div class="btn-rounded bg-primary-soft" (click)="openModal('accept-meeting', null)">
                      <label>
                        <i class="bi bi-check-circle">
                        </i>
                        Accept Meeting
                      </label>
                    </div>
                    <div *ngIf="!userProfile?.isEnigmaTalent" class="btn-border-rounded btn-border-primary-soft"
                      (click)="openModal('reschedule-meeting', null)">
                      <label>
                        <i class="bi bi-calendar2-x">
                        </i>
                        Request schedule
                      </label>
                    </div>
                  </div>
                  <ng-container *ngIf="recruitmentData.lastStep === 'interview' && recruitmentData.interviewData2.invitationLink &&
                  (!recruitmentData.interviewData2.status || recruitmentData.interviewData2.status === 'RESCHEDULE')">
                    <div class="flex-row">
                      <div (click)="openLink(recruitmentData.interviewData2.invitationLink)"
                        class="btn-rounded bg-primary-soft">
                        <label>
                          <i class="bi bi-link-45deg">
                          </i>
                          Access Meeting
                        </label>
                      </div>
                      <div (click)="copyText(recruitmentData.interviewData2.invitationLink, 'Meeting link')"
                        class="btn-border-rounded btn-border-primary-soft">
                        <label>
                          <i class="bi bi-clipboard">
                          </i>
                          Copy link
                        </label>
                      </div>
                    </div>
                  </ng-container>
                  <button
                    *ngIf="!recruitmentData.interviewData2.invitationLink && recruitmentData.interviewData2.scheduleStatus === interviewStatus.accepted"
                    disabled type="button" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                    Interview link not available yet
                  </button>
                </ng-container>
                <button *ngIf="!recruitmentData.interviewData2" disabled type="button"
                  class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                  Interview has not setup yet
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="recruitmentData.interviewData3
      || (recruitmentData.lastStep === 'interview' && recruitmentData.interviewData2?.status === 'APPROVED')">
        <div class="test3">3rd Interview</div>
        <div class="card">
          <div class="div">
            <div class="card2">
              <div class="desc">
                <ng-container *ngIf="recruitmentData.interviewData3">
                  <div class="tag-wrapper">
                    <ng-container *ngIf="isPendingReschedule('INTERVIEW-3')">
                      <div class="tag-job-status bg-warning-soft">
                        <label>Reschedule time is on pending approval</label>
                      </div>
                    </ng-container>
                  </div>
                  <div class="deadline">
                    <div class="deadline2">{{recruitmentData.interviewData3.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                      recruitmentData.interviewData3.time }}
                      {{recruitmentData.interviewData3.timezone}}
                    </div>
                    <ng-container *ngIf="!recruitmentData.interviewData3?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData3.scheduleStatus === interviewStatus.accepted">
                        <label>Accepted</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft" *ngIf="isPendingReschedule('INTERVIEW-3')">
                        <label>Reschedule</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="recruitmentData.interviewData3?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.interviewData3?.status === 'APPROVED'">
                        <label>Passed</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.interviewData3?.status === 'REJECTED'">
                        <label>Rejected</label>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="isPendingReschedule('INTERVIEW-3')" class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.interviewData3.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at
                      {{
                      recruitmentData.interviewData3.latestTime}} -
                      {{recruitmentData.interviewData3.latestTimezone}}
                    </div>
                  </div>
                  <div class="on-boarding-desc">
                    <div [innerHtml]="recruitmentData.interviewData3.description | safeHtml"></div>
                  </div>
                  <!-- waiting talent to accept / reschedule -->
                  <div *ngIf="recruitmentData.interviewData3.scheduleStatus === interviewStatus.invitationSent"
                    class="button-group">
                    <div class="btn-rounded bg-primary-soft" (click)="openModal('accept-meeting', null)">
                      <label>
                        <i class="bi bi-check-circle">
                        </i>
                        Accept Meeting
                      </label>
                    </div>
                    <div *ngIf="!userProfile?.isEnigmaTalent" class="btn-border-rounded btn-border-primary-soft"
                      (click)="openModal('reschedule-meeting', null)">
                      <label>
                        <i class="bi bi-calendar2-x">
                        </i>
                        Request schedule
                      </label>
                    </div>
                  </div>
                  <ng-container *ngIf="recruitmentData.lastStep === 'interview' && recruitmentData.interviewData3.invitationLink &&
                  (!recruitmentData.interviewData3.status || recruitmentData.interviewData3.status === 'RESCHEDULE')">
                    <div class="flex-row">
                      <div (click)="openLink(recruitmentData.interviewData3.invitationLink)"
                        class="btn-rounded bg-primary-soft">
                        <label>
                          <i class="bi bi-link-45deg">
                          </i>
                          Access Meeting
                        </label>
                      </div>
                      <div (click)="copyText(recruitmentData.interviewData3.invitationLink, 'Meeting link')"
                        class="btn-border-rounded btn-border-primary-soft">
                        <label>
                          <i class="bi bi-clipboard">
                          </i>
                          Copy link
                        </label>
                      </div>
                    </div>
                  </ng-container>
                  <button
                    *ngIf="!recruitmentData.interviewData3.invitationLink && recruitmentData.interviewData3.scheduleStatus === interviewStatus.accepted"
                    disabled type="button" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                    Interview link not available yet
                  </button>
                </ng-container>
                <button *ngIf="!recruitmentData.interviewData3" disabled type="button"
                  class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                  Interview has not setup yet
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="activeStep === 'on-boarding'"> <!-- as talent -->
      <ng-container>
        <div class="test3">Onboarding</div>
        <div class="card">
          <div class="div">
            <div class="card2">
              <div class="desc">
                <ng-container *ngIf="recruitmentData.onBoardingData">
                  <div class="deadline">
                    <div class="deadline2">{{recruitmentData.onBoardingData.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                      recruitmentData.onBoardingData.time }}
                      {{recruitmentData.onBoardingData.timezone}}
                    </div>
                    <ng-container *ngIf="!recruitmentData.onBoardingData?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.onBoardingData.invitationStatus === interviewStatus.accepted">
                        <label>Accepted</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.onBoardingData.invitationStatus === interviewStatus.reschedule">
                        <label>Reschedule</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="recruitmentData.onBoardingData?.status">
                      <div class="tag-job-status bg-success-soft"
                        *ngIf="recruitmentData.onBoardingData?.status === 'APPROVED'">
                        <label>Passed</label>
                      </div>
                      <div class="tag-job-status bg-danger-soft"
                        *ngIf="recruitmentData.onBoardingData?.status === 'REJECTED'">
                        <label>Rejected</label>
                      </div>
                      <div class="tag-job-status bg-warning-soft"
                        *ngIf="recruitmentData.onBoardingData?.status === 'INVITATION'">
                        <label>Invitation</label>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="isPendingReschedule('INTERVIEW')" class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.onBoardingData.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at
                      {{
                      recruitmentData.onBoardingData.latestTime}} -
                      {{recruitmentData.onBoardingData.latestTimezone}}
                    </div>
                  </div>
                  <div class="on-boarding-desc">
                    <div [innerHtml]="recruitmentData.onBoardingData.message | safeHtml"></div>
                  </div>
                  <div class="form-group-label-input">
                    <div class="form-label-input">
                      Location
                    </div>
                    <div class="onboarding-office">
                      <label> {{ getOnboardingOffice() }}</label>
                    </div>
                  </div>
                  <!-- <div *ngIf="recruitmentData.onBoardingData.status === 'INVITATION'" (click)="talentAcceptOnboardingSchedule()" class="btn-rounded bg-primary-soft">
                    <label>
                      Accept onboarding time
                    </label>
                  </div> -->
                </ng-container>
                <button *ngIf="!recruitmentData.onBoardingData" disabled type="button"
                  class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                  Onboarding has not setup yet
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- login as talent -->
    <ng-container *ngIf="activeStep === 'nda'">
      <div class="test3">NDA</div>
      <div class="card">
        <div class="div">
          <div class="card2">
            <div class="desc">
              <div class="tag-wrapper">
                <div class="tag-job-status bg-success-soft" *ngIf="recruitmentData.ndaData.talentNdaLink">
                  <label> Your signed NDA has submitted</label>
                </div>
                <div class="tag-job-status bg-danger-soft"
                  *ngIf="!recruitmentData.ndaData.talentNdaLink && recruitmentData.ndaData.corporateNdaLink">
                  <label> Waiting to sign NDA</label>
                </div>
              </div>
              <div class="on-boarding-desc">
                NDA is a legal agreement between two or more parties that stipulates that
                certain information shared will remain confidential. Commonly used in business
                settings, it&#039;s a way to protect sensitive information, trade secrets, or
                proprietary knowledge from being disclosed to others or the public. It&#039;s
                often used when businesses enter into negotiations, partnerships, or when
                hiring new employees who will have access to confidential information.
              </div>
              <div *ngIf="recruitmentData.ndaData && recruitmentData.ndaData.talentNdaLink"
                class="uploaded-nda-section">
                <label> Uploaded Signed NDA: </label>
                <a target="_blank" [href]="recruitmentData.ndaData.talentNdaLink">Preview</a>
              </div>
              <div class="button-group">
                <div *ngIf="!recruitmentData.ndaData || !recruitmentData.ndaData.corporateNdaLink"
                  class="btn-border-rounded btn-border-danger-soft">
                  <label>
                    This company has not uploaded NDA
                  </label>
                </div>
                <div *ngIf="recruitmentData.ndaData && recruitmentData.ndaData.corporateNdaLink"
                  (click)="openLink(recruitmentData.ndaData.corporateNdaLink)" class="btn-rounded bg-success-soft">
                  <label>
                    <i class="bi bi-download">
                    </i>
                    Download Company NDA
                  </label>
                </div>
                <div
                  *ngIf="recruitmentData.ndaData && recruitmentData.ndaData.corporateNdaLink && !recruitmentData.ndaData.talentNdaLink"
                  class="btn-rounded bg-primary-soft" (click)="openModal('upload-nda-form', 'approved')">
                  <label>
                    <i class="bi bi-upload"></i>
                    {{ isUploadingFile ? 'Uploading ...': 'Upload'}}
                  </label>
                  <span *ngIf="isUploadingFile" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeStep === 'welcome'"> <!-- login as talent -->
      <div class="test3">Welcome</div>
      <div class="card">
        <div class="div">
          <div class="card2">
            <div class="desc">
              <div class="on-boarding-desc">
                <div *ngIf="recruitmentData.welcomeData && recruitmentData.welcomeData.welcomeNote"
                  [innerHtml]="recruitmentData.welcomeData.welcomeNote | safeHtml"></div>
                <div *ngIf="!recruitmentData.welcomeData || !recruitmentData.welcomeData.welcomeNote"
                  [innerHtml]="getDefaultWelcomeText()| safeHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="action" *ngIf="!userProfile?.isEnigmaTalent && !isRejectedApplicant()">
      <div class="btn-border-rounded btn-border-danger-soft bg-white-danger" (click)="openModal('resign-application', null)">
        <label>
          <i class="bi bi-link-45deg">
          </i>
          Resign Application
        </label>
      </div>
    </div>
    <popup-confirmation id="resign-application">
      <div class="popup popup-danger">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <div class="popup-title-label popup-danger">
              Wait! You are about to resign this application
            </div>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <div class="form-group-label-input rejected-form">
          <div class="form-label-input">
            Tell us why
          </div>
          <div class="radio-input-group">
            <input (click)="onRejectedReasonClick()" type="radio" name="showAs" id="rejectedReason"
              class="ec-form-check-input-sm" value="I got hired somewhere else."
              [checked]="rejectedReason.value === 'I got hired somewhere else.'"
              (click)="rejectedReason.setValue('I got hired somewhere else.')">
            <label for="rejectedReason" class="ec-form-check-label">I got hired somewhere else.</label>
          </div>
          <div class="radio-input-group">
            <input (click)="onRejectedReasonClick()" type="radio" name="showAs" id="rejectedReason"
              class="ec-form-check-input-sm" value="It’s not for me."
              [checked]="rejectedReason.value === 'It’s not for me.'"
              (click)="rejectedReason.setValue('It’s not for me.')">
            <label for="rejectedReason" class="ec-form-check-label">It’s not for me.</label>
          </div>
          <div class="radio-input-group">
            <input type="radio" name="showAs" id="rejectedReason" class="ec-form-check-input-sm" value="other"
              [checked]="rejectedReason.value === 'other'" (click)="rejectedReason.setValue('other')">
            <label for="rejectedReason" class="ec-form-check-label">Other</label>
          </div>
          <div class="textarea-input-group">
            <textarea [disabled]="rejectedReason.value != 'other'" [(ngModel)]="notes"
              class="ec-form-control-rounded ec-danger" placeholder="Leave a note"></textarea>
            <div>
              <div class="invalid-feedback-text" *ngIf="getWordCount(null) > 250">
                Notes length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} words
              </div>
              <div class="text-right">
                <label>{{ getWordCount(null) }}/{{ 'TEXT_AREA_SMALL' | maxLength }} words </label>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-button">
          <button [disabled]="!rejectedReason.value || getWordCount(null) > 250" type="button"
            (click)="resignApplication()" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, I want to reject
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
    <popup-confirmation id="reschedule-meeting">
      <div class="popup popup-primary">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <div class="popup-title-label popup-primary">
              Request Change Schedule
            </div>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Request the date
          </div>
          <input [(ngModel)]="rescheduledDate" type="date" id="rescheduledDate"
            class="ec-form-control-rounded ec-is-invalid" placeholder="Input the rejected reason" [min]="minDate">
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Time
          </div>
          <input [(ngModel)]="rescheduledTime" type="time" id="rescheduledTime"
            class="ec-form-control-rounded ec-is-invalid" placeholder="Input the rejected reason">
        </div>
        <div class="popup-button">
          <button type="button" [disabled]="!rescheduledDate || !rescheduledTime" (click)="rescheduleMeeting()"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, Reschedule
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
    <popup-confirmation id="accept-meeting">
      <div class="popup popup-primary">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <div class="popup-title-label popup-primary">
              Accept Meeting
            </div>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <label style="color: #141414;">The meeting is on</label>
        <ng-container
          *ngIf="recruitmentData.interviewData && recruitmentData.interviewData.scheduleStatus === interviewStatus.invitationSent">
          <div class="deadline">
            <div class="deadline2">{{recruitmentData.interviewData.date | date: 'EEEE, dd MMMM YYYY'}} at {{
              recruitmentData.interviewData.time }} {{recruitmentData.interviewData.timezone}}</div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="recruitmentData.interviewData2 && recruitmentData.interviewData2.scheduleStatus === interviewStatus.invitationSent">
          <div class="deadline">
            <div class="deadline2">{{recruitmentData.interviewData2.date | date: 'EEEE, dd MMMM YYYY'}} at {{
              recruitmentData.interviewData2.time }} {{recruitmentData.interviewData2.timezone}}</div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="recruitmentData.interviewData3 && recruitmentData.interviewData3.scheduleStatus === interviewStatus.invitationSent">
          <div class="deadline">
            <div class="deadline2">{{recruitmentData.interviewData3.date | date: 'EEEE, dd MMMM YYYY'}} at {{
              recruitmentData.interviewData3.time }} {{recruitmentData.interviewData3.timezone}}</div>
          </div>
        </ng-container>
        <div class="popup-button">
          <button type="button" (click)="acceptMeeting()"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Accept
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
  </ng-container>
  <ng-container *ngIf="loginAs === 'CORPORATE' && recruitmentData">
    <ng-container *ngIf="activeStep === 'test'">
      <div class="test3">Test</div>
      <div class="card">
        <div class="div">
          <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
          <ng-container *ngIf="isPlacementOwner">
            <div *ngIf="!isTechnicalTestExist" class="card2">
              <technical-test-form [onBoardingId]="onBoardingId" [jobId]="recruitmentData.jobId" style="width: 100%;"
                [formType]="'recruitment'"></technical-test-form>
            </div>
            <div *ngIf="isTechnicalTestExist" class="card2">

              <div class="desc3">
                <div class="on-boarding-desc">
                  <div [innerHTML]="recruitmentData.testData.description | safeHtml"></div>
                </div>
                <div class="deadline">
                  <div class="deadline2">Deadline</div>
                  <div class="time">
                    <svg class="calendar-2-week-fill" width="16" height="17" viewBox="0 0 16 17" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.5 0.5C3.77614 0.5 4 0.723858 4 1V1.5H12V1C12 0.723858 12.2239 0.5 12.5 0.5C12.7761 0.5 13 0.723858 13 1V1.5H14C15.1046 1.5 16 2.39543 16 3.5V14.5C16 15.6046 15.1046 16.5 14 16.5H2C0.895431 16.5 0 15.6046 0 14.5V3.5C0 2.39543 0.895431 1.5 2 1.5H3V1C3 0.723858 3.22386 0.5 3.5 0.5ZM13.4545 3.5H2.54545C2.24421 3.5 2 3.72386 2 4V5C2 5.27614 2.24421 5.5 2.54545 5.5H13.4545C13.7558 5.5 14 5.27614 14 5V4C14 3.72386 13.7558 3.5 13.4545 3.5ZM8.5 7.5C8.22386 7.5 8 7.72386 8 8V9C8 9.27614 8.22386 9.5 8.5 9.5H9.5C9.77614 9.5 10 9.27614 10 9V8C10 7.72386 9.77614 7.5 9.5 7.5H8.5ZM11.5 7.5C11.2239 7.5 11 7.72386 11 8V9C11 9.27614 11.2239 9.5 11.5 9.5H12.5C12.7761 9.5 13 9.27614 13 9V8C13 7.72386 12.7761 7.5 12.5 7.5H11.5ZM3 11V12C3 12.2761 3.22386 12.5 3.5 12.5H4.5C4.77614 12.5 5 12.2761 5 12V11C5 10.7239 4.77614 10.5 4.5 10.5H3.5C3.22386 10.5 3 10.7239 3 11ZM6.5 10.5C6.22386 10.5 6 10.7239 6 11V12C6 12.2761 6.22386 12.5 6.5 12.5H7.5C7.77614 12.5 8 12.2761 8 12V11C8 10.7239 7.77614 10.5 7.5 10.5H6.5Z"
                        fill="#757575" />
                    </svg>
                    <div class="_14-05-2024">{{ recruitmentData.testData.deadline | date: 'dd/MM/YYYY'}}</div>
                  </div>
                </div>
                <ng-container *ngIf="recruitmentData.testData.testType === 1">
                  <div class="btn-rounded bg-primary-soft" (click)="openLink(recruitmentData.testData.link)">
                    <label>
                      <i class="bi bi-download">
                      </i>
                      Download file
                    </label>
                  </div>
                </ng-container>
                <ng-container *ngIf="recruitmentData.testData.testType === 2">
                  <div class="btn-rounded bg-primary-soft" (click)="openLink(recruitmentData.testData.link)">
                    <label>
                      <i class="bi bi-link-45deg">
                      </i>
                      See link
                    </label>
                  </div>
                </ng-container>
              </div>
              <svg class="breaker" width="1" preserveAspectRatio="none" viewBox="0 0 1 179" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="4" x2="0.499993" y2="175" stroke="#C2C2C2" />
              </svg>
              <div class="desc2">
                <div class="tag-wrapper">
                  <ng-container *ngIf="recruitmentData.testData.answerLink">
                    <div class="tag-job-status bg-success-soft">
                      <label>Technical test answer has submitted</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!recruitmentData.testData.answerLink">
                    <div class="tag-job-status bg-warning-soft">
                      <label>Waiting talent to answer technical test</label>
                    </div>
                  </ng-container>
                </div>
                <div class="no-file-uploaded"> Test Result :
                  <a *ngIf="recruitmentData.testData.answerLink" target="_blank"
                    [href]="recruitmentData.testData.answerLink">
                    Preview
                  </a>
                  <label *ngIf="!recruitmentData.testData.answerLink"> - </label>
                </div>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="isPlacementOwner && recruitmentData.lastStep === 'test' && !isRejectedApplicant()">
        <!-- corpo -->
        <div class="action">
          <div class="btn-rounded bg-danger-soft" (click)="openModal('technical-test-form', 'rejected')">
            <label>
              <i class="bi bi-remove">
              </i>
              Reject
            </label>
          </div>
          <div *ngIf="recruitmentData.testData && recruitmentData.testData.answerLink"
            class="btn-rounded bg-primary-soft" (click)="openModal('technical-test-form', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- loginAs === 'CORPORATE' -->
    <ng-container *ngIf="activeStep === 'interview'">
      <div class="test3">1st Interview</div>
      <div class="card">
        <div class="div">
          <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
          <div *ngIf="isPlacementOwner && !isInterviewTestExist" class="card2">
            <interview-form [interviewType]="'interview-1'" [onBoardingId]="onBoardingId"
              style="width: 100%;"></interview-form>
          </div>
          <div *ngIf="isPlacementOwner && isInterviewTestExist" class="card2">
            <div class="desc">
              <div class="deadline">
                <div class="deadline2">{{recruitmentData.interviewData.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                  recruitmentData.interviewData.time }} {{ recruitmentData.interviewData.timezone }}</div>
                <ng-container *ngIf="!recruitmentData.interviewData?.status">
                  <div class="tag-job-status bg-success-soft"
                    *ngIf="recruitmentData.interviewData.scheduleStatus === interviewStatus.accepted && !recruitmentData.interviewData?.invitationLink">
                    <label>Accepted</label>
                  </div>
                  <div class="tag-job-status bg-warning-soft"
                    *ngIf="recruitmentData.interviewData.scheduleStatus === interviewStatus.invitationSent">
                    <label>Invitation sent</label>
                  </div>
                  <div class="tag-job-status bg-danger-soft" *ngIf="isPendingReschedule('INTERVIEW')">
                    <label>Reschedule</label>
                  </div>
                </ng-container>
                <ng-container *ngIf="recruitmentData.interviewData?.status">
                  <div class="tag-job-status bg-success-soft"
                    *ngIf="recruitmentData.interviewData?.status === 'APPROVED'">
                    <label>Passed</label>
                  </div>
                  <div class="tag-job-status bg-danger-soft"
                    *ngIf="recruitmentData.interviewData?.status === 'REJECTED'">
                    <label>Rejected</label>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="!recruitmentData.interviewData?.status || recruitmentData.interviewData?.status ==='RESCHEDULE'">
                  <div class="tag-job-status bg-warning-soft" *ngIf="recruitmentData.interviewData?.invitationLink">
                    <label>Meeting Invitation Sent</label>
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="isPendingReschedule('INTERVIEW-1')">
                <div class="reschedule-section">
                  <label>Rescheduled time is on approval: </label>
                  <div class="reschedule-time">{{recruitmentData.interviewData.latestDate | date: 'EEEE, dd MMMM YYYY'}}
                    at {{
                    recruitmentData.interviewData.latestTime}} - {{recruitmentData.interviewData.latestTimezone}}</div>
                </div>
                <div class="btn-rounded bg-danger-soft" disabled (click)="rejectRescheduledTime()">
                  <label>
                    <i class="bi bi-calendar-x-fill">
                    </i>
                    Reject Rescheduled Time
                  </label>
                </div>
                <div class="btn-rounded bg-primary-soft" disabled (click)="acceptRescheduledTime()">
                  <label>
                    <i class="bi bi-calendar-check-fill">
                    </i>
                    Accept Rescheduled Time
                  </label>
                </div>
              </ng-container>
              <div class="on-boarding-desc">
                <div [innerHtml]="recruitmentData.interviewData.description | safeHtml"></div>
              </div>
              <ng-container *ngIf="isAbleToSetMeetingLink('firstInterview')">
                <div class="form-group-label-input">
                  <div class="form-label-input" required>
                    Meeting Link
                  </div>
                  <div class="ec-input-group-rounded input-text-space">
                    <span class="ec-input-group-text">
                      <i class="bi bi-link-45deg"></i>
                    </span>
                    <input type="text" [formControl]="meetingLink" class="ec-form-control-rounded ec-is-invalid"
                      placeholder="https://links.com">
                    <div class="ec-invalid-feedback" *ngIf="meetingLink.touched && meetingLink.hasError('required')">
                      Meeting Link is required
                    </div>
                    <div class="ec-invalid-feedback" *ngIf="meetingLink.touched && meetingLink.hasError('pattern')">
                      Meeting Link should be a valid url
                    </div>
                  </div>
                </div>
                <div *ngIf="recruitmentData.lastStep ==='interview'
                && (!recruitmentData.interviewData.status || recruitmentData.interviewData.status === 'RESCHEDULE')"
                  class="btn-rounded bg-primary-soft" disabled (click)="setMeetingLink('interview-1')">
                  <label>
                    <i class="bi bi-link-45deg">
                    </i>
                    Set Meeting Link
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="recruitmentData.interviewData2
      || (recruitmentData.lastStep === 'interview' && recruitmentData.interviewData?.status === 'APPROVED')">
        <div class="test3">2nd Interview</div>
        <div class="card">
          <div class="div">
            <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
            <div *ngIf="isPlacementOwner && recruitmentData.lastStep === 'interview'
              && !recruitmentData.interviewData2" class="card2">
              <interview-form [interviewType]="'interview-2'" [onBoardingId]="onBoardingId"
                style="width: 100%;"></interview-form>
            </div>
            <div *ngIf="isPlacementOwner && recruitmentData.interviewData2" class="card2">
              <div class="desc">
                <div class="deadline">
                  <div class="deadline2">{{recruitmentData.interviewData2.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                    recruitmentData.interviewData2.time }} {{ recruitmentData.interviewData2.timezone }}</div>
                  <ng-container *ngIf="!recruitmentData.interviewData2?.status">
                    <div class="tag-job-status bg-success-soft"
                      *ngIf="recruitmentData.interviewData2.scheduleStatus === interviewStatus.accepted && !recruitmentData.interviewData2?.invitationLink">
                      <label>Accepted</label>
                    </div>
                    <div class="tag-job-status bg-warning-soft"
                      *ngIf="recruitmentData.interviewData2.scheduleStatus === interviewStatus.invitationSent">
                      <label>Invitation sent</label>
                    </div>
                    <div class="tag-job-status bg-danger-soft" *ngIf="isPendingReschedule('INTERVIEW-2')">
                      <label>Reschedule</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="recruitmentData.interviewData2?.status">
                    <div class="tag-job-status bg-success-soft"
                      *ngIf="recruitmentData.interviewData2?.status === 'APPROVED'">
                      <label>Passed</label>
                    </div>
                    <div class="tag-job-status bg-danger-soft"
                      *ngIf="recruitmentData.interviewData2?.status === 'REJECTED'">
                      <label>Rejected</label>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="!recruitmentData.interviewData2?.status || recruitmentData.interviewData2?.status ==='RESCHEDULE'">
                    <div class="tag-job-status bg-warning-soft" *ngIf="recruitmentData.interviewData2?.invitationLink">
                      <label>Meeting Invitation Sent</label>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="isPendingReschedule('INTERVIEW-2')">
                  <div class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.interviewData2.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at {{
                      recruitmentData.interviewData2.latestTime}} - {{recruitmentData.interviewData2.latestTimezone}}
                    </div>
                  </div>
                  <div class="btn-rounded bg-danger-soft" disabled (click)="rejectRescheduledTime()">
                    <label>
                      <i class="bi bi-calendar-x-fill">
                      </i>
                      Reject Rescheduled Time
                    </label>
                  </div>
                  <div class="btn-rounded bg-primary-soft" disabled (click)="acceptRescheduledTime()">
                    <label>
                      <i class="bi bi-calendar-check-fill">
                      </i>
                      Accept Rescheduled Time
                    </label>
                  </div>
                </ng-container>
                <div class="on-boarding-desc">
                  <div [innerHtml]="recruitmentData.interviewData2.description | safeHtml"></div>
                </div>
                <ng-container *ngIf="isAbleToSetMeetingLink('secondInterview')">
                  <div class="form-group-label-input">
                    <div class="form-label-input" required>
                      Meeting Link
                    </div>
                    <div class="ec-input-group-rounded input-text-space">
                      <span class="ec-input-group-text">
                        <i class="bi bi-link-45deg"></i>
                      </span>
                      <input type="text" [formControl]="meetingLink2" class="ec-form-control-rounded ec-is-invalid"
                        placeholder="https://links.com">
                      <div class="ec-invalid-feedback"
                        *ngIf="meetingLink2.touched && meetingLink2.hasError('required')">
                        Meeting Link is required
                      </div>
                      <div class="ec-invalid-feedback" *ngIf="meetingLink2.touched && meetingLink2.hasError('pattern')">
                        Meeting Link should be a valid url
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="recruitmentData.lastStep ==='interview'
                  && (!recruitmentData.interviewData2.status || recruitmentData.interviewData2.status === 'RESCHEDULE')"
                    class="btn-rounded bg-primary-soft" disabled (click)="setMeetingLink('interview-2')">
                    <label>
                      <i class="bi bi-link-45deg">
                      </i>
                      Set Meeting Link
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="recruitmentData.interviewData3
      || (recruitmentData.lastStep === 'interview' && recruitmentData.interviewData2?.status === 'APPROVED')">
        <div class="test3">3rd Interview</div>
        <div class="card">
          <div class="div">
            <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
            <div *ngIf="isPlacementOwner && recruitmentData.lastStep === 'interview'
            && !recruitmentData.interviewData3" class="card2">
              <interview-form [interviewType]="'interview-3'" [onBoardingId]="onBoardingId"
                style="width: 100%;"></interview-form>
            </div>
            <div *ngIf="isPlacementOwner && recruitmentData.interviewData3" class="card2">
              <div class="desc">
                <div class="deadline">
                  <div class="deadline2">{{recruitmentData.interviewData3.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                    recruitmentData.interviewData3.time }} {{ recruitmentData.interviewData3.timezone }}</div>
                  <ng-container *ngIf="!recruitmentData.interviewData3?.status">
                    <div class="tag-job-status bg-success-soft"
                      *ngIf="recruitmentData.interviewData3.scheduleStatus === interviewStatus.accepted && !recruitmentData.interviewData3?.invitationLink">
                      <label>Accepted</label>
                    </div>
                    <div class="tag-job-status bg-warning-soft"
                      *ngIf="recruitmentData.interviewData3.scheduleStatus === interviewStatus.invitationSent">
                      <label>Invitation sent</label>
                    </div>
                    <div class="tag-job-status bg-danger-soft" *ngIf="isPendingReschedule('INTERVIEW-3')">
                      <label>Reschedule</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="recruitmentData.interviewData3?.status">
                    <div class="tag-job-status bg-success-soft"
                      *ngIf="recruitmentData.interviewData3?.status === 'APPROVED'">
                      <label>Passed</label>
                    </div>
                    <div class="tag-job-status bg-danger-soft"
                      *ngIf="recruitmentData.interviewData3?.status === 'REJECTED'">
                      <label>Rejected</label>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="!recruitmentData.interviewData3?.status || recruitmentData.interviewData3?.status ==='RESCHEDULE'">
                    <div class="tag-job-status bg-warning-soft" *ngIf="recruitmentData.interviewData3?.invitationLink">
                      <label>Meeting Invitation Sent</label>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="isPendingReschedule('INTERVIEW-3')">
                  <div class="reschedule-section">
                    <label>Rescheduled time is on approval: </label>
                    <div class="reschedule-time">{{recruitmentData.interviewData3.latestDate | date: 'EEEE, dd MMMM
                      YYYY'}}
                      at {{
                      recruitmentData.interviewData3.latestTime}} - {{recruitmentData.interviewData3.latestTimezone}}
                    </div>
                  </div>
                  <div class="btn-rounded bg-danger-soft" disabled (click)="rejectRescheduledTime()">
                    <label>
                      <i class="bi bi-calendar-x-fill">
                      </i>
                      Reject Rescheduled Time
                    </label>
                  </div>
                  <div class="btn-rounded bg-primary-soft" disabled (click)="acceptRescheduledTime()">
                    <label>
                      <i class="bi bi-calendar-check-fill">
                      </i>
                      Accept Rescheduled Time
                    </label>
                  </div>
                </ng-container>
                <div class="on-boarding-desc">
                  <div [innerHtml]="recruitmentData.interviewData3.description | safeHtml"></div>
                </div>
                <ng-container *ngIf="isAbleToSetMeetingLink('thirdInterview')">
                  <div class="form-group-label-input">
                    <div class="form-label-input" required>
                      Meeting Link
                    </div>
                    <div class="ec-input-group-rounded input-text-space">
                      <span class="ec-input-group-text">
                        <i class="bi bi-link-45deg"></i>
                      </span>
                      <input type="text" [formControl]="meetingLink3" class="ec-form-control-rounded ec-is-invalid"
                        placeholder="https://links.com">
                      <div class="ec-invalid-feedback"
                        *ngIf="meetingLink3.touched && meetingLink3.hasError('required')">
                        Meeting Link is required
                      </div>
                      <div class="ec-invalid-feedback" *ngIf="meetingLink3.touched && meetingLink3.hasError('pattern')">
                        Meeting Link should be a valid url
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="recruitmentData.lastStep ==='interview'
                  && (!recruitmentData.interviewData3.status || recruitmentData.interviewData3.status === 'RESCHEDULE')"
                    class="btn-rounded bg-primary-soft" disabled (click)="setMeetingLink('interview-3')">
                    <label>
                      <i class="bi bi-link-45deg">
                      </i>
                      Set Meeting Link
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="action">
        <!-- for interview 1 -->
        <ng-container *ngIf="isPlacementOwner && !isRejectedApplicant() && recruitmentData.lastStep === 'interview' && recruitmentData.interviewData &&
          (!recruitmentData.interviewData.status || recruitmentData.interviewData.status === 'RESCHEDULE')
          ">
          <div class="btn-rounded bg-danger-soft" (click)="openModal('interview-test-form', 'rejected')">
            <label>
              <i class="bi bi-remove">
              </i>
              Reject
            </label>
          </div>
          <div *ngIf="recruitmentData.interviewData && recruitmentData.interviewData.invitationLink"
            class="btn-rounded bg-primary-soft" (click)="openModal('interview-test-form', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </ng-container>
        <!-- for interview 2 -->
        <ng-container *ngIf="isPlacementOwner && !isRejectedApplicant() && recruitmentData.interviewData2 &&
          (!recruitmentData.interviewData2.status || recruitmentData.interviewData2.status === 'RESCHEDULE')
          ">
          <div class="btn-rounded bg-danger-soft" (click)="openModal('interview-test-form', 'rejected')">
            <label>
              <i class="bi bi-remove">
              </i>
              Reject
            </label>
          </div>
          <div class="btn-rounded bg-primary-soft" (click)="openModal('interview-test-form', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </ng-container>
        <!-- for interview 3 -->
        <ng-container *ngIf="isPlacementOwner && !isRejectedApplicant() && recruitmentData.interviewData3 &&
          (!recruitmentData.interviewData3.status || recruitmentData.interviewData3.status === 'RESCHEDULE')
          ">
          <div class="btn-rounded bg-danger-soft" (click)="openModal('interview-test-form', 'rejected')">
            <label>
              <i class="bi bi-remove">
              </i>
              Reject
            </label>
          </div>
          <div class="btn-rounded bg-primary-soft" (click)="openModal('interview-test-form', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="activeStep === 'on-boarding' && !recruitmentData.uncompletedSteps.includes('on-boarding')">
      <div class="test3">Onboarding</div>
      <div class="card">
        <div class="div">
          <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
          <div class="card2" *ngIf="isPlacementOwner && !hasSetOnboardingData()">
            <on-boarding-form [onBoardingId]="onBoardingId" style="width: 100%;"></on-boarding-form>
          </div>
          <div *ngIf="isPlacementOwner && hasSetOnboardingData()" class="card2">
            <div class="desc">
              <div class="deadline">
                <div class="deadline2">{{recruitmentData.onBoardingData.date | date: 'EEEE, dd MMMM YYYY'}} at {{
                  recruitmentData.onBoardingData.time }} {{recruitmentData.onBoardingData.timezone}}</div>
                <div class="tag-job-status bg-success-soft"
                  *ngIf="recruitmentData.onBoardingData.status === 'ACCEPTED'">
                  <label>Accepted</label>
                </div>
                <div class="tag-job-status bg-warning-soft"
                  *ngIf="recruitmentData.onBoardingData.status === 'INVITATION'">
                  <label>Invitation sent</label>
                </div>
                <div class="tag-job-status bg-danger-soft"
                  *ngIf="recruitmentData.onBoardingData.status === 'RESCHEDULE'">
                  <label>Reschedule</label>
                </div>
              </div>
              <div class="deadline">
                <div class="on-boarding-desc">
                  <div [innerHtml]="recruitmentData.onBoardingData.message | safeHtml"></div>
                </div>
                <i *ngIf="recruitmentData.lastStep === 'on-boarding'" class="bi bi-pencil-fill edit-button"
                  (click)="openModal('edit-onboarding-form', 'approved')"></i>
              </div>
              <div class="form-group-label-input">
                <div class="form-label-input">
                  Location
                </div>
                <div class="onboarding-office">
                  <label> {{ getOnboardingOffice() }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action">
        <ng-container
          *ngIf="isPlacementOwner && !isRejectedApplicant() && recruitmentData.lastStep === 'on-boarding' && hasSetOnboardingData()">
          <div class="btn-rounded bg-danger-soft" (click)="openModal('move-next-placement', 'rejected')">
            <label>
              <i class="bi bi-remove">
              </i>
              Reject
            </label>
          </div>
          <div class="btn-rounded bg-primary-soft" (click)="openModal('move-next-placement', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- loginAs === 'CORPORATE' -->
    <ng-container *ngIf="activeStep === 'nda'">
      <div class="test3">NDA</div>
      <div class="card">
        <div class="div">
          <placement-assignee [isPlacementOwner]="isPlacementOwner"></placement-assignee>
          <div class="card2">
            <div class="desc">
              <div class="on-boarding-desc">
                *Importance of NDAs : Safeguarding sensitive information, fostering trust in professional relationships,
                providing legal protection, maintaining competitive advantages, enabling safe collaborations, and
                protecting personal privacy.
              </div>
              <div *ngIf="recruitmentData.ndaData && recruitmentData.ndaData.corporateNdaLink"
                class="uploaded-nda-section">
                <label> Uploaded Corporate NDA: </label>
                <a class="nda-file" target="_blank" [href]="recruitmentData.ndaData.corporateNdaLink">Preview</a>
                <svg *ngIf="!recruitmentData.ndaData.talentNdaLink" (click)="openModal('remove-corporate-nda', null)"
                  class="trash-3" width="16" height="17" viewBox="0 0 16 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.5 1.5H9.5C9.77614 1.5 10 1.72386 10 2V3H6V2C6 1.72386 6.22386 1.5 6.5 1.5ZM11 3V2C11 1.17157 10.3284 0.5 9.5 0.5H6.5C5.67157 0.5 5 1.17157 5 2V3H2.50566C2.50226 2.99997 2.49885 2.99997 2.49544 3H1.5C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H2.0384L2.89116 14.6595C2.97431 15.6989 3.84207 16.5 4.88479 16.5H11.1152C12.1579 16.5 13.0257 15.6989 13.1088 14.6595L13.9616 4H14.5C14.7761 4 15 3.77614 15 3.5C15 3.22386 14.7761 3 14.5 3H13.5046C13.5011 2.99997 13.4977 2.99997 13.4943 3H11ZM12.9584 4L12.112 14.5797C12.0704 15.0994 11.6366 15.5 11.1152 15.5H4.88479C4.36343 15.5 3.92955 15.0994 3.88798 14.5797L3.0416 4H12.9584ZM5.47064 5.00086C5.74631 4.98465 5.98292 5.19497 5.99914 5.47064L6.49914 13.9706C6.51535 14.2463 6.30503 14.4829 6.02936 14.4991C5.7537 14.5154 5.51708 14.305 5.50086 14.0294L5.00086 5.52936C4.98465 5.2537 5.19497 5.01708 5.47064 5.00086ZM10.5294 5.00086C10.805 5.01708 11.0154 5.2537 10.9991 5.52936L10.4991 14.0294C10.4829 14.305 10.2463 14.5154 9.97064 14.4991C9.69497 14.4829 9.48465 14.2463 9.50086 13.9706L10.0009 5.47064C10.0171 5.19497 10.2537 4.98465 10.5294 5.00086ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V14C8.5 14.2761 8.27614 14.5 8 14.5C7.72386 14.5 7.5 14.2761 7.5 14V5.5C7.5 5.22386 7.72386 5 8 5Z"
                    fill="#CB3A31" />
                </svg>
              </div>
              <div class="button-group">
                <div *ngIf="recruitmentData.ndaData && recruitmentData.ndaData.talentNdaLink"
                  class="btn-border-rounded btn-border-primary-soft"
                  (click)="openLink(recruitmentData.ndaData.talentNdaLink)">
                  <label>
                    <i class="bi bi-download">
                    </i>
                    Download Signed Talent NDA
                  </label>
                </div>
                <div *ngIf="!recruitmentData.ndaData.talentNdaLink" class="btn-rounded bg-primary-soft"
                  (click)="openModal('upload-nda-form', 'approved')">
                  <label>
                    <i class="bi bi-upload"></i>
                    {{ isUploadingFile ? 'Uploading ...': 'Upload'}}
                  </label>
                  <span *ngIf="isUploadingFile" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action">
        <ng-container *ngIf="eligibleMoveTo('welcome')">
          <div class="btn-rounded bg-primary-soft" (click)="openModal('move-next-placement', 'approved')">
            <label>
              Accept & Continue
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="activeStep === 'welcome'">
      <div class="test3">Welcome</div>
      <div class="card">
        <div class="div">
          <placement-assignee *ngIf="!isPlacementOwner" [isPlacementOwner]="isPlacementOwner"></placement-assignee>
          <div *ngIf="isPlacementOwner" class="card2">
            <div class="desc">
              <div class="deadline">
                <ng-container *ngIf="!isOnEditWelcomeSection">
                  <div *ngIf="!recruitmentData.welcomeData || !recruitmentData.welcomeData.welcomeNote"
                    class="on-boarding-desc">
                    <div [innerHtml]="getDefaultWelcomeText() | safeHtml"></div>
                  </div>
                  <div *ngIf="recruitmentData.welcomeData && recruitmentData.welcomeData.welcomeNote"
                    [innerHtml]="recruitmentData.welcomeData.welcomeNote | safeHtml"></div>
                  <i class="bi bi-pencil-fill edit-button" (click)="onEditWelcomeSection()"></i>
                </ng-container>
                <ng-container *ngIf="isOnEditWelcomeSection">
                  <form style="width: 100%;" [formGroup]="welcomeForm" (keydown.enter)="$event.preventDefault()">
                    <div style="display: flex; flex-direction: column; width: 100%;">
                      <div class="textarea-input-group">
                        <div class="form-label-input">
                          Welcome Text
                        </div>
                        <div class="input-text-space">
                          <ckeditor id="description" name="description" [editor]="Editor"
                            [disableTwoWayDataBinding]="false" formControlName="description"> </ckeditor>
                          <div class="text-right">
                            <label>{{ getWordCount('welcomeForm') }}/{{ 'TEXT_AREA_LARGE' | maxLength }} words
                            </label>
                          </div>
                          <div class="invalid-feedback-text"
                            *ngIf="welcomeForm.get('description')?.touched && welcomeForm.get('description')?.hasError('required')">
                            Welcome Text is required
                          </div>
                          <div class="invalid-feedback-text"
                            *ngIf="welcomeForm.get('description')?.touched && welcomeForm.get('description')?.hasError('textAreaMaxLength')">
                            Welcome Text length maximum {{ 'TEXT_AREA_LARGE' | maxLength }} words
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
                        <button type="button" (click)="saveWelomeData()"
                          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isPlacementOwner && recruitmentData.lastStep === 'welcome' && recruitmentData.status === 1">
        <!-- corpo -->
        <div class="action">
          <div class="btn-rounded bg-primary-soft" (click)="openModal('move-next-placement', 'approved')">
            <label>
              Mark as complete
              <i class="bi bi-arrow-right">
              </i>
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <popup-confirmation id="reschedule-meeting">
      <div class="popup popup-primary">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <div class="popup-title-label popup-primary">
              Request Change Schedule
            </div>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Request the date
          </div>
          <input [(ngModel)]="rescheduledDate" type="date" id="rescheduledDate"
            class="ec-form-control-rounded ec-is-invalid" placeholder="Input the rejected reason" [min]="minDate">
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Time
          </div>
          <input [(ngModel)]="rescheduledTime" type="time" id="rescheduledTime"
            class="ec-form-control-rounded ec-is-invalid" placeholder="Input the rejected reason">
        </div>
        <div class="popup-button">
          <button type="button" [disabled]="!rescheduledDate || !rescheduledTime" (click)="rescheduleMeeting()"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, Reschedule
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
    <popup-confirmation id="technical-test-form">
      <div class="popup-md" [class]="modalFormType === 'approved' ? 'popup-primary': 'popup-danger'">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <ng-container *ngIf="modalFormType === 'approved'">
              <div class="popup-title-label popup-primary">
                You are about to move forward wih this talent!
              </div>
            </ng-container>
            <ng-container *ngIf="modalFormType === 'rejected'">
              <div class="popup-title-label popup-danger">
                Wait! You are about to decline a talent!
              </div>
            </ng-container>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <label *ngIf="modalFormType === 'approved'" style="color: #4888d3;">Help us improve by giving a valuation to
          this talent!</label>
        <label *ngIf="modalFormType === 'rejected'" style="color: #CB3A31;">Help us improve by giving a valuation to
          this talent!</label>
        <form style="width: 100%;" [formGroup]="technicalTestApprovalForm" (keydown.enter)="$event.preventDefault()">
          <div class="form-group-label-input">
            <div class="form-label-input">
              Proficiency
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-bar-graph-fill"></i>
              </span>
              <select type="text" formControlName="skillLevel" class="ec-form-select-rounded">
                <option value="" disabled selected>Skill Level</option>
                <option *ngFor="let skillLevel of skillLevels" [value]="skillLevel.value">{{ skillLevel.value
                  }}
                </option>
              </select>
            </div>
            <div
              *ngIf="technicalTestApprovalForm.get('skillLevel')?.touched && !technicalTestApprovalForm.get('skillLevel')?.value"
              class="invalid-feedback-text">
              Proficiency is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Problem Solving Skill
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-bar-graph"></i>
              </span>
              <select type="text" formControlName="solvingSkill" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Score</option>
                <option *ngFor="let solvingSkill of solvingSkills" [value]="solvingSkill.value">{{
                  solvingSkill.value
                  }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="technicalTestApprovalForm.get('solvingSkill')?.touched && technicalTestApprovalForm.get('solvingSkill')?.hasError('required')">
              Solving Skill is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Hands-On task (If-applicable)
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-earmark-bar-graph"></i>
              </span>
              <select type="text" formControlName="handsOn" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Score</option>
                <option *ngFor="let solvingSkill of solvingSkills" [value]="solvingSkill.value">{{
                  solvingSkill.value
                  }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="technicalTestApprovalForm.get('handsOn')?.touched && technicalTestApprovalForm.get('handsOn')?.hasError('required')">
              Hands-On task is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Overall score
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-earmark-medical"></i>
              </span>
              <input type="number" formControlName="overallScore" class="ec-form-control-rounded ec-is-invalid"
                placeholder="75">
            </div>
            <div class="invalid-feedback-text "
              *ngIf="technicalTestApprovalForm.get('overallScore')?.touched && technicalTestApprovalForm.get('overallScore')?.hasError('required')">
              Overall score is required
            </div>
            <div class="invalid-feedback-text "
              *ngIf="technicalTestApprovalForm.get('overallScore')?.touched && technicalTestApprovalForm.get('overallScore')?.hasError('max')">
              Maximum Overall score is 100
            </div>
            <div class="invalid-feedback-text "
              *ngIf="technicalTestApprovalForm.get('overallScore')?.touched && technicalTestApprovalForm.get('overallScore')?.hasError('min')">
              Maximum Overall score is 1
            </div>
          </div>
          <div class="textarea-input-group">
            <div class="form-label-input">
              Notes
            </div>
            <textarea formControlName="notes" class="ec-form-control-rounded" placeholder="Leave a note"></textarea>
            <div>
              <div class="invalid-feedback-text" *ngIf="getWordCount('technicalTestApprovalForm') > 250">
                Notes length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} words
              </div>
              <div class="invalid-feedback-text"
                *ngIf="technicalTestApprovalForm.get('notes')?.touched && technicalTestApprovalForm.get('notes')?.hasError('required')">
                Notes is required
              </div>
              <div class="text-right">
                <label>{{ getWordCount('technicalTestApprovalForm') }}/{{ 'TEXT_AREA_SMALL' | maxLength }} words
                </label>
              </div>
            </div>
          </div>
        </form>
        <div class="popup-button">
          <button type="submit" [disabled]="!technicalTestApprovalForm.valid" *ngIf="modalFormType === 'approved'"
            (click)="onTechnicalTestSubmit('approved')"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, Move forward
          </button>
          <button type="submit" [disabled]="!technicalTestApprovalForm.valid" *ngIf="modalFormType === 'rejected'"
            (click)="onTechnicalTestSubmit('rejected')"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, I want to reject
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
    <popup-confirmation id="interview-test-form">
      <div class="popup-md" [class]="modalFormType === 'approved' ? 'popup-primary': 'popup-danger'">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <ng-container *ngIf="modalFormType === 'approved'">
              <div class="popup-title-label popup-primary">
                You are about to move forward wih this talent!
              </div>
            </ng-container>
            <ng-container *ngIf="modalFormType === 'rejected'">
              <div class="popup-title-label popup-danger">
                Wait! You are about to decline a talent!
              </div>
            </ng-container>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <label *ngIf="modalFormType === 'approved'" style="color: #4888d3;">Help us improve by giving a valuation to
          this talent!</label>
        <label *ngIf="modalFormType === 'rejected'" style="color: #CB3A31;">Help us improve by giving a valuation to
          this talent!</label>
        <form style="width: 100%;" [formGroup]="interviewTestApprovalForm" (keydown.enter)="$event.preventDefault()">
          <div class="form-group-label-input">
            <div class="form-label-input">
              Recruit type
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-bar-graph-fill"></i>
              </span>
              <select type="text" formControlName="recruitType" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Recruit type</option>
                <option *ngFor="let recruitType of recruitTypeList" [value]="recruitType.value">{{ recruitType.value
                  }}
                </option>
              </select>
            </div>
            <div
              *ngIf="interviewTestApprovalForm.get('recruitType')?.touched && !interviewTestApprovalForm.get('recruitType')?.value"
              class="invalid-feedback-text">
              Recruit type is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Attitude
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-bar-graph"></i>
              </span>
              <select type="text" formControlName="attitude" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Attitude</option>
                <option *ngFor="let attitude of solvingSkills" [value]="attitude.value">{{
                  attitude.value
                  }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="interviewTestApprovalForm.get('attitude')?.touched && interviewTestApprovalForm.get('attitude')?.hasError('required')">
              Attitude is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Communication Skill
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-earmark-bar-graph"></i>
              </span>
              <select type="text" formControlName="communication" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Communication</option>
                <option *ngFor="let communication of solvingSkills" [value]="communication.value">{{
                  communication.value
                  }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="interviewTestApprovalForm.get('communication')?.touched && interviewTestApprovalForm.get('communication')?.hasError('required')">
              Communication Skill is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Expeience & Skill
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-file-earmark-bar-graph"></i>
              </span>
              <select type="text" formControlName="experience" class="ec-form-select-rounded">
                <option value="" disabled selected>Choose Experience</option>
                <option *ngFor="let experience of solvingSkills" [value]="experience.value">{{
                  experience.value
                  }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="interviewTestApprovalForm.get('experience')?.touched && interviewTestApprovalForm.get('experience')?.hasError('required')">
              Expeience & Skill is required
            </div>
          </div>
          <div class="textarea-input-group">
            <div class="form-label-input">
              Notes
            </div>
            <textarea formControlName="notes" class="ec-form-control-rounded" placeholder="Leave a note"></textarea>
            <div>
              <div class="invalid-feedback-text" *ngIf="getWordCount('interviewTestApprovalForm') > 250">
                Notes length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} words
              </div>
              <div class="invalid-feedback-text"
                *ngIf="interviewTestApprovalForm.get('notes')?.touched && interviewTestApprovalForm.get('notes')?.hasError('required')">
                Notes is required
              </div>
              <div class="text-right">
                <label>{{ getWordCount('interviewTestApprovalForm') }}/{{ 'TEXT_AREA_SMALL' | maxLength }} words
                </label>
              </div>
            </div>
          </div>
        </form>
        <div style="display: flex; gap: 5px; align-items: center;">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill="#4888D3" />
            <path
              d="M5.25511 5.78615C5.24752 5.92237 5.3599 6.03271 5.49634 6.03271H6.32082C6.45889 6.03271 6.56868 5.92013 6.58723 5.78331C6.67618 5.12718 7.1265 4.64893 7.92922 4.64893C8.61477 4.64893 9.24318 4.9917 9.24318 5.81689C9.24318 6.45166 8.86867 6.74365 8.27834 7.18799C7.60549 7.67676 7.07229 8.24805 7.11037 9.1748L7.11334 9.39161C7.11521 9.52833 7.22658 9.63818 7.36332 9.63818H8.17434C8.31241 9.63818 8.42434 9.52625 8.42434 9.38818V9.28271C8.42434 8.56543 8.69729 8.35596 9.43361 7.79736C10.043 7.33398 10.6778 6.81982 10.6778 5.74072C10.6778 4.22998 9.40188 3.5 8.00539 3.5C6.73831 3.5 5.34964 4.09061 5.25511 5.78615ZM6.81203 11.5488C6.81203 12.082 7.23732 12.4756 7.82131 12.4756C8.43068 12.4756 8.84963 12.082 8.84963 11.5488C8.84963 10.9966 8.43068 10.6094 7.82131 10.6094C7.23732 10.6094 6.81203 10.9966 6.81203 11.5488Z"
              fill="#4888D3" />
          </svg>
          <label *ngIf="modalFormType === 'approved'" style="color: #4888d3;">You can do up to 3 interviews!</label>
        </div>
        <ng-container *ngIf="modalFormType === 'approved'">
          <div class="popup-button2">
            <button type="submit" [disabled]="!interviewTestApprovalForm.valid"
              (click)="onInterviewTestSubmit('approved', false)"
              class="button-save ec-btn ec-btn-sm ec-btn-outline-primary ec-btn-rounded mb-2 me-2">
              Set-Up Next interview
            </button>

            <button type="submit" [disabled]="!interviewTestApprovalForm.valid"
              (click)="onInterviewTestSubmit('approved', true)"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Yes, Onboard talent
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
          <div style="display: flex; justify-content: center; width: 100%;">
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              Cancel
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="modalFormType === 'rejected'">
          <div class="popup-button">
            <button type="submit" [disabled]="!interviewTestApprovalForm.valid"
              (click)="onInterviewTestSubmit('rejected', false)"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Yes, I want to reject
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              Cancel
            </button>
          </div>
        </ng-container>

      </div>
    </popup-confirmation>
    <popup-confirmation id="edit-onboarding-form">
      <div class="popup-lg" [class]="modalFormType === 'approved' ? 'popup-primary': 'popup-danger'">
        <div class="popup-frame-1673">
          <div class="popup-title">
            <ng-container *ngIf="modalFormType === 'approved'">
              <div class="popup-title-label popup-primary">
                Change Schedule
              </div>
            </ng-container>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <form style="width: 100%;" id="onboardingForm" [formGroup]="onboardingForm"
          (keydown.enter)="$event.preventDefault()">
          <div class="form-group-label-input">
            <div class="form-label-input">
              Date
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-calendar2-week-fill"></i>
              </span>
              <input class="ec-form-control-rounded ec-is-invalid" formControlName="onboardingDate" type="date"
                [min]="minDate">
              <div class="ec-invalid-feedback"
                *ngIf="onboardingForm.controls.onboardingDate.touched && onboardingForm.controls.onboardingDate.hasError('required')">
                Onboarding date is required
              </div>
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Time
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-clock-fill"></i>
              </span>
              <input class="ec-form-control-rounded ec-is-invalid" formControlName="onboardingTime" type="time">
              <div class="ec-invalid-feedback"
                *ngIf="onboardingForm.controls.onboardingTime.touched && onboardingForm.controls.onboardingTime.hasError('required')">
                Onboarding time is required
              </div>
            </div>
          </div>
          <div class="textarea-input-group">
            <div class="form-label-input">
              Description
            </div>
            <div class="input-text-space">
              <ckeditor id="description" name="description" [editor]="Editor" [disableTwoWayDataBinding]="false"
                formControlName="description"> </ckeditor>
              <div class="text-right">
                <label>{{ getWordCount('onboardingForm') }}/{{ 'TEXT_AREA_LARGE' | maxLength }} words </label>
              </div>
              <div class="invalid-feedback-text"
                *ngIf="onboardingForm.get('description')?.touched && onboardingForm.get('description')?.hasError('required')">
                Description is required
              </div>
              <div class="invalid-feedback-text"
                *ngIf="onboardingForm.get('description')?.touched && onboardingForm.get('description')?.hasError('textAreaMaxLength')">
                Description length maximum {{ 'TEXT_AREA_LARGE' | maxLength }} words
              </div>
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Location
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-pin-map-fill"></i>
              </span>
              <select type="text" formControlName="officeId" class="ec-form-select-rounded">
                <option selected disabled value="">Select Location</option>
                <option *ngFor="let branch of branchList" [value]="branch.id">{{ branch.name }} - {{ branch.timezone }}
                </option>
              </select>
            </div>
            <div class="invalid-feedback-text "
              *ngIf="interviewTestApprovalForm.get('communication')?.touched && interviewTestApprovalForm.get('communication')?.hasError('required')">
              Communication Skill is required
            </div>
          </div>
        </form>
        <div class="popup-button">
          <button type="submit" [disabled]="!onboardingForm.valid" (click)="updateOnboardingMeeting()"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Update
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
    <popup-confirmation id="move-next-placement">
      <div class="popup-lg" [class]="modalFormType === 'approved' ? 'popup-primary': 'popup-danger'">
        <div class="popup-frame-1673">
          <div class="popup-title" *ngIf="recruitmentData.lastStep != 'welcome'">
            <ng-container *ngIf="modalFormType === 'approved'">
              <div class="popup-title-label popup-primary">
                Are you sure want to move to the next placement ?
              </div>
            </ng-container>
            <ng-container *ngIf="modalFormType === 'rejected'">
              <div class="popup-title-label popup-dabger">
                Are you sure want to reject this talent ?
              </div>
            </ng-container>
          </div>
          <div class="popup-title" *ngIf="recruitmentData.lastStep === 'welcome'">
            <ng-container *ngIf="modalFormType === 'approved'">
              <div class="popup-title-label popup-primary">
                Are you sure you want to complete this placement?
              </div>
            </ng-container>
            <ng-container *ngIf="modalFormType === 'rejected'">
              <div class="popup-title-label popup-dabger">
                Are you sure want to reject this talent ?
              </div>
            </ng-container>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <ng-container *ngIf="modalFormType === 'rejected'">
          <div class="popup-button">
            <button (click)="moveToNextPlacement('REJECTED')"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Yes, I want to reject
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              Cancel
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="modalFormType === 'approved'">
          <div class="popup-button">
            <button (click)="moveToNextPlacement('APPROVED')"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Yes, move forward
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              Cancel
            </button>
          </div>
        </ng-container>
      </div>
    </popup-confirmation>
    <popup-confirmation id="remove-corporate-nda">
      <div class="popup-md" style="padding: 15px 20px;" class="popup-danger">
        <div class="popup-frame-1673" style="margin-bottom: 20px;">
          <div class="popup-title">
            <div class="popup-title-label popup-danger">
              Are you sure want to delete NDA ?
            </div>
          </div>
          <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
              fill="#757575" />
          </svg>
        </div>
        <div class="popup-button">
          <button (click)="removeNDA()" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Yes, I want to delete
          </button>
          <button type="button" (click)="onCloseModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
            Cancel
          </button>
        </div>
      </div>
    </popup-confirmation>
  </ng-container>
  <popup-confirmation id="upload-nda-form">
    <div class="popup-md" [class]="modalFormType === 'approved' ? 'popup-primary': 'popup-danger'">
      <div class="popup-frame-1673">
        <div class="popup-title">
          <ng-container *ngIf="modalFormType === 'approved'">
            <div class="popup-title-label popup-primary">
              Non-Disclosure Agreement!
            </div>
          </ng-container>
        </div>
        <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div style="display: flex; flex-direction: row; gap: 10px">
        <recruitment-svg [step]="'nda-upload'"></recruitment-svg>
        <div class="upload-nda-section">
          <div (click)="selectNDAFile()" class="btn-border-rounded btn-border-primary-soft">
            <label *ngIf="loginAs === 'CORPORATE'">
              <i class="bi bi-upload">
              </i>
              Select NDA file
            </label>
            <label *ngIf="loginAs === 'TALENT'">
              <i class="bi bi-upload">
              </i>
              Select signed NDA file
            </label>
            <span *ngIf="isUploadingFile" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            <input type="file" style="display: none;" id="nda" class="input-file" (change)="onNDAFileSelected($event)"
              accept=".pdf">
          </div>
          <div *ngIf="!selectedNDAFileName" class="text-grey">
            (No file selected)
          </div>
          <div *ngIf="selectedNDAFileName" class="text-grey">
            Selected file: {{ selectedNDAFileName }}
          </div>

          <div class="text-grey">
            Please make sure that :
            The file is in .pdf format and
            The size doesn’t exceed 10 MB
          </div>
        </div>
      </div>
      <div>
        *Importance of NDAs : Safeguarding sensitive information, fostering trust in professional relationships,
        providing legal protection, maintaining competitive advantages, enabling safe collaborations, and protecting
        personal privacy.
      </div>
      <div class="popup-button">
        <button (click)="uploadNDA()" type="button" [disabled]="!isNDAFileValid"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
        </button>
        <button type="button" (click)="onCloseModal()"
          class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
          Cancel
        </button>
      </div>
    </div>
  </popup-confirmation>
</div>
