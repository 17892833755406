import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { SubNavbar } from '@interfaces/corporate.interface';
import { CorporateService } from '@services/corporate.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit {
  public isLoading = false;
  public errorMessage = '';

  constructor(
    private talentService: TalentService,
    private corporateService: CorporateService,
    private storeService: StoreService,
    private router: Router,
    private toastManager: ToastManager,
  ) {
    this.storeService.showSubNavbar();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try {
      this.setActiveSubNavbar();
      await this.talentService.getProfile();
      await this.corporateService.getCompanyProfile();
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private setActiveSubNavbar() {
    const [_, parentPath, subNavbar] = this.router.url.split("/");
    if (parentPath === 'corporate') {
      this.storeService.setActiveSubNavbar(subNavbar as SubNavbar)
      return;
    }
    this.storeService.setActiveSubNavbar('company-profile')
  }
}
