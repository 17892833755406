// Angular modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard, talentGuard } from '@guards/auth.guard';
import { ResumeComponent } from './resume/resume.component';
import { TalentComponent } from './talent.component';
import { PreferenceComponent } from './preference/preference.component';
import { NotificationComponent } from './notification/notification.component';
import { talentProfileResolver } from 'src/app/shared/resolvers/talent-profile.resolver';

const routes: Routes = [
  {
    path: '',
    component: TalentComponent,
    canActivate: [authGuard, talentGuard],
    resolve: {
      talentProfile: talentProfileResolver
    },
    data: {
      role: 'TALENT'
    },
    children: [
      {
        path: '',
        component: ResumeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'resume',
        component: ResumeComponent,
        canActivate: [authGuard, talentGuard],
      },
      {
        path: 'applications',
        loadChildren: () => import('../applied-job/applied-job.module').then(m => m.AppliedJobModule),
        canActivate: [authGuard, talentGuard],
      },
      {
        path: 'saved-jobs',
        loadChildren: () => import('../saved-job/saved-job.module').then(m => m.SavedJobModule),
        canActivate: [authGuard, talentGuard],
      },
      {
        path: 'placements',
        loadChildren: () => import('../on-boarding/on-boarding.module').then(m => m.OnBoardingModule),
        canActivate: [authGuard, talentGuard],
      },
      {
        path: 'preferences',
        component: PreferenceComponent,
        canActivate: [authGuard, talentGuard],
      },
      {
        path: 'find-job',
        loadChildren: () => import('../find-job/find-job.module').then(m => m.FindJobModule),
      },
      {
        path: 'notification',
        component: NotificationComponent,
        canActivate: [authGuard, talentGuard],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TalentRoutingModule { }
