import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { TalentService } from '@services/talent.service';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-skill-form',
  templateUrl: './skill-form.component.html',
  styleUrls: ['../../../talent/about/about.component.scss']
})
export class SkillFormComponent implements AfterViewInit {
  public formType = 'NEW';
  public hardSkills: string[] = [];
  public softSkills: string[] = [];
  public isLoading = false;
  public newHardSkill: string = '';
  public newSoftSkill: string = '';
  public hardSkillInvalidMessage = '';
  public softSkillInvalidMessage = '';

  constructor
    (
      private talentService: TalentService,
      private dialogRef: MatDialogRef<SkillFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data) {
      const hardSkills = modalData?.data.hardSkills.map((item: any) => item.skill);
      const softSkills = modalData?.data.softSkills.map((item: any) => item.skill);
      this.hardSkills = hardSkills
      this.softSkills = softSkills
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public addHardSkill() {
    const isHardSkillLimitExceeded = this.hardSkills.length > MAX_LENGTH.SKILL;
    const isExist = this.hardSkills.includes(this.newHardSkill);
    if (!isHardSkillLimitExceeded && !isExist && this.newHardSkill.trim() !== '') {
      const isAlphaNumericValue = ALPHANUMERIC_PATTERN.test(this.newHardSkill);
      if (!isAlphaNumericValue) {
        this.toastManager.showError(null, 'Hard skill should be alpha numeric only and minimum 3 characters')
        return;
      }

      this.hardSkills.push(this.newHardSkill.trim());
      this.newHardSkill = '';
    }
  }

  public addSoftSkill() {
    const isSoftSkillLimitExceeded = this.softSkills.length > MAX_LENGTH.SKILL;
    const isExist = this.softSkills.includes(this.newSoftSkill);
    if (!isSoftSkillLimitExceeded && !isExist && this.newSoftSkill.trim() !== '') {
      const isAlphaNumericValue = ALPHANUMERIC_PATTERN.test(this.newSoftSkill);
      if (!isAlphaNumericValue) {
        this.toastManager.showError(null, 'Soft skill should be alpha numeric only and minimum 3 characters')
        return;
      }

      this.softSkills.push(this.newSoftSkill.trim());
      this.newSoftSkill = '';
    }
  }


  public removeSkill(skill: string, type: string) {
    if (type === 'hard') {
      const index = this.hardSkills.indexOf(skill);
      if (index !== -1) {
        this.hardSkills.splice(index, 1);
      }
    }

    if (type === 'soft') {
      const index = this.softSkills.indexOf(skill);
      if (index !== -1) {
        this.softSkills.splice(index, 1);
      }
    }
  }

  public isHardSkillValid = () => {
    if (this.hardSkills.length === 0) {
      this.hardSkillInvalidMessage = 'Hard skills is required'
      return false
    }

    if (this.hardSkills.length > MAX_LENGTH.SKILL) {
      this.hardSkillInvalidMessage = `Hard skills length maximum ${MAX_LENGTH.SKILL} skills`
      return false
    }

    return true
  }

  public isSoftSkillValid = () => {
    if (this.softSkills.length > MAX_LENGTH.SKILL) {
      this.softSkillInvalidMessage = `Soft skills length maximum ${MAX_LENGTH.SKILL} skills`
      return false
    }

    return true
  }

  public async editSkill(): Promise<void> {
    try {
      this.isLoading = true;
      await this.talentService.editSkill(this.hardSkills, this.softSkills);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Skill saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
