import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';


@Pipe({
  name: 'timeRange'
})
export class TimeRangePipe implements PipeTransform {

  transform(data: any): string {
    const isPresent = data.stillAttend || data.isOngoing;
    const startDate = new Date(data.startDate);
    let endDate = new Date(data.endDate);
    if (isPresent) {
      endDate = new Date()
    }
    const startYear = new Date(startDate).getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();

    let yearDiff = endYear - startYear;
    let monthDiff = endMonth - startMonth;

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    const yearLabel = yearDiff === 1 ? 'Year' : 'Year(s)';
    const monthLabel = monthDiff === 1 ? 'Month' : 'Month(s)';

    if (yearDiff > 0 && monthDiff > 0) {
      return `${yearDiff} ${yearLabel} ${monthDiff} ${monthLabel}`;
    } else if (yearDiff > 0) {
      return `${yearDiff} ${yearLabel}`;
    } else if (monthDiff > 0) {
      return `${monthDiff} ${monthLabel}`;
    } else {
      return 'Under a month';
    }
  }
}
