<app-spinner *ngIf="isLoading"></app-spinner>
<div *ngIf="!isLoading" class="navigation">
  <div class="content">
    <corporate-navbar></corporate-navbar>
    <router-outlet></router-outlet>
  </div>
  <div class="dash-footer">
    <div class="footer-text">
      Enigma Camp - Olympus © 2024. All rights reserved </div>
  </div>
</div>
