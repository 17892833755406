// Angular modules
import { Component } from '@angular/core';

// Services
import { ToastManager } from './toast.manager';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  constructor(public toastManager: ToastManager) { }

  getFillByType(type: string): string {
    if (type === 'info') {
      return '#284b74';
    } else if (type === 'warning') {
      return '#8c6500';
    } else {
      return '#70201b';
    }
  }
}
