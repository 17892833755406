// Angular modules
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';

// Internal modules
import { environment } from '@env/environment';
import { StorageHelper } from '@helpers/storage.helper';
import { JobService } from '@services/job.service';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'talent-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class TalentHeaderComponent implements OnInit {
  public appName: string = environment.appName;
  public isMenuCollapsed: boolean = true;

  constructor(
    private router: Router,
    private googleService: SocialAuthService,
    private storeService: StoreService,
    private jobService: JobService,
    private toastManager: ToastManager,
    ) {
    this.router = router;
  }

  public ngOnInit(): void {

  }

  // -------------------------------------------------------------------------------
  // NOTE Init ---------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async onClickLogout(): Promise<void> {
    StorageHelper.removeGoogleState();
    StorageHelper.removeAccessToken();
    StorageHelper.removeRefreshToken();
    const loginType = StorageHelper.getLoginType();
    if (loginType === 'GOOGLE') {
      try {
        await this.googleService.signOut();

      } catch (error: any) {
        this.toastManager.showError('Upload failed', error.message)
      }
    }
    this.storeService.setLogOut(true);
    this.jobService.resetSubscibedData();
    this.router.navigate(['/auth/login']);
  }

  // -------------------------------------------------------------------------------
  // NOTE Computed props -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Helpers ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Requests -----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Subscriptions ------------------------------------------------------------
  // -------------------------------------------------------------------------------

}
