import { Component, Input } from '@angular/core';
import { Account } from '@interfaces/corporate.interface';

@Component({
  selector: 'card-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent {
  @Input() accounts!: Array<Partial<Account>>;
  @Input() manageAccountCallback: Function | undefined;

  triggerCallback() {
    if (this.manageAccountCallback) {
      this.manageAccountCallback();
    }
  }
}
