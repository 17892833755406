// Angular modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard, corporateGuard } from '@guards/auth.guard';
import { CorporateComponent } from './corporate.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { AccountComponent } from './account/account.component';
import { WishComponent } from './wish/wish.component';
import { JobPostedComponent } from '@pages/job/job-posted/job-posted.component';
import { CorporateJobDetailComponent } from '@pages/job/corporate-job-detail/corporate-job-detail.component';
import { OnBoardingComponent } from '@pages/on-boarding/on-boarding.component';
import { JobTemplateComponent } from '@pages/job/job-template/job-template.component';
import { NotificationComponent } from './notification/notification.component';

const routes: Routes = [
  {
    path: '',
    component: CorporateComponent,
    canActivate: [authGuard, corporateGuard],
    data: {
      role: 'CORPORATE'
    },
    children: [
      {
        path: '',
        component: CompanyProfileComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'company-profile',
        component: CompanyProfileComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'job',
        component: JobPostedComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'job-template',
        component: JobTemplateComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'job/:jobId',
        component: CorporateJobDetailComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'on-boarding/:jobId',
        component: OnBoardingComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'wish',
        component: WishComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'notification',
        component: NotificationComponent,
        canActivate: [authGuard, corporateGuard],
      },
      {
        path: 'find-talent',
        loadChildren: () => import('../find-talent/find-talent.module').then(m => m.FindTalentModule),
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateRoutingModule { }
