import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Talent, TalentListType } from '@interfaces/talent.interface';

@Component({
  selector: 'app-talent-card',
  templateUrl: './talent-card.component.html',
  styleUrls: ['./talent-card.component.scss']
})
export class TalentCardComponent {

  @Input() talent!: Talent;
  @Input() talentListType!: TalentListType;
  @Input() isSelected: boolean = false;
  @Output() cardSelected = new EventEmitter<void>();

  ngOnInit() {
  }
  onClickDetailButton() {
    this.cardSelected.emit();
  }
}
