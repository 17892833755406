<div>
  <div class="form-container">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="form-title">
      <div class="title-content">
        <div class="title-text">
          About </div>
        <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="break-line">
      </div>
    </div>
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <form class="form-body" [formGroup]="companyProfileForm" (ngSubmit)="editAbout()"
      (keydown.enter)="$event.preventDefault()">
      <div class="form">
        <div class="input-text-space">
          <ckeditor id="about" name="about" [editor]="Editor" [disableTwoWayDataBinding]="false"
            formControlName="about"> </ckeditor>
          <div class="text-right">
            <label>{{ getWordCount() }}/{{ 'TEXT_AREA_SMALL' | maxLength }} words </label>
          </div>
          <div class="invalid-feedback-text"
            *ngIf="companyProfileForm.get('about')?.touched && companyProfileForm.get('about')?.hasError('required')">
            About is required
          </div>
          <div class="invalid-feedback-text"
            *ngIf="companyProfileForm.get('about')?.touched && companyProfileForm.get('about')?.hasError('textAreaMaxLength')">
            About length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} words
          </div>
        </div>
        <div class="break-line">
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Industry
          </div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-building-fill"></i>
            </span>
            <select type="text" formControlName="industry" class="ec-form-select-rounded">
              <option value="" disabled selected>Industry</option>
              <option *ngFor="let industry of industries" [value]="industry.value">{{ industry.value
                }}
              </option>
            </select>
          </div>
          <div *ngIf="companyProfileForm.get('industry')?.touched && !companyProfileForm.get('industry')?.value"
            class="invalid-feedback-text">
            Industry is required
          </div>
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Company Size
          </div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-people-fill"></i>
            </span>
            <select type="text" formControlName="companySizeRange" class="ec-form-select-rounded">
              <option value="" disabled selected>Company Size</option>
              <option *ngFor="let companySize of companySizeRangeList" [value]="companySize.value">{{ companySize.value
                }}
              </option>
            </select>
          </div>
          <div class="invalid-feedback-text "
            *ngIf="companyProfileForm.get('companySizeRange')?.touched && companyProfileForm.get('companySizeRange')?.hasError('required')">
            Company Size is required
          </div>
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Company Website
          </div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-link-45deg"></i>
            </span>
            <input type="text" formControlName="companySite" class="ec-form-control-rounded ec-is-invalid"
              placeholder="Company Website">
            <div class="ec-invalid-feedback"
              *ngIf="companyProfileForm.get('companySite')?.touched && companyProfileForm.get('companySite')?.hasError('required')">
              Company Website is required
            </div>
            <div class="ec-invalid-feedback"
              *ngIf="companyProfileForm.get('companySite')?.touched && companyProfileForm.get('companySite')?.hasError('maxlength') && !companyProfileForm.get('companySite')?.hasError('pattern')">
              Company Website length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} characters
            </div>
            <div class="ec-invalid-feedback"
              *ngIf="companyProfileForm.get('companySite')?.touched && companyProfileForm.get('companySite')?.hasError('pattern')">
              Company Website should be a valid url
            </div>
          </div>
        </div>
        <div class="form-group-label-input">
          <div class="form-label-input">
            Main Office
          </div>
          <div class="main-branch-wrapper">
            <div class="form-group-label-input">
              <div class="form-label-input">
                Office Name
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-buildings"></i>
                </span>
                <input type="text" formControlName="mainOfficeName" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Head Office">
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeName')?.touched && companyProfileForm.get('mainOfficeName')?.hasError('required')">
                  Main office name is required
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeName')?.touched && companyProfileForm.get('mainOfficeName')?.hasError('maxlength') && !companyProfileForm.get('mainOfficeName')?.hasError('pattern')">
                  Main office length maximum {{ 'INPUT' | maxLength }} characters
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeName')?.touched && companyProfileForm.get('mainOfficeName')?.hasError('pattern')">
                  Main office name should alphanumeric only and minimum 3 characters
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Country
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-pin-map-fill"></i>
                </span>
                <select type="text" formControlName="mainOfficeCountry" class="ec-form-select-rounded">
                  <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}
                  </option>
                </select>
              </div>
              <div class="invalid-feedback-text"
                *ngIf="companyProfileForm.get('country')?.touched && companyProfileForm.get('country')?.hasError('required')">
                Country is required
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                City
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-pin-map-fill"></i>
                </span>
                <input type="text" formControlName="mainOfficeCity" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Head Office">
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeCity')?.touched && companyProfileForm.get('mainOfficeCity')?.hasError('required')">
                  City name is required
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeCity')?.touched && companyProfileForm.get('mainOfficeCity')?.hasError('maxlength') && !companyProfileForm.get('mainOfficeCity')?.hasError('pattern')">
                  City name length maximum {{ 'INPUT' | maxLength }} characters
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="companyProfileForm.get('mainOfficeCity')?.touched && companyProfileForm.get('mainOfficeCity')?.hasError('pattern')">
                  City name should alphanumeric only and minimum 3 characters
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Set Time Zone
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-clock-fill"></i>
                </span>
                <select type="text" formControlName="mainOfficeTimezone" class="ec-form-select-rounded">
                  <option *ngFor="let timezone of timezoneList" [value]="timezone.text">{{ timezone.text }}
                  </option>
                </select>
              </div>
              <div class="invalid-feedback-text"
                *ngIf="companyProfileForm.get('timezone')?.touched && companyProfileForm.get('timezone')?.hasError('required')">
                Timezone is required
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="branchEntries" style="width: 100%">
          <div *ngFor="let entry of branchEntries.controls; let i = index" [formGroupName]="i">
            <div class="form-group-label-input">
              <div class="form-label-input">
                Office
              </div>
              <div class="main-branch-wrapper">
                <div class="form-group-label-input">
                  <div class="form-label-input">
                    Office Name
                  </div>
                  <div class="ec-input-group-rounded input-text-space">
                    <span class="ec-input-group-text">
                      <i class="bi bi-buildings"></i>
                    </span>
                    <input type="text" formControlName="name" class="ec-form-control-rounded ec-is-invalid"
                      placeholder="Office Name">
                    <div class="ec-invalid-feedback"
                      *ngIf="companyProfileForm.get('name')?.touched && companyProfileForm.get('name')?.hasError('required')">
                      Office name is required
                    </div>
                    <div class="ec-invalid-feedback"
                      *ngIf="branchEntries.controls[i]?.get('name')?.touched && branchEntries.controls[i]?.get('name')?.hasError('maxlength')  && !branchEntries.controls[i]?.get('name')?.hasError('pattern')">
                      Office name length maximum {{ 'INPUT' | maxLength }} characters
                    </div>
                    <div class="ec-invalid-feedback"
                      *ngIf="branchEntries.controls[i]?.get('name')?.touched && branchEntries.controls[i]?.get('name')?.hasError('pattern')">
                      Office name should alphanumeric only and minimum 3 characters
                    </div>
                  </div>
                </div>
                <div class="form-group-label-input">
                  <div class="form-label-input">
                    Country
                  </div>
                  <div class="ec-input-group-rounded input-text-space">
                    <span class="ec-input-group-text">
                      <i class="bi bi-pin-map-fill"></i>
                    </span>
                    <select type="text" formControlName="country" class="ec-form-select-rounded">
                      <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="invalid-feedback-text"
                    *ngIf="branchEntries.controls[i].get('country')?.touched && branchEntries.controls[i].get('country')?.hasError('required')">
                    Country is required
                  </div>
                </div>
                <div class="form-group-label-input">
                  <div class="form-label-input">
                    City
                  </div>
                  <div class="ec-input-group-rounded input-text-space">
                    <span class="ec-input-group-text">
                      <i class="bi bi-pin-map-fill"></i>
                    </span>
                    <input type="text" formControlName="city" class="ec-form-control-rounded ec-is-invalid"
                      placeholder="City">
                    <div class="ec-invalid-feedback"
                      *ngIf="branchEntries.controls[i].get('city')?.touched && branchEntries.controls[i].get('city')?.hasError('required')">
                      City name is required
                    </div>
                    <div class="ec-invalid-feedback"
                      *ngIf="branchEntries.controls[i].get('city')?.touched && branchEntries.controls[i].get('city')?.hasError('maxlength') && !companyProfileForm.get('city')?.hasError('pattern')">
                      City name length maximum {{ 'INPUT' | maxLength }} characters
                    </div>
                    <div class="ec-invalid-feedback"
                      *ngIf="branchEntries.controls[i].get('city')?.touched && branchEntries.controls[i].get('city')?.hasError('pattern')">
                      City name should alphanumeric only and minimum 3 characters
                    </div>
                  </div>
                </div>
                <div class="form-group-label-input">
                  <div class="form-label-input">
                    Set Time Zone
                  </div>
                  <div class="ec-input-group-rounded input-text-space">
                    <span class="ec-input-group-text">
                      <i class="bi bi-clock-fill"></i>
                    </span>
                    <select type="text" formControlName="timezone" class="ec-form-select-rounded">
                      <option *ngFor="let timezone of timezoneList" [value]="timezone.text">{{ timezone.text }}
                      </option>
                    </select>
                  </div>
                  <div class="invalid-feedback-text"
                    *ngIf="branchEntries.controls[i].get('timezone')?.touched && branchEntries.controls[i].get('timezone')?.hasError('required')">
                    Timezone is required
                  </div>
                </div>
                <button type="button" (click)="removeBranchEntry(i)"
                  class="ec-btn ec-btn-sm ec-btn-outline-danger ec-btn-rounded mb-2 me-2">
                  <i class="bi bi-x-circle-fill">
                  </i>
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" (click)="addBranchEntry()" class="ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
        <i class="bi bi-plus-circle-fill">
        </i>
        Add More
      </button>
      <div class="break-line">
      </div>
      <div class="button-group">
        <button type="submit" [disabled]="!companyProfileForm.valid"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </form>
  </div>
</div>
