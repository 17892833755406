import { Pipe, PipeTransform } from '@angular/core';
import { MAX_LENGTH } from '../constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(data: string): SafeHtml {
    if(isEmpty(data)){
      return data;
    }
    const replacedFont = data.replace('Tahoma, Geneva', 'Roboto');
    return this.sanitizer.bypassSecurityTrustHtml(replacedFont || '');
  }
}
