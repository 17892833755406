import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyProfile, JobInfo } from '@interfaces/corporate.interface';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {
  @Input() job!: JobInfo;
  @Input() companyProfile!: CompanyProfile;
  @Input() jobInfo!: JobInfo;
  @Input() isSelected: boolean = false;
  @Output() cardSelected = new EventEmitter<void>();
  @Input() showSavedButton: boolean = false;
  @Input() jobListType: string = 'find-job';

  ngOnInit() {
  }
  onClickDetailButton() {
    this.cardSelected.emit();
  }
}
