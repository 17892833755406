<div class="jobcard" [style.background-color]="isSelected ? '#c6daf1' : '#e1e1e1'" (click)="onClickDetailButton()">
  <div class="main">
    <div class="tags">
      <div *ngIf="talent.isEnigmaTalent" class="enigma-graduates-tag-jobtype">
        <div class="enigma-graduates">Enigma Graduates</div>
      </div>
      <div *ngIf="!talent.isEnigmaTalent" class="experience-tag-jobtype">
        <div class="experience">Experience Hiring</div>
      </div>
      <div *ngIf="talentListType === 'corporate-find-talent'">
        <div class="tag-job-status bg-warning-soft" *ngIf="talent.applicationStatus === 0">
          <label>Pending</label>
        </div>
        <div class="tag-job-status bg-success-soft" *ngIf="talent.applicationStatus === 1">
          <label>On Process</label>
        </div>
        <div class="tag-job-status bg-danger-soft" *ngIf="talent.applicationStatus === 2">
          <label>Declined</label>
        </div>
      </div>
    </div>
    <div class="div">
      <div class="card-profile-section">
        <img *ngIf="!talent.profilePictureUrl" class="profile-picture-img"
          src="./assets/img/project/default-user-picture.png" />
        <img *ngIf="talent.profilePictureUrl" class="profile-picture-img" [src]="talent.profilePictureUrl" />
      </div>
      <div class="details">
        <div class="first-name-last-name">{{ talent.firstName}}<br /> {{ talent.lastName }}</div>
        <div class="as">
          <div class="front-end-developer-wannabe">
            {{ talent.position || '-'}}
          </div>
        </div>
      </div>
    </div>
    <div class="cta">
      <button type="button" (click)="onClickDetailButton()"
        class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
        View Profile
        <i class="bi bi-arrow-right">
        </i>
      </button>
    </div>
  </div>
</div>
