<div class='d-flex justify-content-center' [ngClass]="{
  'olympus-spinner-bo': isBackOffice,
  'olympus-spinner': !isBackOffice,
  'spinner-fixed': isPositionFixed,
  'spinner-absolute': !isPositionFixed
}">
  <svg height='80' width='250'>
    <ellipse cx='5' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
  <svg height='80' width='250'>
    <ellipse cx='25' cy='20' fill='none' rx='10' ry='10'></ellipse>
  </svg>
</div>
