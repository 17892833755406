// Angular modules
import { Injectable } from '@angular/core';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class DateHelper {

  public static convertDateToYMD = (dateInput: string) => {
    const dateParts = dateInput.split('/');
    if (dateParts.length === 3) {
      const formattedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
      return formattedDate
    }

    return dateInput;
  }
}