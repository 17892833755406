import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { UIHelper } from '@helpers/ui.helper';
import { CardType } from '@interfaces/corporate.interface';
import { Talent, UserProfile } from '@interfaces/talent.interface';
import { BackOfficeService } from '@services/back-office.service';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { SnackbarService } from '@services/snackbar.service';
import { TalentService } from '@services/talent.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'talent-resume',
  templateUrl: './talent-resume.component.html',
  styleUrls: ['./talent-resume.component.scss']
})
export class TalentResumeComponent implements OnInit {

  public isLoading: boolean = false;
  @Input() userProfile!: UserProfile;
  @Input() applicationStatus!: Number | undefined;
  @Input() isPlacementDetail = false;
  private destroy$ = new Subject<void>();
  public cardType: CardType = 'viewedBy';
  public isOfferingButtonShown: boolean = true;
  public errorPopUpMessage = '';
  public rejectReason = '';
  public jobId = '';
  @ViewChildren('resize') basicElements!: QueryList<ElementRef>;

  constructor(
    private talentService: TalentService,
    private jobService: JobService,
    private router: Router,
    private corporateService: CorporateService,
    private backOfficeService: BackOfficeService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private toastManager: ToastManager,
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.corporateService.cardType$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (cardType) => {
        this.cardType = cardType;
        if (cardType === 'pending-registration') {
          this.backOfficeService.setActiveSidebarMenu('talent')
          this.backOfficeService.setActiveSidebarSubMenu('registration', 'pending')
        }
        if (cardType === 'back-office' && !this.isPlacementDetail) {
          this.backOfficeService.setActiveSidebarMenu('talent')
          this.backOfficeService.setActiveSidebarSubMenu('talent', 'account')
        }
        this.isOfferingButtonShown = false;
        if (['viewedBy'].includes(cardType)) {
          this.isOfferingButtonShown = true;
          return;
        }
      });
    this.route.params
      .subscribe(async (params) => {
        this.jobId = params['jobId'];
      })
  }

  public async onApproveTalentClick() {
    try {
      if (this.userProfile.id) {
        await this.backOfficeService.approvePendingTalents([this.userProfile.id]);
        this.toastManager.showSuccess(`Talent has been approved successfully`)
        this.router.navigate(['talent/account'])
      }
    } catch (error: any) {
      this.errorPopUpMessage = error.message
    } finally {
      this.isLoading = false
    }
  }

  public onGiveAnOfferClick() {
    this.talentService.setOfferedTalentId(this.userProfile.id)
    this.router.navigate(['talent-offer'])
  }

  public async onCloseModal() {
    this.errorPopUpMessage = '';
    this.snackbarService.close();;
  }

  public async openRejetedModal() {

    this.snackbarService.open('reject-talent');
  }

  public async onClickDownloadClick(): Promise<void> {
    if (this.userProfile.id) {
      try {
        this.toastManager.showSuccess('Downloading resume');
        await this.backOfficeService.downloadResume(this.userProfile.id, this.userProfile.firstName, this.userProfile.lastName);
      } catch (error: any) {
        this.toastManager.showError('Download resume failed', error.message);
      }
    }
  }

  public async rejectTalent() {
    try {
      if (this.userProfile.id) {
        const talentIds = [this.userProfile.id]
        await this.backOfficeService.rejectPendingTalents(talentIds, this.rejectReason);
        this.toastManager.showSuccess('Talent has been rejected successfully');
        this.onCloseModal();
        this.router.navigate(['talent/registration/pending'])
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async approveApplicantForJob() {
    try {
      this.isLoading = true
      if (this.userProfile.id && this.jobId) {
        await this.jobService.approveApplicantForJob(this.jobId, this.userProfile.id);
        this.toastManager.showSuccess('Applicant has been approved successfully');
        this.router.navigate(['corporate/on-boarding', this.jobId])
      }
    } catch (error: any) {
      this.toastManager.showError('Failed to approve applicant', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async declineApplicantForJob() {
    try {
      this.isLoading = true
      if (this.userProfile.id && this.jobId) {
        await this.jobService.declineApplicantForJob(this.jobId, this.userProfile.id);
        this.toastManager.showSuccess('Applicant has been declined successfully');
        this.corporateService.setCardType('applicant')
      }
    } catch (error: any) {
      this.toastManager.showError('Failed to decline applicant', error.message)
    } finally {
      this.isLoading = false
    }
  }

  ngAfterViewInit() {
    UIHelper.resizeWidthToColumn(this.basicElements, 550);
    window.addEventListener('resize', () => UIHelper.resizeWidthToColumn(this.basicElements, 550));
  }
}
