// Angular modules
import { Injectable, Injector } from '@angular/core';

// Enums
import { Endpoint } from '@enums/endpoint.enum';

// Interfaces
import { UserProfile } from '@interfaces/talent.interface';

// Services
import { APIBaseService } from './api-base.service';
import { Account, CardType, CompanyAbout, CompanyBasicInfo, CompanyProfile, InvitationInfo } from '@interfaces/corporate.interface';
import { BehaviorSubject } from 'rxjs';
import { PaginatedOnBoardingTalentResponse } from '@interfaces/onboarding.interface';
import { StorageHelper } from '@helpers/storage.helper';

@Injectable()
export class CorporateService extends APIBaseService {

  private readonly _isCorporateInvitation = new BehaviorSubject<boolean>(false);
  private readonly _invitationInfo = new BehaviorSubject<any | null>(null);
  private readonly _cardType = new BehaviorSubject<CardType>('viewedBy');

  readonly isCorporateInvitation$ = this._isCorporateInvitation.asObservable();
  readonly invitationInfo$ = this._invitationInfo.asObservable();
  readonly cardType$ = this._cardType.asObservable();

  constructor(private injector: Injector) {
    super(injector);
  }

  public setCorporateInvitation(state: boolean): void {
    this._isCorporateInvitation.next(state);
  }

  public setInvitationInfo(state: InvitationInfo): void {
    this._invitationInfo.next(state);
  }

  public setCardType(state: CardType): void {
    this._cardType.next(state);
  }

  public async createCompanyProfile(payload: any): Promise<UserProfile> {
    const url = Endpoint.CORPORATE_COMPANY;
    const { data } = await this.talentAPI.post(url, payload);
    return data.data;
  }

  public async getCompanyProfile(): Promise<CompanyProfile> {
    const url = Endpoint.COMPANY;
    const { data } = await this.talentAPI.get(url);
    this.storeService.setCompanyProfile(data.data)
    return data.data;
  }

  public async getCompanyProfileById(id: string): Promise<CompanyProfile> {
    const url = Endpoint.COMPANY;
    const { data } = await this.talentAPI.get(`${url}/${id}`);
    return data.data;
  }


  public async editBasicInfo(updatedBasicInfo: Partial<CompanyBasicInfo>): Promise<any> {
    StorageHelper.checkPermission()
    const url = Endpoint.COMPANY_BASIC_INFO;
    const { data } = await this.talentAPI.patch(url, updatedBasicInfo);
    return data.data;
  }

  public async editCompanyAbout(updatedBasicInfo: Partial<CompanyAbout>): Promise<any> {
    StorageHelper.checkPermission()
    const url = Endpoint.COMPANY_ABOUT;
    const { data } = await this.talentAPI.patch(url, updatedBasicInfo);
    return data.data;
  }

  public async updateProfilePicture(companyId: string, file: File | null): Promise<any> {
    StorageHelper.checkPermission()
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          companyId,
        }
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = Endpoint.COMPANY_PROFILE_PICTURE;
      const { data } = await this.talentAPI.patch(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async removeProfilePicture(): Promise<any> {
    StorageHelper.checkPermission()
    const url = 'user/company/profile-picture/delete';
    const { data } = await this.baseAPI.delete(url);
    return data.data;
  }

  public async getAccounts(): Promise<Account[]> {
    const url = Endpoint.COMPANY;
    const { data } = await this.talentAPI.get(`${url}/accounts`);
    return data.data;
  }

  public async createAccounts(invitationList: Array<{ email: string, role: string }>) {
    const url = Endpoint.COMPANY;
    const { data } = await this.talentAPI.post(`${url}/invite`, { invitations: invitationList });
    return data.data;
  }

  public async updateRole(userIds: Array<string>, role: string) {
    const url = Endpoint.CORPORATE;
    const { data } = await this.talentAPI.patch(`${url}/accounts/role`, { userIds, role });
    return data.data;
  }

  public async removeAccounts(userIds: Array<string>) {
    const url = Endpoint.CORPORATE;
    const { data } = await this.talentAPI.delete(`${url}/accounts`, { data: { userIds } });
    return data.data;
  }

  public async acceptInvitation(token: string): Promise<InvitationInfo> {
    const url = Endpoint.COMPANY;
    const { data } = await this.talentAPI.get(`${url}/accept/invitation?token=${token}`);
    return data.data;
  }

  public async getOnBoardingTalents(params: any): Promise<PaginatedOnBoardingTalentResponse> {
    const { data } = await this.talentAPI.get(`/corporate/wishlist`, { params });
    return data.data;
  }
}
