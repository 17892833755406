<div class="container-fluid" style="margin-top: 15px; padding-bottom: 20px; height: 100vh;">
  <div class="row">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div style="padding: 0px 100px; min-height: 90vh" *ngIf="accountList">
      <div class="frame-1707">
        <div class="frame-1697">
          <div class="title">
            <div class="username">Total</div>
            <div class="username2">{{accountList.length}} Active {{ accountList.length > 1 ? 'Accounts': 'Account'}}
            </div>
          </div>
        </div>
        <div class="tools">
          <button type="button" *ngIf="('TA' | corporateRole)" (click)="openAddAccountModal()"
            class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            <i class="bi bi-plus-circle-fill"></i> Add account </button>
          <div class="change-role-pop-button">
          </div>
          <div class="frame-1706">
            <div class="tablehead">
              <div class="tab">
                <input type="checkbox" hidden (change)="onSelectAll($event)" [(ngModel)]="isVisible" name="selectAll"
                  id="checkboxChecked" class="checkbox">
              </div>
              <div class="tab-profile-header">
                <div class="account">Account</div>
              </div>
              <div class="tab3">
                <div class="last-active">Last login</div>
              </div>
              <div class="tab4">
                <div class="role">Role</div>
              </div>
            </div>
            <div *ngFor="let account of accountList; let i = index; let first = first; let last = last;"
              style="width: 100%;">
              <div [class]="i % 2 === 0 ? 'tabs':'tabs2'">
                <div class="tab">
                  <input *ngIf="('TA' | corporateRole)" type="checkbox" [checked]="account.isChecked"
                    (change)="onSelectAccount($event, i)" (id)="'checkboxChecked' + i" class="checkbox">
                </div>
                <div class="tab-profile">
                  <!-- <div class="user-profile">
                    <div class="pp">
                      <img src="./assets/img/project/default-user-picture.png" class="_01" />
                    </div>
                  </div> -->
                  <div class="details">
                    <div class="first-name-last-name">{{account.firstName}} {{account.lastName}}</div>
                    <div class="as">
                      <div class="firstname-lastname-bca-com">
                        {{account.email}}
                      </div>
                      <div class="hiring-manager">{{account.position}}</div>
                      <div class="active-since-20-19-2023">Active since : {{account.createdAt | date: 'MMM dd, yyyy'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab3">
                  <div *ngIf="account.lastLogin" class="_1-hour-ago"> {{ account.lastLogin | timeAgo: false }}</div>
                  <div *ngIf="!account.lastLogin" class="_1-hour-ago"> - </div>
                </div>
                <div class="tab4">
                  <div class="button-primary-white-rounded">
                    <div class="button-white">{{account.role}}</div>
                  </div>
                </div>
                <!-- <div class="tab">
                  <svg class="three-dots-vertical" width="16" height="17" viewBox="0 0 16 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.5 13.5C9.5 14.3284 8.82843 15 8 15C7.17157 15 6.5 14.3284 6.5 13.5C6.5 12.6716 7.17157 12 8 12C8.82843 12 9.5 12.6716 9.5 13.5ZM9.5 8.5C9.5 9.32843 8.82843 10 8 10C7.17157 10 6.5 9.32843 6.5 8.5C6.5 7.67157 7.17157 7 8 7C8.82843 7 9.5 7.67157 9.5 8.5ZM9.5 3.5C9.5 4.32843 8.82843 5 8 5C7.17157 5 6.5 4.32843 6.5 3.5C6.5 2.67157 7.17157 2 8 2C8.82843 2 9.5 2.67157 9.5 3.5Z"
                      fill="#757575" />
                  </svg>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <popup-confirmation id="change-role" *ngIf="accountList.length">
        <div class="change-role-pop-confirmation-pop-up">
          <div class="change-role-pop-frame-1673">
            <div class="change-role-pop-title">
              <div class="change-role-pop-change-role">Change Role</div>
            </div>
            <svg (click)="onCloseModal()" class="change-role-pop-x" width="16" height="16" viewBox="0 0 16 16"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                fill="#757575" />
            </svg>
          </div>
          <div class="change-role-pop-accounts">{{ selectedAccounts.length > 1 ? 'Accounts': 'Account'}}</div>
          <div *ngFor="let account of selectedAccounts; let i = index">
            <div class="change-role-pop-dobb">
              <div class="change-role-pop-dobb2">
                <div class="change-role-pop-div">
                  <div class="change-role-pop-user-profile">
                    <div class="change-role-pop-pp">
                      <img src="./assets/img/project/default-user-picture.png" class="_01" />
                    </div>
                  </div>
                  <div class="change-role-pop-details">
                    <div class="change-role-pop-first-name-last-name">{{account.firstName}} {{account.lastName}}</div>
                    <div class="change-role-pop-as">
                      <div class="change-role-pop-firstname-lastname-bca-com">
                        {{account.email}}
                      </div>
                      <div class="change-role-pop-hiring-manager">{{account.position || '-'}}</div>
                      <div class="change-role-pop-active-since-20-19-2023">Active since : {{account.createdAt | date:
                        'MMM dd, yyyy'}}</div>
                    </div>
                  </div>
                  <svg class="trash-3-fill" *ngIf="i >= 1" (click)="removeAccountFromSelectedAccountList(account.id)"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                      fill="#CB3A31" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="change-role-pop-change-role2">Change role</div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-building-fill"></i>
            </span>
            <select type="text" class="ec-form-select-rounded" [(ngModel)]="updatedRole">
              <option *ngFor="let accountRole of accountRoleList" [value]="accountRole.value">{{
                accountRole.value}}
              </option>
            </select>
          </div>
          <div class="change-role-pop-button">
            <button type="button" (click)="changeRole()" (disabled)="!updatedRole"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Change Role
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              No, Turn Back
            </button>
          </div>
        </div>
      </popup-confirmation>
      <popup-confirmation id="add-account" *ngIf="accountList.length">
        <form [formGroup]="accountForm" class="form-horizontal" (ngSubmit)="bulkAddAccount()">
          <div class="add-account-pop-confirmation-pop-up">
            <div class="change-role-pop-frame-1673">
              <div class="change-role-pop-title">
                <div class="change-role-pop-change-role">Add Account to send an Invitation!</div>
              </div>
              <svg (click)="onCloseModal()" class="change-role-pop-x" width="16" height="16" viewBox="0 0 16 16"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                  fill="#757575" />
              </svg>
            </div>
            <div class="popup-subtitle">
              The maximum number of active accounts is 10 people
            </div>
            <div formArrayName="accountEntries" style="width: 100%;">
              <div style="display: flex; width: 100%;" *ngFor="let entry of accountEntries.controls; let i = index"
                [formGroupName]="i">
                <div class="change-role-pop-dobb">
                  <div class="input-rounded-parent" style="align-items: center;">
                    <div class="form-group-label-input-no-margin">
                      <div class="form-label-input">
                        Email
                      </div>
                      <div class="ec-input-group-rounded input-text-space">
                        <span class="ec-input-group-text">
                          <i class="bi bi-person-fill"></i>
                        </span>
                        <input type="text" formControlName="email" class="ec-form-control-rounded ec-is-invalid"
                          placeholder="Email">
                      </div>
                    </div>
                    <div class="form-group-label-input-no-margin">
                      <div class="form-label-input">
                        Role
                      </div>
                      <div class="ec-input-group-rounded input-text-space">
                        <span class="ec-input-group-text">
                          <i class="bi bi-building-fill"></i>
                        </span>
                        <select type="text" formControlName="role" class="ec-form-select-rounded">
                          <option value="" disabled>Select Role</option>
                          <option *ngFor="let accountRole of accountRoleList" [value]="accountRole.value">{{
                            accountRole.value}}
                          </option>
                        </select>
                      </div>

                    </div>
                    <svg *ngIf="accountEntries.length > 1" class="trash-3-fill" (click)="removeAccountEntry(i)"
                      width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                        fill="#CB3A31" />
                    </svg>
                  </div>
                  <div class="input-rounded-parent" style="align-items: center;">
                    <div class="form-group-label-input-no-margin">
                      <div class="invalid-feedback-text"
                        *ngIf="accountEntries.controls[i]?.get('email')?.touched && accountEntries.controls[i]?.get('email')?.hasError('email')">
                        Email is not valid email
                      </div>
                      <div class="invalid-feedback-text"
                        *ngIf="accountEntries.controls[i]?.get('email')?.touched && accountEntries.controls[i]?.get('email')?.hasError('required')">
                        Email is required
                      </div>
                    </div>
                    <div class="form-group-label-input-no-margin">
                      <div class="invalid-feedback-text"
                        *ngIf="accountEntries.controls[i]?.get('role')?.touched && accountEntries.controls[i]?.get('role')?.hasError('required')">
                        Role is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-content">
              <button type="button" (click)="addAccountEntry()"
                class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                <i class="bi bi-plus-circle-fill"></i> Add more </button>
            </div>

            <div class="change-role-pop-button">
              <button type="button" (click)="bulkAddAccount()" [disabled]="!accountForm.valid"
                class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                Yes, Send invitation!
              </button>
              <button type="button" (click)="onCloseModal()"
                class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
                No, Turn back
              </button>
            </div>
          </div>
        </form>
      </popup-confirmation>
      <popup-confirmation id="remove-account" *ngIf="accountList.length">
        <div class="popup-wrapper-danger">
          <div class="change-role-pop-frame-1673">
            <div class="change-role-pop-title">
              <div class="popup-title-danger">Wait! You are about to delete an account!</div>
            </div>
            <svg (click)="onCloseModal()" class="change-role-pop-x" width="16" height="16" viewBox="0 0 16 16"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                fill="#757575" />
            </svg>
          </div>
          <div class="change-role-pop-accounts">{{ selectedAccounts.length > 1 ? 'Accounts': 'Account'}}</div>
          <div *ngFor="let account of selectedAccounts; let i = index">
            <div class="change-role-pop-dobb">
              <div class="change-role-pop-dobb2">
                <div class="change-role-pop-div">
                  <div class="change-role-pop-user-profile">
                    <div class="change-role-pop-pp">
                      <img src="./assets/img/project/default-user-picture.png" class="_01" />
                    </div>
                  </div>
                  <div class="change-role-pop-details">
                    <div class="change-role-pop-first-name-last-name">{{account.firstName}} {{account.lastName}}</div>
                    <div class="change-role-pop-as">
                      <div class="change-role-pop-firstname-lastname-bca-com">
                        {{account.email}}
                      </div>
                      <div class="change-role-pop-hiring-manager">{{account.position}}</div>
                      <div class="change-role-pop-active-since-20-19-2023">Active since : {{account.createdAt | date:
                        'MMM dd, yyyy'}}</div>
                    </div>
                  </div>
                  <svg class="trash-3-fill" *ngIf="i >= 1" (click)="removeAccountFromSelectedAccountList(account.id)"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                      fill="#CB3A31" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="note-deleted-accounts">
            Note : Deleted accounts cannot be recovered.
          </div>
          <div class="change-role-pop-button">
            <button type="button" (click)="bulkRemoveAccount()"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Okay, Delete
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              No, Cancel
            </button>
          </div>
        </div>
      </popup-confirmation>
      <popup-confirmation id="delegate-task" *ngIf="accountList.length">
        <div class="popup-wrapper-danger">
          <div class="change-role-pop-frame-1673">
            <div class="change-role-pop-title">
              <div class="popup-title-danger">Hold it!! <br>
                The user you are trying to remove got a pending/on going tasks!
              </div>
            </div>
            <svg (click)="onCloseModal()" class="change-role-pop-x" width="16" height="16" viewBox="0 0 16 16"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                fill="#757575" />
            </svg>
          </div>
          <div class="change-role-pop-accounts">Choose account to delegate</div>
          <div *ngFor="let account of filterUnCheckedAccounts(); let i = index">
            <div class="change-role-pop-dobb">
              <div class="change-role-pop-dobb2">
                <div class="change-role-pop-div"
                  [ngClass]="{'selected': delegatedAccount && delegatedAccount.id === account.id}"
                  (click)="onSelectDelegatedAccount(account)">
                  <div class="change-role-pop-user-profile">
                    <div class="change-role-pop-pp">
                      <img src="./assets/img/project/default-user-picture.png" class="_01" />
                    </div>
                  </div>
                  <div class="change-role-pop-details">
                    <div class="change-role-pop-first-name-last-name">{{account.firstName}} {{account.lastName}}</div>
                    <div class="change-role-pop-as">
                      <div class="change-role-pop-firstname-lastname-bca-com">
                        {{account.email}}
                      </div>
                      <div class="change-role-pop-hiring-manager">{{account.position}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="delegatedAccount">
            <div class="note-deleted-accounts">
              Remove account:
            </div>

            <div class="popup-title-danger">
              {{ selectedAccounts[0].firstName }} {{ selectedAccounts[0].lastName }}
            </div>
            <div class="note-deleted-accounts">
              Delegate tasks to:
            </div>
            <div class="popup-title-danger">
              {{ delegatedAccount.firstName }} {{ delegatedAccount.lastName }}
            </div>
          </ng-container>
          <br>
          <div class="change-role-pop-button">
            <button type="button" [disabled]="!delegatedAccount" (click)="delegateTask()"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Yes, Delegate and Remove
            </button>
            <button type="button" (click)="onCloseModal()"
              class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
              Cancel
            </button>
          </div>
        </div>
      </popup-confirmation>
    </div>
  </div>
</div>
<div class="fade-container" [ngClass]="{'visible': isVisible}">
  <div class="hidden-tools-wrapper">
    <div class="hidden-tools">
      <div class="button-primary-rounded" (click)="openChangeRoleModal(null)">
        <div class="icn">
          <svg class="arrow-repeat" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.5338 7H15.4662C15.6782 7 15.794 7.24721 15.6583 7.41005L13.6921 9.76953C13.5921 9.88947 13.4079 9.88947 13.3079 9.76953L11.3417 7.41005C11.206 7.24721 11.3218 7 11.5338 7Z"
              fill="#FDFDFD" />
            <path
              d="M0.533758 9H4.46624C4.6782 9 4.79399 8.75279 4.65829 8.58995L2.69205 6.23047C2.5921 6.11053 2.40789 6.11053 2.30794 6.23047L0.341702 8.58995C0.206009 8.75279 0.321798 9 0.533758 9Z"
              fill="#FDFDFD" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 3C6.44751 3 5.06056 3.70697 4.14268 4.81839C3.96684 5.03131 3.65169 5.06137 3.43877 4.88552C3.22585 4.70968 3.19579 4.39453 3.37164 4.18161C4.47134 2.85003 6.13662 2 8 2C10.9417 2 13.388 4.1165 13.9011 6.90967C13.9066 6.9397 13.9119 6.96981 13.917 7H12.9001C12.4371 4.71778 10.4186 3 8 3ZM3.09992 9C3.56293 11.2822 5.58137 13 8 13C9.55249 13 10.9394 12.293 11.8573 11.1816C12.0332 10.9687 12.3483 10.9386 12.5612 11.1145C12.7741 11.2903 12.8042 11.6055 12.6284 11.8184C11.5287 13.15 9.86338 14 8 14C5.05829 14 2.61198 11.8835 2.09891 9.09033C2.0934 9.0603 2.0881 9.03019 2.08304 9H3.09992Z"
              fill="#FDFDFD" />
          </svg>
        </div>
        <div class="button">Change Role</div>
      </div>
      <div class="button-alert-rounded" (click)="openRemoveAccountModal()">
        <div class="icn">
          <svg class="x-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
              fill="#FDFDFD" />
          </svg>
        </div>
        <div class="button">Delete Account</div>
      </div>
      <svg class="close-madal" (click)="onHiddenToolsClose()" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
          fill="#757575" />
      </svg>
    </div>
  </div>
</div>
