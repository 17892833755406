<ng-container *ngIf="companyProfile && job">
  <div class="job-form-preview" [ngStyle]="{'background-color': borderColor}">
    <div *ngIf="props" class="cta">
      <div class="your-offering">{{props.modalTitle }}</div>
    </div>
    <div class="main">
      <div class="details">
        <div class="jobtitle">
          <div class="front-end-developer-senior-level">
            {{ job.postTitle}} </div>
          <div class="deets">
            <div class="bca">
              {{ companyProfile.brand}}
            </div>
            <div class="ellipse-435">
            </div>
            <div class="_1-hour-ago">
              {{ job.createdAt | timeAgo}} </div>
          </div>
        </div>
        <div class="cta">
          <div class="cta"
            *ngIf="props && props.notificationTemplate === 'RECEIVED_OFFER' && userProfile && !userProfile.isEnigmaTalent && offerResponse.status === 1">
            <div [ngClass]="'btn-rounded bg-primary-soft'" (click)="approveOffer()">
              <label>Accept
                <i class="bi bi-arrow-up-right-circle">
                </i>
              </label>
            </div>
            <div [ngClass]="'btn-rounded bg-danger-soft'" (click)="declineOffer()">
              <label>Decline
                <i class="bi bi-x-octagon">
                </i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="quick">
        <div class="job">
          <svg class="briefcase-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.5 1C5.67173 1 5 1.67117 5 2.49976V3H1.5C0.671573 3 0 3.67157 0 4.5V5.88414L7.61351 7.91441C7.86674 7.98194 8.13326 7.98194 8.38649 7.91441L16 5.88414V4.5C16 3.67157 15.3284 3 14.5 3H11V2.49976C11 1.67117 10.3283 1 9.5 1H6.5ZM6.5 2H9.5C9.7763 2 10 2.22463 10 2.50061V3H6V2.49976C6 2.22377 6.2237 2 6.5 2Z"
              fill="#757575" />
            <path
              d="M0 12.5C0 13.3284 0.671573 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V6.84919L8.12883 8.94817C8.04442 8.97068 7.95558 8.97068 7.87117 8.94817L0 6.84919V12.5Z"
              fill="#757575" />
          </svg>
          <div class="deets2">
            <div class="tag-jobtype">
              <div class="wfo">
                {{ job.workplaceType }} </div>
            </div>
            <div class="ellipse-4352">
            </div>
            <div class="tag-joblevel">
              <div class="full-time">
                {{ job.employmentType }} </div>
            </div>
            <div class="ellipse-437">
            </div>
            <div class="tag-joblevel">
              <div class="senior">
                {{ job.experienceLevel }} </div>
            </div>
            <div class="ellipse-436">
            </div>
            <div class="tag-joblevel">
              <div class="tech-lead">
                {{job.jobTitle}} </div>
            </div>
          </div>
        </div>
        <div class="industry">
          <svg class="building" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 2.5C4 2.22386 4.22386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5V3.5C6 3.77614 5.77614 4 5.5 4H4.5C4.22386 4 4 3.77614 4 3.5V2.5Z"
              fill="#757575" />
            <path
              d="M7 2.5C7 2.22386 7.22386 2 7.5 2H8.5C8.77614 2 9 2.22386 9 2.5V3.5C9 3.77614 8.77614 4 8.5 4H7.5C7.22386 4 7 3.77614 7 3.5V2.5Z"
              fill="#757575" />
            <path
              d="M10.5 2C10.2239 2 10 2.22386 10 2.5V3.5C10 3.77614 10.2239 4 10.5 4H11.5C11.7761 4 12 3.77614 12 3.5V2.5C12 2.22386 11.7761 2 11.5 2H10.5Z"
              fill="#757575" />
            <path
              d="M4 5.5C4 5.22386 4.22386 5 4.5 5H5.5C5.77614 5 6 5.22386 6 5.5V6.5C6 6.77614 5.77614 7 5.5 7H4.5C4.22386 7 4 6.77614 4 6.5V5.5Z"
              fill="#757575" />
            <path
              d="M7.5 5C7.22386 5 7 5.22386 7 5.5V6.5C7 6.77614 7.22386 7 7.5 7H8.5C8.77614 7 9 6.77614 9 6.5V5.5C9 5.22386 8.77614 5 8.5 5H7.5Z"
              fill="#757575" />
            <path
              d="M10 5.5C10 5.22386 10.2239 5 10.5 5H11.5C11.7761 5 12 5.22386 12 5.5V6.5C12 6.77614 11.7761 7 11.5 7H10.5C10.2239 7 10 6.77614 10 6.5V5.5Z"
              fill="#757575" />
            <path
              d="M4.5 8C4.22386 8 4 8.22386 4 8.5V9.5C4 9.77614 4.22386 10 4.5 10H5.5C5.77614 10 6 9.77614 6 9.5V8.5C6 8.22386 5.77614 8 5.5 8H4.5Z"
              fill="#757575" />
            <path
              d="M7 8.5C7 8.22386 7.22386 8 7.5 8H8.5C8.77614 8 9 8.22386 9 8.5V9.5C9 9.77614 8.77614 10 8.5 10H7.5C7.22386 10 7 9.77614 7 9.5V8.5Z"
              fill="#757575" />
            <path
              d="M10.5 8C10.2239 8 10 8.22386 10 8.5V9.5C10 9.77614 10.2239 10 10.5 10H11.5C11.7761 10 12 9.77614 12 9.5V8.5C12 8.22386 11.7761 8 11.5 8H10.5Z"
              fill="#757575" />
            <path
              d="M2 1C2 0.447716 2.44771 0 3 0H13C13.5523 0 14 0.447715 14 1V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1ZM13 1L3 1V15H6V12.5C6 12.2239 6.22386 12 6.5 12H9.5C9.77614 12 10 12.2239 10 12.5V15H13V1Z"
              fill="#757575" />
          </svg>
          <div class="deets2">
            <div class="it-technology-and-internet">
              {{companyProfile.industry}} </div>
          </div>
        </div>
        <div class="loc">
          <svg class="pin-map-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.1 11.2C3.19443 11.0741 3.34262 11 3.5 11H6C6.27615 11 6.5 11.2239 6.5 11.5C6.5 11.7761 6.27615 12 6 12H3.75L1.5 15H14.5L12.25 12H10C9.72386 12 9.5 11.7761 9.5 11.5C9.5 11.2239 9.72386 11 10 11H12.5C12.6574 11 12.8056 11.0741 12.9 11.2L15.9 15.2C16.0136 15.3515 16.0319 15.5542 15.9472 15.7236C15.8625 15.893 15.6894 16 15.5 16H0.500003C0.310617 16 0.137485 15.893 0.0527893 15.7236C-0.0319067 15.5542 -0.0136288 15.3515 0.100003 15.2L3.1 11.2Z"
              fill="#757575" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.03981 10.4732 7.723 8.5 7.96905V13.5C8.5 13.7761 8.27615 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5V7.96905C5.52685 7.723 4 6.03981 4 4Z"
              fill="#757575" />
          </svg>
          <div class="deets2">
            <div class="jakarta">
              {{ job.office.city }} </div>
            <div class="ellipse-4352">
            </div>
            <div class="indonesia">
              {{ job.office.country }} </div>
          </div>
        </div>
        <div class="loc">
          <svg class="clock-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
              fill="#757575" />
          </svg>
          <div class="deets2">
            <div class="regular-hours">
              {{ job.workingHour }} </div>
          </div>
        </div>
        <div class="loc">
          <svg class="mortarboard-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.21144 2.04691C8.07742 1.98436 7.92258 1.98436 7.78856 2.04691L0.288558 5.54691C0.107914 5.63121 -0.00539504 5.81476 0.000197994 6.01403C0.005791 6.2133 0.129217 6.3902 0.314306 6.46424L7.81431 9.46424C7.93351 9.51192 8.06649 9.51192 8.1857 9.46424L14 7.13852V13C13.4477 13 13 13.4477 13 14V16H16V14C16 13.4477 15.5523 13 15 13V6.73852L15.6857 6.46424C15.8708 6.3902 15.9942 6.2133 15.9998 6.01403C16.0054 5.81476 15.8921 5.63121 15.7114 5.54691L8.21144 2.04691Z"
              fill="#757575" />
            <path
              d="M4.17556 9.03184C4.04549 8.98306 3.90098 8.99059 3.77669 9.05264C3.6524 9.11468 3.55952 9.22564 3.52032 9.35892L3.02032 11.0589C2.94756 11.3063 3.07488 11.5685 3.31431 11.6642L7.81431 13.4642C7.93351 13.5119 8.06649 13.5119 8.1857 13.4642L12.6857 11.6642C12.9251 11.5685 13.0524 11.3063 12.9797 11.0589L12.4797 9.35892C12.4405 9.22564 12.3476 9.11468 12.2233 9.05264C12.099 8.99059 11.9545 8.98306 11.8244 9.03184L8 10.466L4.17556 9.03184Z"
              fill="#757575" />
          </svg>
          <div class="deets2">
            <div class="minimum-bachelor-s-degree">
              {{ job.educationLevel }} </div>
          </div>
        </div>
        <div class="loc">
          <div class="person-fill">
            <svg class="person-fill2" width="12" height="12" viewBox="0 0 12 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1Z" fill="#757575" />
              <path
                d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z"
                fill="#757575" />
            </svg>
          </div>
          <div class="deets2">
            <div class="_2-talents-needed">
              {{ job.talentNeeded }} Talents Needed </div>
            <div class="ellipse-4352">
            </div>
            <div class="_0-applicants">
              {{ job.applicantsCount }} Applicants </div>
          </div>
        </div>
        <div class="loc">
          <svg class="spellcheck" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.2168 11.0684C9.43262 11.0684 10.165 10.1992 10.165 8.75879V8.05566C10.165 6.61523 9.4375 5.75098 8.23633 5.75098C7.49414 5.75098 6.9082 6.09766 6.7373 6.63965H6.67383V3.9834H5.38477V11H6.6543V10.209H6.71777C6.92773 10.7412 7.49414 11.0684 8.2168 11.0684ZM7.78711 10.043C7.12793 10.043 6.67383 9.52539 6.67383 8.76367V8.11914C6.67383 7.29395 7.09375 6.77637 7.77246 6.77637C8.45605 6.77637 8.84668 7.29395 8.84668 8.19238V8.6416C8.84668 9.53027 8.46094 10.043 7.78711 10.043ZM2.2041 11.0781C2.9707 11.0781 3.40527 10.7217 3.61035 10.3408H3.66895V11H4.88477V7.51855C4.88477 6.20508 3.9375 5.73633 2.77539 5.73633C1.35449 5.73633 0.749023 6.41992 0.69043 7.26953H1.90625C1.96973 6.94727 2.21875 6.71777 2.74609 6.71777C3.27344 6.71777 3.61035 6.9668 3.61035 7.48926V7.95312H2.3457C1.14453 7.95312 0.5 8.56836 0.5 9.49609C0.5 10.4727 1.19336 11.0781 2.2041 11.0781ZM2.62402 10.1309C2.18457 10.1309 1.7793 9.89648 1.7793 9.41309C1.7793 9.01758 2.04785 8.72949 2.61914 8.72949H3.61035V9.2666C3.61035 9.76953 3.16602 10.1309 2.62402 10.1309ZM11.5209 10.6978C10.9439 10.2976 10.6211 9.6105 10.6211 8.71484V8.06543C10.6211 6.64453 11.5146 5.72656 12.9258 5.72656C14.2783 5.72656 15.0449 6.54688 15.0645 7.5332H13.8779C13.8389 7.18164 13.5947 6.75684 12.96 6.75684C12.2861 6.75684 11.915 7.27441 11.915 8.08496V8.70996C11.915 9.17782 12.0363 9.54415 12.2576 9.77688L11.5209 10.6978Z"
              fill="#757575" />
            <path
              d="M14.4685 9.41435C14.792 9.67311 14.8444 10.1451 14.5857 10.4685L10.5857 15.4685C10.4524 15.6351 10.2545 15.7371 10.0415 15.7489C9.82847 15.7606 9.62052 15.6812 9.46967 15.5303L6.96967 13.0303C6.67678 12.7374 6.67678 12.2626 6.96967 11.9697C7.26256 11.6768 7.73744 11.6768 8.03033 11.9697L9.93781 13.8772L13.4143 9.53148C13.6731 9.20803 14.1451 9.15559 14.4685 9.41435Z"
              fill="#757575" />
          </svg>
          <div class="deets">
            <div class="indonesia" *ngFor="let item of job.languages; let last = last">
              <label style="margin-right: 5px;">{{item.language}}</label>
              <div *ngIf="!last" class="ellipse-4352"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="details2">
        <div class="job-details">
          Job Details </div>
        <div class="break">
        </div>
        <div [innerHTML]="job.jobDescription | safeHtml"></div>
      </div>
    </div>
    <div class="card">
      <div class="frame-1694">
        <img *ngIf="!companyProfile.profilePictureUrl" src="./assets/img/project/default-company-logo.png"
          class="ellipse-2" />
        <img *ngIf="companyProfile.profilePictureUrl" [src]="companyProfile.profilePictureUrl" class="ellipse-2" />
        <div class="basic">
          <div class="basic2">
            <div class="username">
              {{companyProfile.brand }} </div>
            <div class="as">
              <div class="pt-bank-central-asia">
                {{companyProfile.companyName }} </div>
            </div>
          </div>
        </div>
        <button type="button" (click)="openNewTab(companyProfile.companySite)"
          class="ec-btn ec-btn-md ec-btn-primary ec-btn-rounded mb-2 me-2">
          Visit Page
          <i class="bi bi-arrow-right inner-icon">
          </i>
        </button>
      </div>
      <div class="basic3">
        <div class="title">
          <div class="company-overview">
            Company Overview </div>
        </div>
        <div class="line-4">
        </div>
        <div class="content">
          <div>
            <div *ngIf="showFullCompanyAbout; else truncatedDescription" [innerHTML]="companyProfile.about">
            </div>
            <ng-template #truncatedDescription>
              <div [innerHTML]="truncatedCompanyAbout"></div>
              <a *ngIf="showReadMore" (click)="toggleFullCompanyAbout()" class="show-more">read more</a>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="frame-1632">
        <div class="email">
          <div class="industry2">
            Industry </div>
          <div class="banking-edu-tech">
            {{companyProfile.industry}} </div>
        </div>
        <div class="email">
          <div class="company-size">
            Company Size </div>
          <div class="_100-k-employees">
            {{ companyProfile.companySizeRange }} </div>
        </div>
        <div class="email">
          <div class="location">
            Location </div>
          <div class="indonesia-asia-uct-4-30">
            {{ job.office.name }}, {{ job.office.country }} </div>
        </div>
        <div class="email">
          <div class="website">
            Website </div>
          <div class="https-bca-com">
            {{ companyProfile.companySite }} </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
