import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastManager } from '@blocks/toast/toast.manager';
import { ValidationHelper } from '@helpers/validation.helper';
import { Timezone } from '@interfaces/register.interface';
import { OnBoardingService } from '@services/onboarding.service';
import { ReferenceService } from '@services/reference.service';
import CustomEditor from 'ckeditor5-ng';
import dayjs from 'dayjs';
import { MAX_LENGTH } from 'src/app/shared/constants';
import { TIMEZONE } from 'src/app/shared/constants/timezone';

@Component({
  selector: 'interview-form',
  templateUrl: './interview-form.component.html',
  styleUrls: [
    './interview-form.component.scss',
    '../../../../pages/job/job-detail/job-detail.component.scss',
    '../../../../pages/talent/about/about.component.scss'
  ]
})
export class InterviewFormComponent {
  @Input() onBoardingId: string = '';
  public isLoading = false;
  @Input() interviewType = 'interview-1';
  public minDate = dayjs().format('YYYY-MM-DD');

  public interviewForm !: FormGroup<{
    description: FormControl<string>,
    scheduledDate: FormControl<string>,
    scheduledTime: FormControl<string>,
  }>;
  public emailNotificationList: Array<string> = [];
  public errorMessage: string = '';
  public isAboutValid = true;
  public Editor = CustomEditor;
  public timezoneList: Timezone[] = [];

  constructor
    (
      private cdr: ChangeDetectorRef,
      private onBoardingService: OnBoardingService,
      private toastManager: ToastManager,
      private referenceService: ReferenceService,
    ) {

    this.interviewForm = new FormGroup({
      description: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_LARGE)], nonNullable: true }),
      scheduledDate: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      scheduledTime: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
    });
  }

  async ngOnInit() {
    this.timezoneList = TIMEZONE
    this.onBoardingService.shouldSendMeeting$
      .subscribe(async (shouldSendMeeting) => {
        if (shouldSendMeeting) {

        }
      })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public uploadTestQuestion() {
    const fileInput = document.getElementById('uploadTestQuestion');
    if (fileInput) {
      fileInput.click();
    }
  }

  private mapInterviewPayload() {
    const description = this.interviewForm.get('description')?.getRawValue();
    const date = this.interviewForm.get('scheduledDate')?.getRawValue();
    const time = this.interviewForm.get('scheduledTime')?.getRawValue();
    const timezone = this.interviewForm.get('scheduledTimezone')?.getRawValue();
    return {
      onboardingId: this.onBoardingId,
      description,
      date,
      time,
      timezone,
      interviewType: this.interviewType
    }
  }

  public async sendInterviewMeeting() {
    try {
      this.isLoading = true;
      if (!this.interviewForm.valid) {
        this.toastManager.showError('Meeting invitaion failed', 'Please complete interview form first');
        return;
      }
      const payload = this.mapInterviewPayload();
      await this.onBoardingService.sendMeetingInvitation(payload);
      this.toastManager.showSuccess('Your meeting invitation has submitted successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
    } catch (error: any) {
      this.toastManager.showError('Meeting invitaion failed', error.message);
    } finally {
      this.isLoading = false
    }
  }

  public getWordCount() {
    const description = this.interviewForm.get('description')?.value;
    const words = description && description.trim().split(/\s+/);
    return words?.length || 0;
  }
}
