<div class="container-fluid" style="margin-top: 15px;">
  <div class="row">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div *ngIf="userProfile && !userProfile?.isVerified" class="dasboard-container">
      <div class="verify">
        <div class="verify-title">
          <div class="your-account-is-not-verified-yet">
            Your account is not verified yet!
          </div>
          <div class="verify-info">
            <p>Please check your inbox for a verification email, and click on the link attached. Didn’t get the email?
              <b (click)="resendEmailVerification()">Resend verification email</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="userProfile && userProfile.status === 0" class="dasboard-container">
      <div class="pending-talent">
        <div class="pending-talent-title">
          <div class="pending-talent-label">
            Your account is under review!
          </div>
          <div class="pending-talent-info">
            <p>Appreciate your patience as we carefully assess your account. Thank you for your cooperation.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="userProfile" class="dasboard-container">
      <!-- ========== Start left sidebar ========== -->
      <div class="col-sm-5 col-md-4 col-lg-3"
        style="margin-right: 15px; gap: 15px 0px; display: flex !important; flex-direction: column;">
        <div *ngIf="userProfile.resume && userProfile.resume.resumeUrl" class="resume-uploaded-section">
          <div class="resume-uploaded-group-23">
            <div class="resume-uploaded-group-21">
              <svg class="resume-uploaded-group-20" width="74" height="93" viewBox="0 0 74 93" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.93994 0.401001L64.2119 6.7578L72.8021 16.3077L63.9175 92.1607L0.0681994 84.6821L9.93994 0.401001Z"
                  fill="#81B79D" />
                <path d="M64.4248 6.78302L72.7923 16.3929L63.4277 15.296L64.4248 6.78302Z" fill="#FEFEFE" />
              </svg>
            </div>
            <div class="resume-uploaded-group-22">
              <svg class="resume-uploaded-group-202" width="86" height="100" viewBox="0 0 86 100" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.676 0.606873L76.1895 15.7127L83.1108 26.5334L61.9982 99.9288L0.217613 82.1572L23.676 0.606873Z"
                  fill="#ECF4F0" />
                <path d="M76.3958 15.7723L83.0874 26.616L74.0263 24.0095L76.3958 15.7723Z" fill="#FEFEFE" />
              </svg>
            </div>
          </div>
          <div class="resume-uploaded-title">
            <div class="resume-uploaded-resume-uploaded">
              Resume Uploaded! </div>
            <div class="resume-uploaded-last-updated-12-april-2023">
              Last Updated : {{ userProfile.resume.updatedAt | date: 'd MMM YYYY'}} </div>
          </div>
          <div class="resume-uploaded-action">
            <div class="btn-rounded bg-primary-soft" (click)="onPreviewResume()">
              <label (click)="onPreviewResume()">
                Preview
                <i class="bi bi-arrow-right"></i>
              </label>
            </div>
            <div class="resume-uploaded-file-name-pdf">
              {{ userProfile.resume.resumeFilename }} </div>
          </div>
        </div>
        <div class="upload-resume-section">
          <div class="title5">
            <div class="upload-your-resume">{{ userProfile.resume?.resumeUrl ? 'Update Your Resume!': 'Upload Your
              Resume!'}}</div>
            <div class="let-the-employer-learn-about-you-easier">
              Let the employer learn about you easier.
            </div>
          </div>
          <div class="action2">
            <div class="btn-rounded bg-warning-soft" (click)="uploadTestResult()">
              <label>
                <i class="bi bi-upload"></i>
                {{ isUploadingResume ? 'Uploading ...': 'Upload'}}
              </label>
              <span *ngIf="isUploadingResume" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <input type="file" style="display: none;" id="resume-input" class="input-file"
                (change)="onFileSelected($event)" accept=".pdf, .doc, .docx">
            </div>
            <div class="resume-uploaded-file-name-pdf">
              {{selectedFileName || '(No file selected)'}} </div>
          </div>
        </div>
        <div class="profile-completion">
          <div class="profile-bang-parent">
            <div class="profile-bang-child1">!</div>
            <div class="profile-bang-child2">!</div>
          </div>
          <div class="title5">
            <div class="profile-completion-title">
              {{ profileCompletion?.progress !== 100 ? 'Your profile isn’t complete yet!': 'Thank you for completing
              your
              profile!'}}
            </div>
            <div class="profile-completion-subtitle">
              {{ profileCompletion?.progress !== 100 ?
              'Do you know? completed profile got higher chance to get recommended and get hired!':
              'Just make sure that everything is up to date and you’ll get reached out soon! Good luck!'}}
            </div>
          </div>
          <div class="div">
            <div class="frame-1664">
              <div class="bar" [ngStyle]="{'--my-width': (profileCompletion?.progress) + '%'}"></div>
            </div>
            <div class="precentage">{{profileCompletion?.progress | number:'1.0-0' }} %</div>
          </div>
          <div class="frame-1663">
            <div *ngFor="let section of profileCompletion?.section" class="frame-1658">
              <div *ngIf="section.isComplete" class="checkbox">
                <div class="check">
                  <svg class="check-lg" width="12" height="10" viewBox="0 0 12 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.114 0.337953C10.5455 -0.112651 11.245 -0.112651 11.6764 0.337953C12.1026 0.783032 12.1078 1.50126 11.6921 1.95303L5.81144 9.63035C5.80296 9.64143 5.79389 9.65201 5.78428 9.66205C5.35284 10.1127 4.65333 10.1127 4.22189 9.66205L0.323582 5.59059C-0.107861 5.13999 -0.10786 4.40941 0.323581 3.95881C0.755023 3.5082 1.45453 3.5082 1.88597 3.95881L4.9698 7.1796L10.0847 0.372469C10.0938 0.360362 10.1036 0.348836 10.114 0.337953Z"
                      fill="#FDFDFD" />
                  </svg>
                </div>
              </div>
              <div *ngIf="!section.isComplete" class="checkbox2"></div>
              <div class="personal-info">{{section.label | startCase }}</div>
              <svg class="info-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L7.92875 11.293C7.85844 11.6328 7.95805 11.8262 8.23344 11.8262C8.4268 11.8262 8.71977 11.7559 8.91898 11.5801L8.83109 11.9961C8.54398 12.3418 7.91117 12.5938 7.36625 12.5938C6.66313 12.5938 6.3643 12.1719 6.55766 11.2754L7.29594 7.80664C7.36039 7.51367 7.3018 7.4082 7.00883 7.33789L6.55766 7.25586L6.63969 6.875L8.9307 6.58789ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5C9 5.05228 8.55229 5.5 8 5.5Z"
                  [attr.fill]="progressText" />
              </svg>
            </div>
          </div>
          <!-- <button style="width: 100%;" class="ec-btn ec-btn-sm ec-btn-secondary ec-btn-rounded mb-2 me-2">
            Preview Profile
          </button> -->
        </div>
      </div>
      <!-- ========== End left sidebar ========== -->
      <!-- ========== Start content ========== -->
      <div class="col-sm-7 col-sm-8 col-lg-9 d-md-block" style="padding-right: 15px;">
        <div class="frame-1656">
          <div class="basic">
            <div class="profile-section">
              <img style="cursor: pointer;" title="Edit Photo" *ngIf="!userProfile.profilePictureUrl"
                (click)="editPhotoProfile()" src="./assets/img/project/default-user-picture.png"
                class="profile-picture-img" />
              <img style="cursor: pointer;" title="Edit Photo" *ngIf="userProfile.profilePictureUrl"
                (click)="editPhotoProfile()" [src]="userProfile.profilePictureUrl" class="profile-picture-img" />
            </div>
            <div class="basic2">
              <div class="basic3">
                <div class="username2">{{userProfile.firstName | startCase}} {{userProfile.lastName | startCase}}</div>
                <div class="as">
                  <div class="front-end-developer-wannabe">
                    {{ userProfile.position || '-' }}
                  </div>
                  <div class="working-as-what-and-where-looking-for-job">
                    at {{ userProfile.currentCompany || '-' }} {{ userProfile.isLookingForWork ? ' / Looking for work':
                    '' }}
                  </div>
                </div>
              </div>
              <div class="frame-1632">
                <div class="email">
                  <svg class="at" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.1064 7.22168C13.1064 4.25488 10.8574 2.19043 7.62402 2.19043C4.27441 2.19043 1.97754 4.50781 1.97754 7.8916C1.97754 11.3848 4.21289 13.5996 7.74023 13.5996C8.60156 13.5996 9.42871 13.4766 10.0439 13.2646V12.4033C9.61328 12.6016 8.69043 12.7314 7.75391 12.7314C4.82812 12.7314 2.94141 10.8516 2.94141 7.93262C2.94141 5.08887 4.8623 3.05176 7.53516 3.05176C10.2695 3.05176 12.1426 4.74023 12.1426 7.20801C12.1426 8.88965 11.5889 9.97656 10.7275 9.97656C10.2354 9.97656 9.95508 9.69629 9.95508 9.21777V5.20508H8.92285V6.03906H8.81348C8.54688 5.44434 7.93164 5.0752 7.21387 5.0752C5.8125 5.0752 4.83496 6.2373 4.83496 7.89844C4.83496 9.63477 5.79199 10.8037 7.21387 10.8037C8.01367 10.8037 8.62891 10.4141 8.92285 9.7168H9.03223C9.11426 10.3867 9.73633 10.8652 10.5361 10.8652C12.1084 10.8652 13.1064 9.4502 13.1064 7.22168ZM5.92871 7.92578C5.92871 6.72949 6.46875 6.01855 7.38477 6.01855C8.31445 6.01855 8.90918 6.75684 8.90918 7.92578C8.90918 9.09473 8.30762 9.83984 7.37109 9.83984C6.47559 9.83984 5.92871 9.11523 5.92871 7.92578Z"
                      fill="#757575" />
                  </svg>
                  <div class="email-text">{{userProfile.email}}</div>
                </div>
                <div class="email">
                  <svg class="telephone-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.88477 0.511076C2.62689 -0.231039 3.85149 -0.154797 4.49583 0.673634L6.28954 2.97983C6.6187 3.40304 6.73502 3.95409 6.60498 4.47423L6.05772 6.66329C5.99994 6.8944 6.06766 7.13888 6.2361 7.30732L8.69268 9.7639C8.86113 9.93235 9.1056 10.0001 9.33671 9.94229L11.5258 9.39502C12.0459 9.26499 12.597 9.3813 13.0202 9.71047L15.3264 11.5042C16.1548 12.1485 16.231 13.3731 15.4889 14.1152L14.455 15.1492C13.7153 15.8889 12.6089 16.2137 11.5778 15.8512C9.01754 14.9511 6.61438 13.4774 4.56849 11.4315C2.5226 9.38562 1.04895 6.98246 0.148838 4.42225C-0.213682 3.39112 0.11113 2.28472 0.85085 1.545L1.88477 0.511076Z"
                      fill="#757575" />
                  </svg>

                  <div class="phone-number-text">{{userProfile.phone}}</div>
                </div>
                <div class="email">
                  <svg class="pin-map-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3.1 11.2C3.19443 11.0741 3.34262 11 3.5 11H6C6.27615 11 6.5 11.2239 6.5 11.5C6.5 11.7761 6.27615 12 6 12H3.75L1.5 15H14.5L12.25 12H10C9.72386 12 9.5 11.7761 9.5 11.5C9.5 11.2239 9.72386 11 10 11H12.5C12.6574 11 12.8056 11.0741 12.9 11.2L15.9 15.2C16.0136 15.3515 16.0319 15.5542 15.9472 15.7236C15.8625 15.893 15.6894 16 15.5 16H0.500003C0.310617 16 0.137485 15.893 0.0527893 15.7236C-0.0319067 15.5542 -0.0136288 15.3515 0.100003 15.2L3.1 11.2Z"
                      fill="#757575" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.03981 10.4732 7.723 8.5 7.96905V13.5C8.5 13.7761 8.27615 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5V7.96905C5.52685 7.723 4 6.03981 4 4Z"
                      fill="#757575" />
                  </svg>

                  <div class="country-text">{{(userProfile.country | startCase) || '-'}} ,
                    {{(userProfile.preferredTimezone) || '-'}}</div>
                </div>
              </div>
            </div>
            <div class="actions">
              <svg (click)="editBasicInfo()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                  fill="#233D90" />
                <path
                  d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                  fill="#233D90" />
                <path
                  d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                  fill="#233D90" />
              </svg>
            </div>
          </div>
          <div class="info">
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="about">About</div>
                  <div class="actions">
                    <svg (click)="editAbout()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                        fill="#233D90" />
                      <path
                        d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                        fill="#233D90" />
                      <path
                        d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                        fill="#233D90" />
                    </svg>
                  </div>
                </div>
                <div class="line-4"></div>
                <div class="content">
                  <div class="about-me">
                    <div [innerHTML]="this.userProfile.about | safeHtml"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="card-title-text">Education</div>
                </div>
                <div class="line-4"></div>
                <ng-container *ngFor="let education of userProfile.education">
                  <div class="profile-item">
                    <div class="profile-item-title">
                      <div class="school-name">{{ education.schoolName }}</div>
                      <div class="duration">
                        {{ education.startDate | date: 'MMMM y' }} - {{ education.stillAttend ? 'Present' :
                        education.endDate
                        | date: 'MMMM y' }}
                      </div>
                      <div class="time-range"> {{ education | timeRange }}</div>
                    </div>
                    <div class="actions">
                      <svg (click)="editEducation(education)" class="pencil-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                          fill="#233D90" />
                        <path
                          d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                          fill="#233D90" />
                        <path
                          d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                          fill="#233D90" />
                      </svg>
                      <svg (click)="removeEducation(education)" class="trash-3-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                          fill="#CB3A31" />
                      </svg>
                    </div>
                  </div>
                </ng-container>
                <div class="buttonrounded cursor" (click)="addNewEducation()">
                  <div class="icn">
                    <svg class="plus-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
                        fill="#FDFDFD" />
                    </svg>
                  </div>
                  <div class="button">Add More</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="card-title-text">Work Experience</div>
                </div>
                <div class="line-4"></div>
                <ng-container *ngFor="let experience of userProfile.experience">
                  <div class="profile-item">
                    <div class="experience-left-content">
                      <div class="experience-duration">
                        {{ experience.startDate | date: 'MMM y' }} - {{ experience.stillAttend ? 'Present' :
                        experience.endDate | date: 'MMM y' }}
                      </div>
                      <div class="time-range"> {{ experience | timeRange }}</div>
                    </div>
                    <div class="what">
                      <div class="frame-1635">
                        <div class="job-title">{{ experience.position }}</div>
                        <div class="company-name">
                          {{ experience.companyName }}
                        </div>
                      </div>
                      <div class="desc3">
                        <div class="portfolio-description">
                          <div [innerHTML]="experience.jobDetail | safeHtml"></div>
                        </div>
                      </div>
                    </div>
                    <div class="actions">
                      <svg (click)="editWorkHistory(experience)" class="pencil-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                          fill="#233D90" />
                        <path
                          d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                          fill="#233D90" />
                        <path
                          d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                          fill="#233D90" />
                      </svg>

                      <svg (click)="removeWorkHistory(experience)" class="trash-3-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                          fill="#CB3A31" />
                      </svg>
                    </div>
                  </div>
                </ng-container>
                <div class="buttonrounded cursor" (click)="addNewWorkHistory()">
                  <div class="icn">
                    <svg class="plus-circle-fill2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
                        fill="#FDFDFD" />
                    </svg>
                  </div>
                  <div class="button">Add More</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="skill">Skill</div>
                </div>
                <div class="line-4"></div>
                <div class="skill-sub">Hard Skills</div>
                <div class="tag">
                  <div *ngFor="let item of userProfile.hardSkills" class="buttonrounded-stroked">
                    <div class="button2">{{item.skill}}</div>
                  </div>
                </div>
                <div class="skill-sub">Soft Skills</div>
                <div class="tag">
                  <div *ngFor="let item of userProfile.softSkills" class="buttonrounded-stroked">
                    <div class="button2">{{item.skill}}</div>
                  </div>
                </div>
                <div class="buttonrounded cursor" (click)="editSkills()">
                  <div class="icn">
                    <svg class="plus-circle-fill3" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
                        fill="#FDFDFD" />
                    </svg>
                  </div>
                  <div class="button">Edit / Add More</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="portfolio">Portfolio</div>
                <div class="line-4"></div>
                <ng-container *ngFor="let portfolio of userProfile.portofolio; let last = last">
                  <div class="profile-item">
                    <div class="what">
                      <div class="frame-1635">
                        <div class="project-name">{{portfolio.projectName}}</div>
                        <div class="portfolio-duration">
                          {{ portfolio.startDate | date: 'MMMM y' }} - {{ portfolio.isOngoing ? 'Present' :
                          portfolio.endDate
                          | date: 'MMMM y' }}
                        </div>
                      </div>
                      <div class="desc3">
                        <div class="portfolio-description">
                          <div [innerHTML]="portfolio.description | safeHtml"></div>
                        </div>
                      </div>
                    </div>
                    <div class="actions">
                      <svg (click)="editPortfolio(portfolio)" class="pencil-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                          fill="#233D90" />
                        <path
                          d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                          fill="#233D90" />
                        <path
                          d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                          fill="#233D90" />
                      </svg>

                      <svg (click)="removePortfolio(portfolio)" class="trash-3-fill" width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11 1.5V2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H13.9616L13.1088 14.1595C13.0257 15.1989 12.1579 16 11.1152 16H4.88479C3.84207 16 2.97431 15.1989 2.89116 14.1595L2.0384 3.5H1.5C1.22386 3.5 1 3.27614 1 3C1 2.72386 1.22386 2.5 1.5 2.5H5V1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5ZM6 1.5V2.5H10V1.5C10 1.22386 9.77614 1 9.5 1H6.5C6.22386 1 6 1.22386 6 1.5ZM4.49999 5.0285L4.99999 13.5285C5.0162 13.8042 5.25282 14.0145 5.52849 13.9983C5.80415 13.9821 6.01448 13.7454 5.99826 13.4698L5.49826 4.96978C5.48205 4.69411 5.24543 4.48379 4.96976 4.5C4.6941 4.51622 4.48377 4.75283 4.49999 5.0285ZM11.0302 4.50086C10.7546 4.48465 10.5179 4.69497 10.5017 4.97064L10.0017 13.4706C9.98552 13.7463 10.1958 13.9829 10.4715 13.9991C10.7472 14.0154 10.9838 13.805 11 13.5294L11.5 5.02936C11.5162 4.75369 11.3059 4.51708 11.0302 4.50086ZM8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.72386 14 8 14C8.27615 14 8.5 13.7761 8.5 13.5V5C8.5 4.72386 8.27615 4.5 8 4.5Z"
                          fill="#CB3A31" />
                      </svg>
                    </div>
                  </div>
                  <div *ngIf="!last" class="line-5"></div>
                </ng-container>
                <div class="buttonrounded cursor" (click)="addNewPortfolio()">
                  <div class="icn">
                    <svg class="plus-circle-fill4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
                        fill="#FDFDFD" />
                    </svg>
                  </div>
                  <div class="button">Add More</div>
                </div>
                <div class="line-5"></div>
                <div class="profile-item">
                  <div class="when3">
                    <div class="personal-website">Personal Website</div>
                    <div class="personal-website-text">
                      {{ userProfile.resume?.personalWebsite || '-'}}
                    </div>
                  </div>
                  <div class="actions">
                    <svg (click)="editPersonalWebiste()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                        fill="#233D90" />
                      <path
                        d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                        fill="#233D90" />
                      <path
                        d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                        fill="#233D90" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="skill">Language</div>
                </div>
                <div class="line-4"></div>
                <div class="tag">
                  <div *ngFor="let item of userProfile.languages" class="buttonrounded-stroked">
                    <div class="button2">{{item.language}}</div>
                  </div>
                </div>
                <div class="buttonrounded cursor" (click)="editLanguage()">
                  <div class="icn">
                    <svg class="plus-circle-fill3" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
                        fill="#FDFDFD" />
                    </svg>
                  </div>
                  <div class="button">Edit / Add More</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ========== End content ========== -->

    </div>
  </div>
</div>
