<div class="extra">
  <div class="option">
    <div class="title">
      <div class="frame-1673">
        <div class="number-of-talents">Number of talents</div>
      </div>
      <div class="line-6"></div>
    </div>
    <div class="frame-1702">
      <div class="currency">
        <div class="talents-needed">Talents Needed</div>
        <div class="_03">{{job.talentNeeded}}</div>
      </div>
    </div>
  </div>
  <div class="option">
    <div class="title">
      <div class="frame-1673">
        <div class="offering-rate">Offering rate</div>
      </div>
      <div class="line-6"></div>
    </div>
    <div class="frame-1702">
      <div class="frame-1697">
        <div class="currency">
          <div class="currency2">Currency</div>
          <div class="idr-rupiah">{{job.offerCurrency}}</div>
        </div>
        <div class="currency">
          <div class="nominal">Nominal</div>
          <div class="number">
            <div class="frame-1700">
              <div class="_10-000-000">{{job.minimumSalary | number:'1.0-0'}}</div>
            </div>
            <div class="line-62"></div>
            <div class="frame-1701">
              <div class="_15-000-000">{{job.maximumSalary | number:'1.0-0'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="excludes-stock-bonuses-tips-or-other-compensation">
        Excludes stock, bonuses, tips, or other compensation
      </div>
      <div class="option">
        <div class="pay-period">Pay Period</div>
        <div class="monthly">{{job.paymentPeriod}}</div>
      </div>
    </div>
  </div>
  <div class="email-notif">
    <div class="title">
      <div class="frame-1673">
        <div class="job-post-status">Job post status</div>
      </div>
      <div class="line-6"></div>
    </div>
    <div class="ctn" *ngIf="job.isPublic">
      <div class="public">Public</div>
      <div class="frame-12">
        <div class="public-post-can-be-seen-by-all-verified-and-unverified-accounts">
          *Public post can be seen by all (verified and unverified) accounts.
        </div>
      </div>
    </div>
    <div class="ctn" *ngIf="!job.isPublic">
      <div class="public">Private</div>
      <div class="frame-12">
        <div class="public-post-can-be-seen-by-all-verified-and-unverified-accounts">
          *Private post can be seen only by verified accounts.
        </div>
      </div>
    </div>
  </div>
</div>
