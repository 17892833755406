<div class="accounts">
  <div class="title">
    <div class="accounts2">Accounts</div>
  </div>
  <div class="accounts3">
    <div *ngFor="let account of accounts" class="activeacc">
      <div class="ellipse-2"></div>
      <div class="basic">
        <div class="username">{{account.firstName}} {{account.lastName}}</div>
        <div class="as">
          <div class="admin">Admin</div>
        </div>
      </div>
    </div>
    <!-- <div class="basic2">
      <div class="ellipse-2"></div>
      <div class="basic">
        <div class="username">Biasa aja</div>
        <div class="as">
          <div class="staff">Staff</div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="action">
    <div class="btn-rounded bg-primary-soft" (click)="triggerCallback()">
      <label>
        Manage Account
        <i class="bi bi-arrow-right">
        </i>
      </label>
    </div>
  </div>
</div>
