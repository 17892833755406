import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { Reference } from '@interfaces/reference.interface';
import { UserProfile } from '@interfaces/talent.interface';
import { ReferenceService } from '@services/reference.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})
export class PreferenceComponent implements OnInit {

  public preferenceForm !: FormGroup<{
    workPreferenceInput: FormControl<string | null>,
    industryPreferenceInput: FormControl<string | null>,
    employmentTypeInput: FormControl<string | null>,
    locationInput: FormControl<string | null>,
    workTypePreference: FormControl<string>,
  }>;
  public isLoading = false;
  public errorMessage = '';
  public workPreferenceInvalidMessage = '';
  public industryPreferenceInvalidMessage = '';
  public employmentTypeInvalidMessage = '';
  public locationInvalidMessage = '';
  public workPreferenceList: string[] = [];
  public industryPreferenceList: string[] = [];
  public employmentTypeList: string[] = [];
  public locationList: Reference[] = [];
  public jobTypes: Reference[] = [];
  public userProfile !: UserProfile | null;

  constructor
    (
      private talentService: TalentService,
      private router: Router,
      private referenceService: ReferenceService,
      private storeService: StoreService,
      private toastManager: ToastManager,
    ) {
    this.initFormGroup();
    this.storeService.setActiveSubNavbar('preferences')
  }

  private initFormGroup(): void {
    this.preferenceForm = new FormGroup({
      workPreferenceInput: new FormControl<string>({
        value: '',
        disabled: false
      }),
      industryPreferenceInput: new FormControl<string>({
        value: '',
        disabled: false
      }),
      employmentTypeInput: new FormControl<string>({
        value: '',
        disabled: false
      }),
      locationInput: new FormControl<string>({
        value: '',
        disabled: false
      }),
      workTypePreference: new FormControl<string>({
        value: '',
        disabled: false
      }, {
        validators: [
          Validators.required
        ],
        nonNullable: true
      }),
    })
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.jobTypes = await this.referenceService.getReferenceListByType('WORK_TYPE');
      this.locationList = await this.referenceService.getReferenceListByType('LOCATION');
      this.storeService.userProfile$.subscribe(userProfile => {
        this.userProfile = userProfile;
        if (userProfile) {
          this.setFormValue(userProfile);
        }
      })
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private setFormValue(userProfile: UserProfile) {
    this.preferenceForm.get('locationInput')?.setValue(userProfile.locationPreference || '');
    this.preferenceForm.get('workTypePreference')?.setValue(userProfile.workTypePreference || '');
    this.employmentTypeList = userProfile.employmentPreferences.map(item => item.employment) || [];
    this.industryPreferenceList = userProfile.industryPreference.map(item => item.industry) || [];
    this.workPreferenceList = userProfile.workPreference.map(item => item.work) || [];
  }

  public addWorkPreference() {
    const isWorkPreferenceLimitExceeded = this.workPreferenceList.length > MAX_LENGTH.SKILL;
    const workPreferenceInput = this.preferenceForm.get('workPreferenceInput');
    if (!isWorkPreferenceLimitExceeded) {
      if (workPreferenceInput?.value && !this.workPreferenceList.includes(workPreferenceInput.value)) {
        const isAlphaNumericValue = ALPHANUMERIC_PATTERN.test(workPreferenceInput.value);
        if (!isAlphaNumericValue) {
          this.toastManager.showError(null, 'Work Preference should be alpha numeric only and minimum 3 characters')
          return;
        }
        this.workPreferenceList.push(workPreferenceInput.value);
        workPreferenceInput.setValue('');
      }
    }
  }

  public removeWorkPreference(workPreference: string) {
    const index = this.workPreferenceList.indexOf(workPreference);
    if (index !== -1) {
      this.workPreferenceList.splice(index, 1);
    }
  }

  public isWorkPreferenceValid = () => {
    if (this.workPreferenceList.length > MAX_LENGTH.SKILL) {
      this.workPreferenceInvalidMessage = `Work Preference length maximum ${MAX_LENGTH.SKILL} preferences`
      return false
    }

    return true
  }

  public addEmploymentType() {
    const isEmploymentStatusLimitExceeded = this.employmentTypeList.length > MAX_LENGTH.SKILL;
    const employmentTypeInput = this.preferenceForm.get('employmentTypeInput');
    if (!isEmploymentStatusLimitExceeded) {
      if (employmentTypeInput?.value && !this.employmentTypeList.includes(employmentTypeInput.value)) {
        const isAlphaNumericValue = ALPHANUMERIC_PATTERN.test(employmentTypeInput.value);
        if (!isAlphaNumericValue) {
          this.toastManager.showError(null, 'Employment Type should be alpha numeric only and minimum 3 characters')
          return;
        }

        this.employmentTypeList.push(employmentTypeInput.value);
        employmentTypeInput.setValue('');
      }
    }
  }

  public removeEmploymentType(employmentType: string) {
    const index = this.employmentTypeList.indexOf(employmentType);
    if (index !== -1) {
      this.employmentTypeList.splice(index, 1);
    }
  }

  public isEmploymentTypeValid = () => {
    if (this.employmentTypeList.length > MAX_LENGTH.SKILL) {
      this.employmentTypeInvalidMessage = `Employment type length maximum ${MAX_LENGTH.SKILL} preferences`
      return false
    }

    return true
  }

  public addIndustryPreference() {
    const isIndustryPreferenceLimitExceeded = this.industryPreferenceList.length > MAX_LENGTH.SKILL;
    const industryPreferenceInput = this.preferenceForm.get('industryPreferenceInput');
    if (!isIndustryPreferenceLimitExceeded) {
      if (industryPreferenceInput?.value && !this.industryPreferenceList.includes(industryPreferenceInput.value)) {
        const isAlphaNumericValue = ALPHANUMERIC_PATTERN.test(industryPreferenceInput.value);
        if (!isAlphaNumericValue) {
          this.toastManager.showError(null, 'Industry Preference should be alpha numeric only and minimum 3 characters')
          return;
        }

        this.industryPreferenceList.push(industryPreferenceInput.value);
        industryPreferenceInput.setValue('');
      }
    }
  }

  public removeIndustryPreference(industryPreference: string) {
    const index = this.industryPreferenceList.indexOf(industryPreference);
    if (index !== -1) {
      this.industryPreferenceList.splice(index, 1);
    }
  }

  public isIndustryPreferenceValid = () => {
    if (this.industryPreferenceList.length > MAX_LENGTH.SKILL) {
      this.industryPreferenceInvalidMessage = `Industry Preference length maximum ${MAX_LENGTH.SKILL} preferences`
      return false
    }

    return true
  }


  public async onClickSave(): Promise<void> {
    try {
      this.isLoading = true;
      const preferencePayload = {
        industryPreference: this.industryPreferenceList,
        workPreference: this.workPreferenceList,
        employmentTypePreference: this.employmentTypeList,
        locationPreference: this.preferenceForm.value.locationInput,
        workTypePreference: this.preferenceForm.value.workTypePreference,
      }
      await this.talentService.editPreference(preferencePayload);
      await this.talentService.getProfile();
      this.toastManager.showSuccess('Preference saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
