<div class="job-form" *ngIf="userProfile">
  <div class="basic">
    <div class="basic2" #resize>
      <div class="resume-profile-section">
        <img *ngIf="!userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
          class="profile-picture-img" />
        <img *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl" class="profile-picture-img" />
      </div>
      <div class="basic3">
        <div class="basic4">
          <div class="username">{{userProfile.firstName}} {{ userProfile.lastName}}</div>
          <div class="as">
            <div class="front-end-developer-wannabe">
              {{ userProfile.position || '-' }}
            </div>
            <div class="at-company-x">
              <span><span *ngIf="userProfile.currentCompany" class="at-company-x-span">at </span><span
                  class="at-company-x-span2">
                  {{ userProfile.currentCompany || '-' }} {{ userProfile.isLookingForWork ? ' / Looking for work':
                  '' }}
                </span></span>
            </div>
          </div>
          <div class="chip-border" [ngClass]="userProfile.isEnigmaTalent ? 'border-orange': 'border-blue'">
            <label>{{ userProfile.isEnigmaTalent ? 'Enigma Graduates': 'Experience Hiring'}}
            </label>
          </div>
        </div>
        <div [ngSwitch]="cardType" style="width: 100%;">
          <div [ngSwitch]="cardType" class="frame-1632" *ngSwitchCase="'pending-registration'">
            <div class="email">
              <i class="bi bi-at"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.email) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-telephone-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.phone) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
          <div [ngSwitch]="cardType" class="frame-1632" *ngSwitchCase="'back-office'">
            <div class="email">
              <i class="bi bi-at"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.email) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-telephone-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.phone) || '-'}}
              </div>
            </div>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
          <div class="frame-1632" *ngSwitchDefault>
            <div class="email">
              <i class="bi bi-pin-map-fill"></i>
              <div class="jakarta-indonesia-asia-uct-4-30">
                {{(userProfile.country | startCase) || '-'}} ,
                {{(userProfile.preferredTimezone) || '-'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngSwitch]="cardType">
        <div class="cta" *ngSwitchCase="'applicant'">
          <div class="btn-rounded bg-success-soft" *ngIf="applicationStatus === 1">
            <label>
              <i class="bi bi-file-earmark-check">
              </i>
              On Process
            </label>
          </div>
          <ng-container>
            <div class="btn-rounded bg-primary-soft" (click)="approveApplicantForJob()" *ngIf="applicationStatus === 0">
              <label>
                <i class="bi bi-file-earmark-check">
                </i>
                Approve
              </label>
            </div>
            <div class="btn-rounded bg-danger-soft" (click)="declineApplicantForJob()" *ngIf="applicationStatus === 0">
              <label>
                <i class="bi bi-file-earmark-excel">
                </i>
                Decline
              </label>
            </div>
          </ng-container>
          <div class="btn-rounded bg-danger-soft" *ngIf="applicationStatus === 2">
            <label>
              <i class="bi bi-file-earmark-excel">
              </i>
              Declined
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" *ngIf="applicationStatus !== 2"
            (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchCase="'pending-registration'">
          <div class="btn-rounded bg-primary-soft" (click)="onApproveTalentClick()">
            <label>
              <i class="bi bi-patch-check">
              </i>
              Approve
            </label>
          </div>
          <div class="btn-border-rounded  btn-border-danger-soft" (click)="openRejetedModal()">
            <label>
              <i class="bi bi-x-circle-fill">
              </i>
              Reject
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchCase="'on-boarding-talent'">
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
        <div class="cta" *ngSwitchDefault>
          <div class="btn-rounded bg-primary-soft" (click)="onGiveAnOfferClick()" *ngIf="isOfferingButtonShown">
            <label>
              <i class="bi bi-person-fill-add">
              </i>
              Give an Offer
            </label>
          </div>
          <div class="btn-border-rounded btn-border-primary-soft" (click)="onClickDownloadClick()">
            <label>
              <i class="bi bi-download">
              </i>
              Download
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="content">
        <div class="about" *ngIf="userProfile.about">
          <div [innerHTML]="userProfile.about | safeHtml"></div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="education">Education</div>
      </div>
      <div class="line-4"></div>
      <div class="ctn" *ngFor="let education of userProfile.education">
        <div class="job">
          <div class="when">
            <div class="smk-sekolah">{{ education.schoolName }}</div>
            <div class="august-2018-march-2020">{{ education.startDate | date: 'MMM y' }} - {{ education.stillAttend ?
              'Present' :
              education.endDate
              | date: 'MMM y' }}</div>
            <div class="_2-year-s-1-month-s">{{ education | timeRange }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="work-experience">Work Experience</div>
      </div>
      <div class="line-4"></div>
      <div class="ctn" *ngFor="let experience of userProfile.experience">
        <div class="job" #resize>
          <div class="when2">
            <div class="august-2018-march-20202">
              {{ experience.startDate | date: 'MMM y' }} - {{ experience.stillAttend ? 'Present' :
              experience.endDate | date: 'MMM y' }}
            </div>
            <div class="_2-year-s-1-month-s"> {{ experience | timeRange }}</div>
          </div>
          <div class="what">
            <div class="frame-1635">
              <div class="job-title">{{ experience.position }}</div>
              <div class="pt-company-indonesia"> {{ experience.companyName }}</div>
            </div>
            <div class="desc" *ngIf="experience.jobDetail">
              <div [innerHTML]="experience.jobDetail | safeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="skill">Skill</div>
      </div>
      <div class="line-4"></div>
      <div class="div">
        <div class="hardskill">
          <div class="title2">
            <div class="sub">Hard Skill</div>
          </div>
          <div class="frame-1674">
            <div *ngFor="let item of userProfile.hardSkills" class="buttonrounded-stroked">
              <div class="button3">{{item.skill}}</div>
            </div>
          </div>
        </div>
        <div class="soft-skill">
          <div class="title2">
            <div class="sub">Soft Skill</div>
          </div>
          <div class="frame-1675">
            <div *ngFor="let item of userProfile.softSkills" class="buttonrounded-stroked">
              <div class="button3">{{item.skill}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="portfolios">
      <div class="basic5">
        <div class="portfolio">Portfolio</div>
        <div class="line-4"></div>
        <div class="ctn2" *ngFor="let portfolio of userProfile.portofolio; let last = last">
          <div class="job">
            <div class="what">
              <div class="frame-16352">
                <div class="project-name">{{portfolio.projectName}}</div>
                <div class="august-2018-march-20203">
                  {{ portfolio.startDate | date: 'MMMM y' }} - {{ portfolio.isOngoing ? 'Present' :
                  portfolio.endDate
                  | date: 'MMMM y' }}
                </div>
              </div>
              <div class="desc2">
                <ng-container *ngIf="portfolio.description">
                  <div [innerHTML]="portfolio.description | safeHtml"></div>
                </ng-container>
                <div class="project-link-https-links-com">
                  {{ portfolio.url}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!last" class="line-5"></div>
        </div>
        <div *ngIf="userProfile.portofolio.length > 1" class="line-52"></div>
        <div class="job">
          <div class="when3">
            <div class="personal-website">Personal Website</div>
            <div class="https-personal-com-si-paling-personalwebsitebro">
              {{ userProfile.resume?.personalWebsite || '-'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="basic5">
      <div class="title">
        <div class="language">Language</div>
      </div>
      <div class="line-4"></div>
      <div class="div">
        <div class="frame-1674">
          <div *ngFor="let item of userProfile.languages" class="buttonrounded-stroked">
            <div class="button3">{{item.language}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <popup-confirmation id="reject-talent">
    <div class="popup popup-danger">
      <div class="popup-frame-1673">
        <div class="popup-title">
          <div class="popup-title-label popup-danger">Reject</div>
        </div>
        <svg (click)="onCloseModal()" class="popup-x" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="popup-data-length">
        Talent
      </div>
      <div *ngIf="errorPopUpMessage" class="error-message">{{ errorPopUpMessage }}</div>
      <div class="popup-selected-items">
        <!-- <div *ngFor="let talent of selectedTalents; let i = index"> -->
        <div class="popup-user-profile-section">
          <div class="popup-user-profile">
            <div class="popup-pp">
              <img *ngIf="!userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
                class="popup-image" />
              <img *ngIf="userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl" class="popup-image" />
            </div>
          </div>
          <div class="popup-details">
            <div class="popup-name">{{userProfile.firstName}} {{userProfile.lastName}}</div>
            <div class="popup-as">
              <div class="popup-email">{{userProfile.email}}</div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Reason
        </div>
        <input [(ngModel)]="rejectReason" type="text" id="rejectReason" class="ec-form-control-rounded ec-is-invalid"
          placeholder="Input the rejected reason">
      </div>
      <div class="popup-button">
        <button type="button" (click)="rejectTalent()"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Okay, Reject
        </button>
        <button type="button" (click)="onCloseModal()"
          class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
          No, Turn Back
        </button>
      </div>
    </div>
  </popup-confirmation>
</div>
