import { Component, Input } from '@angular/core';

@Component({
  selector: 'recruitment-svg',
  templateUrl: './recruitment-svg.component.html',
  styleUrls: ['./recruitment-svg.component.scss']
})
export class RecruitmentSvgComponent {

  @Input() step!: string;
}
