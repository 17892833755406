<app-spinner *ngIf="isLoading"></app-spinner>
<ng-container *ngIf="userProfile">
  <div class="navbar">
    <div class="log-section">
      <img src="./assets/img/project/enigma-logo-white.svg" class="navbar-logo" />
    </div>
    <div class="navbar-menu">
      <div [ngClass]="activeNavbar === 'dashboard' ? 'active-navbar': 'inactive-navbar'"
        (click)="onNavbarClick('dashboard')">
        <div class="nav-bar-text">Dashboard</div>
      </div>
      <div [ngClass]="activeNavbar === 'find-job' ? 'active-navbar': 'inactive-navbar'"
        (click)="onNavbarClick('find-job')">
        <div class="nav-bar-text">Find Job</div>
      </div>
    </div>
    <div class="navbar-profile">
      <div (click)="onNavbarClick('notification')" class="notification-container">
        <svg class="bell-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 16C9.10457 16 10 15.1046 10 14H6C6 15.1046 6.89543 16 8 16Z" fill="#FDFDFD" />
          <path
            d="M8.99516 1.09903C8.99836 1.06646 9 1.03342 9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1C7 1.03342 7.00164 1.06646 7.00484 1.09904C4.7202 1.56045 3.00002 3.57934 3.00002 6C3.00002 7.0976 2.5 12 1 13H15C13.5 12 13 7.0976 13 6C13 3.57932 11.2798 1.56042 8.99516 1.09903Z"
            fill="#FDFDFD" />
        </svg>
        <ng-container *ngIf="notificationCount > 0">
          <span class="notification-count" *ngIf="notificationCount > 0 && showCount">{{ notificationCount }}</span>
          <span class="notification-dot" *ngIf="notificationCount > 99"></span>
        </ng-container>
      </div>
      <div class="line-1"></div>
      <div class="nav-bar-text">{{userProfile.email}}</div>
      <div class="dropdown">
        <svg (click)="manageAccount()" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#FDFDFD" />
        </svg>
        <div class="dropdown-content">
          <div class="profile-drop-down">
            <div class="email">
              <div class="sirojuldakilla-gmail-com">
                {{userProfile.email}}
              </div>
            </div>
            <div class="company">
              <div class="profile-section">
                <img *ngIf="userProfile && userProfile.profilePictureUrl" [src]="userProfile.profilePictureUrl"
                  class="profile-picture-img" />
                <img *ngIf="userProfile && !userProfile.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
                  class="profile-picture-img" />
              </div>
              <div class="details">
                <div class="sirojul-da-killa">
                  {{userProfile.firstName}} {{userProfile.lastName}}</div>
                <div *ngIf="userProfile.type === 2" class="enigma-graduates">
                  {{ userProfile.isEnigmaTalent ? 'Enigma Graduates': 'Experience Hiring' }} </div>
              </div>
              <svg (click)="manageAccount()" class="person-gear" width="16" height="17" viewBox="0 0 16 17" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 5.5C11 7.15685 9.65685 8.5 8 8.5C6.34315 8.5 5 7.15685 5 5.5C5 3.84315 6.34315 2.5 8 2.5C9.65685 2.5 11 3.84315 11 5.5ZM8 7.5C9.10457 7.5 10 6.60457 10 5.5C10 4.39543 9.10457 3.5 8 3.5C6.89543 3.5 6 4.39543 6 5.5C6 6.60457 6.89543 7.5 8 7.5Z"
                  fill="#757575" />
                <path
                  d="M8.25606 14.5C8.14314 14.1805 8.06527 13.8445 8.02708 13.4965H3C3.00142 13.2497 3.15375 12.5104 3.8321 11.8321C4.48435 11.1798 5.71088 10.5 7.99999 10.5C8.26049 10.5 8.50723 10.5088 8.74092 10.5254C8.96608 10.184 9.23648 9.87517 9.54358 9.60733C9.07708 9.53817 8.56399 9.5 8 9.5C3 9.5 2 12.5 2 13.5C2 14.5 3 14.5 3 14.5H8.25606Z"
                  fill="#757575" />
                <path
                  d="M11.8855 9.95954C12.0661 9.34682 12.9339 9.34682 13.1145 9.95954L13.1583 10.1081C13.2737 10.4996 13.7209 10.6849 14.0793 10.4896L14.2154 10.4155C14.7763 10.11 15.39 10.7237 15.0845 11.2846L15.0104 11.4207C14.8151 11.7791 15.0004 12.2263 15.3919 12.3417L15.5405 12.3855C16.1532 12.5661 16.1532 13.4339 15.5405 13.6145L15.3919 13.6583C15.0004 13.7737 14.8151 14.2209 15.0104 14.5793L15.0845 14.7154C15.39 15.2763 14.7763 15.89 14.2154 15.5845L14.0793 15.5104C13.7209 15.3151 13.2737 15.5004 13.1583 15.8919L13.1145 16.0405C12.9339 16.6532 12.0661 16.6532 11.8855 16.0405L11.8417 15.8919C11.7263 15.5004 11.2791 15.3151 10.9207 15.5104L10.7846 15.5845C10.2237 15.89 9.60997 15.2763 9.91553 14.7154L9.98963 14.5793C10.1849 14.2209 9.99964 13.7737 9.60814 13.6583L9.45954 13.6145C8.84682 13.4339 8.84682 12.5661 9.45954 12.3855L9.60814 12.3417C9.99964 12.2263 10.1849 11.7791 9.98963 11.4207L9.91553 11.2846C9.60997 10.7237 10.2237 10.11 10.7846 10.4155L10.9207 10.4896C11.2791 10.6849 11.7263 10.4996 11.8417 10.1081L11.8855 9.95954ZM14 13C14 12.1716 13.3284 11.5 12.5 11.5C11.6716 11.5 11 12.1716 11 13C11 13.8284 11.6716 14.5 12.5 14.5C13.3284 14.5 14 13.8284 14 13Z"
                  fill="#757575" />
              </svg>
            </div>
            <div class="tabs-logout" style="cursor: pointer;" (click)="logout()">
              <div class="log-out">
                Log Out </div>
              <div class="box-arrow-left">
                <svg class="box-arrow-bg" width="9" height="10" viewBox="0 0 9 10" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.5 8.375C1.5 8.58211 1.66789 8.75 1.875 8.75L7.875 8.75C8.08211 8.75 8.25 8.58211 8.25 8.375L8.25 1.625C8.25 1.41789 8.08211 1.25 7.875 1.25L1.875 1.25C1.66789 1.25 1.5 1.41789 1.5 1.625L1.5 3.125C1.5 3.33211 1.33211 3.5 1.125 3.5C0.917893 3.5 0.75 3.33211 0.75 3.125L0.75 1.625C0.75 1.00368 1.25368 0.499999 1.875 0.499999L7.875 0.499999C8.49632 0.499999 9 1.00368 9 1.625L9 8.375C9 8.99632 8.49632 9.5 7.875 9.5L1.875 9.5C1.25368 9.5 0.75 8.99632 0.75 8.375L0.75 6.875C0.75 6.66789 0.917893 6.5 1.125 6.5C1.33211 6.5 1.5 6.66789 1.5 6.875L1.5 8.375Z"
                    fill="#C2C2C2" />
                </svg>
                <svg class="box-arrow" width="8" height="6" viewBox="0 0 8 6" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.109835 3.26517C-0.0366118 3.11872 -0.0366118 2.88128 0.109835 2.73484L2.35983 0.484836C2.50628 0.338388 2.74372 0.338388 2.89016 0.484835C3.03661 0.631282 3.03661 0.868719 2.89016 1.01517L1.28033 2.625L7.875 2.625C8.08211 2.625 8.25 2.79289 8.25 3C8.25 3.20711 8.08211 3.375 7.875 3.375L1.28033 3.375L2.89016 4.98483C3.03661 5.13128 3.03661 5.36872 2.89016 5.51516C2.74372 5.66161 2.50628 5.66161 2.35984 5.51516L0.109835 3.26517Z"
                    fill="#C2C2C2" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isSubNavbarShow" class="sub-navbar">
    <div
      [ngClass]="{'inactive-subnavbar': activeSubNavbar !== 'resume', 'active-subnavbar': activeSubNavbar === 'resume'}"
      (click)="onSubNavbarClick('resume')">
      <div class="sub-navbar-menu">
        <div class="sub-navbar-title">Resume</div>
      </div>
      <div class="sub-navbar-menu">
        <div class="sub-navbar-subtitle">Personalize your resume</div>
      </div>
    </div>
    <div
      [ngClass]="{'inactive-subnavbar': activeSubNavbar !== 'applications', 'active-subnavbar': activeSubNavbar === 'applications'}"
      (click)="onSubNavbarClick('applications')">
      <div class="sub-navbar-menu">
        <div class="sub-navbar-title">Applications</div>
      </div>
      <div class="sub-navbar-menu">
        <div class="sub-navbar-subtitle">Manage your applications</div>
      </div>
    </div>
    <div
      [ngClass]="{'inactive-subnavbar': activeSubNavbar !== 'saved-jobs', 'active-subnavbar': activeSubNavbar === 'saved-jobs'}"
      (click)="onSubNavbarClick('saved-jobs')">
      <div class="sub-navbar-menu">
        <div class="sub-navbar-title">Saved Jobs</div>
      </div>
      <div class="sub-navbar-menu">
        <div class="sub-navbar-subtitle">See your saved jobs</div>
      </div>
    </div>
    <div
      [ngClass]="{'inactive-subnavbar': activeSubNavbar !== 'placements', 'active-subnavbar': activeSubNavbar === 'placements'}"
      (click)="onSubNavbarClick('placements')">
      <div class="sub-navbar-menu">
        <div class="sub-navbar-title">Placements</div>
      </div>
      <div class="sub-navbar-menu">
        <div class="sub-navbar-subtitle">Check your progress</div>
      </div>
    </div>
    <div
      [ngClass]="{'inactive-subnavbar': activeSubNavbar !== 'preferences', 'active-subnavbar': activeSubNavbar === 'preferences'}"
      (click)="onSubNavbarClick('preferences')">
      <div class="sub-navbar-menu">
        <div class="sub-navbar-title">Preference</div>
      </div>
      <div class="sub-navbar-menu">
        <div class="sub-navbar-subtitle">Set up your job preferences</div>
      </div>
    </div>
  </div>
</ng-container>
