<div class="form-container">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="form-title">
    <div class="title-content">
      <div class="title-text">
        Personal Website </div>
      <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
          fill="#757575" />
      </svg>
    </div>
    <div class="break-line">
    </div>
  </div>
  <form class="form-body-personal-website" [formGroup]="personalWebsiteForm" (ngSubmit)="updatePersonalWebsite()">
    <div class="form">
      <div class="ec-input-group-rounded input-text-space">
        <span class="ec-input-group-text">
          <i class="bi bi-link-45deg"></i>
        </span>
        <input type="text" formControlName="personalWebsite" class="ec-form-control-rounded ec-is-invalid"
          placeholder="https://your-personal-webiste.com">
        <div class="ec-invalid-feedback"
          *ngIf="personalWebsiteForm.get('personalWebsite')?.touched && personalWebsiteForm.get('personalWebsite')?.hasError('required')">
          Personal website is required
        </div>
        <div class="ec-invalid-feedback"
          *ngIf="personalWebsiteForm.get('personalWebsite')?.touched && personalWebsiteForm.get('personalWebsite')?.hasError('maxlength') && !personalWebsiteForm.get('personalWebsite')?.hasError('pattern')">
          Personal website length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} characters
        </div>
        <div class="ec-invalid-feedback"
          *ngIf="personalWebsiteForm.get('personalWebsite')?.touched && personalWebsiteForm.get('personalWebsite')?.hasError('pattern')">
          Company Website should be url format
        </div>
      </div>
    </div>
    <div class="break-line">
    </div>
    <div class="button-group">
      <button type="submit" [disabled]="!personalWebsiteForm.valid"
        class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
        Save
        <i class="bi bi-arrow-right">
        </i>
      </button>
    </div>
  </form>
</div>
