import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { UserType } from '@enums/login.enum';
import { StorageHelper } from '@helpers/storage.helper';
import { UserProfile } from '@interfaces/talent.interface';
import { ProfileComponent } from '@pages/profile/profile.component';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { ModalInterface, ModalService } from '@services/modal.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'corporate-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public isLoading: boolean = false;
  public userProfile !: UserProfile | null;
  public activeNavbar = 'dashboard';
  public activeSubNavbar = 'company-profile';
  public errorMessage = '';
  public isSubNavbarShow = true;
  public isLogout = false;
  public loginAs = 'TALENT';
  private destroy$ = new Subject<void>();
  notificationCount: number = 0;

  constructor(
    private router: Router,
    private googleService: SocialAuthService,
    private talentService: TalentService,
    private corporateService: CorporateService,
    private modalService: ModalService,
    private storeService: StoreService,
    private jobService: JobService,
    private toastManager: ToastManager,
  ) {
    this.storeService.isSubNavbarShow$.subscribe((value) => {
      this.isSubNavbarShow = value;
    });
  }

  async ngOnInit(): Promise<void> {
    const currentLoggedUser = StorageHelper.getCurrentLoggedUser();
    if (currentLoggedUser?.type === UserType.TALENT) {
      this.storeService.setLoginAs('TALENT')
    }
    if (currentLoggedUser?.type === UserType.CORPORATE) {
      this.storeService.setLoginAs('CORPORATE')
    }

    combineLatest([
      this.storeService.activeSubnavbar$,
      this.storeService.userProfile$,
      this.storeService.companyProfile$,
      this.storeService.isSubNavbarShow$,
      this.storeService.loginAs$,
      this.talentService.unreadNotification$,
      this.storeService.activeNavbar$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        subNavbar,
        userProfile,
        companyProfile,
        isSubNavbarShow,
        loginAs,
        unreadNotificationCount,
        activeNavbar
      ]: [any, any, any, any, string, number, any]) => {
        this.notificationCount = unreadNotificationCount;
        if (activeNavbar) {
          this.activeNavbar = activeNavbar;
        }
        this.isSubNavbarShow = isSubNavbarShow;
        this.activeSubNavbar = subNavbar;
        this.loginAs = loginAs;

        if (subNavbar) {
          this.activeSubNavbar = subNavbar;
        }

        if (!companyProfile) {
          await this.getCompanyProfile();
        }

        if (!userProfile && !this.isLogout) {
          await this.getUserProfile();
        } else {
          this.userProfile = userProfile;
        }
      })
    if (StorageHelper.getCurrentLoggedUser()) {
      const unreadNotificationCount = await this.talentService.getUnredNotificationCount();
      this.notificationCount = unreadNotificationCount
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get showCount(): boolean {
    return this.notificationCount <= 99;
  }

  private async getUserProfile() {
    try {
      this.isLoading = true;
      const userProfile = await this.talentService.getProfile();
      this.userProfile = userProfile;
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private async getCompanyProfile() {
    try {
      this.isLoading = true;
      await this.corporateService.getCompanyProfile();
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public async logout(): Promise<void> {
    StorageHelper.removeGoogleState();
    StorageHelper.removeAccessToken();
    StorageHelper.removeRefreshToken();
    const loginType = StorageHelper.getLoginType();
    this.isLogout = true;
    if (loginType === 'GOOGLE') {
      try {
        await this.googleService.signOut();

      } catch (error: any) {
      }
    }
    this.storeService.resetUserProfile()
    this.storeService.setLogOut(true);
    this.jobService.resetSubscibedData();
    this.router.navigate(['/auth/login']);
  }

  public manageAccount() {
    const modalConfig: ModalInterface = {
      component: ProfileComponent,
      customLayout: {
        width: '856px',
      },
      callback: this.getUserProfile.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public onSubNavbarClick(category: string) {
    this.activeSubNavbar = category;
    const targetedRoute = `/corporate/${category}`;
    this.router.navigate([targetedRoute]);
  }

  public onNavbarClick(menu: string) {
    this.storeService.setActiveNavbar(menu)
    if (menu === 'find-talent') {
      this.storeService.hideSubNavbar();
      this.router.navigate(['/corporate/find-talent']);
    }

    if (menu === 'dashboard') {
      this.storeService.showSubNavbar();
      this.activeSubNavbar = 'company-profile';
      this.router.navigate(['/corporate/company-profile']);
    }

    if (menu === 'notification') {
      this.storeService.hideSubNavbar();
      this.router.navigate(['/corporate/notification']);
    }
  }
}
