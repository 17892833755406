<div class="container">
  <div class="row">
    <div class="col-md-6 no-padding active">
      <div class="left-content">
        <svg class="ilustrations" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path [@moveUpDown]="showCircle ? 'show' : 'hide'"
              d="M0 0H50C77.6142 0 100 22.3858 100 50V100H50C22.3858 100 0 77.6142 0 50V0Z" fill="#FFBB95" />
            <path d="M0 150C0 122.386 22.3858 100 50 100H100V200H0V150Z" fill="#FF6B18" />
            <circle [@moveUpDown]="showCircle ? 'show' : 'hide'" cx="150" cy="150" r="50" fill="#9AA6CC" />
            <circle cx="50" cy="250" r="50" fill="#9AA6CC" />
            <path d="M100 250C100 222.386 122.386 200 150 200H200V300H100V250Z" fill="#FF6B18" />
            <circle cx="250" cy="250" r="50" fill="#FF6B18" />
            <path d="M0 350C0 322.386 22.3858 300 50 300H100V400H50C22.3858 400 0 377.614 0 350Z" fill="#FFBB95" />
            <path [@moveUpDown]="showCircle ? 'show' : 'hide'"
              d="M100 300H150C177.614 300 200 322.386 200 350V400H150C122.386 400 100 377.614 100 350V300Z"
              fill="#FFBB95" />
            <rect x="200" y="300" width="100" height="100" fill="#9AA6CC" />
            <circle [@moveUpDown]="showCircle ? 'show' : 'hide'" cx="350" cy="350" r="50" fill="#9AA6CC" />
            <path d="M0 400H100V500H0V400Z" fill="#FF6B18" />
            <path d="M200 400V500H100L200 400Z" fill="#0F1A3C" />
            <path d="M200 400H300V450C300 477.614 277.614 500 250 500H200V400Z" fill="#FFBB95" />
            <circle cx="350" cy="450" r="50" fill="#9AA6CC" />
            <path [@moveUpDown]="showCircle ? 'show' : 'hide'"
              d="M400 400H450C477.614 400 500 422.386 500 450V500H450C422.386 500 400 477.614 400 450V400Z"
              fill="#FFBB95" />
          </g>
        </svg>
        <div class="content-wrapper">
          <div class="back-button" routerLink="/">
            <div class="back">
              <svg class="chevron-left" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.3536 1.64645C11.5488 1.84171 11.5488 2.15829 11.3536 2.35355L5.70711 8L11.3536 13.6464C11.5488 13.8417 11.5488 14.1583 11.3536 14.3536C11.1583 14.5488 10.8417 14.5488 10.6464 14.3536L4.64645 8.35355C4.45118 8.15829 4.45118 7.84171 4.64645 7.64645L10.6464 1.64645C10.8417 1.45118 11.1583 1.45118 11.3536 1.64645Z"
                  fill="#C2C2C2" />
              </svg>
              <div class="return">Return</div>
            </div>
          </div>
          <ng-container [ngSwitch]="selectedLayoutName">
            <ng-template [ngSwitchCase]="'LOGIN'">
              <div class="greeting-card">
                <div class="greeting-title">Well Hello!</div>
                <b class="greeting-content">
                  <p class="text-black">Welcome.
                  </p>
                  <p class="text-black">
                    <span>We've been </span>
                    <span class="text-orange">expecting </span>
                    <span class="you">you.</span>
                  </p>
                </b>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'FORGOT_PASSWORD'">
              <div class="greeting-card">
                <div class="greeting-title">Oh no!</div>
                <b class="greeting-content">
                  <p class="text-black">Did you
                  </p>
                  <p class="text-black">
                    <span>forget your </span>
                    <span class="text-orange">password? </span>
                  </p>
                </b>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'CHOOSE_SIGNUP_TYPE'">
              <div class="greeting-card">
                <div class="greeting-title">First thing first!</div>
                <b class="greeting-content">
                  <p class="text-black">What are you
                  </p>
                  <p class="text-black">
                    <span class="text-orange">looking </span>
                    <span>for? </span>
                  </p>
                </b>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'TALENT_SIGNUP'">
              <div class="greeting-card">
                <div class="greeting-title">First thing first!</div>
                <b class="greeting-content">
                  <p class="text-black">How do you
                  </p>
                  <p class="text-black">want to
                  </p>
                  <p class="text-black">
                    <span class="text-orange">Sign Up?</span>
                  </p>
                </b>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'CORPORATION_SIGNUP'">
              <div class="greeting-card">
                <div class="greeting-title">First thing first!</div>
                <b class="greeting-content">
                  <p class="text-black">How do you
                  </p>
                  <p class="text-black">want to
                  </p>
                  <p class="text-black">
                    <span class="text-orange">Sign Up?</span>
                  </p>
                </b>
              </div>
            </ng-template>
            <ng-template [ngSwitchDefault]>
              <div class="greeting-card">
                <div class="greeting-title">Welcome</div>
                <b class="greeting-content">
                  <p class="text-black">Find the right job or
                  </p>
                  <span class="text-orange">internship </span>
                  <span>for you</span>
                </b>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6 no-padding">
      <div class="right-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
