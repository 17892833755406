import { Component, Input, OnInit } from '@angular/core';
import { ToastManager } from '@blocks/toast/toast.manager';
import { Reference } from '@interfaces/reference.interface';
import { JobService } from '@services/job.service';
import { ReferenceService } from '@services/reference.service';
import { TalentService } from '@services/talent.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LIMIT_GET_LIST } from 'src/app/shared/constants';

@Component({
  selector: 'corporate-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class CorporateSearchBarComponent implements OnInit {

  @Input() searchBarType: string = 'find-talent';
  public keyword!: string;
  public location!: string;
  public isLoading = false;

  public experienceLevelList: Reference[] = [];
  public employmentTypeList: Reference[] = [];
  public workplaceTypeList: Reference[] = [];
  public industries: Reference[] = [];
  public languages: Reference[] = [];

  public selectedOrder!: string;
  public selectedExperienceList: string[] = [];
  public selectedLanguages: string[] = [];
  public showTalentAsGridList = false;
  public showJobAsGridList = false;
  public searchParams = { size: 10 };
  public placeholderKeyword = 'Skill, Job Title, Keyword';
  private destroy$ = new Subject<void>();
  private onSearchCalled = false;
  public filterBar = 'filterbar';

  constructor
    (
      private talentService: TalentService,
      private referenceService: ReferenceService,
      private jobService: JobService,
      private toastManager: ToastManager,
    ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.isLoading = true;
      this.experienceLevelList = await this.referenceService.getReferenceListByType('EXPERIENCE_LEVEL');
      this.languages = await this.referenceService.getReferenceListByType('LANGUAGE');
      this.talentService.showTalentAsGridList$
        .pipe(takeUntil(this.destroy$))
        .subscribe(showTalentAsGridList => {
          this.showTalentAsGridList = showTalentAsGridList
        })


      if (this.searchBarType === 'find-talent') {
        this.filterBar = 'filterbar-with-margin';
        this.talentService.searchParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (params) => {
            this.searchParams = { ...this.searchParams, ...params }
            this.keyword = params.keyword;
            return;
          })
      }

      if (this.searchBarType === 'corporate-find-job') {
        this.filterBar = 'filterbar';
        this.jobService.searchParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(params => {
            this.searchParams = params
            this.keyword = params.keyword;
          })
        const size = 20;
        combineLatest([
          this.jobService.corporateJobParams$])
          .pipe(takeUntil(this.destroy$))
          .subscribe(async ([corporateJobParams]) => {
            try {
              const { status, pageNumber } = corporateJobParams
              const updatedParams = {
                ...this.searchParams,
                size,
                status,
                page: pageNumber,
              }
              if (!this.onSearchCalled) {
                await this.jobService.getCorporateJobList(updatedParams);
              }
            } catch (error: any) {
              this.toastManager.showError('', error.message);
            } finally {
              this.isLoading = false;
            }
          })
      }
    } catch (error: any) {
      this.toastManager.showError('', error.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async onTalentListModeClick() {
    try {
      this.isLoading = true;
      const value = !this.showTalentAsGridList;
      this.showTalentAsGridList = value
      this.talentService.setShowTalentAsGridList(value)
      await this.onSearch()
    } catch (error: any) {
      this.talentService.setErrorMessage(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async onJobListModeClick() {
    try {
      this.isLoading = true;
      const value = !this.showJobAsGridList;
      this.showJobAsGridList = value
      this.jobService.setShowJobAsGridList(value)
      await this.onSearch()
    } catch (error: any) {
      this.jobService.setJobErrorMessage(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  onExperienceLevelCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedExperienceList.push(value)
      return
    }
    const indexToRemove = this.selectedExperienceList.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedExperienceList.splice(indexToRemove, 1);
    }
  }

  onLanguageCheckClick(event: any) {
    const isChecked = event.target.checked;
    const value = event.target.value;
    if (isChecked) {
      this.selectedLanguages.push(value)
      return
    }
    const indexToRemove = this.selectedLanguages.indexOf(value);
    if (indexToRemove !== -1) {
      this.selectedLanguages.splice(indexToRemove, 1);
    }
  }

  onOrderByOptionClick(event: any) {
    const value = event.target.value;
    this.selectedOrder = value;
  }

  async onSearch() {
    try {
      this.isLoading = true;
      this.onSearchCalled = true;
      const params = {
        keyword: this.keyword,
        location: this.location,
        experienceLevel: this.selectedExperienceList.join('|'),
        language: this.selectedLanguages.join('|'),
        orderBy: this.selectedOrder,
        size: LIMIT_GET_LIST,
        status: null
      }
      if (this.searchBarType === 'find-talent') {
        try {
          this.talentService.setSearchParams({ ...this.searchParams, ...params, page: 1 })
          await this.talentService.getAvailableTalentList(params);
          return;
        }
        catch (error: any) {
          this.toastManager.showError('', error.message);
        } finally {
          this.isLoading = false;
        }
      }

      if (this.searchBarType === 'corporate-find-job') {
        this.jobService.setSearchParams(params)
        this.jobService.corporateJobParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async ({ status, pageNumber }) => {
            try {
              this.isLoading = true;
              await this.jobService.getCorporateJobList({ ...params, status, page: pageNumber });
            }
            catch (error: any) {
              this.toastManager.showError('', error.message);
            } finally {
              this.isLoading = false;
            }
          })
        return;
      }
    }
    catch (error: any) {
      this.toastManager.showError('', error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
