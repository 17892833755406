import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TalentService } from '@services/talent.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomEditor from 'ckeditor5-ng';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH, URL_PATTERN } from 'src/app/shared/constants';
import { Branch, CompanyAbout, CompanyBasicInfo } from '@interfaces/corporate.interface';
import { CorporateService } from '@services/corporate.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReferenceService } from '@services/reference.service';
import { Reference } from '@interfaces/reference.interface';
import { ValidationHelper } from '@helpers/validation.helper';
import { ToastManager } from '@blocks/toast/toast.manager';
import { COUNTRY_DIAL_CODES } from 'src/app/shared/constants/country-dial-code';
import { TIMEZONE } from 'src/app/shared/constants/timezone';
import { CountryDialCode, Timezone } from '@interfaces/register.interface';

@Component({
  selector: 'app-about-form',
  templateUrl: './about-form.component.html',
  styleUrls: ['/about-form.component.scss', '/../../../talent/about/about.component.scss']
})
export class AboutFormComponent implements OnInit {
  public isLoading = false;
  public oldCompanyAbout!: CompanyAbout;

  public companyProfileForm !: FormGroup;
  public errorMessage: string = '';
  public industryInvalidMessage: string = '';
  public isAboutValid = true;
  public Editor = CustomEditor;
  public timezoneList: Timezone[] = [];
  public countries: CountryDialCode[] = [];
  public cities: Reference[] = [];
  public companySizeRangeList: Reference[] = [];
  public industries: Reference[] = [];
  private defaultCountry = 'Indonesia';
  private defaultTimezone = '(UTC+07:00) Bangkok, Hanoi, Jakarta'

  constructor
    (
      private corporateService: CorporateService,
      private dialogRef: MatDialogRef<AboutFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private referenceService: ReferenceService,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data?.companyAbout) {
      this.oldCompanyAbout = modalData?.data?.companyAbout
    }

    this.companyProfileForm = new FormGroup({
      about: new FormControl<string>({
        value: this.oldCompanyAbout.about,
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_SMALL)], nonNullable: true }),
      companySizeRange: new FormControl<string>({
        value: this.oldCompanyAbout.companySizeRange || '',
        disabled: false
      }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT)], nonNullable: true }),
      mainOfficeName: new FormControl<string>({
        value: this.oldCompanyAbout.mainOffice?.name,
        disabled: false
      }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)], nonNullable: true }),
      mainOfficeCountry: new FormControl<string>({
        value: this.oldCompanyAbout.mainOffice?.country || this.defaultCountry,
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      mainOfficeCity: new FormControl<string>({
        value: this.oldCompanyAbout.mainOffice?.city,
        disabled: false
      }, { validators: [Validators.required, Validators.pattern(ALPHANUMERIC_PATTERN), Validators.maxLength(MAX_LENGTH.INPUT)], nonNullable: true }),
      mainOfficeTimezone: new FormControl<string>({
        value: this.oldCompanyAbout.mainOffice?.timezone || this.defaultTimezone,
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      industry: new FormControl<string>({
        value: this.oldCompanyAbout.industry || '',
        disabled: false
      }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT)], nonNullable: true }),
      companySite: new FormControl<string>({
        value: this.oldCompanyAbout.companySite,
        disabled: false
      }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.TEXT_AREA_SMALL), Validators.pattern(URL_PATTERN)], nonNullable: true }),
      branchEntries: new FormArray(this.showCurrentBranches()),
    });
  }

  showCurrentBranches() {
    if (this.oldCompanyAbout.branches.length > 0) {
      return this.oldCompanyAbout.branches.map((branch: any) => {
        return new FormGroup({
          id: new FormControl(branch.id),
          name: new FormControl<string>({
            value: branch.name,
            disabled: false
          }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)], nonNullable: true }),
          country: new FormControl<string>({
            value: branch.country || this.defaultCountry,
            disabled: false
          }, { validators: [Validators.required], nonNullable: true }),
          city: new FormControl<string>({
            value: branch.city,
            disabled: false
          }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)], nonNullable: true }),
          timezone: new FormControl<string>({
            value: branch.timezone,
            disabled: false
          }, { validators: [Validators.required], nonNullable: true }),
        });
      });
    }
    return []
  }
  async ngOnInit() {
    this.countries = COUNTRY_DIAL_CODES;
    this.cities = await this.referenceService.getReferenceListByType('CITY');
    this.timezoneList = TIMEZONE;
    this.companySizeRangeList = await this.referenceService.getReferenceListByType('COMPANY_SIZE');
    this.industries = await this.referenceService.getReferenceListByType('INDUSTRY');
  }

  get branchEntries() {
    return this.companyProfileForm.get('branchEntries') as FormArray;
  }

  private branchFormEntry(): FormGroup {
    const educationEntry = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)]),
      country: new FormControl(this.defaultCountry, Validators.required),
      city: new FormControl('', [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)]),
      timezone: new FormControl(this.defaultTimezone, Validators.required),
    });

    return educationEntry;
  }

  addBranchEntry() {
    if (this.branchEntries.length > 10) {
      this.errorMessage = 'You can only add 10 entries'
      return;
    }
    this.branchEntries.push(this.branchFormEntry());
  }

  removeBranchEntry(index: number) {
    this.branchEntries.removeAt(index);
  }

  protected mapBranchPayload() {
    const branches = this.branchEntries.value.map((branch: Branch) => {
      return {
        id: branch.id,
        name: branch.name,
        country: branch.country,
        city: branch.city,
        timezone: branch.timezone,
      };
    });

    return branches;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public onValueChange(newValue: string) {
    if (newValue.length > MAX_LENGTH.TEXT_AREA_SMALL) {
      this.isAboutValid = false;
      return;
    }
    this.isAboutValid = true;
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public addIndustryPreference() {
    const isIndustryLimitExceeded = this.industries.length > MAX_LENGTH.INDUSTRY;
    const industryPreferenceInput = this.companyProfileForm.get('industryPreferenceInput');
    if (!isIndustryLimitExceeded) {
      if (industryPreferenceInput?.value && !this.industries.includes(industryPreferenceInput.value)) {
        this.industries.push(industryPreferenceInput.value);
        industryPreferenceInput.setValue('');
      }
    }
  }

  public getWordCount() {
    const about = this.companyProfileForm.get('about')?.value;
    const words = about && about.trim().split(/\s+/);
    return words?.length || 0;
  }

  private mapCompanyAboutPayload(): Partial<CompanyAbout> {
    const about = this.companyProfileForm.get('about')?.getRawValue();
    const companySizeRange = this.companyProfileForm.get('companySizeRange')?.getRawValue();
    const mainOfficeName = this.companyProfileForm.get('mainOfficeName')?.getRawValue();
    const mainOfficeCountry = this.companyProfileForm.get('mainOfficeCountry')?.getRawValue();
    const mainOfficeCity = this.companyProfileForm.get('mainOfficeCity')?.getRawValue();
    const mainOfficeTimezone = this.companyProfileForm.get('mainOfficeTimezone')?.getRawValue();
    const companySite = this.companyProfileForm.get('companySite')?.getRawValue();
    const industry = this.companyProfileForm.get('industry')?.getRawValue();
    return {
      about,
      companySizeRange,
      companySite,
      industry,
      mainOffice: {
        name: mainOfficeName,
        country: mainOfficeCountry,
        city: mainOfficeCity,
        timezone: mainOfficeTimezone,
      },
      branches: this.mapBranchPayload()
    }
  }

  public async editAbout() {
    try {
      this.isLoading = true;
      await this.corporateService.editCompanyAbout(this.mapCompanyAboutPayload());
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('About Information saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
