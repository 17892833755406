<!-- For more settings use the AutoHTML plugin tab ... -->
<div class="dasboard-container">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <form class="content" [formGroup]="preferenceForm" (ngSubmit)="onClickSave()"
    (keydown.enter)="$event.preventDefault()">
    <div class="title-container">
      <div class="frame-1698">
        <div class="header">
          <div class="title">
            Set up your preferences </div>
          <div class="subtitle">
            *this is used to help us recommend jobs suited with your preferences </div>
        </div>
        <div class="save">
          <button type="submit" class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
            Save Changes
          </button>
        </div>
      </div>
      <div class="line-6">
      </div>
    </div>
    <div class="form-group-label-input skill-group">
      <div class="form-label-input">
        Job Title
      </div>
      <div class="input-section">
        <div class="chip" *ngFor="let workPreference of workPreferenceList">
          {{ workPreference }}
          <div class="close-icon" (click)="removeWorkPreference(workPreference)">
            <svg class="x-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
                fill="#233D90" />
            </svg>
          </div>
        </div>
        <input class="skill-input-text" type="text" formControlName="workPreferenceInput"
          (keydown.enter)="addWorkPreference()" (blur)="addWorkPreference()" placeholder="Type and enter to add your work or job title (UI Designer, Software Engineer)" />
        <div *ngIf="!('desktop' | deviceDetector)" class="btn-rounded bg-success-soft" (click)="addWorkPreference()">
          <label>
            <i class="bi bi-plus-circle-fill">
            </i>
            Add
          </label>
        </div>
      </div>
      <div class="invalid-feedback-text" *ngIf="!isWorkPreferenceValid()">
        {{ workPreferenceInvalidMessage }}
      </div>
    </div>
    <div class="form-group-label-input skill-group">
      <div class="form-label-input" required>
        Work Type
      </div>
      <div class="ec-input-group-rounded input-text-space">
        <span class="ec-input-group-text">
          <i class="bi bi-book-fill"></i>
        </span>
        <select type="text" formControlName="workTypePreference" class="ec-form-select-rounded">
          <option value='' disabled selected>Job Type</option>
          <option *ngFor="let jobType of jobTypes" [value]="jobType.value">{{ jobType.value }}</option>
        </select>
      </div>
      <div class="invalid-feedback-text"
        *ngIf="preferenceForm.controls.workTypePreference.touched && preferenceForm.controls.workTypePreference.hasError('required')">
        Job Type is required
      </div>
    </div>
    <div class="form-group-label-input skill-group">
      <div class="form-label-input">
        Employment Types
      </div>
      <div class="input-section">
        <div class="chip" *ngFor="let employmentType of employmentTypeList">
          {{ employmentType }}
          <div class="close-icon" (click)="removeEmploymentType(employmentType)">
            <svg class="x-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
                fill="#233D90" />
            </svg>
          </div>
        </div>
        <input class="skill-input-text" type="text" formControlName="employmentTypeInput"
          (keydown.enter)="addEmploymentType()" (blur)="addEmploymentType()" placeholder="Type and enter to add your employment type (Hybrid, Remote, Onsite)" />
        <div *ngIf="!('desktop' | deviceDetector)" class="btn-rounded bg-success-soft" (click)="addEmploymentType()">
          <label>
            <i class="bi bi-plus-circle-fill">
            </i>
            Add
          </label>
        </div>
      </div>
      <div class="invalid-feedback-text" *ngIf="!isEmploymentTypeValid()">
        {{ employmentTypeInvalidMessage }}
      </div>
    </div>
    <div class="form-group-label-input skill-group">
      <div class="form-label-input">
        Industries
      </div>
      <div class="input-section">
        <div class="chip" *ngFor="let industry of industryPreferenceList">
          {{ industry }}
          <div class="close-icon" (click)="removeIndustryPreference(industry)">
            <svg class="x-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
                fill="#233D90" />
            </svg>
          </div>
        </div>
        <input class="skill-input-text" type="text" formControlName="industryPreferenceInput"
          (keydown.enter)="addIndustryPreference()" (blur)="addIndustryPreference()" placeholder="Type and enter to add your industry preference (Banking, Fintech, Edutech)" />
        <div *ngIf="!('desktop' | deviceDetector)" class="btn-rounded bg-success-soft"
          (click)="addIndustryPreference()">
          <label>
            <i class="bi bi-plus-circle-fill">
            </i>
            Add
          </label>
        </div>
      </div>
      <div class="invalid-feedback-text" *ngIf="!isIndustryPreferenceValid()">
        {{ industryPreferenceInvalidMessage }}
      </div>
    </div>

    <div class="form-group-label-input skill-group">
      <div class="form-label-input" required>
        Locations
      </div>
      <div class="ec-input-group-rounded input-text-space">
        <span class="ec-input-group-text">
          <i class="bi bi-pin-map-fill"></i>
        </span>
        <select type="text" formControlName="locationInput" class="ec-form-select-rounded">
          <option value='' disabled selected>Select Location</option>
          <option *ngFor="let location of locationList" [value]="location.value">{{ location.value }}</option>
        </select>
      </div>
      <div class="invalid-feedback-text"
        *ngIf="preferenceForm.controls.locationInput.touched && preferenceForm.controls.locationInput.hasError('required')">
        Location is required
      </div>
    </div>
  </form>
</div>
