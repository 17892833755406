import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flattenArray'
})
export class FlattenArrayPipe implements PipeTransform {
  transform(inputArray: any[], key: string, type?: string): string[] | string {

    if (!inputArray || !Array.isArray(inputArray)) {
      return [];
    }

    const result: string[] = [];

    for (const obj of inputArray) {
      if (obj[key] && typeof obj[key] === 'string') {
        result.push(obj[key]);
      }
    }

    if (type === 'dot') {
      return result.join(' \u00B7 ');
    }

    return result;
  }
}