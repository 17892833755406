import { Injectable, Injector } from '@angular/core';

import { StorageHelper } from '@helpers/storage.helper';

import { Endpoint } from '@enums/endpoint.enum';
import { RegistrationStep, SignUpResponse } from '@interfaces/register.interface';

import { LoginType } from '@enums/login.enum';
import { APIBaseService } from './api-base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RegisterService extends APIBaseService {

  private readonly _registerStepInfo = new BehaviorSubject<RegistrationStep>({
    stepName: 'Personal Info',
    stepCountOf: '1 / 5',
    code: 'personalInfo',
    signUpAs: 'TALENT'
  });

  readonly registerStepInfo$ = this._registerStepInfo.asObservable();

  constructor(private injector: Injector) {
    super(injector);
  }

  public setRegisterStepInfo(state: RegistrationStep): void {
    this._registerStepInfo.next(state);
  }
  public async createPersonalInfo(personalInfoPayload: any, signUpAs: string): Promise<void> {
    const url = Endpoint.SIGNUP;
    const { data }: { data: SignUpResponse } = await this.authAPI.post(url, personalInfoPayload);
    StorageHelper.setAccessToken(data.data.accessToken, LoginType.SELF);
  }

  public async isEmailExist(email: string): Promise<{ isExist: boolean }> {
    const url = Endpoint.CHECK_EMAIL;
    const { data }: { data: any } = await this.authAPI.get(`${url}?email=${email}`);
    return data.data;
  }

  public async resendEmailActivation(email: string): Promise<any> {
    const url = Endpoint.RESEND_EMAIL_ACTIVATION;
    const { data }: { data: any } = await this.talentAPI.post(url, { email });
    return data.data;
  }

  public async verifyCaptcha(captchaToken: string): Promise<any> {
    const url = Endpoint.VERIFY_CAPTCHA;
    const { data }: { data: any } = await this.authAPI.post(url, { captchaToken });
    return data.data;
  }
}
