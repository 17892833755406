import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { Notification } from '@interfaces/talent.interface';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';

@Component({
  selector: 'talent-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public isLoading = false;
  public notifications !: Notification[];
  public selectedNotificationType = 'all';
  public pageNumber = 1;
  public pageLimit = 5;
  public paginationNumberList: any = [];
  public originTotalPages: number = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private talentService: TalentService,
    private storeService: StoreService,
    private toastManager: ToastManager,
  ) {
    this.storeService.hideSubNavbar();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      const type = params['type'];
      if (type) {
        this.selectedNotificationType = type;
      } else {
        this.selectedNotificationType = 'all';
      }

      const pageNumber = params['pageNumber'];
      if (pageNumber) {
        this.pageNumber = pageNumber;
      }
      try {
        this.isLoading = true;
        const notificationResponse = await this.talentService.getNotifications({
          page: this.pageNumber,
          type: this.selectedNotificationType,
          size: this.pageLimit
        });
        this.notifications = notificationResponse.notifications;
        const length = Math.ceil(notificationResponse.totalData / this.pageLimit);
        this.originTotalPages = length;
        this.paginationNumberList = Array.from({ length }, (_, index) => index + 1);
      } catch (error: any) {
        this.toastManager.showError(null, error.message);
      } finally {
        this.isLoading = false;
      }

    });
    this.storeService.setActiveNavbar('notification')
  }

  public onClickReturn() {
    this.storeService.setActiveNavbar('dashboard');
    this.storeService.showSubNavbar();
    this.router.navigate(['talent/resume'])
  }

  public async onNotitificationTypeChange(type: string) {
    this.storeService.setActiveNavbar('notification');
    await this.talentService.getUnredNotificationCount();
    this.selectedNotificationType = type;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { type, pageNumber: 1 },
      queryParamsHandling: 'merge'
    });
    this.pageNumber = 1;
  }

  public async onPaginationChange(pageNumber: number) {
    this.storeService.setActiveNavbar('notification');
    await this.talentService.getUnredNotificationCount();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { pageNumber },
      queryParamsHandling: 'merge'
    });
    this.pageNumber = pageNumber;
  }
}
