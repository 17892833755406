import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy  {

  public activeSidebar = 'my-account';

  constructor(private storeService: StoreService) {
  }

  ngOnInit(): void {
    this.storeService.activeSidebar$.subscribe((activeSidebar) => {
      this.activeSidebar = activeSidebar;
    });
  }

  ngOnDestroy(): void {
    this.storeService.setActiveSidebar('my-account')
  }
}
