import { Component, HostBinding, OnInit } from '@angular/core';
import { BasicInfo, Education, Portfolio, ProfileCompletion, UserProfile, WorkHistory } from '@interfaces/talent.interface';
import { TalentService } from '@services/talent.service';
import delay from 'delay';

import { ToastManager } from '@blocks/toast/toast.manager';
import { ValidationHelper } from '@helpers/validation.helper';
import { EducationFormComponent } from '@pages/register/education/education-form/education-form.component';
import { BasicInfoFormComponent } from '@pages/register/personal-info/basic-info-form/basic-info-form.component';
import { LanguageFormComponent } from '@pages/register/skill-resume/language-form/language-form.component';
import { PersonalWebsiteComponent } from '@pages/register/skill-resume/personal-website/personal-website.component';
import { PortfolioFormComponent } from '@pages/register/skill-resume/portfolio-form/portfolio-form.component';
import { SkillFormComponent } from '@pages/register/skill-resume/skill-form/skill-form.component';
import { WorkHistoryFormComponent } from '@pages/register/work-history/work-history-form/work-history-form.component';
import { ModalInterface, ModalService } from '@services/modal.service';
import { RegisterService } from '@services/register.service';
import { StoreService } from '@services/store.service';
import { AboutComponent } from '../about/about.component';
import { PhotoProfileFormComponent } from '@pages/register/personal-info/photo-profile-form/photo-profile-form.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmationComponent } from '@blocks/popup-confirmation/popup-confirmation.component';
import { ConfirmationDialogComponent } from '@modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'talent-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  public isLoading: boolean = false;
  public userProfile !: UserProfile | null;
  public profileCompletion !: ProfileCompletion | null;
  public errorMessage = '';
  public activeSubNavbar = 'resume';
  public selectedFile: File | null = null;
  public selectedFileName: string | null = null;
  public isUploadingResume = false;
  @HostBinding('style.--background-color') backgroundColor = '#faebea';
  @HostBinding('style.--progress-bar') progressBar = '#e7a4a0';
  @HostBinding('style.--progress-text') progressText = '#cb3a31';

  constructor(
    private talentService: TalentService,
    private registerService: RegisterService,
    private modalService: ModalService,
    private storeService: StoreService,
    private toastManager: ToastManager,
    public dialog: MatDialog
  ) {

    this.storeService.showSubNavbar();
    this.storeService.setActiveNavbar('dashboard');
    this.storeService.setActiveSubNavbar('resume');
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try {
      this.storeService.setActiveNavbar('dashboard');

      const [profileCompletion, userProfile] = await Promise.all([
        this.talentService.getProfileCompletion(),
        this.talentService.getProfile()
      ]);
      this.userProfile = userProfile;
      this.profileCompletion = profileCompletion;
      if (profileCompletion.isMandatoryComplete) {
        this.backgroundColor = '#edf3fb';
        this.progressBar = '#abc8eb';
        this.progressText = '#4888d3';
      }
    } catch (error: any) {
      this.errorMessage = error[0]?.message || error.message;
    } finally {
      this.isLoading = false;
    }
  }

  private async getDasboardData() {
    this.isLoading = true;
    try {
      const [userProfile, profileCompletion] = await Promise.all([
        this.talentService.getProfile(),
        this.talentService.getProfileCompletion()
      ]);
      this.userProfile = userProfile;
      this.storeService.setUserProfile(userProfile);
      this.profileCompletion = profileCompletion;
      if (profileCompletion.isMandatoryComplete) {
        this.backgroundColor = '#edf3fb';
        this.progressBar = '#abc8eb';
        this.progressText = '#4888d3';
      }
    } catch (error: any) {
      this.errorMessage = error[0]?.message || error.message;
    } finally {
      this.isLoading = false;
    }
  }

  async uploadTestResult() {
    const fileInput = document.getElementById('resume-input');
    if (fileInput) {
      fileInput.click();
    }
  }

  async onFileSelected(event: any) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = inputFile.files[0];
      this.selectedFileName = inputFile.files[0].name;
      try {
        ValidationHelper.uploadFileValidator(inputFile);
        this.isUploadingResume = true;
        await this.talentService.uploadResume(this.selectedFile);
        await delay(5000);
        const updatedUserProfile = await this.talentService.getProfile();
        this.userProfile = updatedUserProfile;
        this.toastManager.showSuccess('Your resume has been successfully uploaded')
      } catch (error: any) {
        this.toastManager.showError('Upload failed', error.message)
      } finally {
        this.isUploadingResume = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
      this.isUploadingResume = false;
    }
  }

  public onPreviewResume() {
    if (this.userProfile?.resume?.resumeUrl) {
      window.open(this.userProfile.resume.resumeUrl, '_blank');
    }
  }

  public editPhotoProfile() {
    const basicInfo: Partial<BasicInfo> = {
      profilePictureUrl: this.userProfile?.profilePictureUrl,
    }
    const modalConfig: ModalInterface = {
      component: PhotoProfileFormComponent,
      customLayout: {
        width: '410px',
      },
      data: {
        basicInfo: basicInfo,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public addNewEducation() {
    const modalConfig: ModalInterface = {
      component: EducationFormComponent,
      customLayout: {
        width: '710px',
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editEducation(selectedEducation: Education) {
    const modalConfig: ModalInterface = {
      component: EducationFormComponent,
      customLayout: {
        width: '710px',
      },
      data: selectedEducation,
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async removeEducation(selectedEducation: Education) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedEducation.schoolName}" \n Are you sure you want to remove this education?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          await this.talentService.removeEducation(selectedEducation.id);
          const updatedUserProfile = await this.talentService.getProfile();
          this.userProfile = updatedUserProfile;
          this.toastManager.showSuccess('Education removed successfully')
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    });


  }

  public addNewWorkHistory() {
    const modalConfig: ModalInterface = {
      component: WorkHistoryFormComponent,
      customLayout: {
        width: '710px',
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editWorkHistory(selectedWorkHistory: WorkHistory) {
    const modalConfig: ModalInterface = {
      component: WorkHistoryFormComponent,
      customLayout: {
        width: '710px',
      },
      data: selectedWorkHistory,
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async removeWorkHistory(selectedWorkHistory: WorkHistory) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedWorkHistory.companyName}" \n Are you sure you want to remove this work experience?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          await this.talentService.removeWorkHistory(selectedWorkHistory.id);
          const updatedUserProfile = await this.talentService.getProfile();
          this.userProfile = updatedUserProfile;
          this.toastManager.showSuccess('Work experience removed successfully');
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  public editPersonalWebiste() {
    const modalConfig: ModalInterface = {
      component: PersonalWebsiteComponent,
      customLayout: {
        width: '710px',
      },
      data: this.userProfile?.resume?.personalWebsite,
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public addNewPortfolio() {
    const modalConfig: ModalInterface = {
      component: PortfolioFormComponent,
      customLayout: {
        width: '710px',
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editPortfolio(selectedPortfolio: Portfolio) {
    const modalConfig: ModalInterface = {
      component: PortfolioFormComponent,
      customLayout: {
        width: '710px',
      },
      data: selectedPortfolio,
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async removePortfolio(selectedPortfolio: Portfolio) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '340px',
      data: {
        title: 'Confirmation',
        message: `"${selectedPortfolio.projectName}" \n Are you sure you want to remove this portofolio?`
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.isLoading = true;
          await this.talentService.removePortfolio(selectedPortfolio.id);
          const updatedUserProfile = await this.talentService.getProfile();
          this.userProfile = updatedUserProfile;
          this.toastManager.showSuccess('Portofolio removed successfully');
        } catch (error: any) {
          this.toastManager.showError(null, error.message)
        } finally {
          this.isLoading = false;
        }
      }
    })
  }

  public editSkills() {
    const modalConfig: ModalInterface = {
      component: SkillFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        hardSkills: this.userProfile?.hardSkills,
        softSkills: this.userProfile?.softSkills
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editLanguage() {
    const modalConfig: ModalInterface = {
      component: LanguageFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        languages: this.userProfile?.languages,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editAbout() {
    const modalConfig: ModalInterface = {
      component: AboutComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        about: this.userProfile?.about,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public editBasicInfo() {
    const basicInfo: Partial<BasicInfo> = {
      firstName: this.userProfile?.firstName,
      lastName: this.userProfile?.lastName,
      email: this.userProfile?.email,
      phone: this.userProfile?.phone,
      position: this.userProfile?.position,
      isLookingForWork: this.userProfile?.isLookingForWork,
      preferredTimezone: this.userProfile?.preferredTimezone,
      currentCompany: this.userProfile?.currentCompany,
      profilePictureUrl: this.userProfile?.profilePictureUrl,
    }
    const modalConfig: ModalInterface = {
      component: BasicInfoFormComponent,
      customLayout: {
        width: '710px',
      },
      data: {
        basicInfo: basicInfo,
      },
      callback: this.getDasboardData.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public async resendEmailVerification() {
    try {
      this.isLoading = true;
      if (this.userProfile) {
        await this.registerService.resendEmailActivation(this.userProfile?.email);
        this.toastManager.showSuccess('Resend email activation successfully')
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}

