import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TableHelper } from '@helpers/table.helper';
import { CorporateJobStatus } from '@interfaces/corporate.interface';
import { JobService } from '@services/job.service';
import { StoreService } from '@services/store.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LIMIT_GET_LIST } from 'src/app/shared/constants';

@Component({
  selector: 'app-job-posted',
  templateUrl: './job-posted.component.html',
  styleUrls: ['./job-posted.component.scss']
})
export class JobPostedComponent {
  public isLoading = false;
  public errorMessage = '';
  public selectedJobStatus: CorporateJobStatus = 'all';
  private destroy$ = new Subject<void>();
  public currentPage: number = 1;
  public currentPageSize: number = LIMIT_GET_LIST;
  public paginationNumberList !: Array<number>;

  constructor(
    private storeService: StoreService,
    private jobService: JobService,
    private router: Router,
  ) {
    this.storeService.showSubNavbar();
    this.storeService.setActiveSubNavbar('job');
  }

  ngOnDestroy(): void {
    this.jobService.setShowJobAsGridList(false)
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {
    combineLatest([
      this.jobService.isLoading$,
      this.jobService.jobErrorMessage$,
      this.jobService.corporateJobParams$,
      this.jobService.totalJob$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        isLoading,
        errorMessage,
        corporateJobParams,
        totalJob,
      ]: [any, any, any, any]) => {
        this.isLoading = isLoading;
        this.errorMessage = errorMessage;
        this.selectedJobStatus = corporateJobParams.status;
        this.currentPage = corporateJobParams.pageNumber;
        const length = Math.ceil(totalJob / LIMIT_GET_LIST);
        this.paginationNumberList = Array.from({ length }, (_, index) => index + 1);
        this.paginationNumberList = TableHelper.generatePagination(this.paginationNumberList, this.currentPage)
      })
  }

  public async onStatusChange(status: CorporateJobStatus): Promise<void> {
    this.selectedJobStatus = status;
    this.jobService.setCorporateJobParams(status);
  }

  public async onPaginationChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.jobService.setCorporateJobParams(this.selectedJobStatus, pageNumber);
  }

  public navigateToJobTemplate() {
    this.router.navigate(['/corporate/job-template']);
  }
}
