<div class="dashboard-container">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <ng-container *ngIf="!isLoading">
    <div class="notification-wrapper">
      <div class="row">
        <div class="col-lg-3">
          <div class="ctn">
            <div class="tits">
              <svg class="bell-fill" width="28" height="28" viewBox="0 0 28 28" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M14 28C15.933 28 17.5 26.433 17.5 24.5H10.5C10.5 26.433 12.067 28 14 28Z" fill="#FF6B18" />
                <path
                  d="M15.7415 1.92331C15.7471 1.8663 15.75 1.80848 15.75 1.75C15.75 0.783502 14.9665 0 14 0C13.0335 0 12.25 0.783502 12.25 1.75C12.25 1.80849 12.2529 1.86631 12.2585 1.92332C8.26035 2.73078 5.25004 6.26384 5.25004 10.5C5.25004 12.4208 4.375 21 1.75 22.75H26.25C23.625 21 22.75 12.4208 22.75 10.5C22.75 6.26382 19.7397 2.73074 15.7415 1.92331Z"
                  fill="#FF6B18" />
              </svg>
              <div class="notifications">Notifications</div>
            </div>
            <div (click)="onClickReturn()" class="back">
              <svg class="chevron-left" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.3536 1.64645C11.5488 1.84171 11.5488 2.15829 11.3536 2.35355L5.70711 8L11.3536 13.6464C11.5488 13.8417 11.5488 14.1583 11.3536 14.3536C11.1583 14.5488 10.8417 14.5488 10.6464 14.3536L4.64645 8.35355C4.45118 8.15829 4.45118 7.84171 4.64645 7.64645L10.6464 1.64645C10.8417 1.45118 11.1583 1.45118 11.3536 1.64645Z"
                  fill="#C2C2C2" />
              </svg>
              <div class="return">Return</div>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="notification-wrapper">
            <div class="button-group-tabs">
              <div
                [ngClass]="selectedNotificationType === 'all' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
                (click)="onNotitificationTypeChange('all')">
                <label>
                  <i class="bi bi-files">
                  </i>
                  All
                </label>
              </div>
              <div
                [ngClass]="selectedNotificationType === 'APPLICATION' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
                (click)="onNotitificationTypeChange('APPLICATION')">
                <label>
                  <i class="bi bi-file-text-fill">
                  </i>
                  Applications
                </label>
              </div>
              <div
                [ngClass]="selectedNotificationType === 'OFFERING' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
                (click)="onNotitificationTypeChange('OFFERING')">
                <label>
                  <i class="bi bi-send-check">
                  </i>
                  Offering
                </label>
              </div>
              <div
                [ngClass]="selectedNotificationType === 'PLACEMENT' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
                (click)="onNotitificationTypeChange('PLACEMENT')">
                <label>
                  <i class="bi bi-person-vcard">
                  </i>
                  Placements
                </label>
              </div>
            </div>
            <ng-container *ngFor="let notification of notifications">
              <notification [data]="notification"></notification>
            </ng-container>
            <pagination *ngIf="paginationNumberList.length > 1" [originTotalPages]="originTotalPages" [currentPage]="pageNumber" [paginationNumberList]="paginationNumberList" (paginationChange)="onPaginationChange($event)"></pagination>
            <empty-result [emptyResultType]="'notification'"
              *ngIf="notifications && notifications.length === 0"></empty-result>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
