import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { ValidationHelper } from '@helpers/validation.helper';
import { Reference } from '@interfaces/reference.interface';
import { Timezone } from '@interfaces/register.interface';
import { BasicInfo } from '@interfaces/talent.interface';
import { ReferenceService } from '@services/reference.service';
import { TalentService } from '@services/talent.service';
import { ALPHABET_PATTERN, ALPHANUMERIC_PATTERN, MAX_LENGTH, PHONE_NUMBER_PATTERN } from 'src/app/shared/constants';
import { TIMEZONE } from 'src/app/shared/constants/timezone';

@Component({
  selector: 'app-basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['../../../talent/about/about.component.scss', './basic-info-form.component.scss']
})
export class BasicInfoFormComponent implements OnInit, AfterViewInit {

  public oldBasicInfo !: BasicInfo;
  public isLoading = false;
  public errorMessage = '';
  public basicInfoForm !: FormGroup;
  public employmentStatuses: Reference[] = [];
  public timezoneList: Timezone[] = [];
  public isLookingForWork = false;
  public selectedFile: File | null = null;
  public selectedFileName: string | null = null;

  constructor
    (
      private referenceService: ReferenceService,
      private talentService: TalentService,
      private dialogRef: MatDialogRef<BasicInfoFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data) {
      this.oldBasicInfo = modalData.data.basicInfo;
      this.mapEditedBasicInfo()
    }
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.employmentStatuses = await this.referenceService.getReferenceListByType('EMPLOYMENT_STATUS');
      this.timezoneList = TIMEZONE
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private mapEditedBasicInfo() {
    this.basicInfoForm = new FormGroup({
      firstName: new FormControl<string>({
        value: this.oldBasicInfo.firstName,
        disabled: false
      }, {
        validators: [
          Validators.required, Validators.pattern(ALPHABET_PATTERN), Validators.maxLength(MAX_LENGTH.INPUT)
        ], nonNullable: true
      }),
      lastName: new FormControl<string | null>({
        value: this.oldBasicInfo.lastName,
        disabled: false
      }, {
        validators: [Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHABET_PATTERN)],
        nonNullable: false
      }),
      email: new FormControl<string>({
        value: this.oldBasicInfo.email,
        disabled: false
      }, {
        validators: [
          Validators.required, Validators.email, Validators.maxLength(MAX_LENGTH.INPUT)
        ], nonNullable: true
      }),
      position: new FormControl<string>({
        value: this.oldBasicInfo.position,
        disabled: false
      }, { validators: [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)], nonNullable: true }),
      phone: new FormControl<string>({
        value: this.oldBasicInfo.phone,
        disabled: false
      }, {
        validators: [
          Validators.required, Validators.minLength(9), Validators.maxLength(MAX_LENGTH.PHONE), Validators.pattern(PHONE_NUMBER_PATTERN)
        ], nonNullable: true
      }),
      currentCompany: new FormControl<string>({
        value: this.oldBasicInfo.currentCompany,
        disabled: false
      }, {
        validators: [
          Validators.pattern(ALPHANUMERIC_PATTERN), Validators.maxLength(MAX_LENGTH.INPUT)
        ], nonNullable: true
      }),
      isLookingForWork: new FormControl<boolean | null>({
        value: this.oldBasicInfo.isLookingForWork || false,
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      preferredTimezone: new FormControl<string>({
        value: this.oldBasicInfo.preferredTimezone || '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),

    },);
  }


  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  private mapBasicInfoPayload(): Partial<BasicInfo> {
    const firstName = this.basicInfoForm.get('firstName')?.getRawValue();
    const lastName = this.basicInfoForm.get('lastName')?.getRawValue();
    const email = this.basicInfoForm.get('email')?.getRawValue();
    const position = this.basicInfoForm.get('position')?.getRawValue();
    const phone = this.basicInfoForm.get('phone')?.getRawValue();
    const isLookingForWork = this.basicInfoForm.get('isLookingForWork')?.getRawValue();
    const preferredTimezone = this.basicInfoForm.get('preferredTimezone')?.getRawValue();
    const currentCompany = this.basicInfoForm.get('currentCompany')?.getRawValue();
    return {
      firstName,
      lastName,
      email,
      position,
      phone,
      isLookingForWork,
      preferredTimezone,
      currentCompany,
    }
  }

  openFileInput() {
    const fileInput = document.getElementById('profilePicture');
    if (fileInput) {
      fileInput.click();
    }
  }

  public async onFileSelected(event: any) {
    const inputFile = event.target as HTMLInputElement;
    this.isLoading = true;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = inputFile.files[0].name;
      try {
        ValidationHelper.uploadFileValidator(inputFile);
        await this.talentService.updateProfilePicture(this.selectedFile);
        await this.modalData.callback();
        this.dialogRef.close()
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
    }
  }

  public async onClickUpdate() {
    try {
      this.isLoading = true;
      const newBasicInfo = this.mapBasicInfoPayload();
      await this.talentService.editBasicInfo(newBasicInfo);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Basic Information saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
