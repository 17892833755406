import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({
  name: 'deviceDetector'
})
export class DeviceDetectorPipe implements PipeTransform {

  constructor(private deviceService: DeviceDetectorService) {}

  transform(value: any): any {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    switch (value) {
      case 'mobile':
        return isMobile;
      case 'tablet':
        return isTablet;
      case 'desktop':
        return isDesktop;
      default:
        return this.deviceService.getDeviceInfo();
    }
  }
}
