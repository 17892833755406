import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';


@Pipe({
  name: 'initialName'
})
export class InitialNamePipe implements PipeTransform {

  transform(firstName: string, lastName: string): string {
    const firstNameInitial = firstName.substring(0, 1).toUpperCase();
    const lastNameInitial = lastName?.substring(0, 1).toUpperCase();
    return firstNameInitial + lastNameInitial
  }
}