import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { CardType, CompanyProfile, CorporateTalentStatus, Job } from '@interfaces/corporate.interface';
import { Talent } from '@interfaces/talent.interface';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { SnackbarService } from '@services/snackbar.service';
import { StoreService } from '@services/store.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-corporate-job-detail',
  templateUrl: './corporate-job-detail.component.html',
  styleUrls: [
    './corporate-job-detail.component.scss',
    '../job-posted/job-posted.component.scss',
    '../../../shared/components/layouts/job-list/job-list.component.scss',
    '../../job/job-preview/job-preview.component.scss',
  ],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: '0' })),
      transition('expanded <=> collapsed', animate('1300ms ease-in-out'))
    ])
  ]
})
export class CorporateJobDetailComponent {
  public isLoading = false;
  public errorMessage: string = '';
  public cardType: CardType = 'viewedBy';
  public jobId: string | null = '';
  public job !: Job;
  public companyProfile !: CompanyProfile | null;
  public isMainExpanded: boolean = false;
  public appliedTalentList!: Array<Talent>;
  public selectedTalentStatus: CorporateTalentStatus = 'all';
  public isDropsApplicationVisible = false;
  @HostBinding('style.--drops-application-display') dropDownApplicationDisplay = 'none';

  private destroy$ = new Subject<void>();

  constructor
    (
      private jobService: JobService,
      private corporateService: CorporateService,
      private storeService: StoreService,
      private route: ActivatedRoute,
      private router: Router,
      private toastManager: ToastManager,
      private snackbarService: SnackbarService,
    ) {
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.route.paramMap,
      this.storeService.companyProfile$,
      this.corporateService.cardType$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async ([
          params,
          companyProfile,
          cardType
        ]: [any, any, any]) => {
          const jobId = params.get('jobId');
          this.jobId = jobId;
          this.companyProfile = companyProfile;
          if (jobId) {

            this.job = await this.jobService.getCorporateJobById(jobId);
            this.cardType = cardType;
            try {
              if (this.cardType === 'viewedBy') {
                await this.jobService.getViewedTalentList(jobId, {})
              }

              if (this.cardType === 'applicant') {
                this.appliedTalentList = await this.jobService.getAppliedTalentList(jobId, {
                  page: 1,
                  status: this.selectedTalentStatus,
                })
              }

              if (this.cardType === 'offering') {
                await this.jobService.getOfferedTalentList(jobId, {})
              }

              if (this.cardType === 'on-boarding-talent') {
                await this.jobService.getOfferedTalentList(jobId, {})
              }
            } catch (error: any) {
              this.toastManager.showError(null, error.message)
            } finally {
              this.isLoading = false
            }
          }

        });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public expandJobDetail() {
    this.isMainExpanded = !this.isMainExpanded;
  }

  public goBackGirdViewJob() {
  }

  public onStatCardClick(cardType: CardType) {
    this.corporateService.setCardType(cardType);
    this.cardType = cardType;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { cardType: cardType },
      queryParamsHandling: 'merge'
    });
    return;
  }

  public navigateToOnBoardingPage() {
    this.router.navigate(['corporate/on-boarding', this.jobId]);
  }

  public editJob() {
    this.router.navigate(['/job/initiation', this.jobId])
  }

  public async onStatusChange(status: CorporateTalentStatus): Promise<void> {
    this.selectedTalentStatus = status;
    this.jobService.setCorporateTalentStatus(status);
    if (this.cardType === 'applicant' && this.jobId) {
      this.appliedTalentList = await this.jobService.getAppliedTalentList(this.jobId, {
        page: 1,
        status: this.selectedTalentStatus,
      })
    }
  }

  public async deactivateJob() {
    try {
      this.isLoading = true;
      if (this.job.id) {
        await this.jobService.deactivateJob(this.job.id);
        this.toastManager.showSuccess('Job deactivated successfully')
        this.jobService.searchParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (params) => {
            await this.jobService.getCorporateJobList(params)
            this.router.navigate(['/corporate/job'])
          })
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public openModal(modalName: string) {
    this.snackbarService.open(modalName);
  }

  public async onCloseModal() {
    this.snackbarService.close();
  }

  public async deleteJob() {
    this.isLoading = true;
    try {
      if (this.job.id) {
        await this.jobService.deleteJob(this.job.id);
        this.toastManager.showSuccess('Job deleted successfully')
        this.router.navigate(['corporate/job'])
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async helpJob() {

  }

  public toggleDropsApplication() {
    this.dropDownApplicationDisplay = 'none';
    this.isDropsApplicationVisible = !this.isDropsApplicationVisible;
    if (this.isDropsApplicationVisible) {
      this.dropDownApplicationDisplay = 'flex'
    }
  }
}
