<div>
  <div class="form-container">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="form-title">
      <div class="title-content">
        <div class="title-text">
          Basic Information
        </div>
        <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="break-line">
      </div>
    </div>
    <form [formGroup]="basicInfoForm" class="form-body" (ngSubmit)="onClickUpdate()">
      <div class="profile-name-section">
        <div class="profile-section-basic-info">
          <img *ngIf="oldBasicInfo && oldBasicInfo.profilePictureUrl" [src]="oldBasicInfo.profilePictureUrl"
            class="profile-picture-img" />
          <img *ngIf="!oldBasicInfo.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
            class="profile-picture-img" />

        </div>
        <div class="form-group-label-input flex-end">
          <div class="form-label-input">
            Preferred Name
          </div>
          <div class="input-rounded-parent">
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-person-fill"></i>
              </span>
              <input type="text" formControlName="firstName" class="ec-form-control-rounded ec-is-invalid"
                placeholder="First Name">
              <div class="ec-invalid-feedback"
                *ngIf="basicInfoForm.get('firstName')?.touched && basicInfoForm.get('firstName')?.hasError('required')">
                First name is required
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="basicInfoForm.get('firstName')?.touched && basicInfoForm.get('firstName')?.hasError('maxlength') && !basicInfoForm.get('lastName')?.hasError('pattern')">
                First Name length maximum {{ 'INPUT' | maxLength }} characters
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="basicInfoForm.get('firstName')?.touched && basicInfoForm.get('firstName')?.hasError('pattern')">
                First name should be a alphabetically
              </div>
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-person-fill"></i>
              </span>
              <input type="text" formControlName="lastName" class="ec-form-control-rounded ec-is-invalid"
                placeholder="Last Name">
              <div class="ec-invalid-feedback"
                *ngIf="basicInfoForm.get('lastName')?.touched && basicInfoForm.get('lastName')?.hasError('maxlength') && !basicInfoForm.get('lastName')?.hasError('pattern')">
                Last Name length maximum {{ 'INPUT' | maxLength }} characters
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="basicInfoForm.get('lastName')?.touched && basicInfoForm.get('lastName')?.hasError('pattern')">
                Last Name should be a alphabetically
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Position
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-person-vcard-fill"></i>
          </span>
          <input type="email" formControlName="position" class="ec-form-control-rounded ec-is-invalid"
            placeholder="Position">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('position')?.touched && basicInfoForm.get('position')?.hasError('required')">
            Position is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('position')?.touched && basicInfoForm.get('position')?.hasError('pattern')">
            Position should be a alphanumeric and minimum 3 characters
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Status
        </div>
        <div class="checkbox-parent">
          <input type="checkbox" name="isLookingForWork" formControlName="isLookingForWork" id="isLookingForWork"
            class="ec-form-check-input-sm ec-is-invalid">
          <div class="sub">
            <span>Looking for work</span>
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Current Company
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-building-fill"></i>
          </span>
          <input type="text" formControlName="currentCompany" class="ec-form-control-rounded ec-is-invalid"
            placeholder="Current Company">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('currentCompany')?.touched && basicInfoForm.get('currentCompany')?.hasError('pattern')">
            Current Company should be a alphanumeric and minimum 3 characters
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Phone Number
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-telephone-fill"></i>
          </span>
          <input type="email" formControlName="phone" class="ec-form-control-rounded ec-is-invalid"
            placeholder="Phone Number">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('phone')?.touched && basicInfoForm.get('phone')?.hasError('required')">
            Phone number is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('phone')?.touched && basicInfoForm.get('phone')?.hasError('minlength') && !basicInfoForm.get('phone')?.hasError('pattern')">
            Phone number length minimum 9 characters
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('phone')?.touched && basicInfoForm.get('phone')?.hasError('pattern')">
            Phone number should be a number and avoid leading zeros
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('phone')?.touched && basicInfoForm.get('phone')?.hasError('maxlength') && !basicInfoForm.get('phone')?.hasError('pattern')">
            Phone number length maximum {{ 'PHONE' | maxLength }} characters
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Email
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-at"></i>
          </span>
          <input type="email" formControlName="email" id="email" class="ec-form-control-rounded ec-is-invalid"
            placeholder="Email">
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('email')?.touched && basicInfoForm.get('email')?.hasError('required')">
            Email is required
          </div>
          <div class="ec-invalid-feedback"
            *ngIf="basicInfoForm.get('email')?.touched && basicInfoForm.get('email')?.hasError('email')">
            Email is not valid email
          </div>
        </div>
      </div>
      <div class="form-group-label-input">
        <div class="form-label-input">
          Set Time Zone
        </div>
        <div class="ec-input-group-rounded">
          <span class="ec-input-group-text">
            <i class="bi bi-pin-map-fill"></i>
          </span>
          <select type="text" formControlName="preferredTimezone" class="ec-form-select-rounded">
            <option value="" disabled selected>Timezone</option>
            <option *ngFor="let timezone of timezoneList" [value]="timezone.text">{{ timezone.text }}</option>
          </select>
        </div>
        <div class="invalid-feedback-text"
          *ngIf="basicInfoForm.get('preferredTimezone')?.touched && basicInfoForm.get('preferredTimezone')?.hasError('required')">
          Timezone is required
        </div>
      </div>
      <div class="break-line">
      </div>
      <div class="button-group">
        <button type="submit" [disabled]="!basicInfoForm.valid || isLoading"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </form>
  </div>
</div>
