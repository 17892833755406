<div *ngIf="!tooltipText && tooltipType === ''" class="custom-tooltip">
  {{ tooltipText }}
</div>

<div *ngIf="tooltipType === 'hardSkill'" class="custom-tooltip-wrapper">
  <div>
    Technical abilities you learn through training, like coding or software proficiency.
  </div>
  <div>Example:</div>
  <ul>
    <li> Web</li>
    <li> Developer</li>
    <li> Front-End Development</li>
    <li> SEO</li>
    <li> Graphic Design</li>
    <li> Adobe Photoshop</li>
  </ul>
</div>

<div *ngIf="tooltipType === 'softSkill'" class="custom-tooltip-wrapper">
  <div>
    Interpersonal qualities, like communication and teamwork, that enhance your professional journey.
  </div>
  <div>Example:</div>
  <ul>
    <li> Communication</li>
    <li> Teamwork</li>
    <li> Leadership</li>
  </ul>
</div>