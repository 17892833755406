<div *ngIf="!isLoading" class="navigation">
  <corporate-search-bar searchBarType="corporate-find-job"></corporate-search-bar>
  <div class="tools">
    <div class="tabs">
      <div
        [ngClass]="selectedJobStatus === 'all' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
        (click)="onStatusChange('all')">
        <label>
          <i class="bi bi-files">
          </i>
          All Posts
        </label>
      </div>
      <div
        [ngClass]="selectedJobStatus === 'active' ? 'btn-rounded bg-success-soft': 'btn-border-rounded btn-border-success-soft'"
        (click)="onStatusChange('active')">
        <label>
          <i class="bi bi-file-earmark-check">
          </i>
          Active
        </label>
      </div>
      <div
        [ngClass]="selectedJobStatus === 'pending' ? 'btn-rounded bg-warning-soft': 'btn-border-rounded btn-border-warning-soft'"
        (click)="onStatusChange('pending')">
        <label>
          <i class="bi bi-file-break">
          </i>
          Pending
        </label>
      </div>
      <div
        [ngClass]="selectedJobStatus === 'declined' ? 'btn-rounded bg-danger-soft': 'btn-border-rounded btn-border-danger-soft'"
        (click)="onStatusChange('declined')">
        <label>
          <i class="bi bi-file-earmark-excel">
          </i>
          Rejected
        </label>
      </div>
      <div
        [ngClass]="selectedJobStatus === 'inactive' ? 'btn-rounded bg-grey-soft': 'btn-border-rounded btn-border-grey-soft'"
        (click)="onStatusChange('inactive')">
        <label>
          <i class="bi bi-file-earmark-minus">
          </i>
          Inactive
        </label>
      </div>
    </div>
    <div (click)="navigateToJobTemplate()" class="btn-rounded bg-orange-soft">
      <label>
        <i class="bi bi-clipboard-fill">
        </i>
        Draft / Template
        <i class="bi bi-arrow-right">
        </i>
      </label>
    </div>
  </div>
  <job-list jobListType="corporate-find-job"></job-list>
</div>
