<div *ngIf="!isLoading" class="navigation">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <popup-confirmation id="delete-job">
    <div class="confirmation-pop-up">
      <div class="frame-1673">
        <div class="confirmation-title">
          Are you sure you want to delete?
        </div>
        <svg (click)="onCloseModal()" class="x" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="button">
        <button type="button" (click)="deleteJob()"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Yes, Delete
        </button>
        <button type="button" (click)="onCloseModal()"
          class="button-save ec-btn ec-btn-sm ec-btn-link-secondary ec-btn-rounded mb-2 me-2">
          No, I don’t want to delete
        </button>
      </div>
    </div>
  </popup-confirmation>
  <div class="dasboard-container" style="margin: 10px 0px;">
    <div *ngIf="job && companyProfile" class="form-body" style="width: 100%;">
      <div class="form-job-list" style="margin: 0px;">
        <div class="job-form-preview" style="background-color: #c6daf1;">
          <div class="statistic">
            <div [ngClass]="cardType === 'viewedBy' ? 'stat-card-active' : 'stat-card'"
              (click)="onStatCardClick('viewedBy')">
              <div class="deets">
                <div class="viewed-by">Viewed by</div>
                <svg class="icon-card" width="21" height="20" viewBox="0 0 21 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.458 10C13.458 11.7259 12.0589 13.125 10.333 13.125C8.60712 13.125 7.20801 11.7259 7.20801 10C7.20801 8.27411 8.60712 6.875 10.333 6.875C12.0589 6.875 13.458 8.27411 13.458 10Z"
                    fill="#757575" />
                  <path
                    d="M0.333008 10C0.333008 10 4.08301 3.125 10.333 3.125C16.583 3.125 20.333 10 20.333 10C20.333 10 16.583 16.875 10.333 16.875C4.08301 16.875 0.333008 10 0.333008 10ZM10.333 14.375C12.7493 14.375 14.708 12.4162 14.708 10C14.708 7.58375 12.7493 5.625 10.333 5.625C7.91676 5.625 5.95801 7.58375 5.95801 10C5.95801 12.4162 7.91676 14.375 10.333 14.375Z"
                    fill="#757575" />
                </svg>
              </div>
              <div class="_120-people">{{job.viewedCount || 0}} People</div>
            </div>
            <div [ngClass]="cardType === 'applicant' ? 'stat-card-active' : 'stat-card'"
              (click)="onStatCardClick('applicant')">
              <div class="deets">
                <div class="viewed-by">Applicants</div>
                <svg class="icon-card" width="21" height="20" viewBox="0 0 21 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.41602 17.5C9.41602 17.5 8.16602 17.5 8.16602 16.25C8.16602 15 9.41602 11.25 14.416 11.25C19.416 11.25 20.666 15 20.666 16.25C20.666 17.5 19.416 17.5 19.416 17.5H9.41602Z"
                    fill="#757575" />
                  <path
                    d="M14.416 10C16.4871 10 18.166 8.32107 18.166 6.25C18.166 4.17893 16.4871 2.5 14.416 2.5C12.3449 2.5 10.666 4.17893 10.666 6.25C10.666 8.32107 12.3449 10 14.416 10Z"
                    fill="#757575" />
                  <path
                    d="M7.18647 17.5C7.01047 17.1449 6.91602 16.7239 6.91602 16.25C6.91602 14.5557 7.76483 12.8132 9.33593 11.6C8.64863 11.3801 7.84739 11.25 6.91602 11.25C1.91602 11.25 0.666016 15 0.666016 16.25C0.666016 17.5 1.91602 17.5 1.91602 17.5H7.18647Z"
                    fill="#757575" />
                  <path
                    d="M6.29102 10C8.01691 10 9.41602 8.60089 9.41602 6.875C9.41602 5.14911 8.01691 3.75 6.29102 3.75C4.56513 3.75 3.16602 5.14911 3.16602 6.875C3.16602 8.60089 4.56513 10 6.29102 10Z"
                    fill="#757575" />
                </svg>
              </div>
              <div class="_120-people">{{job.applicantCount || 0}} People</div>
            </div>
            <div [ngClass]="cardType === 'offering' ? 'stat-card-active' : 'stat-card'"
              (click)="onStatCardClick('offering')">
              <div class="deets">
                <div class="viewed-by">Wishlist &amp; Offering</div>
                <svg class="icon-card" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.375 1.55797C15.4152 0.488751 18.0158 2.35988 14.375 4.76562C10.7342 2.35988 13.3348 0.488751 14.375 1.55797ZM11.25 6.25C11.25 8.32107 9.57107 10 7.5 10C5.42893 10 3.75 8.32107 3.75 6.25C3.75 4.17893 5.42893 2.5 7.5 2.5C9.57107 2.5 11.25 4.17893 11.25 6.25ZM0 16.25C0 17.5 1.25 17.5 1.25 17.5H13.75C13.75 17.5 15 17.5 15 16.25C15 15 13.75 11.25 7.5 11.25C1.25 11.25 0 15 0 16.25ZM16.875 6.13828C18.6087 4.35625 22.9431 7.47481 16.875 11.4844C10.8069 7.47481 15.1413 4.35625 16.875 6.13828ZM18.75 2.70531C19.4435 1.9925 21.1772 3.23992 18.75 4.84375C16.3228 3.23992 18.0565 1.9925 18.75 2.70531Z"
                    fill="#757575" />
                </svg>
              </div>
              <div class="_120-people">{{job.offerCount || 0}} People</div>
            </div>
            <div [ngClass]="cardType === 'on-boarding-talent' ? 'stat-card-active' : 'stat-card'"
              (click)="navigateToOnBoardingPage()">
              <div class="deets">
                <div class="viewed-by">Placements</div>
                <svg class="icon-card" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.625 20C18.0412 20 20 18.0412 20 15.625C20 13.2088 18.0412 11.25 15.625 11.25C13.2088 11.25 11.25 13.2088 11.25 15.625C11.25 18.0412 13.2088 20 15.625 20ZM16.25 13.75V15H17.5C17.8452 15 18.125 15.2798 18.125 15.625C18.125 15.9702 17.8452 16.25 17.5 16.25H16.25V17.5C16.25 17.8452 15.9702 18.125 15.625 18.125C15.2798 18.125 15 17.8452 15 17.5V16.25H13.75C13.4048 16.25 13.125 15.9702 13.125 15.625C13.125 15.2798 13.4048 15 13.75 15H15V13.75C15 13.4048 15.2798 13.125 15.625 13.125C15.9702 13.125 16.25 13.4048 16.25 13.75Z"
                    fill="#757575" />
                  <path
                    d="M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z"
                    fill="#757575" />
                  <path
                    d="M2.5 16.25C2.5 17.5 3.75 17.5 3.75 17.5H10.3201C10.1128 16.9135 10 16.2824 10 15.625C10 13.9329 10.7471 12.4153 11.9295 11.3842C11.3463 11.2977 10.705 11.25 10 11.25C3.75 11.25 2.5 15 2.5 16.25Z"
                    fill="#757575" />
                </svg>
              </div>
              <div class="_120-people">{{job.onboardingCount || 0}} People</div>
            </div>
          </div>
          <div class="chevron" (click)="expandJobDetail()">
            <i *ngIf="!isMainExpanded" class="bi bi-chevron-down"></i>
            <i *ngIf="isMainExpanded" class="bi bi-chevron-up"></i>
          </div>
          <!-- <div class="main" [@expandCollapse]="isMainExpanded ? 'expanded' : 'collapsed'"> -->
          <div class="main" *ngIf="isMainExpanded">
            <div class="details">
              <div class="jobtitle">
                <div class="front-end-developer-senior-level">
                  {{ job.postTitle}} </div>
                <div class="deets">
                  <div class="bca">
                    {{ companyProfile.brand}}
                  </div>
                  <div class="ellipse-435">
                  </div>
                  <div class="_1-hour-ago">
                    {{ job.createdAt | timeAgo}} </div>
                </div>
              </div>
              <div class="cta">
                <div class="cta" *ngIf="job.status === 0">
                  <div [ngClass]="'btn-rounded bg-primary-soft'" (click)="editJob()">
                    <label>Continue
                      <i class="bi bi-arrow-up-right-circle">
                      </i>
                    </label>
                  </div>
                  <div [ngClass]="'btn-border-rounded btn-border-danger-soft'" (click)="openModal('delete-job')">
                    <label>Delete
                    </label>
                  </div>
                  <!-- <svg (click)="toggleDropsApplication()" class="three-dots" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z"
                      fill="#757575" />
                  </svg> -->
                </div>
                <!-- pending -->
                <div class="cta" *ngIf="job.status === 1">
                  <div [ngClass]="'btn-rounded bg-warning-soft'">
                    <label>On Review
                    </label>
                  </div>
                  <!-- <svg (click)="toggleDropsApplication()" class="three-dots" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z"
                      fill="#757575" />
                  </svg> -->
                </div>
                <!-- active -->
                <div class="cta" *ngIf="job.status === 2">
                  <div [ngClass]="'btn-rounded bg-primary-soft'" (click)="editJob()">
                    <label>Edit
                      <i class="bi bi-arrow-up-right-circle">
                      </i>
                    </label>
                  </div>
                  <div [ngClass]="'btn-rounded bg-warning-soft'" (click)="deactivateJob()">
                    <label>Deactivate
                    </label>
                  </div>
                  <div [ngClass]="'btn-border-rounded btn-border-danger-soft'" (click)="openModal('delete-job')">
                    <label>Delete
                    </label>
                  </div>
                  <!-- <svg (click)="toggleDropsApplication()" class="three-dots" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z"
                      fill="#757575" />
                  </svg> -->
                </div>
                <!-- closed -->
                <div class="cta" *ngIf="job.status === 3">
                  <div [ngClass]="'btn-border-rounded btn-border-danger-soft'" (click)="openModal('delete-job')">
                    <label>Delete
                    </label>
                  </div>
                  <!-- <svg (click)="toggleDropsApplication()" class="three-dots" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z"
                      fill="#757575" />
                  </svg> -->
                </div>
                <!-- declined/rejected -->
                <div class="cta" *ngIf="job.status === 4">
                  <div [ngClass]="'btn-rounded bg-warning-soft'" (click)="helpJob()">
                    <label>Help
                    </label>
                  </div>
                  <div [ngClass]="'btn-border-rounded btn-border-danger-soft'" (click)="openModal('delete-job')">
                    <label>Delete
                    </label>
                  </div>
                  <!-- <svg (click)="toggleDropsApplication()" class="three-dots" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 14.25C3.25736 14.25 2.25 13.2426 2.25 12C2.25 10.7574 3.25736 9.75 4.5 9.75C5.74264 9.75 6.75 10.7574 6.75 12C6.75 13.2426 5.74264 14.25 4.5 14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25ZM19.5 14.25C18.2574 14.25 17.25 13.2426 17.25 12C17.25 10.7574 18.2574 9.75 19.5 9.75C20.7426 9.75 21.75 10.7574 21.75 12C21.75 13.2426 20.7426 14.25 19.5 14.25Z"
                      fill="#757575" />
                  </svg> -->
                </div>
                <!-- <div class="drops-application">
                  <div class="drops">
                    <svg class="flag-fill" width="12" height="12" viewBox="0 0 12 12" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.0839 0.0635577C11.1877 0.133191 11.25 0.249985 11.25 0.375V6C11.25 6.15334 11.1566 6.29123 11.0143 6.34818L10.875 6C11.0143 6.34818 11.0143 6.34818 11.0143 6.34818L11.0123 6.34895L11.0077 6.35079L10.9906 6.35754C10.9757 6.36335 10.9542 6.37175 10.9265 6.38235C10.8712 6.40356 10.7916 6.43363 10.6926 6.46961C10.4949 6.54149 10.2193 6.63738 9.90716 6.73342C9.29564 6.92158 8.49861 7.125 7.875 7.125C7.23996 7.125 6.71374 6.91444 6.25662 6.73154L6.23573 6.72318C5.76019 6.53296 5.35526 6.375 4.875 6.375C4.34988 6.375 3.64619 6.54695 3.04691 6.73312C2.75303 6.82442 2.49439 6.91585 2.30931 6.98444C2.28859 6.99212 2.2688 6.99951 2.25 7.00658V11.625C2.25 11.8321 2.08211 12 1.875 12C1.66789 12 1.5 11.8321 1.5 11.625V0.375C1.5 0.167893 1.66789 0 1.875 0C2.08211 0 2.25 0.167893 2.25 0.375V0.586689C2.41958 0.527356 2.62209 0.459506 2.84284 0.391583C3.45436 0.203423 4.25139 0 4.875 0C5.50556 0 6.01792 0.207695 6.46573 0.389222C6.47663 0.393641 6.48749 0.398043 6.49831 0.402427C6.96438 0.591198 7.37086 0.75 7.875 0.75C8.40012 0.75 9.10381 0.578054 9.70309 0.391883C9.99697 0.300585 10.2556 0.209148 10.4407 0.140559C10.5331 0.106307 10.6069 0.0778596 10.6572 0.0581211C10.6824 0.0482545 10.7016 0.0405722 10.7144 0.0354377L10.7287 0.0296954L10.732 0.0283446L10.7327 0.0280535"
                        fill="#757575" />
                    </svg>

                    <div class="report-this-post">Report this post</div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="quick">
              <div class="job">
                <svg class="briefcase-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.5 1C5.67173 1 5 1.67117 5 2.49976V3H1.5C0.671573 3 0 3.67157 0 4.5V5.88414L7.61351 7.91441C7.86674 7.98194 8.13326 7.98194 8.38649 7.91441L16 5.88414V4.5C16 3.67157 15.3284 3 14.5 3H11V2.49976C11 1.67117 10.3283 1 9.5 1H6.5ZM6.5 2H9.5C9.7763 2 10 2.22463 10 2.50061V3H6V2.49976C6 2.22377 6.2237 2 6.5 2Z"
                    fill="#757575" />
                  <path
                    d="M0 12.5C0 13.3284 0.671573 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V6.84919L8.12883 8.94817C8.04442 8.97068 7.95558 8.97068 7.87117 8.94817L0 6.84919V12.5Z"
                    fill="#757575" />
                </svg>
                <div class="deets2">
                  <div class="tag-jobtype">
                    <div class="wfo">
                      {{ job.workplaceType }} </div>
                  </div>
                  <div class="ellipse-4352">
                  </div>
                  <div class="tag-joblevel">
                    <div class="full-time">
                      {{ job.employmentType }} </div>
                  </div>
                  <div class="ellipse-437">
                  </div>
                  <div class="tag-joblevel">
                    <div class="senior">
                      {{ job.experienceLevel }} </div>
                  </div>
                  <div class="ellipse-436">
                  </div>
                  <div class="tag-joblevel">
                    <div class="tech-lead">
                      {{job.jobTitle}} </div>
                  </div>
                </div>
              </div>
              <div class="industry">
                <svg class="building" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 2.5C4 2.22386 4.22386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5V3.5C6 3.77614 5.77614 4 5.5 4H4.5C4.22386 4 4 3.77614 4 3.5V2.5Z"
                    fill="#757575" />
                  <path
                    d="M7 2.5C7 2.22386 7.22386 2 7.5 2H8.5C8.77614 2 9 2.22386 9 2.5V3.5C9 3.77614 8.77614 4 8.5 4H7.5C7.22386 4 7 3.77614 7 3.5V2.5Z"
                    fill="#757575" />
                  <path
                    d="M10.5 2C10.2239 2 10 2.22386 10 2.5V3.5C10 3.77614 10.2239 4 10.5 4H11.5C11.7761 4 12 3.77614 12 3.5V2.5C12 2.22386 11.7761 2 11.5 2H10.5Z"
                    fill="#757575" />
                  <path
                    d="M4 5.5C4 5.22386 4.22386 5 4.5 5H5.5C5.77614 5 6 5.22386 6 5.5V6.5C6 6.77614 5.77614 7 5.5 7H4.5C4.22386 7 4 6.77614 4 6.5V5.5Z"
                    fill="#757575" />
                  <path
                    d="M7.5 5C7.22386 5 7 5.22386 7 5.5V6.5C7 6.77614 7.22386 7 7.5 7H8.5C8.77614 7 9 6.77614 9 6.5V5.5C9 5.22386 8.77614 5 8.5 5H7.5Z"
                    fill="#757575" />
                  <path
                    d="M10 5.5C10 5.22386 10.2239 5 10.5 5H11.5C11.7761 5 12 5.22386 12 5.5V6.5C12 6.77614 11.7761 7 11.5 7H10.5C10.2239 7 10 6.77614 10 6.5V5.5Z"
                    fill="#757575" />
                  <path
                    d="M4.5 8C4.22386 8 4 8.22386 4 8.5V9.5C4 9.77614 4.22386 10 4.5 10H5.5C5.77614 10 6 9.77614 6 9.5V8.5C6 8.22386 5.77614 8 5.5 8H4.5Z"
                    fill="#757575" />
                  <path
                    d="M7 8.5C7 8.22386 7.22386 8 7.5 8H8.5C8.77614 8 9 8.22386 9 8.5V9.5C9 9.77614 8.77614 10 8.5 10H7.5C7.22386 10 7 9.77614 7 9.5V8.5Z"
                    fill="#757575" />
                  <path
                    d="M10.5 8C10.2239 8 10 8.22386 10 8.5V9.5C10 9.77614 10.2239 10 10.5 10H11.5C11.7761 10 12 9.77614 12 9.5V8.5C12 8.22386 11.7761 8 11.5 8H10.5Z"
                    fill="#757575" />
                  <path
                    d="M2 1C2 0.447716 2.44771 0 3 0H13C13.5523 0 14 0.447715 14 1V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1ZM13 1L3 1V15H6V12.5C6 12.2239 6.22386 12 6.5 12H9.5C9.77614 12 10 12.2239 10 12.5V15H13V1Z"
                    fill="#757575" />
                </svg>
                <div class="deets2">
                  <div class="it-technology-and-internet">
                    {{companyProfile.industry}}
                  </div>
                </div>
              </div>
              <div class="loc">
                <svg class="pin-map-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.1 11.2C3.19443 11.0741 3.34262 11 3.5 11H6C6.27615 11 6.5 11.2239 6.5 11.5C6.5 11.7761 6.27615 12 6 12H3.75L1.5 15H14.5L12.25 12H10C9.72386 12 9.5 11.7761 9.5 11.5C9.5 11.2239 9.72386 11 10 11H12.5C12.6574 11 12.8056 11.0741 12.9 11.2L15.9 15.2C16.0136 15.3515 16.0319 15.5542 15.9472 15.7236C15.8625 15.893 15.6894 16 15.5 16H0.500003C0.310617 16 0.137485 15.893 0.0527893 15.7236C-0.0319067 15.5542 -0.0136288 15.3515 0.100003 15.2L3.1 11.2Z"
                    fill="#757575" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.03981 10.4732 7.723 8.5 7.96905V13.5C8.5 13.7761 8.27615 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5V7.96905C5.52685 7.723 4 6.03981 4 4Z"
                    fill="#757575" />
                </svg>
                <div class="deets2">
                  <div class="jakarta">
                    {{ job.office.city }} </div>
                  <div class="ellipse-4352">
                  </div>
                  <div class="indonesia">
                    {{ job.office.country }} </div>
                </div>
              </div>
              <div class="loc">
                <svg class="clock-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
                    fill="#757575" />
                </svg>
                <div class="deets2">
                  <div class="regular-hours">
                    {{ job.workingHour }} </div>
                </div>
              </div>
              <div class="loc">
                <svg class="mortarboard-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.21144 2.04691C8.07742 1.98436 7.92258 1.98436 7.78856 2.04691L0.288558 5.54691C0.107914 5.63121 -0.00539504 5.81476 0.000197994 6.01403C0.005791 6.2133 0.129217 6.3902 0.314306 6.46424L7.81431 9.46424C7.93351 9.51192 8.06649 9.51192 8.1857 9.46424L14 7.13852V13C13.4477 13 13 13.4477 13 14V16H16V14C16 13.4477 15.5523 13 15 13V6.73852L15.6857 6.46424C15.8708 6.3902 15.9942 6.2133 15.9998 6.01403C16.0054 5.81476 15.8921 5.63121 15.7114 5.54691L8.21144 2.04691Z"
                    fill="#757575" />
                  <path
                    d="M4.17556 9.03184C4.04549 8.98306 3.90098 8.99059 3.77669 9.05264C3.6524 9.11468 3.55952 9.22564 3.52032 9.35892L3.02032 11.0589C2.94756 11.3063 3.07488 11.5685 3.31431 11.6642L7.81431 13.4642C7.93351 13.5119 8.06649 13.5119 8.1857 13.4642L12.6857 11.6642C12.9251 11.5685 13.0524 11.3063 12.9797 11.0589L12.4797 9.35892C12.4405 9.22564 12.3476 9.11468 12.2233 9.05264C12.099 8.99059 11.9545 8.98306 11.8244 9.03184L8 10.466L4.17556 9.03184Z"
                    fill="#757575" />
                </svg>
                <div class="deets2">
                  <div class="minimum-bachelor-s-degree">
                    {{ job.educationLevel }} </div>
                </div>
              </div>
              <div class="loc">
                <div class="person-fill">
                  <svg class="person-fill2" width="12" height="12" viewBox="0 0 12 12" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1Z" fill="#757575" />
                    <path
                      d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z"
                      fill="#757575" />
                  </svg>
                </div>
                <div class="deets2">
                  <div class="_2-talents-needed">
                    {{ job.talentNeeded }} Talents Needed </div>
                  <div class="ellipse-4352">
                  </div>
                  <div class="_0-applicants">
                    {{ job.applicantsCount }} Applicants </div>
                </div>
              </div>
              <div class="loc">
                <svg class="spellcheck" width="16" height="16" viewBox="0 0 16 16" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.2168 11.0684C9.43262 11.0684 10.165 10.1992 10.165 8.75879V8.05566C10.165 6.61523 9.4375 5.75098 8.23633 5.75098C7.49414 5.75098 6.9082 6.09766 6.7373 6.63965H6.67383V3.9834H5.38477V11H6.6543V10.209H6.71777C6.92773 10.7412 7.49414 11.0684 8.2168 11.0684ZM7.78711 10.043C7.12793 10.043 6.67383 9.52539 6.67383 8.76367V8.11914C6.67383 7.29395 7.09375 6.77637 7.77246 6.77637C8.45605 6.77637 8.84668 7.29395 8.84668 8.19238V8.6416C8.84668 9.53027 8.46094 10.043 7.78711 10.043ZM2.2041 11.0781C2.9707 11.0781 3.40527 10.7217 3.61035 10.3408H3.66895V11H4.88477V7.51855C4.88477 6.20508 3.9375 5.73633 2.77539 5.73633C1.35449 5.73633 0.749023 6.41992 0.69043 7.26953H1.90625C1.96973 6.94727 2.21875 6.71777 2.74609 6.71777C3.27344 6.71777 3.61035 6.9668 3.61035 7.48926V7.95312H2.3457C1.14453 7.95312 0.5 8.56836 0.5 9.49609C0.5 10.4727 1.19336 11.0781 2.2041 11.0781ZM2.62402 10.1309C2.18457 10.1309 1.7793 9.89648 1.7793 9.41309C1.7793 9.01758 2.04785 8.72949 2.61914 8.72949H3.61035V9.2666C3.61035 9.76953 3.16602 10.1309 2.62402 10.1309ZM11.5209 10.6978C10.9439 10.2976 10.6211 9.6105 10.6211 8.71484V8.06543C10.6211 6.64453 11.5146 5.72656 12.9258 5.72656C14.2783 5.72656 15.0449 6.54688 15.0645 7.5332H13.8779C13.8389 7.18164 13.5947 6.75684 12.96 6.75684C12.2861 6.75684 11.915 7.27441 11.915 8.08496V8.70996C11.915 9.17782 12.0363 9.54415 12.2576 9.77688L11.5209 10.6978Z"
                    fill="#757575" />
                  <path
                    d="M14.4685 9.41435C14.792 9.67311 14.8444 10.1451 14.5857 10.4685L10.5857 15.4685C10.4524 15.6351 10.2545 15.7371 10.0415 15.7489C9.82847 15.7606 9.62052 15.6812 9.46967 15.5303L6.96967 13.0303C6.67678 12.7374 6.67678 12.2626 6.96967 11.9697C7.26256 11.6768 7.73744 11.6768 8.03033 11.9697L9.93781 13.8772L13.4143 9.53148C13.6731 9.20803 14.1451 9.15559 14.4685 9.41435Z"
                    fill="#757575" />
                </svg>
                <div class="deets">
                  <div class="indonesia" *ngFor="let item of job.languages; let last = last">
                    <label style="margin-right: 5px;">{{item.language}}</label>
                    <div *ngIf="!last" class="ellipse-4352"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="details2">
              <div class="job-details">
                Job Details </div>
              <div class="break">
              </div>
              <div [innerHTML]="job.jobDescription | safeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tabs" *ngIf="cardType === 'applicant'">
    <div
      [ngClass]="selectedTalentStatus === 'all' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
      (click)="onStatusChange('all')">
      <label>
        <i class="bi bi-files">
        </i>
        All Status
      </label>
    </div>
    <div
      [ngClass]="selectedTalentStatus === 'on process' ? 'btn-rounded bg-success-soft': 'btn-border-rounded btn-border-success-soft'"
      (click)="onStatusChange('on process')">
      <label>
        <i class="bi bi-file-earmark-check">
        </i>
        On Process
      </label>
    </div>
    <div
      [ngClass]="selectedTalentStatus === 'pending' ? 'btn-rounded bg-warning-soft': 'btn-border-rounded btn-border-warning-soft'"
      (click)="onStatusChange('pending')">
      <label>
        <i class="bi bi-file-break">
        </i>
        Pending
      </label>
    </div>
    <div
      [ngClass]="selectedTalentStatus === 'declined' ? 'btn-rounded bg-danger-soft': 'btn-border-rounded btn-border-danger-soft'"
      (click)="onStatusChange('declined')">
      <label>
        <i class="bi bi-file-earmark-excel">
        </i>
        Declined
      </label>
    </div>
  </div>
  <talent-list talentListType="corporate-find-talent"></talent-list>
</div>
