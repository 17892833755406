// Angular modules
import { Injectable } from '@angular/core';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

// Internal modules
import { environment } from '@env/environment';
import { SignUpData } from '@interfaces/register.interface';
import { UserProfile } from '@interfaces/talent.interface';
import { CompanyProfile, SubNavbar } from '@interfaces/corporate.interface';

/**
 * Angular Application State Management: You Do (Not) Need External Data Stores like NgRx, Redux, and Other
 * https://www.maestralsolutions.com/angular-application-state-management-you-do-not-need-external-data-stores/
 */
@Injectable()
export class StoreService {
  // NOTE Data
  private readonly _pageTitleSource = new BehaviorSubject<string>(environment.appName);
  private readonly _isLoadingSource = new BehaviorSubject<boolean>(false);
  private readonly _signUpData = new BehaviorSubject<SignUpData>({ email: '', signUpType: 'SELF', firstName: '', lastName: '', signUpAs: '' });
  private readonly _userProfile = new BehaviorSubject<UserProfile | null>(null);
  private readonly _companyProfile = new BehaviorSubject<CompanyProfile | null>(null);
  private readonly _activeSidebar = new BehaviorSubject<string>('my-account');
  private readonly _activeNavbar = new BehaviorSubject<string>('');
  private readonly _activeSubnavbar = new BehaviorSubject<string>('');
  private readonly _isSubNavbarShow = new BehaviorSubject<boolean>(true);
  private readonly _loginAs = new BehaviorSubject<string>('TALENT');
  private readonly _isLogOut = new BehaviorSubject<boolean>(false);

  // NOTE Events
  readonly pageTitle$ = this._pageTitleSource.asObservable();
  readonly isLoading$ = this._isLoadingSource.asObservable();
  readonly signUpData$ = this._signUpData.asObservable();
  readonly userProfile$ = this._userProfile.asObservable();
  readonly companyProfile$ = this._companyProfile.asObservable();
  readonly activeSidebar$ = this._activeSidebar.asObservable();
  readonly activeNavbar$ = this._activeNavbar.asObservable();
  readonly activeSubnavbar$ = this._activeSubnavbar.asObservable();
  readonly isSubNavbarShow$ = this._isSubNavbarShow.asObservable();
  readonly loginAs$ = this._loginAs.asObservable();
  readonly isLogOut$ = this._isLogOut.asObservable();

  constructor
    (
      private translateService: TranslateService,
    ) {
  }

  // -------------------------------------------------------------------------------
  // NOTE Page title ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getPageTitle(): string {
    return this._pageTitleSource.getValue();
  }

  public setPageTitle(title: string, translate: boolean = true): void {
    const pageTitle = translate ? this.translateService.instant(title) : title;
    this._pageTitleSource.next(pageTitle);
  }

  // -------------------------------------------------------------------------------
  // NOTE Is loading ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public getIsLoading(): boolean {
    return this._isLoadingSource.getValue();
  }

  public setIsLoading(state: boolean): void {
    this._isLoadingSource.next(state);
  }

  public getSignUpData(): SignUpData {
    return this._signUpData.getValue();
  }

  public setSignUpData(signUpData: SignUpData): void {
    this._signUpData.next(signUpData);
  }

  public setUserProfile(userProfile: UserProfile | null): void {
    this._userProfile.next(userProfile);
  }

  public resetUserProfile(): void {
    this._userProfile.next(null);
  }


  public setCompanyProfile(companyProfile: CompanyProfile): void {
    this._companyProfile.next(companyProfile);
  }

  public setActiveNavbar(navbar: string): void {
    this._activeNavbar.next(navbar);
  }

  public setActiveSubNavbar(subNavbar: SubNavbar): void {
    this._activeSubnavbar.next(subNavbar);
  }

  public setActiveSidebar(activeSidebar: string): void {
    this._activeSidebar.next(activeSidebar);
  }

  public hideSubNavbar(): void {
    this._isSubNavbarShow.next(false);
  }

  public showSubNavbar(): void {
    this._isSubNavbarShow.next(true);
  }

  public setLoginAs(loginAs: 'TALENT' | 'CORPORATE' | 'BACK_OFFICE'): void {
    this._loginAs.next(loginAs);
  }

  public setLogOut(isLogOut: boolean): void {
    this._isLogOut.next(isLogOut);
  }
}
