<div>
  <div class="form-container">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="form-title">
      <div class="title-content">
        <div class="title-text">
          Portfolio </div>
        <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="break-line">
      </div>
    </div>
    <form class="form-body" [formGroup]="skillForm" (ngSubmit)="onClickSubmit()">
      <div class="form-list" formArrayName="portfolioEntries">
        <div *ngFor="let entry of portfolioEntries.controls; let i = index" [formGroupName]="i">
          <div class="form">
            <div class="form-group-label-input">
              <div class="form-label-input">
                Project Name
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-rocket-takeoff-fill"></i>
                </span>
                <input type="text" formControlName="projectName" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Project Name">
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('projectName')?.touched && portfolioEntries.controls[i]?.get('projectName')?.hasError('required')">
                  Project name is required
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('projectName')?.touched && portfolioEntries.controls[i]?.get('projectName')?.hasError('maxlength') && !portfolioEntries.controls[i]?.get('projectName')?.hasError('pattern')">
                  Project name length maximum {{ 'INPUT' | maxLength }} characters
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('projectName')?.touched && portfolioEntries.controls[i]?.get('projectName')?.hasError('pattern')">
                  Project name should be a alphanumeric and minimum 3 characters
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Project Description
              </div>
              <div class="input-text-space">
                <ckeditor id="description" name="description" [editor]="Editor" [disableTwoWayDataBinding]="false"
                  formControlName="description"> </ckeditor>
                <div class="text-right">
                  <label>{{ getWordCount(i) }}/{{ 'TEXT_AREA_SMALL' | maxLength }} words </label>
                </div>
                <div class="invalid-feedback-text"
                  *ngIf="portfolioEntries.controls[i]?.get('description')?.touched && portfolioEntries.controls[i]?.get('description')?.hasError('required')">
                  Description is required
                </div>
                <div class="invalid-feedback-text"
                  *ngIf="portfolioEntries.controls[i]?.get('description')?.touched && portfolioEntries.controls[i]?.get('description')?.hasError('textAreaMaxLength')">
                  Description length maximum {{ 'TEXT_AREA_SMALL' | maxLength }} words
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Role
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-file-person"></i>
                </span>
                <input type="text" formControlName="role" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Role">
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('role')?.touched && portfolioEntries.controls[i]?.get('role')?.hasError('required') && !portfolioEntries.controls[i]?.get('role')?.hasError('pattern')">
                  Role is required
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('role')?.touched && portfolioEntries.controls[i]?.get('role')?.hasError('pattern')">
                  Role should be a alphanumeric and minimum 3 characters
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Start Date
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-calendar2-week-fill"></i>
                </span>
                <input type="text" formControlName="startDate" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Start Date" onfocus="(this.type='date')" min="1970-01-01" [max]="maxDate">
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('startDate')?.touched && portfolioEntries.controls[i]?.get('startDate')?.hasError('required')">
                  Start date is required
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="checkbox-parent input-text-space">
                <input type="checkbox" (change)="onIsOngoingClick(i)" name="privacyPolicyCheck"
                  formControlName="isOngoing" class="ec-form-check-input-sm ec-is-invalid">
                <div class="sub">
                  <span>It's an on going project</span>
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                End Date
              </div>
              <div class="ec-input-group-rounded">
                <span class="ec-input-group-text">
                  <i class="bi bi-calendar2-week-fill"></i>
                </span>
                <input type="text" formControlName="endDate" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="End Date" onfocus="(this.type='date')" min="1970-01-01" [max]="maxDate">
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.hasError('endDateLessThanStartDate')">
                  End date can't be less than start date!
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('endDate')?.touched && portfolioEntries.controls[i]?.get('endDate')?.hasError('required')">
                  End date is required
                </div>
              </div>
            </div>
            <div class="form-group-label-input">
              <div class="form-label-input">
                Project Link
              </div>
              <div class="ec-input-group-rounded input-text-space">
                <span class="ec-input-group-text">
                  <i class="bi bi-link-45deg"></i>
                </span>
                <input type="text" formControlName="url" class="ec-form-control-rounded ec-is-invalid"
                  placeholder="Project Link">
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('url')?.touched && portfolioEntries.controls[i]?.get('url')?.hasError('maxlength') && !portfolioEntries.controls[i]?.get('url')?.hasError('pattern')">
                  Project link length maximum {{ 'INPUT' | maxLength }} characters
                </div>
                <div class="ec-invalid-feedback"
                  *ngIf="portfolioEntries.controls[i]?.get('url')?.touched && portfolioEntries.controls[i]?.get('url')?.hasError('pattern')">
                  Project link must valid url
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="break-line">
      </div>
      <div class="button-group">
        <button type="submit" [disabled]="!isPortfolioFormValid() || isLoading"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </form>
  </div>
</div>