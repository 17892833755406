export enum LoginType {
  SELF = 'SELF',
  GOOGLE = 'GOOGLE',
}


export enum UserType {
  BACK_OFFICE = 1,
  TALENT = 2,
  CORPORATE = 3,
}
