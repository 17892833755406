<app-spinner *ngIf="isLoading"></app-spinner>
<div [title]="notification.isRead ? '' : 'Unread notification'" [ngClass]="notification.notitificationBorder"
  (click)="onClickDetail()" class="notification">
  <div class="cta">
    <div class="you-got-an-offering">{{notification.group | startCase }}
    </div>
    <div class="right-title">
      <!-- <i class="bi bi-eye-slash-fill" style="color: #d5615a" *ngIf="!isReadNotifation">
      </i> -->
      <div class="_25-april-2025">{{notification.notificationDate}}</div>
    </div>
  </div>
  <div class="main">
    <div class="ctn">
      <div class="details">
        <div class="front-end-developer-senior-level">
          {{notification.title}}
        </div>
      </div>
      <div class="deets">
        <div class="bca" [innerHTML]="notification.content | safeHtml"></div>
      </div>
    </div>
    <div *ngIf="notification.needAction && notification.buttonLabel" class="btn-rounded bg-primary-soft">
      <label>
        {{notification.buttonLabel}}
        <i class="bi bi-arrow-right">
        </i>
      </label>
    </div>
  </div>
</div>
