// Angular modules
import { Injectable } from '@angular/core';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class DataHelper {

  public static mapOnBoardingStatus = (status: number): string => {
    switch (status) {
      case 1:
        return "on process";
      case 2:
        return "accepted";
      case 3:
      case 4:
        return "declined";
      default:
        return "unknown status";
    }
  }
}
