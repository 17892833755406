// Angular modules
import { ElementRef, Injectable, QueryList } from '@angular/core';

@Injectable()
export class UIHelper {

  public static resizeWidthToColumn(basicElements: QueryList<ElementRef>, minimumWidth: number) {
    basicElements.forEach(basicElement => {
      const basicWidth = basicElement.nativeElement.offsetWidth;
      if (basicWidth < minimumWidth) {
        basicElement.nativeElement.style.flexDirection = 'column';
      } else {
        basicElement.nativeElement.style.flexDirection = 'row';
      }
    });
  }

  public static resizeWidthToRow(basicElements: QueryList<ElementRef>, minimumWidth: number) {
    basicElements.forEach(basicElement => {
      const basicWidth = basicElement.nativeElement.offsetWidth;
      if (basicWidth < minimumWidth) {
        basicElement.nativeElement.style.flexDirection = 'row';
      } else {
        basicElement.nativeElement.style.flexDirection = 'column';
      }
    });
  }
}
