<div [ngClass]="filterBar">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="filters">
    <div class="searchbar">
      <div class="ec-input-group-rounded">
        <span class="ec-input-group-text">
          <i class="bi bi-person-fill"></i>
        </span>
        <input (keydown.enter)="onSearch()" [(ngModel)]="keyword" type="text" id="firstName" class="ec-form-control-rounded ec-is-invalid"
          [placeholder]="placeholderKeyword">
      </div>
      <button (click)="onSearch()" style="display: flex; gap: 5px;"
        class="ec-btn ec-btn-primary ec-btn-rounded default">
        <i class="bi bi-search"></i> Search </button>
    </div>
    <div class="sort-by">
      <svg class="grid" *ngIf="searchBarType === 'find-talent' && !showTalentAsGridList"
        (click)="onTalentListModeClick()" width="17" height="16" viewBox="0 0 17 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75 2.5C1.75 1.67157 2.42157 1 3.25 1L6.25 1C7.07843 1 7.75 1.67157 7.75 2.5V5.5C7.75 6.32843 7.07843 7 6.25 7H3.25C2.42157 7 1.75 6.32843 1.75 5.5L1.75 2.5ZM3.25 2C2.97386 2 2.75 2.22386 2.75 2.5V5.5C2.75 5.77614 2.97386 6 3.25 6H6.25C6.52614 6 6.75 5.77614 6.75 5.5V2.5C6.75 2.22386 6.52614 2 6.25 2H3.25ZM9.75 2.5C9.75 1.67157 10.4216 1 11.25 1L14.25 1C15.0784 1 15.75 1.67157 15.75 2.5V5.5C15.75 6.32843 15.0784 7 14.25 7H11.25C10.4216 7 9.75 6.32843 9.75 5.5V2.5ZM11.25 2C10.9739 2 10.75 2.22386 10.75 2.5V5.5C10.75 5.77614 10.9739 6 11.25 6H14.25C14.5261 6 14.75 5.77614 14.75 5.5V2.5C14.75 2.22386 14.5261 2 14.25 2H11.25ZM1.75 10.5C1.75 9.67157 2.42157 9 3.25 9H6.25C7.07843 9 7.75 9.67157 7.75 10.5V13.5C7.75 14.3284 7.07843 15 6.25 15H3.25C2.42157 15 1.75 14.3284 1.75 13.5L1.75 10.5ZM3.25 10C2.97386 10 2.75 10.2239 2.75 10.5V13.5C2.75 13.7761 2.97386 14 3.25 14H6.25C6.52614 14 6.75 13.7761 6.75 13.5V10.5C6.75 10.2239 6.52614 10 6.25 10H3.25ZM9.75 10.5C9.75 9.67157 10.4216 9 11.25 9H14.25C15.0784 9 15.75 9.67157 15.75 10.5V13.5C15.75 14.3284 15.0784 15 14.25 15H11.25C10.4216 15 9.75 14.3284 9.75 13.5V10.5ZM11.25 10C10.9739 10 10.75 10.2239 10.75 10.5V13.5C10.75 13.7761 10.9739 14 11.25 14H14.25C14.5261 14 14.75 13.7761 14.75 13.5V10.5C14.75 10.2239 14.5261 10 14.25 10H11.25Z"
          fill="#757575" />
      </svg>
      <svg class="layout-sidebar-inset" *ngIf="searchBarType === 'find-talent' && showTalentAsGridList"
        (click)="onTalentListModeClick()" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 2C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H2C1.44772 14 1 13.5523 1 13V3C1 2.44772 1.44772 2 2 2H14ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z"
          fill="#757575" />
        <path
          d="M3 4C3 3.44772 3.44772 3 4 3H6C6.55228 3 7 3.44772 7 4V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V4Z"
          fill="#757575" />
      </svg>
      <svg class="grid" *ngIf="searchBarType === 'corporate-find-job' && !showJobAsGridList"
        (click)="onJobListModeClick()" width="17" height="16" viewBox="0 0 17 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75 2.5C1.75 1.67157 2.42157 1 3.25 1L6.25 1C7.07843 1 7.75 1.67157 7.75 2.5V5.5C7.75 6.32843 7.07843 7 6.25 7H3.25C2.42157 7 1.75 6.32843 1.75 5.5L1.75 2.5ZM3.25 2C2.97386 2 2.75 2.22386 2.75 2.5V5.5C2.75 5.77614 2.97386 6 3.25 6H6.25C6.52614 6 6.75 5.77614 6.75 5.5V2.5C6.75 2.22386 6.52614 2 6.25 2H3.25ZM9.75 2.5C9.75 1.67157 10.4216 1 11.25 1L14.25 1C15.0784 1 15.75 1.67157 15.75 2.5V5.5C15.75 6.32843 15.0784 7 14.25 7H11.25C10.4216 7 9.75 6.32843 9.75 5.5V2.5ZM11.25 2C10.9739 2 10.75 2.22386 10.75 2.5V5.5C10.75 5.77614 10.9739 6 11.25 6H14.25C14.5261 6 14.75 5.77614 14.75 5.5V2.5C14.75 2.22386 14.5261 2 14.25 2H11.25ZM1.75 10.5C1.75 9.67157 2.42157 9 3.25 9H6.25C7.07843 9 7.75 9.67157 7.75 10.5V13.5C7.75 14.3284 7.07843 15 6.25 15H3.25C2.42157 15 1.75 14.3284 1.75 13.5L1.75 10.5ZM3.25 10C2.97386 10 2.75 10.2239 2.75 10.5V13.5C2.75 13.7761 2.97386 14 3.25 14H6.25C6.52614 14 6.75 13.7761 6.75 13.5V10.5C6.75 10.2239 6.52614 10 6.25 10H3.25ZM9.75 10.5C9.75 9.67157 10.4216 9 11.25 9H14.25C15.0784 9 15.75 9.67157 15.75 10.5V13.5C15.75 14.3284 15.0784 15 14.25 15H11.25C10.4216 15 9.75 14.3284 9.75 13.5V10.5ZM11.25 10C10.9739 10 10.75 10.2239 10.75 10.5V13.5C10.75 13.7761 10.9739 14 11.25 14H14.25C14.5261 14 14.75 13.7761 14.75 13.5V10.5C14.75 10.2239 14.5261 10 14.25 10H11.25Z"
          fill="#757575" />
      </svg>
      <svg class="layout-sidebar-inset" *ngIf="searchBarType === 'corporate-find-job' && showJobAsGridList"
        (click)="onJobListModeClick()" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 2C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H2C1.44772 14 1 13.5523 1 13V3C1 2.44772 1.44772 2 2 2H14ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z"
          fill="#757575" />
        <path
          d="M3 4C3 3.44772 3.44772 3 4 3H6C6.55228 3 7 3.44772 7 4V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V4Z"
          fill="#757575" />
      </svg>
    </div>
    <div class="open">
      <div class="sort-by2">
        <div class="sort-by3">Sort By</div>
        <svg class="chevron-down" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options">
            <input type="radio" (change)="onOrderByOptionClick($event)" name="orderBy" id="radioCheckedDefault"
              class="ec-form-check-input-sm" value="date">
            <label for="radioCheckedDefault" class="ec-form-check-label">Date Posted</label>
          </div>
          <div class="options">
            <input type="radio" (change)="onOrderByOptionClick($event)" name="orderBy" id="radioCheckedDefault"
              class="ec-form-check-input-sm" value="relevant">
            <label for="radioCheckedDefault" class="ec-form-check-label">Most Relevant</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="experience-level">
        <div class="experience-level2">Experience level</div>
        <svg class="chevron-down2" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.89645 4.64645C2.09171 4.45118 2.40829 4.45118 2.60355 4.64645L8.25 10.2929L13.8964 4.64645C14.0917 4.45118 14.4083 4.45118 14.6036 4.64645C14.7988 4.84171 14.7988 5.15829 14.6036 5.35355L8.60355 11.3536C8.40829 11.5488 8.09171 11.5488 7.89645 11.3536L1.89645 5.35355C1.70118 5.15829 1.70118 4.84171 1.89645 4.64645Z"
            fill="#757575" />
        </svg>
        <div class="filter-box">
          <div class="onsite">
            <div class="options" *ngFor="let experienceLevel of experienceLevelList">
              <input type="checkbox" (change)="onExperienceLevelCheckClick($event)" [value]="experienceLevel.value"
                name="" id="checkboxChecked" class="checkbox">
              <label for="checkboxChecked" class="ec-form-check-label">{{experienceLevel.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="open" *ngIf="searchBarType !== 'corporate-find-job'">
      <div class="language">
        <div class="language2">Language</div>
        <svg class="chevron-down3" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box-language">
        <div class="onsite">
          <div class="options" *ngFor="let language of languages">
            <input type="checkbox" (change)="onLanguageCheckClick($event)" [value]="language.value" name=""
              id="checkboxChecked" class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{language.value}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
