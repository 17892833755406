import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';


@Pipe({
  name: 'startCase'
})
export class StartCasePipe implements PipeTransform {

  transform(label: any): string {
     return _.startCase(_.toLower(label));
  }
}