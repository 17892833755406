export enum Endpoint {
  AUTHENTICATE = 'login',
  LOGIN_BY_GOOGLE = 'verify/google',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'password/forget',
  RESET_PASSWORD = 'password/forget-update',
  VALIDATE_ACCOUNT = 'validate-account',
  USER_PROFILE = 'user/profile',
  TALENT_EDUCATION = 'talent/education',
  TALENT_WORK_HISTORY = 'talent/experience',
  TALENT_PORTFOLIO = 'talent/portfolio',
  TALENT_SKILL = 'talent/skill',
  TALENT_LANGUAGE = 'talent/language',
  TALENT_ABOUT = 'talent/about',
  TALENT_BASIC_INFO = 'talent/basic-info',
  TALENT_PERSONAL_INFO = 'talent/personal-info',
  TALENT_PROFILE_NOTIFICATION = 'talent/notification-preference',
  TALENT_PROFILE_PRIVACY = 'talent/privacy',
  TALENT_PROFILE_SETTING = 'talent/setting',
  TALENT_PROFILE_COOKIE = 'talent/cookie-setting',
  TALENT_PERSONAL_WEBSITE = 'talent/personal-website',
  TALENT_JOB_PREFERENCE = 'talent/job-preference',
  TALENT_PREFERENCE = 'talent/preference',
  CHECK_EMAIL = 'check-email',
  RESEND_EMAIL_ACTIVATION = 'user/activation-email/resend',
  ACTIVATE_EMAIL_ACTIVATION = 'user/activate',
  REFERENCE = 'reference',
  VERIFY_CAPTCHA = 'verify-captcha',
  USER_PROFILE_COMPLETION = 'talent/profile-progress',
  UPLOAD_RESUME = 'talent/upload-resume',
  COMPANY_PROFILE_PICTURE = 'company/profile-picture',
  TALENT_PROFILE_PICTURE = 'talent/profile-picture',
  CORPORATE_COMPANY = 'corporate/company',
  COMPANY = 'company',
  CORPORATE = 'corporate',
  COMPANY_BASIC_INFO = 'company/basic-info',
  COMPANY_ABOUT = 'company/about',
  JOB_INITIATE = 'job/job/initiate',
  JOB_DETAIL = 'job/job/job-detail',
  JOB_APPLICANT_PREFERENCE = 'job/job/applicant-preference',
  JOB_SUBMIT = 'job/job/submit',
  JOB = 'job/job',
  SAVE_JOB = 'job/save',
  APPLY_JOB = 'job/apply',
  TALENT_LIST = 'user/talent/list',
  TALENT = 'talent',
  JOB_OFFER = 'job/job-offer',
}
