import { ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { CardType, CompanyProfile } from '@interfaces/corporate.interface';
import { ProfileCompletion, Talent, TalentListType, UserProfile } from '@interfaces/talent.interface';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { TalentService } from '@services/talent.service';
import { isEqual } from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { OnBoardingService } from '@services/onboarding.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableHelper } from '@helpers/table.helper';

@Component({
  selector: 'talent-list',
  templateUrl: './talent-list.component.html',
  styleUrls: ['./talent-list.component.scss']
})
export class TalentListComponent {
  @Input() talentListType: TalentListType = 'find-talent';
  @Input() showPagination: boolean = false;
  public isLoading = false;
  public errorMessage: string = '';
  public talentList!: Array<Talent> | null;
  public similarTalentList!: Array<Talent> | null;
  public selectedJobId: string = '';
  public selectedTalent: any;
  public detailedTalent !: UserProfile;
  public companyProfile !: CompanyProfile | null;
  public showFullCompanyAbout = false;
  public truncatedCompanyAbout!: string | undefined;
  public truncatedNumber = 300;
  public showReadMore = false;
  public userProfile !: UserProfile | null;
  public profileCompletion !: ProfileCompletion | null;
  public isDropsApplicationVisible = false;
  public showTalentAsGridList = false;
  public isOnGridViewProfile = false;
  @HostBinding('style.--drops-application-display') dropDownApplicationDisplay = 'none';
  public cardType: CardType = 'viewedBy';
  public applicationStatus: number | undefined;
  private destroy$ = new Subject<void>();
  public searchParams = { size: 10 };
  public currentPage: number = 1;
  public originTotalPages: number = 1;
  public paginationNumberList !: Array<number>;

  constructor
    (
      private cdr: ChangeDetectorRef,
      private talentService: TalentService,
      private jobService: JobService,
      private corporateService: CorporateService,
      private onBoardingService: OnBoardingService,
      private toastManager: ToastManager,
      private route: ActivatedRoute,
      private router: Router,
    ) {
  }

  async ngOnInit(): Promise<void> {
    this.talentService.showTalentAsGridList$.subscribe((showTalentAsGridList) => {
      this.showTalentAsGridList = showTalentAsGridList;
      if (showTalentAsGridList) {
        this.selectedTalent = null
        this.isOnGridViewProfile = false;
      }
    });

    await this.setTalentListByType();
  }

  public goBackGirdViewProfile() {
    this.isOnGridViewProfile = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async setTalentListByType() {
    combineLatest([
      this.route.params,
      this.corporateService.cardType$,
      this.talentService.availableTalentList$,
      this.onBoardingService.onboardingTalentList$,
      this.jobService.viewedTalentList$,
      this.jobService.appliedTalentList$,
      this.jobService.offeredTalentList$,
      this.talentService.searchParams$,
      this.talentService.totalTalent$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async ([
          params,
          cardType,
          availableTalentList,
          onboardingTalentList,
          viewedTalentList,
          appliedTalentList,
          offeredTalentList,
          searchParams,
          totalTalent
        ]: [any, any, any, any, any, any, any, any, any]) => {
          this.selectedJobId = params['jobId'];
          this.cardType = cardType;
          if (searchParams?.page) {
            this.currentPage = searchParams.page;
          }
          this.searchParams = { ...this.searchParams, ...searchParams };
          const length = Math.ceil(totalTalent / this.searchParams.size);
          this.originTotalPages = length;
          this.paginationNumberList = Array.from({ length }, (_, index) => index + 1);
          this.paginationNumberList = TableHelper.generatePaginationWithMaxList(this.paginationNumberList, this.currentPage, 5);

          if (this.talentListType === 'find-talent') {
            this.showPagination = true;
            const availableTalentListSame = isEqual(this.talentList, availableTalentList);
            if (!availableTalentListSame) {
              this.talentList = availableTalentList;
              if (availableTalentList && availableTalentList.length) {
                if (!this.isOnGridViewProfile && !this.showTalentAsGridList) {
                  await this.selectTalent(availableTalentList[0]);
                  return
                }
              }
            }

            this.companyProfile = null;
          }

          if (this.talentListType === 'on-boarding-talent') {
            this.talentService.setShowTalentAsGridList(false);
            this.talentList = onboardingTalentList;
            if (onboardingTalentList && onboardingTalentList.length) {
              if (!this.isOnGridViewProfile && !this.showTalentAsGridList) {
                await this.selectTalent(onboardingTalentList[0]);
                return
              }
            }
            this.companyProfile = null;
          }

          if (this.talentListType === 'corporate-find-talent') {
            if (this.cardType === 'viewedBy') {
              this.talentList = viewedTalentList;
              if (viewedTalentList && viewedTalentList.length) {
                if (!this.isOnGridViewProfile && !this.showTalentAsGridList) {
                  await this.selectTalent(viewedTalentList[0]);
                  return
                }
              }
              this.companyProfile = null;
            }

            if (this.cardType === 'applicant') {
              this.talentList = appliedTalentList;
              if (appliedTalentList && appliedTalentList.length) {
                if (!this.isOnGridViewProfile && !this.showTalentAsGridList) {
                  await this.selectTalent(appliedTalentList[0]);
                  return
                }
              }
              this.companyProfile = null;
            }

            if (this.cardType === 'offering') {
              this.talentList = offeredTalentList;
              if (offeredTalentList && offeredTalentList.length) {
                if (!this.isOnGridViewProfile && !this.showTalentAsGridList) {
                  await this.selectTalent(offeredTalentList[0]);
                  return
                }
              }
              this.companyProfile = null;
            }
          }
        })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public toggleDropsApplication() {
    this.dropDownApplicationDisplay = 'none';
    this.isDropsApplicationVisible = !this.isDropsApplicationVisible;
    if (this.isDropsApplicationVisible) {
      this.dropDownApplicationDisplay = 'flex'
    }
  }

  public async selectTalent(talent: Talent) {
    try {
      this.isLoading = true;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { talentId: talent.talentId },
        queryParamsHandling: 'merge',
      });
      const detailedTalent = await this.talentService.getTalentById(talent.talentId);
      this.detailedTalent = detailedTalent;
      this.selectedTalent = talent;
      this.applicationStatus = talent.applicationStatus;
      if (this.talentListType === 'on-boarding-talent') {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { onboardingId: talent.onboardingId },
          queryParamsHandling: 'merge'
        });
        this.onBoardingService.setSelectedOnBoardingId(talent.onboardingId)
      }
      if (this.showTalentAsGridList && this.selectedTalent) {
        this.isOnGridViewProfile = true;
        this.similarTalentList = await this.talentService.getSimilarTalentList({}, detailedTalent.id);
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public toggleFullCompanyAbout() {
    this.showFullCompanyAbout = !this.showFullCompanyAbout;
  }

  public openNewTab(url: string) {
    window.open(url, '_blank');
  }

  public async onPaginationChange(pageNumber: number) {
    this.isLoading = true;
    this.currentPage = pageNumber;
    const updatedParams = {
      ...this.searchParams,
      page: pageNumber
    }
    this.talentService.setSearchParams(updatedParams);
    await this.talentService.getAvailableTalentList(updatedParams);
    this.isLoading = false;
  }
}
