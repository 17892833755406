<app-spinner *ngIf="isLoading"></app-spinner>
<div *ngIf="!isLoading" class="navigation content-under-navbar">
  <div class="dasboard-container pt-3">
    <div style="width: 100%;">
      <div class="back" (click)="navigateTo('corporate/job')">
        <svg class="chevron-left" width="16" height="17" viewBox="0 0 16 17" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.3536 2.14645C11.5488 2.34171 11.5488 2.65829 11.3536 2.85355L5.70711 8.5L11.3536 14.1464C11.5488 14.3417 11.5488 14.6583 11.3536 14.8536C11.1583 15.0488 10.8417 15.0488 10.6464 14.8536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645L10.6464 2.14645C10.8417 1.95118 11.1583 1.95118 11.3536 2.14645Z"
            fill="#C2C2C2" />
        </svg>
        <div class="return">Job Posts</div>
      </div>

      <div class="draft-section">
        <div class="draft-title">
          <div class="draft-username">Draft/Template</div>
          <div class="draft-username2">You can have up to 10 drafts</div>
        </div>

        <div (click)="navigateTo('job/template-initiation')" class="btn-rounded bg-orange-soft">
          <label>
            <i class="bi bi-clipboard-fill">
            </i>
            Create a Draft / Template
            <i class="bi bi-arrow-right">
            </i>
          </label>
        </div>
      </div>
    </div>
  </div>
  <job-list jobListType="job-template"></job-list>
</div>
