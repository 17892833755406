import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobService } from '@services/job.service';


@Component({
  selector: 'job-detail-modal',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent {

  public jobId !: string;
  public isLoading = true;
  public props !: any;

  constructor
    (
      private dialogRef: MatDialogRef<JobDetailComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
    ) {
    if (modalData?.data) {
      this.jobId = modalData.data.refId;
      this.props = modalData.data;
    }
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public callbackData($event: any) {
    this.isLoading = $event.isLoading;
    if ($event.isError) {
      this.dialogRef.close()
    }

    if ($event.closedModal) {
      this.dialogRef.close()
    }
  }
}



