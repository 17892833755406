<div>
  <div class="form-container">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="form-title">
      <div class="title-content">
        <div class="title-text">
          Languages </div>
        <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="break-line">
      </div>
    </div>
    <div class="form-skill">
      <div class="skills">
        <div class="skills-input">
          <div class="chip" *ngFor="let language of languages">
            {{ language }}
            <div class="close-icon" (click)="removeSkill(language, 'language')">
              <svg class="x-circle-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
                  fill="#233D90" />
              </svg>
            </div>
          </div>
          <input type="text" [(ngModel)]="newLanguage" (keydown.enter)="addLanguage()" (blur)="addLanguage()"
            placeholder="Type and enter to add your language" />
          <div *ngIf="!('desktop' | deviceDetector)" class="btn-rounded bg-success-soft" (click)="addLanguage()">
            <label>
              <i class="bi bi-plus-circle-fill">
              </i>
              Add
            </label>
          </div>
        </div>
      </div>
      <div class="invalid-feedback-text" *ngIf="!isLanguageValid()">
        {{ languageInvalidMessage }}
      </div>
      <div class="break-line-button">
      </div>
      <div class="button-group">
        <button type="button" (click)="editLanguage()" [disabled]="!isLanguageValid()"
          class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
          Save
          <i class="bi bi-arrow-right">
          </i>
        </button>
      </div>
    </div>
  </div>
</div>