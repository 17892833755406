import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TableHelper } from '@helpers/table.helper';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Output() paginationChange = new EventEmitter<number>();
  @Input() currentPage = 1;
  @Input() maxPageList = 10;
  public pageNumber = 1;
  @Input() paginationNumberList: number[] = [];
  @Input() originTotalPages: number = this.maxPageList;

  ngOnChanges(changes: any) {
    if (changes.currentPage) {
      this.currentPage = Number(changes.currentPage.currentValue);
      this.paginationNumberList = TableHelper.generatePaginationWithMaxList(this.paginationNumberList, this.currentPage, this.maxPageList);
      return;
    }

    if (changes.paginationNumberList) {
      this.paginationNumberList = changes.paginationNumberList.currentValue
      this.paginationNumberList = TableHelper.generatePaginationWithMaxList(this.paginationNumberList, this.currentPage, this.maxPageList);
      return;
    }
  }

  public onPaginationChange(pageNumber: number) {
    this.paginationChange.emit(pageNumber);
    this.currentPage = pageNumber;
  }
}
