import { Component } from '@angular/core';
import { interval } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { AuthLayoutName } from '@interfaces/authentication.interface';

@Component({
  selector: 'un-authenticated-page',
  templateUrl: './un-authenticated-page.component.html',
  styleUrls: ['./un-authenticated-page.component.scss'],
  animations: [
    trigger('moveUpDown', [
      transition('void => *', [
        style({ transform: 'translateY(-50px)' }),
        animate('1s ease-in-out')
      ]),
      transition('* => void', [
        animate('1s ease-in-out', style({ transform: 'translateY(50px)' }))
      ]),
    ])
  ]
})
export class UnAuthenticatedPageComponent {

  showCircle = false;
  public selectedLayoutName: AuthLayoutName = 'LOGIN';
  public isReturnLinkShown: boolean = true;
  public isBackOfficePage: boolean = environment.isBackOfficePage;
  public currentRoute!: string | undefined;

  constructor
    (
      private route: ActivatedRoute,
      private authService: AuthService,
    ) {
  }

  ngOnInit() {
    interval(10000)
      .subscribe(() => {
        this.showCircle = !this.showCircle;
      });

    this.authService.selectedLayoutName$.subscribe((selectedLayoutName) => {
      this.selectedLayoutName = selectedLayoutName;
    })

    this.route.url.subscribe(urlSegments => {
      const currentUrl = urlSegments.map(segment => segment.path).join('/');
      if ((currentUrl === '' || currentUrl === 'login') && this.isBackOfficePage) {
        this.isReturnLinkShown = false;
      }
      if (!this.isBackOfficePage) {
        this.isReturnLinkShown = true;
      }
    })

  }
}
