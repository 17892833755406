import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastManager } from '@blocks/toast/toast.manager';
import { CompanyProfile, Job, JobOffer } from '@interfaces/corporate.interface';
import { UserProfile } from '@interfaces/talent.interface';
import { JobService } from '@services/job.service';
import { OnBoardingService } from '@services/onboarding.service';
import { StoreService } from '@services/store.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: [
    './job.component.scss',
    '../../../../pages/job/job-preview/job-preview.component.scss'
  ]
})
export class JobComponent {

  @Input() jobId: string = '';
  public job !: Job;
  public isLoading = false;
  public companyProfile !: Partial<CompanyProfile> | null;
  public showFullCompanyAbout = false;
  public truncatedCompanyAbout!: string | undefined;
  public truncatedNumber = 300;
  public showReadMore = false;
  public userProfile !: UserProfile | null;
  @Input() props !: any;
  @Output() callbackData = new EventEmitter<any>();
  @Input() borderColor: string = '#abc8eb';
  private destroy$ = new Subject<void>();
  public offerResponse !: JobOffer;

  constructor(
    private jobService: JobService,
    private onBoardingService: OnBoardingService,
    private toastManager: ToastManager,
    private storeService: StoreService
  ) {
  }

  async ngOnInit() {
    if (this.jobId) {
      combineLatest([
        this.storeService.userProfile$,
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(async ([
          userProfile,
        ]: [any]) => {
          this.userProfile = userProfile;
        })
      if (this.props?.borderColor) {
        this.borderColor = this.props.borderColor;
      }
      try {
        this.isLoading = true;
        this.callbackData.emit({ isLoading: this.isLoading });
        if (this.props && this.props.notificationTemplate === 'RECEIVED_OFFER') {
          const offerResponse = await this.jobService.getJobOfferById(this.props.refId);
          this.jobId = offerResponse.basedJobId;
          this.offerResponse = offerResponse
        }

        if (this.props && this.props.group === 'WISHLIST') {
          const offerResponse = await this.jobService.getJobOfferById(this.props.refId);
          this.jobId = offerResponse.basedJobId
        }

        if (this.props && this.props.group === 'PLACEMENT') {
          const offerResponse = await this.onBoardingService.getOnBoardingDataById(this.props.refId);
          this.jobId = offerResponse.jobId
        }
        this.job = await this.jobService.getJobById(this.jobId);
        this.companyProfile = this.job.company;

        if (this.companyProfile?.about && this.companyProfile?.about?.length > 300) {
          this.truncatedCompanyAbout = `${this.companyProfile?.about?.slice(0, this.truncatedNumber)} ...`;
          this.showReadMore = true;
        } else {
          this.truncatedCompanyAbout = this.companyProfile?.about || '-';
          this.showReadMore = false;
        }

        if (this.companyProfile?.about && this.companyProfile.about?.length <= this.truncatedNumber) {
          this.showFullCompanyAbout = true;
        }
      } catch (error: any) {
        this.toastManager.showError(null, error.message);
        this.callbackData.emit({ isLoading: false, isError: true });
      } finally {
        this.isLoading = false;
        this.callbackData.emit({ isLoading: this.isLoading });
      }
    }
  }

  public openNewTab(url: string | undefined) {
    window.open(url, '_blank');
  }

  public toggleFullCompanyAbout() {
    this.showFullCompanyAbout = !this.showFullCompanyAbout;
  }

  public async approveOffer() {
    try {
      this.callbackData.emit({ isLoading: this.isLoading });
      await this.jobService.acceptOffer(this.props.refId);
      this.toastManager.showSuccess('Offer accepted successfully');
      this.callbackData.emit({ closedModal: true });
    } catch (error: any) {
      this.toastManager.showError('Approve Failed', error.message);
      this.callbackData.emit({ isLoading: false, isError: true });
    } finally {
      this.isLoading = false;
      this.callbackData.emit({ isLoading: this.isLoading });
    }
  }

  public async declineOffer() {
    try {
      this.callbackData.emit({ isLoading: this.isLoading });
      await this.jobService.declineOffer(this.props.refId);
      this.toastManager.showSuccess('Offer declined successfully');
      this.callbackData.emit({ closedModal: true });
    } catch (error: any) {
      this.toastManager.showError('Decline Failed', error.message);
      this.callbackData.emit({ isLoading: false, isError: true });
    } finally {
      this.isLoading = false;
      this.callbackData.emit({ isLoading: this.isLoading });
    }
  }

}
