import { Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  selector: 'popup-confirmation',
  templateUrl: './popup-confirmation.component.html',
  styleUrls: ['./popup-confirmation.component.scss']
})
export class PopupConfirmationComponent {
  @Input() id!: string;
  @Input() title!: string;
  @Input() content!: string;
  @Input() buttonText!: string;
  isOpen = false;
  private element: any;

  constructor(
    private snackbarService: SnackbarService,
    private el: ElementRef,
    private router: Router
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.snackbarService.add(this);

    document.body.appendChild(this.element);
    this.element.style.display = 'none';

    // close modal on background click
    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'modal') {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    // remove self from modal service
    this.snackbarService.remove(this);

    // remove modal element from html
    this.element.remove();

  }

  open() {
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.isOpen = true;
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;
  }
}
