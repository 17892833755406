import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';


@Pipe({
  name: 'stringCase'
})
export class StringCasePipe implements PipeTransform {

  transform(string: string, type: string, config?: any): string {
    const originMaxWords = string.length;
    if (type === 'trim') {
      let maxWords = 25;
      if (config) {
        maxWords = config;
      }

      if (originMaxWords > maxWords) {
        return string.substring(0, maxWords).concat('...')
      }
      return string;
    }
    if (type === 'upload-filename') {
      let maxWords = 30;
      if (config) {
        maxWords = config;
      }

      if (originMaxWords > maxWords) {
        let fileExtension = '.doc';
        const extension = string.substring(originMaxWords - 5, originMaxWords)
        if (extension.includes('pdf')) {
          fileExtension = '.pdf'
        }
        if (extension.includes('docx')) {
          fileExtension = '.docx'
        }
        return string.substring(0, maxWords).concat('...').concat(fileExtension);
      }
      return string;
    }
    return string;
  }
}
