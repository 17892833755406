import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { TableHelper } from '@helpers/table.helper';
import { CardType, CompanyProfile, CorporateJobStatus, Job, JobInfo } from '@interfaces/corporate.interface';
import { ProfileCompletion, UserProfile } from '@interfaces/talent.interface';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { OnBoardingService } from '@services/onboarding.service';
import { SnackbarService } from '@services/snackbar.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import { isNil } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LIMIT_GET_LIST } from 'src/app/shared/constants';

type JobListType = 'find-job' | 'applied-job' | 'saved-job' | 'active-job' | 'corporate-find-job' | 'on-boarding-job' | 'job-template';

@Component({
  selector: 'job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss', '../../../../pages/job/job-preview/job-preview.component.scss']
})
export class JobListComponent implements OnInit, AfterViewInit {

  @Input() jobListType: JobListType = 'find-job';
  public isLoading = false;
  public errorMessage: string = '';
  public jobList!: Array<JobInfo> | null;
  public selectedJobId: string = '';
  public selectedJob: any;
  public draftedJob !: Job;
  public companyProfile !: CompanyProfile | null;
  public showFullCompanyAbout = false;
  public truncatedCompanyAbout!: string | undefined;
  public truncatedNumber = 300;
  public showReadMore = false;
  public userProfile !: UserProfile | null;
  public profileCompletion !: ProfileCompletion | null;
  public isDropsApplicationVisible = false;
  public showJobAsGridList = false;
  public isOnGridViewJob = false;
  public talentId !: string | undefined;
  public withdrawReason = '';
  public withdrawOtherReason = '';
  @HostBinding('style.--drops-application-display') dropDownApplicationDisplay = 'none';
  private destroy$ = new Subject<void>();
  public isJobApplied = false;
  public currentPage: number = 1;
  public paginationNumberList !: Array<number>;
  public selectedJobStatus: CorporateJobStatus = 'all';

  constructor
    (
      private cdr: ChangeDetectorRef,
      private jobService: JobService,
      private router: Router,
      private corporateService: CorporateService,
      private talentService: TalentService,
      private storeService: StoreService,
      private snackbarService: SnackbarService,
      private toastManager: ToastManager,
      private onBoardingService: OnBoardingService,
      private route: ActivatedRoute,
    ) {
  }

  async ngOnInit(): Promise<void> {
    this.storeService.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile) => {
        this.userProfile = userProfile;
      });
    this.jobService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    this.jobService.jobErrorMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (errorMessage) => {
        if (errorMessage) {
          this.toastManager.showError(null, errorMessage)
        }
      })

    this.jobService.showJobAsGridList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((showJobAsGridList) => {
        this.showJobAsGridList = showJobAsGridList;
        if (showJobAsGridList) {
          this.selectedJob = null;
          return;
        }
        this.selectedJob = this.jobList && this.jobList[0];
      });

    this.talentService.offeredTalentId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (talentId) => {
        this.talentId = talentId
      })

    this.jobService.corporateJobParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe(corporateJobParams => {
        this.selectedJobStatus = corporateJobParams.status;
        this.currentPage = corporateJobParams.pageNumber;
      })

    this.jobService.totalJob$
      .pipe(takeUntil(this.destroy$))
      .subscribe(totalJob => {
        const length = Math.ceil(totalJob / LIMIT_GET_LIST);
        this.paginationNumberList = Array.from({ length }, (_, index) => index + 1);
        this.paginationNumberList = TableHelper.generatePagination(this.paginationNumberList, this.currentPage)
      })

    await this.setJobListByType();
  }

  public goBackGirdViewJob() {
    this.isOnGridViewJob = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.jobService.setJobErrorMessage("");
  }

  public isApplied() {
    if (isNil(this.draftedJob.applicationStatus)) {
      return this.isJobApplied = false;
    }
    return this.isJobApplied = true;
  }

  private async setJobListByType() {
    if (this.jobListType === 'find-job') {
      this.jobService.availableJobList$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });

      try {
        const [profileCompletion] = await Promise.all([
          this.talentService.getProfileCompletion()
        ]);
        this.profileCompletion = profileCompletion;
      } catch (error: any) {
        this.errorMessage = error[0]?.message || error.message;
      } finally {
        this.isLoading = false;
      }
    }

    if (this.jobListType === 'applied-job') {
      this.jobService.appliedJobList$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });
    }

    if (this.jobListType === 'saved-job') {
      this.jobService.savedJobList$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });
    }

    if (this.jobListType === 'active-job') {
      this.jobService.corporateActiveJobList$
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });
    }

    // /corporate/job
    if (this.jobListType === 'corporate-find-job') {
      this.jobService.corporateJobList$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });
    }
    // TODO
    if (this.jobListType === 'on-boarding-job') {
      this.onBoardingService.onboardingJobList$
        .pipe(
          filter(jobList => !!jobList),
          takeUntil(this.destroy$)
        )
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              if ((jobList[0].onboardingId)) {
                this.onBoardingService.setSelectedOnBoardingId(jobList[0].onboardingId)
              }
              return
            }
          }
          this.companyProfile = null;
        })
    }

    if (this.jobListType === 'job-template') {
      this.jobService.jobTemplateList$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(async (jobList) => {
          this.jobList = jobList;
          if (jobList && jobList.length) {
            if (!this.isOnGridViewJob && !this.showJobAsGridList) {
              await this.selectJob(jobList[0]);
              return
            }
          }
          this.companyProfile = null;
        });
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onWithdrawReasonClick(event: any) {
    const value = event.target.value;
    this.withdrawReason = value;
    if (value !== 'other') {
      this.withdrawOtherReason = '';
    }
  }

  public editJob() {
    if (this.jobListType === 'job-template') {
      this.router.navigate(['job/template-initiation', this.draftedJob.id])
      return;
    }
    this.router.navigate(['job/initiation', this.draftedJob.id])
  }

  public async deactivateJob() {
    try {
      this.isLoading = true;
      if (this.draftedJob.id) {
        await this.jobService.deactivateJob(this.draftedJob.id);
        this.toastManager.showSuccess('Job deactivated successfully')
        this.jobService.corporateJobParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (params) => {
            await this.jobService.getCorporateJobList(params)
          })
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }
  public async deleteJob() {
    try {
      this.isLoading = true;
      if (this.draftedJob.id) {
        await this.jobService.deleteJob(this.draftedJob.id);
        this.toastManager.showSuccess('Job deleted successfully');
        this.snackbarService.close();
        this.jobService.searchParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (params) => {
            await this.jobService.getCorporateJobList(params)
          })
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async deleteJobTemplate() {
    try {
      this.isLoading = true;
      if (this.draftedJob.id) {
        await this.jobService.deleteJobTemplate(this.draftedJob.id);
        this.toastManager.showSuccess('Job Template deleted successfully');
        this.jobService.searchParams$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (params) => {
            await this.jobService.getJobTemplateList(params)
          })
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public helpJob() {

  }

  public onReviewJob() {

  }
  public toggleDropsApplication() {
    this.dropDownApplicationDisplay = 'none';
    this.isDropsApplicationVisible = !this.isDropsApplicationVisible;
    if (this.isDropsApplicationVisible) {
      this.dropDownApplicationDisplay = 'flex'
    }
  }

  public async selectJob(job: JobInfo) {
    try {
      this.isLoading = true;
      let draftedJob !: Job;
      this.selectedJob = job;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { jobId: job.jobId },
        queryParamsHandling: 'merge'
      });

      if (this.jobListType !== 'corporate-find-job') {
        if (this.jobListType === 'job-template') {
          draftedJob = await this.jobService.getJobTemplateById(job.jobId);
        } else {
          draftedJob = await this.jobService.getJobById(job.jobId);
        }
      }
      if (this.jobListType === 'corporate-find-job') {
        if (this.showJobAsGridList) {
          this.router.navigate(['corporate/job', job.jobId]);
          return;
        }
        if (job.status === 1) { // PENDING
          draftedJob = await this.jobService.getDraftJobById(job.jobId);
        } else {
          draftedJob = await this.jobService.getCorporateJobById(job.jobId);
        }
      }
      if (this.jobListType === 'on-boarding-job' && job.onboardingId) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { onboardingId: job.onboardingId },
          queryParamsHandling: 'merge'
        });
        this.onBoardingService.setSelectedOnBoardingId(job.onboardingId)
      }
      this.draftedJob = draftedJob;
      const companyProfile = await this.corporateService.getCompanyProfileById(job.companyId);
      this.companyProfile = companyProfile;
      if (this.companyProfile?.about?.length > 300) {
        this.truncatedCompanyAbout = `${this.companyProfile?.about?.slice(0, this.truncatedNumber)} ...`;
        this.showReadMore = true;
      } else {
        this.truncatedCompanyAbout = this.companyProfile?.about || '-';
        this.showReadMore = false;
      }

      if (this.companyProfile?.about?.length <= this.truncatedNumber) {
        this.showFullCompanyAbout = true;
      }
      this.isOnGridViewJob = false;
      if (this.showJobAsGridList) {
        this.isOnGridViewJob = true;
      }
      this.isApplied()
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public onStatCardClick(cardType: CardType) {
    this.corporateService.setCardType(cardType);
    this.router.navigate(['/corporate/job', this.draftedJob.id]);
    return;
  }

  public toggleFullCompanyAbout() {
    this.showFullCompanyAbout = !this.showFullCompanyAbout;
  }

  public navigateToOnBoardingPage() {
    this.router.navigate(['corporate/on-boarding', this.draftedJob.id]);
  }

  public async saveJob(jobId: string | undefined) {
    if (jobId) {
      try {
        this.isLoading = true
        await this.jobService.saveJobById(jobId);
        await this.getUpdatedJobList();
        const draftedJob = await this.jobService.getJobById(this.selectedJob.jobId);
        this.draftedJob = draftedJob;
        this.isOnGridViewJob = false;
        this.toastManager.showSuccess('Job saved successfully')
        // this.selectedJob = draftedJob
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false
      }
    }
  }

  public async offerToTalent(jobId: string | undefined) {
    if (this.talentId && jobId) {
      try {
        this.isLoading = true;
        const createdJobOffer = await this.jobService.createJobOffeByActiveJobId(jobId, this.talentId);
        this.router.navigate(['talent-offer/preview', createdJobOffer.id])
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false;
      }
    }
  }

  private async getUpdatedJobList() {
    try {
      this.isLoading = true;
      if (this.jobListType === 'find-job') {
        await this.jobService.getJobList({ size: LIMIT_GET_LIST });
      }
      if (this.jobListType === 'saved-job') {
        await this.jobService.getSavedJobList({ size: LIMIT_GET_LIST });
      }
      if (this.jobListType === 'applied-job') {
        await this.jobService.getAppliedJobList({ size: LIMIT_GET_LIST });
      }
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public applyJob(jobId: string | undefined) {
    if (this.userProfile?.isVerified) {
      this.router.navigate(['/job/apply', jobId, 'step1']);
      return;
    }

    this.toastManager.showError('Apply job failed', 'Please verify your email')
  }

  public openNewTab(url: string) {
    window.open(url, '_blank');
  }

  public async onDeleteApplication() {
    this.snackbarService.open('delete-application');
  }

  public async deleteApplication() {
    if (this.draftedJob.id) {
      try {
        this.isLoading = true;
        await this.jobService.deleteJobFromTalent(this.draftedJob.id);
        this.toastManager.showSuccess('Talent Job deleted successfully')
        await this.getUpdatedJobList();
        this.snackbarService.close();
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false
      }
    }
  }


  public openModal(modalName: string) {
    this.snackbarService.open(modalName);
  }

  public async onWithdrawApplication() {
    this.snackbarService.open('withdraw-application');
  }

  public async onCloseModal() {
    this.snackbarService.close();
  }

  public async withdrawApplication() {
    if (this.draftedJob.id) {
      try {
        this.isLoading = true;
        let withdrawReason = this.withdrawReason;
        if (withdrawReason === 'other') {
          withdrawReason = this.withdrawOtherReason;
        }
        await this.jobService.withdrawJobFromTalent(this.draftedJob.id, withdrawReason);
        this.toastManager.showSuccess('Withdraw job successfully')
        this.snackbarService.close();
        await this.getUpdatedJobList()
      } catch (error: any) {
        this.toastManager.showError(null, error.message)
      } finally {
        this.isLoading = false
      }
    }
  }

  public async onPaginationChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.jobService.setCorporateJobParams(this.selectedJobStatus, pageNumber);
  }
}
