// Angular modules
import { Injectable } from '@angular/core';

@Injectable()
export class TableHelper {

  public static generatePagination(paginationNumberList: number[], currentPage: number,): number[] {
    const totalPages = paginationNumberList.length
    let updatedPaginationNumberList = [];

    let startPage = 1;
    let endPage = Math.min(totalPages, 10);

    if (totalPages > 10) {
      if (currentPage <= 5) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage > totalPages - 5) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      updatedPaginationNumberList.push(i);
    }

    return updatedPaginationNumberList;
  }

  public static generatePaginationWithMaxList(paginationNumberList: number[], currentPage: number, maxPageList: number): number[] {
    const totalPages = paginationNumberList.length
    let updatedPaginationNumberList = [];

    let startPage = 1;
    let endPage = Math.min(totalPages, maxPageList);

    if (maxPageList === 10) {
      if (totalPages > 10) {
        if (currentPage <= 5) {
          startPage = 1;
          endPage = 10;
        } else if (currentPage > totalPages - 5) {
          startPage = totalPages - 9;
          endPage = totalPages;
        } else {
          startPage = currentPage - 5;
          endPage = currentPage + 4;
        }
      }
    }
    if (maxPageList === 5) {
      if (totalPages > 5) {
        if (currentPage <= 3) {
          startPage = 1;
          endPage = 5;
        } else if (currentPage > totalPages - 3) {
          startPage = totalPages - 4;
          endPage = totalPages;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;

          if (endPage > totalPages) {
            endPage = totalPages;
          }
        }
      }
      else {
        startPage = 1;
        endPage = totalPages;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      updatedPaginationNumberList.push(i);
    }

    return updatedPaginationNumberList;
  }
}
