import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { UserType } from '@enums/login.enum';
import { StorageHelper } from '@helpers/storage.helper';
import { UserProfile } from '@interfaces/talent.interface';
import { ProfileComponent } from '@pages/profile/profile.component';
import { JobService } from '@services/job.service';
import { ModalInterface, ModalService } from '@services/modal.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';


@Component({
  selector: 'talent-navbar',
  templateUrl: './talent-navbar.component.html',
  styleUrls: ['./talent-navbar.component.scss']
})
export class TalentNavbarComponent implements OnInit {
  public isLoading: boolean = true;
  public userProfile !: UserProfile | null;
  public activeNavbar = 'dashboard';
  public activeSubNavbar = 'resume';
  public errorMessage = '';
  public isSubNavbarShow = true;
  public isLogout = false;
  public notificationCount: number = 0;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private googleService: SocialAuthService,
    private talentService: TalentService,
    private modalService: ModalService,
    private storeService: StoreService,
    private jobService: JobService,
    private toastManager: ToastManager,
  ) {
  }

  async ngOnInit(): Promise<void> {
    const currentLoggedUser = StorageHelper.getCurrentLoggedUser();
    if (currentLoggedUser?.type === UserType.TALENT) {
      this.storeService.setLoginAs('TALENT')
    }
    if (currentLoggedUser?.type === UserType.CORPORATE) {
      this.storeService.setLoginAs('CORPORATE')
    }

    combineLatest([
      this.storeService.userProfile$,
      this.storeService.isSubNavbarShow$,
      this.storeService.activeNavbar$,
      this.storeService.activeSubnavbar$,
      this.talentService.unreadNotification$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        userProfile,
        isSubNavbarShow,
        activeNavbar,
        activeSubnavbar,
        unreadNotificationCount,
      ]: [any, any, any, any, any]) => {
        try {
          this.isLoading = true;
          this.notificationCount = unreadNotificationCount
          if (!userProfile && currentLoggedUser) {
            await this.getUserProfile();
            this.isLoading = false;
            return;
          }
          this.userProfile = userProfile;
          if (this.userProfile) {
            this.isSubNavbarShow = isSubNavbarShow;

            if (activeNavbar) {
              this.activeNavbar = activeNavbar;
            }

            if (activeSubnavbar) {
              this.activeSubNavbar = activeSubnavbar;
            }
          }
        } catch (error: any) {
          this.toastManager.showError('Failed to notification', error.message)
        } finally {
          this.isLoading = false;
        }
      })

    if (StorageHelper.getCurrentLoggedUser()) {
      await this.talentService.getUnredNotificationCount();
    }
  }

  get showCount(): boolean {
    return this.notificationCount <= 100;
  }

  private async getUserProfile() {
    try {
      this.isLoading = true;
      const userProfile = await this.talentService.getProfile();
      this.userProfile = userProfile;
      this.storeService.setUserProfile(userProfile);
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public async logout(): Promise<void> {
    StorageHelper.removeGoogleState();
    StorageHelper.removeAccessToken();
    StorageHelper.removeRefreshToken();
    const loginType = StorageHelper.getLoginType();
    this.isLogout = true;
    if (loginType === 'GOOGLE') {
      try {
        await this.googleService.signOut();

      } catch (error: any) {
      }
    }
    this.storeService.resetUserProfile();
    this.storeService.setLogOut(true);
    this.jobService.resetSubscibedData();
    this.router.navigate(['/auth/login']);
  }

  public manageAccount() {
    const modalConfig: ModalInterface = {
      component: ProfileComponent,
      customLayout: {
        width: '856px',
      },
      callback: this.getUserProfile.bind(this)
    }
    this.modalService.openModal(modalConfig)
  }

  public onSubNavbarClick(category: string) {
    this.activeSubNavbar = category;
    const targetedRoute = `/talent/${category}`;
    this.router.navigate([targetedRoute]);
  }

  public onNavbarClick(menu: string) {
    this.activeNavbar = menu;
    if (menu === 'find-job') {
      this.storeService.hideSubNavbar();
      this.router.navigate(['/talent/find-job']);
    }

    if (menu === 'dashboard') {
      this.storeService.showSubNavbar();
      this.activeSubNavbar = 'resume';
      this.router.navigate(['/talent/resume']);
    }

    if (menu === 'notification') {
      this.storeService.hideSubNavbar();
      this.router.navigate(['/talent/notification']);
    }
  }
}
