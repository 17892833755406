<!-- For more settings use the AutoHTML plugin tab ... -->
<app-spinner *ngIf="isLoading"></app-spinner>
<div [ngClass]="filterBar">
  <div class="searchbar">
    <div class="ec-input-group-rounded">
      <span class="ec-input-group-text">
        <i class="bi bi-briefcase-fill"></i>
      </span>
      <input [(ngModel)]="keyword" type="text" id="firstName" class="ec-form-control-rounded ec-is-invalid"
        placeholder="Job Title, Keyword, Company" (keydown.enter)="onSearch()">
    </div>
    <div class="ec-input-group-rounded">
      <span class="ec-input-group-text">
        <i class="bi bi-pin-map-fill"></i>
      </span>
      <input [(ngModel)]="location" type="text" id="lastName" class="ec-form-control-rounded ec-is-invalid"
        placeholder="Location, City, Country" (keydown.enter)="onSearch()">
    </div>
    <button (click)="onSearch()" style="display: flex; gap: 5px;" class="ec-btn ec-btn-primary ec-btn-rounded default">
      <i class="bi bi-search"></i> Search </button>
  </div>
  <div class="line-7">
  </div>
  <div class="filters">
    <div class="sort-by">
      <svg class="grid" *ngIf="!showJobAsGridList" (click)="onTalentListModeClick()" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 2.5C1 1.67157 1.67157 1 2.5 1L5.5 1C6.32843 1 7 1.67157 7 2.5V5.5C7 6.32843 6.32843 7 5.5 7H2.5C1.67157 7 1 6.32843 1 5.5L1 2.5ZM2.5 2C2.22386 2 2 2.22386 2 2.5V5.5C2 5.77614 2.22386 6 2.5 6H5.5C5.77614 6 6 5.77614 6 5.5V2.5C6 2.22386 5.77614 2 5.5 2H2.5ZM9 2.5C9 1.67157 9.67157 1 10.5 1L13.5 1C14.3284 1 15 1.67157 15 2.5V5.5C15 6.32843 14.3284 7 13.5 7H10.5C9.67157 7 9 6.32843 9 5.5V2.5ZM10.5 2C10.2239 2 10 2.22386 10 2.5V5.5C10 5.77614 10.2239 6 10.5 6H13.5C13.7761 6 14 5.77614 14 5.5V2.5C14 2.22386 13.7761 2 13.5 2H10.5ZM1 10.5C1 9.67157 1.67157 9 2.5 9H5.5C6.32843 9 7 9.67157 7 10.5V13.5C7 14.3284 6.32843 15 5.5 15H2.5C1.67157 15 1 14.3284 1 13.5L1 10.5ZM2.5 10C2.22386 10 2 10.2239 2 10.5V13.5C2 13.7761 2.22386 14 2.5 14H5.5C5.77614 14 6 13.7761 6 13.5V10.5C6 10.2239 5.77614 10 5.5 10H2.5ZM9 10.5C9 9.67157 9.67157 9 10.5 9H13.5C14.3284 9 15 9.67157 15 10.5V13.5C15 14.3284 14.3284 15 13.5 15H10.5C9.67157 15 9 14.3284 9 13.5V10.5ZM10.5 10C10.2239 10 10 10.2239 10 10.5V13.5C10 13.7761 10.2239 14 10.5 14H13.5C13.7761 14 14 13.7761 14 13.5V10.5C14 10.2239 13.7761 10 13.5 10H10.5Z"
          fill="#757575" />
      </svg>
      <svg class="grid" *ngIf="showJobAsGridList" (click)="onTalentListModeClick()" width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 2C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H2C1.44772 14 1 13.5523 1 13V3C1 2.44772 1.44772 2 2 2H14ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z"
          fill="#757575" />
        <path
          d="M3 4C3 3.44772 3.44772 3 4 3H6C6.55228 3 7 3.44772 7 4V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V4Z"
          fill="#757575" />
      </svg>
    </div>
    <div class="open">
      <div class="sort-by2">
        <div class="sort-by3">
          Sort By </div>
        <svg class="chevron-down" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options">
            <input type="radio" (change)="onOrderByOptionClick($event)" name="orderBy" id="radioCheckedDefault" class="ec-form-check-input-sm" value="date">
            <label for="radioCheckedDefault" class="ec-form-check-label">Date Posted</label>
          </div>
          <div class="options">
            <input type="radio" (change)="onOrderByOptionClick($event)" name="orderBy" id="radioCheckedDefault" class="ec-form-check-input-sm" value="relevant">
            <label for="radioCheckedDefault" class="ec-form-check-label">Most Relevant</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="onsite-remote">
        <div class="on-site-remote">
          On-site/Remote </div>
        <svg class="chevron-down2" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options" *ngFor="let workplaceType of workplaceTypeList">
            <input type="checkbox" (change)="onWorkplaceCheckClick($event)" [value]="workplaceType.value" name=""
              id="checkboxChecked" class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{workplaceType.value}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="employment-type">
        <div class="employment-type2">
          Employment Type </div>
        <svg class="chevron-down3" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options" *ngFor="let employmentType of employmentTypeList">
            <input type="checkbox" name="" (change)="onEmploymentTypeCheckClick($event)" [value]="employmentType.value"
              id="checkboxChecked" class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{employmentType.value}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="experience-level">
        <div class="experience-level2">
          Experience level </div>
        <svg class="chevron-down4" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options" *ngFor="let experienceLevel of experienceLevelList">
            <input type="checkbox" (change)="onExperienceLevelCheckClick($event)" [value]="experienceLevel.value"
              name="" id="checkboxChecked" class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{experienceLevel.value}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="industry">
        <div class="industry2">
          Industry </div>
        <svg class="chevron-down5" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box-right">
        <div class="onsite">
          <div class="options" *ngFor="let industry of industries; let i = index">
            <input type="checkbox" (change)="onIndustryCheckClick($event)" [value]="industry.value" id="checkboxChecked"
              class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{industry.value}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open">
      <div class="language">
        <div class="language2">
          Language </div>
        <svg class="chevron-down6" width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box-language">
        <div class="onsite">
          <div class="options" *ngFor="let language of languages">
            <input type="checkbox" (change)="onLanguageCheckClick($event)" [value]="language.value" name=""
              id="checkboxChecked" class="checkbox">
            <label for="checkboxChecked" class="ec-form-check-label">{{language.value}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="open" *ngIf="searchBarType !== 'find-job'">
      <div class="sort-by2">
        <div class="sort-by3">
          Show Closed </div>
        <svg class="chevron-down" width="17" height="16" viewBox="0 0 17 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.14645 4.64645C2.34171 4.45118 2.65829 4.45118 2.85355 4.64645L8.5 10.2929L14.1464 4.64645C14.3417 4.45118 14.6583 4.45118 14.8536 4.64645C15.0488 4.84171 15.0488 5.15829 14.8536 5.35355L8.85355 11.3536C8.65829 11.5488 8.34171 11.5488 8.14645 11.3536L2.14645 5.35355C1.95118 5.15829 1.95118 4.84171 2.14645 4.64645Z"
            fill="#757575" />
        </svg>
      </div>
      <div class="filter-box">
        <div class="onsite">
          <div class="options">
            <input type="radio" (change)="onShowClosedOptionClick($event)" name="orderBy" id="radioCheckedDefault" class="ec-form-check-input-sm" value="yes">
            <label for="radioCheckedDefault" class="ec-form-check-label">Yes</label>
          </div>
          <div class="options">
            <input type="radio" (change)="onShowClosedOptionClick($event)" name="orderBy" id="radioCheckedDefault" class="ec-form-check-input-sm" value="no">
            <label for="radioCheckedDefault" class="ec-form-check-label">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
