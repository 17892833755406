<div class="cards04">
  <div class="cards04-inner">
    <div class="enigma-graduates-wrapper">
      <div class="enigma-graduates">ENIGMA GRADUATES</div>
    </div>
  </div>
  <div class="pictures-parent">
    <div class="pictures">
      <div class="profiles">
        <img class="icon" alt="" src="https://material.angular.io/assets/img/examples/shiba2.jpg">
      </div>
    </div>
    <div class="name-parent">
      <b class="name">Stephanie Corneli</b>
      <div class="name1">Web Developer</div>
      <div class="place">
        <img class="pin-map-fill-icon" alt="" src="pin-map-fill.svg">
        <div class="united-states">United States</div>
      </div>
    </div>
  </div>
  <div class="skills-parent">
    <b class="skills">Skills</b>
    <div class="frame-parent">
      <div class="quality-assurance-wrapper">
        <div class="quality-assurance">Quality Assurance</div>
      </div>
      <div class="quality-assurance-wrapper">
        <div class="quality-assurance">UI Designer</div>
      </div>
      <div class="quality-assurance-wrapper">
        <div class="quality-assurance">Front-End Developer</div>
      </div>
    </div>
  </div>
  <div class="view">
    <div class="buttonrounded">
      <div class="enigma-graduates">View Resume</div>
    </div>
    <div class="buttonrounded-stroked">
      <div class="enigma-graduates">View Profile</div>
    </div>
  </div>
</div>