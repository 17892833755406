import { Component, Input } from '@angular/core';

@Component({
  selector: 'placement-assignee',
  templateUrl: './placement-assignee.component.html',
  styleUrls: ['./placement-assignee.component.scss']
})
export class PlacementAssigneeComponent {
  @Input() isPlacementOwner: boolean = false;

}
