<ng-container *ngFor="let toast of toastManager.toasts">

  <!-- NOTE With header -->
  <ng-container *ngIf="toast.headerKey && toast.withHeader else withoutHeader">

    <ngb-toast class="bg-{{ toast.type }}" [autohide]="toast.autoHide" [delay]="toast.delay"
      (hide)="toastManager.remove(toast.id)" class="custom-toast">
      <ng-template ngbToastHeader>
        <!-- TODO Icon : Success / Info / Error -->
        <div class="text-white me-auto">{{ toast.headerKey | translate }}</div>
      </ng-template>
      <div class="text-dark">{{ toast.body }}</div>
    </ngb-toast>

  </ng-container>

  <!-- NOTE Without header -->
  <ng-template #withoutHeader>

    <ngb-toast class="bg-{{ toast.type }}" [autohide]="toast.autoHide" [delay]="toast.delay"
      (hide)="toastManager.remove(toast.id)">
      <div class="toast-wrapper">
        <div class="toast-custom-icon">
          <svg *ngIf="toast.type === 'success'" class="checkmark" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="#0d6807" />
            <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke="#ffffff" />
          </svg>
          <svg *ngIf="toast.type === 'danger'" class="error-icon" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="12" fill="#70201b" />
            <g class="exclamation">
              <path fill="#ffffff" d="M13 13h-2v-8h2v8zm0 4h-2v-2h2v2z" />
            </g>
          </svg>
        </div>
        <div class="toast-custom">
          <div class="toast-custom-header">
            <div class="toast-custom-title text-soft-{{ toast.type }}">

              {{ toast.title }}
            </div>
            <svg (click)="toastManager.remove(toast.id)" class="x bg-soft-{{ toast.type }}" width="20" height="20"
              viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="bg-soft-danger"
                d="M5.80806 5.80806C6.05214 5.56398 6.44786 5.56398 6.69194 5.80806L10 9.11612L13.3081 5.80806C13.5521 5.56398 13.9479 5.56398 14.1919 5.80806C14.436 6.05214 14.436 6.44786 14.1919 6.69194L10.8839 10L14.1919 13.3081C14.436 13.5521 14.436 13.9479 14.1919 14.1919C13.9479 14.436 13.5521 14.436 13.3081 14.1919L10 10.8839L6.69194 14.1919C6.44786 14.436 6.05214 14.436 5.80806 14.1919C5.56398 13.9479 5.56398 13.5521 5.80806 13.3081L9.11612 10L5.80806 6.69194C5.56398 6.44786 5.56398 6.05214 5.80806 5.80806Z"
                [ngStyle]="{'fill': getFillByType(toast.type)}" />
            </svg>
          </div>
          <div class="toast-custom-body  text-soft-{{ toast.type }}">{{ toast.body }}</div>
        </div>
      </div>
    </ngb-toast>

  </ng-template>

</ng-container>
