import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { StorageHelper } from '@helpers/storage.helper';
import { OnBoardingService } from '@services/onboarding.service';
import { StoreService } from '@services/store.service';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'on-boarding-search-bar',
  templateUrl: './on-boarding-search-bar.component.html',
  styleUrls: ['./on-boarding-search-bar.component.scss', '../search-bar/search-bar.component.scss']
})
export class OnBoardingSearchBarComponent implements OnInit {
  public isLoading = false;
  public searchValue = '';
  public showAsControl: FormControl = new FormControl('on process');
  public loginAs = 'TALENT';
  public jobId = '';
  private destroy$ = new Subject<void>();
  public onboardingId!: string;


  constructor
    (
      private onBoardingService: OnBoardingService,
      private toastManager: ToastManager,
      private storeService: StoreService,
      private route: ActivatedRoute,
      private router: Router,
    ) {
    this.onboardingId = this.route.snapshot.queryParams['onboardingId'];
    const status = this.route.snapshot.queryParams['status'];
    if (status) {
      this.showAsControl.setValue(status);
    }
  }

  async ngOnInit() {
    combineLatest([
      this.storeService.loginAs$,
      // this.route.queryParams,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([
        loginAs,
        // queryParams
      ]: [
        any,
        // any
      ]) => {
        this.loginAs = loginAs;
        if (this.loginAs === 'TALENT') {
          await this.getOnBoardingJobList()
        }

        // const status = queryParams['status'];
        if(status) {
          this.showAsControl.setValue(status);
        }

        if (this.loginAs === 'CORPORATE') {
          this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (params) => {
              this.jobId = params['jobId'];
              await this.getOnBoardingTalentListByJob()
            })
        }
      })
  }

  public async onSearch() {
    if (this.loginAs === 'TALENT') {
      await this.getOnBoardingJobList()
    }

    if (this.loginAs === 'CORPORATE') {
      await this.getOnBoardingTalentListByJob()
    }
  }

  public async onOnboardingStatusChange(value: string) {
    this.showAsControl.setValue(value);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { status: value },
      queryParamsHandling: 'merge'
    });
    await this.onSearch();
  }

  private async getOnBoardingJobList() {
    try {
      this.isLoading = true;
      const params: any = {
        keyword: this.searchValue,
        status: this.showAsControl.value,
      }
      if (this.onboardingId) {
        params.onboardingId = this.onboardingId
      }
      await this.onBoardingService.getMyOnBoardingJobList(params)
    } catch (error: any) {
      this.toastManager.showError('Get job failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private async getOnBoardingTalentListByJob() {
    try {
      this.isLoading = true;
      if (this.jobId) {
        const params: any = {
          keyword: this.searchValue,
          status: this.showAsControl.value,
          jobId: this.jobId,
        }
        if (this.onboardingId) {
          params.onboardingId = this.onboardingId
        }
        await this.onBoardingService.getOnBoardingTalentListByJobId(params)
      }

    } catch (error: any) {
      this.toastManager.showError('Get job failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
