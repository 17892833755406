import { Component } from '@angular/core';
import { JobService } from '@services/job.service';
import { StoreService } from '@services/store.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss']
})
export class OnBoardingComponent {
  public isLoading = false;
  public errorMessage = '';
  public loginAs = 'TALENT';
  private destroy$ = new Subject<void>();

  constructor(
    private storeService: StoreService,
    private jobService: JobService,
  ) {
    this.storeService.showSubNavbar();
    this.storeService.setActiveSubNavbar('placements');
  }

  ngOnInit() {
    this.storeService.loginAs$
      .pipe(takeUntil(this.destroy$))
      .subscribe(loginAs => {
        this.loginAs = loginAs;
      })

    if(this.loginAs === 'CORPORATE') {
      this.storeService.setActiveSubNavbar('job')
    } else {
      this.storeService.setActiveSubNavbar('placements');
    }
  }

  ngOnDestroy() {
    this.jobService.setShowJobAsGridList(false)
    this.destroy$.next();
    this.destroy$.complete();
  }
}
