// Angular modules
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

// Components
import { NotFoundComponent } from './static/not-found/not-found.component';
import { environment } from '@env/environment';

const talentRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'talent',
    loadChildren: () => import('./pages/talent/talent.module').then(m => m.TalentModule),
  },
  {
    path: 'corporate',
    loadChildren: () => import('./pages/corporate/corporate.module').then(m => m.CorporateModule),
  },
  {
    path: 'job',
    loadChildren: () => import('./pages/job/job.module').then(m => m.JobModule),
  },
  {
    path: 'talent-offer',
    loadChildren: () => import('./pages/talent-offer/talent-offer.module').then(m => m.TalentOfferModule),
  },
  { path: '**', component: NotFoundComponent }
];

const backOfficeRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/back-office/back-office.module').then(m => m.BackOfficeModule),
  },
  { path: '**', component: NotFoundComponent }
];

const routes: Routes = [];

if(environment.enableTalentRoutes) {
  routes.push(...talentRoutes)
}

if(environment.enableBackOfficeRoutes) {
  routes.push(...backOfficeRoutes)
}
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
