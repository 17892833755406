import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-talent',
  templateUrl: './registration-talent.component.html',
  styleUrls: ['./registration-talent.component.scss']
})
export class RegistrationTalentComponent {

}
