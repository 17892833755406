<div class="navigation">
  <div class="content">
    <talent-navbar></talent-navbar>
    <router-outlet></router-outlet>
  </div>
  <div class="dash-footer">
    <div class="footer-text">
      Enigma Camp - Olympus © 2024. All rights reserved </div>
  </div>
</div>
