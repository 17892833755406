import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(date: string, utc = true): string {
    const now = dayjs();
    const pastDate = dayjs(date).utc(utc).format()

    const secondsAgo = now.diff(pastDate, 'second');
    if (secondsAgo < 60) {
      return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
    }

    const minutesAgo = now.diff(pastDate, 'minute');
    if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    }

    const hoursAgo = now.diff(pastDate, 'hour');
    if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    }

    const daysAgo = now.diff(pastDate, 'day');
    if (daysAgo < 7) {
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    }

    const weeksAgo = now.diff(pastDate, 'week');
    if (weeksAgo < 4) {
      return `${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
    }

    const monthsAgo = now.diff(pastDate, 'month');
    if (monthsAgo < 12) {
      return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
    }

    const yearsAgo = now.diff(pastDate, 'year');
    return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
  }
}
