<div class="navigation">
  <div class="tools">
    <div class="tabs3">
      <div
        [ngClass]="selectedOnBoardingStatus === 'all' ? 'btn-rounded bg-primary-soft': 'btn-border-rounded btn-border-primary-soft'"
        (click)="onStatusChange('all')">
        <label>
          <i class="bi bi-files">
          </i>
          All
        </label>
      </div>
      <div
        [ngClass]="selectedOnBoardingStatus === 'on process' ? 'btn-rounded bg-success-soft': 'btn-border-rounded btn-border-success-soft'"
        (click)="onStatusChange('on process')">
        <label>
          <i class="bi bi-file-earmark-check">
          </i>
          On Going
        </label>
      </div>
      <div
        [ngClass]="selectedOnBoardingStatus === 'waiting' ? 'btn-rounded bg-warning-soft': 'btn-border-rounded btn-border-warning-soft'"
        (click)="onStatusChange('waiting')">
        <label>
          <i class="bi bi-file-break">
          </i>
          Waiting Respond
        </label>
      </div>
      <div
        [ngClass]="selectedOnBoardingStatus === 'declined' ? 'btn-rounded bg-danger-soft': 'btn-border-rounded btn-border-danger-soft'"
        (click)="onStatusChange('declined')">
        <label>
          <i class="bi bi-file-earmark-excel">
          </i>
          Rejected
        </label>
      </div>
    </div>
    <!-- <div *ngIf="selectedOnBoardingStatus === 'declined'" class="btn-rounded bg-danger-soft">
      <label>
        <i class="bi bi-trash">
        </i>
        Delete
      </label>
    </div> -->
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
  <ng-container *ngIf="!isLoading">
    <div class="main-table">
      <div class="numpage">
        <div class="data-per-page">Data per page</div>
        <div class="custom-select-wrapper">
          <div class="button-primary-rounded custom-select">
            <div class="button">{{currentPageSize}}</div>
            <div class="icn">
              <svg class="chevron-down" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.64645 4.64645C1.84171 4.45118 2.15829 4.45118 2.35355 4.64645L8 10.2929L13.6464 4.64645C13.8417 4.45118 14.1583 4.45118 14.3536 4.64645C14.5488 4.84171 14.5488 5.15829 14.3536 5.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L1.64645 5.35355C1.45118 5.15829 1.45118 4.84171 1.64645 4.64645Z"
                  fill="#233D90" />
              </svg>
            </div>
            <select class="hidden-select" [(ngModel)]="currentPageSize" (change)="onPaginationSizeChange()">
              <option *ngFor="let paginationSize of paginationSizeList" [value]="paginationSize">
                {{ paginationSize
                }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="frame-1706" *ngIf="!isLoading">
        <div class="tablehead">
          <div class="tab-profile-header" (click)="onSortByChange('name')">
            <div class="talent">Talent</div>
            <i [@fadeInOut] *ngIf="isSortByShown('name', 'asc')" class="bi bi-sort-alpha-down"></i>
            <i [@fadeInOut] *ngIf="!isSortByShown('name', 'asc')" class="bi bi-sort-alpha-down-alt"></i>
          </div>
          <div class="tab3" (click)="onSortByChange('date')">
            <div class="registered-at">Last Updated</div>
            <i *ngIf="isSortByShown('date', 'asc')" class="bi bi-sort-alpha-down"></i>
            <i *ngIf="!isSortByShown('date', 'asc')" class="bi bi-sort-alpha-down-alt"></i>
          </div>
          <div class="tab3" (click)="onSortByChange('status')">
            <div class="registered-at">Status</div>
            <i *ngIf="isSortByShown('status', 'asc')" class="bi bi-sort-alpha-down"></i>
            <i *ngIf="!isSortByShown('status', 'asc')" class="bi bi-sort-alpha-down-alt"></i>
          </div>
          <div class="tab3" (click)="onSortByChange('process')">
            <div class="verification-status">Process</div>
            <i *ngIf="isSortByShown('process', 'asc')" class="bi bi-sort-alpha-down"></i>
            <i *ngIf="!isSortByShown('process', 'asc')" class="bi bi-sort-alpha-down-alt"></i>
          </div>
          <div class="tab3" (click)="onSortByChange('title')">
            <div class="verification-status">JobTitle</div>
            <i *ngIf="isSortByShown('title', 'asc')" class="bi bi-sort-alpha-down"></i>
            <i *ngIf="!isSortByShown('title', 'asc')" class="bi bi-sort-alpha-down-alt"></i>
          </div>
          <div class="tab2">
            <div class="action">Action</div>
          </div>
        </div>
        <app-empty-table [text]="'ONBOARDING TALENT NOT FOUND'" class="table-content"
          *ngIf="!talentList || talentList.length === 0"></app-empty-table>
        <div class="table-content" *ngIf="talentList && talentList.length > 0">
          <div *ngFor="let talent of talentList; let i = index" [class]="i % 2 === 0 ? 'tabs':'tabs2'">
            <div class="tab-profile">
              <div class="card-profile-section">
                <img *ngIf="!talent.profilePictureUrl" src="./assets/img/project/default-user-picture.png"
                  class="profile-picture-img" />
                <img *ngIf="talent.profilePictureUrl" [src]="talent.profilePictureUrl" class="profile-picture-img" />
              </div>
              <div class="details">
                <div class="first-name-last-name">{{ talent.firstName}} {{ talent.lastName}}</div>
                <div class="as">
                  <div class="text-gray">
                    {{ talent.position || '-'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="tab3">
              <div class="date-text" [title]="talent.updatedAt | date: 'dd MMM yyyy':'-0000'">
                {{ talent.updatedAt | date: 'dd/MM/yyyy':'-0000'}}
                <br />
                {{ talent.updatedAt | date: 'HH:mm:ss':'-0000'}}
              </div>
            </div>
            <div class="tab3">
              <div class="date-text">
                <div class="tag-job-status bg-warning-soft" *ngIf="talent.status === 'Waiting Respond'">
                  <label> {{ talent.status }}</label>
                </div>
                <div class="tag-job-status bg-success-soft" *ngIf="talent.status === 'On Going'">
                  <label> {{ talent.status }}</label>
                </div>
                <div class="tag-job-status bg-danger-soft" *ngIf="talent.status === 'Rejected'">
                  <label> {{ talent.status }}</label>
                </div>
              </div>
            </div>
            <div class="tab3">
              <div class="last-step">
                {{talent.mappedLastStep || '-'}}
              </div>
            </div>
            <div class="tab3">
              <div class="date-text">
                {{ talent.jobTitle || '-'}}
              </div>
            </div>
            <div class="tab2">
              <div class="btn-border-rounded btn-border-primary-soft" [navigateUrl]="'/corporate/find-talent?talentId=' + talent.talentId">
                <label>
                  <i class="bi bi-person-bounding-box">
                  </i>
                  See Profile
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" *ngIf="talentList && talentList.length > 0">
        <div class="pagination">
          <div class="num" *ngIf="currentPage === 1">
            <div class="_2">
              <svg class="arrow-sm" width="8" height="8" viewBox="0 0 8 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.5 4C7.5 4.24163 7.30412 4.4375 7.0625 4.4375H1.99372L3.87186 6.31564C4.04271 6.48649 4.04271 6.76351 3.87186 6.93436C3.70101 7.10521 3.42399 7.10521 3.25314 6.93436L0.62814 4.30936C0.457286 4.13851 0.457286 3.8615 0.62814 3.69064L3.25314 1.06564C3.42399 0.894786 3.70101 0.894786 3.87186 1.06564C4.04271 1.2365 4.04271 1.51351 3.87186 1.68436L1.99372 3.5625L7.0625 3.5625C7.30412 3.5625 7.5 3.75838 7.5 4Z"
                  fill="#C2C2C2" />
              </svg>
            </div>
          </div>
          <div *ngIf="currentPage !== 1" class="num2" (click)="onPaginationChange(currentPage - 1)">
            <div class="_2">
              <svg class="arrow-sm" width="8" height="8" viewBox="0 0 8 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.5 4C7.5 4.24163 7.30412 4.4375 7.0625 4.4375H1.99372L3.87186 6.31564C4.04271 6.48649 4.04271 6.76351 3.87186 6.93436C3.70101 7.10521 3.42399 7.10521 3.25314 6.93436L0.62814 4.30936C0.457286 4.13851 0.457286 3.8615 0.62814 3.69064L3.25314 1.06564C3.42399 0.894786 3.70101 0.894786 3.87186 1.06564C4.04271 1.2365 4.04271 1.51351 3.87186 1.68436L1.99372 3.5625L7.0625 3.5625C7.30412 3.5625 7.5 3.75838 7.5 4Z"
                  fill="#FDFDFD" />
              </svg>
            </div>
          </div>
          <div *ngFor="let pageNumber of paginationNumberList" [ngClass]="currentPage === pageNumber ? 'num2': 'num'"
            (click)="onPaginationChange(pageNumber)">
            <div class="_2">
              <div [ngClass]="currentPage === pageNumber ? '_012': '_013'">{{ pageNumber }}</div>
            </div>
          </div>
          <div *ngIf="paginationNumberList?.length === currentPage" class="num">
            <div class="_2">
              <svg class="arrow-sm2" width="8" height="8" viewBox="0 0 8 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.5 4C0.5 3.75837 0.695875 3.5625 0.9375 3.5625L6.00628 3.5625L4.12814 1.68436C3.95729 1.51351 3.95729 1.23649 4.12814 1.06564C4.29899 0.894786 4.57601 0.894786 4.74686 1.06564L7.37186 3.69064C7.54271 3.86149 7.54271 4.1385 7.37186 4.30936L4.74686 6.93436C4.57601 7.10521 4.29899 7.10521 4.12814 6.93436C3.95729 6.7635 3.95729 6.48649 4.12814 6.31564L6.00628 4.4375H0.9375C0.695875 4.4375 0.5 4.24162 0.5 4Z"
                  fill="#C2C2C2" />
              </svg>
            </div>
          </div>
          <div *ngIf="paginationNumberList?.length !== currentPage" class="num2"
            (click)="onPaginationChange(currentPage + 1)">
            <div class="_2">
              <svg class="arrow-sm2" width="8" height="8" viewBox="0 0 8 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.5 4C0.5 3.75837 0.695875 3.5625 0.9375 3.5625L6.00628 3.5625L4.12814 1.68436C3.95729 1.51351 3.95729 1.23649 4.12814 1.06564C4.29899 0.894786 4.57601 0.894786 4.74686 1.06564L7.37186 3.69064C7.54271 3.86149 7.54271 4.1385 7.37186 4.30936L4.74686 6.93436C4.57601 7.10521 4.29899 7.10521 4.12814 6.93436C3.95729 6.7635 3.95729 6.48649 4.12814 6.31564L6.00628 4.4375H0.9375C0.695875 4.4375 0.5 4.24162 0.5 4Z"
                  fill="#FDFDFD" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
