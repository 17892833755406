import { Component, HostBinding } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastManager } from '@blocks/toast/toast.manager';
import { Account, CompanyProfile } from '@interfaces/corporate.interface';
import { Reference } from '@interfaces/reference.interface';
import { UserProfile } from '@interfaces/talent.interface';
import { CorporateService } from '@services/corporate.service';
import { JobService } from '@services/job.service';
import { ReferenceService } from '@services/reference.service';
import { SnackbarService } from '@services/snackbar.service';
import { StoreService } from '@services/store.service';
import { get, isEmpty } from 'lodash';
import { MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  public userProfile !: UserProfile | null;
  public companyProfile !: CompanyProfile | null;
  public isLoading: boolean = false;
  public errorMessage = '';
  public errorPopUpMessage = '';
  public accountForm !: FormGroup;

  public keyword!: string;
  public selectedOrder!: string;

  public accountList !: any[];
  public accountRoleList: Reference[] = [];
  public selectedAccounts: Account[] = [];

  public isVisible: boolean = false;
  updatedRole: string = '';
  public delegatedAccount!: Account;

  constructor
    (
      private corporateService: CorporateService,
      private referenceService: ReferenceService,
      private snackbarService: SnackbarService,
      private jobService: JobService,
      private toastManager: ToastManager,
      private storeService: StoreService,
    ) {

    this.accountForm = new FormGroup({
      accountEntries: new FormArray([]),
    });
    this.storeService.setActiveSubNavbar('account');
  }

  get accountEntries() {
    return this.accountForm.get('accountEntries') as FormArray;
  }

  async getAccounts() {
    const accountListResponse = await this.corporateService.getAccounts();
    this.accountList = accountListResponse.map(account => ({ ...account, isChecked: false }))
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.storeService.showSubNavbar();
      this.storeService.setActiveSubNavbar("account");
      await this.getAccounts()
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public onSelectAll(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.isVisible = true;
      this.accountList = this.accountList.map(account => ({ ...account, isChecked: true }))
      this.selectedAccounts = this.accountList;
      return
    }
    this.accountList = this.accountList.map(account => ({ ...account, isChecked: false }))
    this.selectedAccounts = [];
    this.isVisible = false;
  }

  public onSelectAccount(event: any, index: number) {
    const isChecked = event.target.checked;
    this.accountList = this.accountList.map((account, indexAccount) => {
      if (indexAccount === index) {
        return { ...account, isChecked }
      }
      return { ...account, isChecked: false }
    });
    this.selectedAccounts = this.accountList.filter(i => i.isChecked);

    const isSelectedAccountExist = !isEmpty(this.accountList.filter(i => i.isChecked))
    if (isSelectedAccountExist) {
      this.isVisible = true;
      return
    }

    this.isVisible = false;
  }

  public filterUnCheckedAccounts() {
    return this.accountList.filter(i => !i.isChecked)
  }

  public onSelectDelegatedAccount(account: Account) {
    this.delegatedAccount = account
  }

  public removeAccountFromSelectedAccountList(targetAccountId: string) {
    const targetAccount = this.accountList.find(account => account.id === targetAccountId);
    if (targetAccount) {
      targetAccount.isChecked = false;
    }
    this.selectedAccounts = this.accountList.filter(i => i.isChecked);
  }

  public async openChangeRoleModal(account: Account | null) {
    if (account) {
      this.selectedAccounts = [account];
      this.updatedRole = account?.role
    }

    if (!account) {
      this.updatedRole = get(this.selectedAccounts, '[0].role', 'User')
    }
    try {
      this.isLoading = true;
      this.accountRoleList = await this.referenceService.getReferenceListByType('CORPORATE_ROLE');
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
    this.snackbarService.open('change-role');
  }

  private accountyFormEntry(): FormGroup {
    const accountEntry = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(MAX_LENGTH.INPUT)]),
      role: new FormControl('', Validators.required),
    });

    return accountEntry;
  }

  public async addAccountEntry() {
    this.accountEntries.push(this.accountyFormEntry());
  }

  public removeAccountEntry(index: number) {
    this.accountEntries.removeAt(index);
  }

  public async openAddAccountModal() {
    try {
      this.isLoading = true;
      this.accountRoleList = await this.referenceService.getReferenceListByType('CORPORATE_ROLE');
      this.addAccountEntry();
      this.snackbarService.open('add-account');
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async openRemoveAccountModal() {
    this.snackbarService.open('remove-account');
  }

  public async onCloseModal() {
    this.errorPopUpMessage = '';
    this.accountEntries.clear()
    this.snackbarService.close();;
    this.onHiddenToolsClose()
  }

  public async changeRole() {
    const userIds = this.selectedAccounts.map(account => account.id);
    try {
      await this.corporateService.updateRole(userIds, this.updatedRole);
      await this.getAccounts();
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public onHiddenToolsClose() {
    this.isVisible = false;
    this.accountList = this.accountList.map(account => ({ ...account, isChecked: false }))
  }

  public async bulkAddAccount() {
    const invitationList = this.accountEntries.value;
    try {
      this.isLoading = true;
      await this.corporateService.createAccounts(invitationList);
      await this.getAccounts();
      this.onCloseModal();
      this.toastManager.showSuccess('Corporate account added successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async bulkRemoveAccount() {
    try {
      const userIds = this.selectedAccounts.map(account => account.id);
      if (this.selectedAccounts[0] && this.selectedAccounts[0].hasPendingTask) {
        this.snackbarService.close();;
        this.openDelegateAccountModal();
        return;
      }
      await this.corporateService.removeAccounts(userIds);
      await this.getAccounts();
      this.onCloseModal();
      this.toastManager.showSuccess('Corporate account removed successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async delegateTask() {
    try {
      if(!this.delegatedAccount) {
        throw new Error('Delegated account not found')
      }
      const userIds = this.selectedAccounts.map(account => account.id);
      const payload = {
        from: this.selectedAccounts[0].id,
        to: this.delegatedAccount.id
      }
      await this.jobService.delegateTask(payload);
      await this.corporateService.removeAccounts(userIds);

      await this.getAccounts();
      this.onCloseModal();
      this.toastManager.showSuccess('Account removed and delegate task successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async openDelegateAccountModal() {
    this.snackbarService.open('delegate-task');
  }
}
