<div class="container-fluid back-office-container-fluid">
  <div class="row">
    <!-- <app-spinner [isPositionFixed]="true" *ngIf="isLoading"></app-spinner> -->
    <div *ngIf="userProfile && userProfile?.status === 0" class="dasboard-container">
      <div class="pending-talent">
        <div class="pending-talent-title">
          <div class="pending-talent-label">
            Your account is under review!
          </div>
          <div class="pending-talent-info">
            <p>Appreciate your patience as we carefully assess your account. Thank you for your cooperation.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="companyProfile" class="dasboard-container">
      <!-- ========== Start content ========== -->
      <div class="col-sm-7 col-sm-8 col-lg-9 d-md-block" style="padding-right: 15px;">
        <div class="frame-1656">
          <div class="basic">
            <ng-container *ngIf="loginAs !== 'BACK_OFFICE'">
              <ng-container *ngIf="('TA' | corporateRole)">
                <div class="company-profile-section">
                  <img style="cursor: pointer;" title="Edit Company Photo" (click)="editPhotoProfile()"
                    *ngIf="!companyProfile.profilePictureUrl" src="./assets/img/project/default-company-logo.png"
                    class="profile-picture-img" />
                  <img style="cursor: pointer;" title="Edit Company Photo" (click)="editPhotoProfile()"
                    *ngIf="companyProfile.profilePictureUrl" [src]="companyProfile.profilePictureUrl"
                    class="profile-picture-img" />
                </div>
              </ng-container>
              <ng-container *ngIf="('USER' | corporateRole)">
                <div class="company-profile-section">
                  <img style="cursor: pointer;" title="Edit Company Photo" *ngIf="!companyProfile.profilePictureUrl"
                    src="./assets/img/project/default-company-logo.png" class="profile-picture-img"
                    style="cursor: default;" />
                  <img style="cursor: pointer;" title="Edit Company Photo" *ngIf="companyProfile.profilePictureUrl"
                    [src]="companyProfile.profilePictureUrl" class="profile-picture-img" style="cursor: default;" />
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="loginAs === 'BACK_OFFICE'">
              <div class="company-profile-section">
                <img style="cursor: pointer;" title="Edit Company Photo" (click)="editPhotoProfile()"
                  *ngIf="!companyProfile.profilePictureUrl" src="./assets/img/project/default-company-logo.png"
                  class="profile-picture-img" />
                <img style="cursor: pointer;" title="Edit Company Photo" (click)="editPhotoProfile()"
                  *ngIf="companyProfile.profilePictureUrl" [src]="companyProfile.profilePictureUrl"
                  class="profile-picture-img" />
              </div>
            </ng-container>

            <div class="basic2" style="justify-content: center;">
              <div class="basic3">
                <div class="username2">{{companyProfile.brand}}</div>
                <div class="as">
                  <div class="front-end-developer-wannabe">
                    {{ companyProfile.companyName}}
                  </div>
                </div>
              </div>
            </div>
            <div class="actions" *ngIf="('TA' | corporateRole)">
              <svg (click)="editBasicInfo()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                  fill="#233D90" />
                <path
                  d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                  fill="#233D90" />
                <path
                  d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                  fill="#233D90" />
              </svg>
            </div>
          </div>
          <div class="info">
            <div class="card">
              <div class="card-content">
                <div class="card-title">
                  <div class="about">About</div>
                  <div class="actions" *ngIf="('TA' | corporateRole)">
                    <svg (click)="editAbout()" class="pencil-fill" width="16" height="16" viewBox="0 0 16 16"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
                        fill="#233D90" />
                      <path
                        d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
                        fill="#233D90" />
                      <path
                        d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
                        fill="#233D90" />
                    </svg>
                  </div>
                </div>
                <div class="line-4"></div>
                <div class="content">
                  <div class="about-me">
                    <div [innerHTML]="this.companyProfile.about | safeHtml"></div>
                  </div>
                </div>
                <div class="company-info">
                  <div class="company-info-section">
                    <div class="company-info-title">
                      Industry </div>
                    <div class="company-info-value">
                      {{companyProfile.industry}} </div>
                  </div>
                  <div class="company-info-section">
                    <div class="company-info-title">
                      Company Size </div>
                    <div class="company-info-value">
                      {{companyProfile.companySizeRange}} </div>
                  </div>
                  <div class="company-info-section">
                    <div class="company-info-title">
                      Website </div>
                    <div class="company-info-value">
                      {{companyProfile.companySite}} </div>
                  </div>
                </div>
                <div class="branch-list-wrapper">
                  <div class="company-info-title">
                    Main Office
                  </div>
                  <div class="frame-1677" *ngIf="companyProfile.mainOffice">
                    <svg class="building-fill" width="12" height="12" viewBox="0 0 12 12" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.25 0C1.83579 0 1.5 0.335787 1.5 0.75V11.25C1.5 11.6642 1.83579 12 2.25 12H4.5V9.375C4.5 9.16789 4.66789 9 4.875 9H7.125C7.33211 9 7.5 9.16789 7.5 9.375V12H9.75C10.1642 12 10.5 11.6642 10.5 11.25V0.75C10.5 0.335786 10.1642 0 9.75 0H2.25ZM3 1.875C3 1.66789 3.16789 1.5 3.375 1.5H4.125C4.33211 1.5 4.5 1.66789 4.5 1.875V2.625C4.5 2.83211 4.33211 3 4.125 3H3.375C3.16789 3 3 2.83211 3 2.625V1.875ZM5.25 1.875C5.25 1.66789 5.41789 1.5 5.625 1.5H6.375C6.58211 1.5 6.75 1.66789 6.75 1.875V2.625C6.75 2.83211 6.58211 3 6.375 3H5.625C5.41789 3 5.25 2.83211 5.25 2.625V1.875ZM7.875 1.5H8.625C8.83211 1.5 9 1.66789 9 1.875V2.625C9 2.83211 8.83211 3 8.625 3H7.875C7.66789 3 7.5 2.83211 7.5 2.625V1.875C7.5 1.66789 7.66789 1.5 7.875 1.5ZM3 4.125C3 3.91789 3.16789 3.75 3.375 3.75H4.125C4.33211 3.75 4.5 3.91789 4.5 4.125V4.875C4.5 5.08211 4.33211 5.25 4.125 5.25H3.375C3.16789 5.25 3 5.08211 3 4.875V4.125ZM5.625 3.75H6.375C6.58211 3.75 6.75 3.91789 6.75 4.125V4.875C6.75 5.08211 6.58211 5.25 6.375 5.25H5.625C5.41789 5.25 5.25 5.08211 5.25 4.875V4.125C5.25 3.91789 5.41789 3.75 5.625 3.75ZM7.5 4.125C7.5 3.91789 7.66789 3.75 7.875 3.75H8.625C8.83211 3.75 9 3.91789 9 4.125V4.875C9 5.08211 8.83211 5.25 8.625 5.25H7.875C7.66789 5.25 7.5 5.08211 7.5 4.875V4.125ZM3.375 6H4.125C4.33211 6 4.5 6.16789 4.5 6.375V7.125C4.5 7.33211 4.33211 7.5 4.125 7.5H3.375C3.16789 7.5 3 7.33211 3 7.125V6.375C3 6.16789 3.16789 6 3.375 6ZM5.25 6.375C5.25 6.16789 5.41789 6 5.625 6H6.375C6.58211 6 6.75 6.16789 6.75 6.375V7.125C6.75 7.33211 6.58211 7.5 6.375 7.5H5.625C5.41789 7.5 5.25 7.33211 5.25 7.125V6.375ZM7.875 6H8.625C8.83211 6 9 6.16789 9 6.375V7.125C9 7.33211 8.83211 7.5 8.625 7.5H7.875C7.66789 7.5 7.5 7.33211 7.5 7.125V6.375C7.5 6.16789 7.66789 6 7.875 6Z"
                        fill="#757575" />
                    </svg>
                    <div class="bca-jakarta">{{companyProfile.mainOffice.name}}</div>
                    <div class="jakarta-indonesia-uct-4-30">
                      {{companyProfile.mainOffice.city}}, {{companyProfile.mainOffice.country}},
                      {{companyProfile.mainOffice.timezone}}
                    </div>
                  </div>
                </div>
                <div *ngIf="branches.length > 0" class="branch-list-wrapper">
                  <div class="company-info-title">
                    Branch Office(s)
                  </div>
                  <div *ngFor="let branch of branches" class="frame-1677">
                    <svg class="building-fill" width="12" height="12" viewBox="0 0 12 12" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.25 0C1.83579 0 1.5 0.335787 1.5 0.75V11.25C1.5 11.6642 1.83579 12 2.25 12H4.5V9.375C4.5 9.16789 4.66789 9 4.875 9H7.125C7.33211 9 7.5 9.16789 7.5 9.375V12H9.75C10.1642 12 10.5 11.6642 10.5 11.25V0.75C10.5 0.335786 10.1642 0 9.75 0H2.25ZM3 1.875C3 1.66789 3.16789 1.5 3.375 1.5H4.125C4.33211 1.5 4.5 1.66789 4.5 1.875V2.625C4.5 2.83211 4.33211 3 4.125 3H3.375C3.16789 3 3 2.83211 3 2.625V1.875ZM5.25 1.875C5.25 1.66789 5.41789 1.5 5.625 1.5H6.375C6.58211 1.5 6.75 1.66789 6.75 1.875V2.625C6.75 2.83211 6.58211 3 6.375 3H5.625C5.41789 3 5.25 2.83211 5.25 2.625V1.875ZM7.875 1.5H8.625C8.83211 1.5 9 1.66789 9 1.875V2.625C9 2.83211 8.83211 3 8.625 3H7.875C7.66789 3 7.5 2.83211 7.5 2.625V1.875C7.5 1.66789 7.66789 1.5 7.875 1.5ZM3 4.125C3 3.91789 3.16789 3.75 3.375 3.75H4.125C4.33211 3.75 4.5 3.91789 4.5 4.125V4.875C4.5 5.08211 4.33211 5.25 4.125 5.25H3.375C3.16789 5.25 3 5.08211 3 4.875V4.125ZM5.625 3.75H6.375C6.58211 3.75 6.75 3.91789 6.75 4.125V4.875C6.75 5.08211 6.58211 5.25 6.375 5.25H5.625C5.41789 5.25 5.25 5.08211 5.25 4.875V4.125C5.25 3.91789 5.41789 3.75 5.625 3.75ZM7.5 4.125C7.5 3.91789 7.66789 3.75 7.875 3.75H8.625C8.83211 3.75 9 3.91789 9 4.125V4.875C9 5.08211 8.83211 5.25 8.625 5.25H7.875C7.66789 5.25 7.5 5.08211 7.5 4.875V4.125ZM3.375 6H4.125C4.33211 6 4.5 6.16789 4.5 6.375V7.125C4.5 7.33211 4.33211 7.5 4.125 7.5H3.375C3.16789 7.5 3 7.33211 3 7.125V6.375C3 6.16789 3.16789 6 3.375 6ZM5.25 6.375C5.25 6.16789 5.41789 6 5.625 6H6.375C6.58211 6 6.75 6.16789 6.75 6.375V7.125C6.75 7.33211 6.58211 7.5 6.375 7.5H5.625C5.41789 7.5 5.25 7.33211 5.25 7.125V6.375ZM7.875 6H8.625C8.83211 6 9 6.16789 9 6.375V7.125C9 7.33211 8.83211 7.5 8.625 7.5H7.875C7.66789 7.5 7.5 7.33211 7.5 7.125V6.375C7.5 6.16789 7.66789 6 7.875 6Z"
                        fill="#757575" />
                    </svg>
                    <div class="bca-jakarta">{{branch.name}}</div>
                    <div class="jakarta-indonesia-uct-4-30">
                      {{branch.city}}, {{branch.country}},
                      {{branch.timezone}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ========== End content ========== -->
      <!-- ========== Start right sidebar ========== -->
      <div class="col-sm-5 col-md-4 col-lg-3 d-md-block sidebar">
        <div class="right-sidebar">
          <!-- For more settings use the AutoHTML plugin tab ... -->
          <div *ngIf="loginAs === 'CORPORATE'" class="post-a-job">
            <div class="post-a-job-title">
              <div class="post-a-job2">
                Post A Job! </div>
              <div class="create-a-job-post">
                Create a job post </div>
              <svg class="post-job-background1" width="74" height="93" viewBox="0 0 74 93" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.93991 0.401001L64.2118 6.7578L72.8021 16.3077L63.9175 92.1607L0.0681689 84.6821L9.93991 0.401001Z"
                  fill="#84AFE2" />
                <path d="M64.4248 6.78299L72.7923 16.3929L63.4277 15.296L64.4248 6.78299Z" fill="#FEFEFE" />
              </svg>
              <svg class="post-job-background2" width="86" height="100" viewBox="0 0 86 100" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.676 0.606857L76.1895 15.7127L83.1107 26.5334L61.9981 99.9288L0.217552 82.1572L23.676 0.606857Z"
                  fill="#EDF3FB" />
                <path d="M76.3958 15.7723L83.0875 26.616L74.0263 24.0095L76.3958 15.7723Z" fill="#FEFEFE" />
              </svg>
            </div>
            <div class="button-group">
              <button type="button" (click)="onClickManageJob()"
                class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                Create Job Post
                <i class="bi bi-arrow-right">
                </i>
              </button>
            </div>
          </div>

          <div class="account-accounts">
            <div class="account-title">
              <div class="account-accounts2">
                Accounts </div>
              <div class="account-let-the-employer-learn-about-you-easier">
                Let the employer learn about you easier. </div>
            </div>
            <div class="account-accounts3">
              <div class="account-activeacc" *ngFor="let user of companyProfile.users">
                <!-- <div class="account-ellipse-2">
                </div> -->
                <div class="account-basic">
                  <div class="account-username">
                    {{ user.firstName + ' ' + user.lastName | stringCase : 'trim': 20 }} </div>
                  <div class="account-as">
                    <div class="account-position">
                      {{ user.position }} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-group" *ngIf="('TA' | corporateRole)">
              <button type="button" (click)="goToManageAccount()"
                class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
                Manage Accounts
                <i class="bi bi-arrow-right">
                </i>
              </button>
            </div>
          </div>
          <ng-container *ngIf="loginAs === 'BACK_OFFICE'">
            <app-todo-card [todoType]="'warning'">
              <div class="title-section">
                <div class="title">There {{pendingTasks.totalJobPost > 1 ? 'are': 'is'}}</div>
                <div class="subtitle">{{pendingTasks.totalJobPost ? pendingTasks.totalJobPost: 'No'}} Pending Job Post
                  Request(s)</div>
              </div>
              <div class="action" *ngIf="pendingTasks.totalJobPost">
                <div class="button-warning-rounded"
                  (click)="onClickPendingTaskButton('corporate/details/job-post-request')">
                  <div class="button">See Requests
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </app-todo-card>
            <app-todo-card [todoType]="'success'">
              <div class="title-section">
                <div class="title">There {{pendingTasks.totalOnBoarding > 1 ? 'are': 'is'}}</div>
                <div class="subtitle">{{pendingTasks.totalOnBoarding ? pendingTasks.totalOnBoarding: 'No'}} On Boarding
                  Process</div>
              </div>
              <!-- <div class="action" *ngIf="pendingTasks.totalOnBoarding">
                <div class="button-success-rounded" (click)="onClickPendingTaskButton('corporate/talent-on-boarding')">
                  <div class="button">Manage
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div> -->
            </app-todo-card>
            <app-todo-card [todoType]="'danger'">
              <div class="title-section">
                <div class="title">There {{pendingTasks.totalTalentRequest > 1 ? 'are': 'is'}}</div>
                <div class="subtitle">{{pendingTasks.totalTalentRequest ? pendingTasks.totalTalentRequest: 'No'}} Talent
                  Request(s)</div>
              </div>
              <div class="action" *ngIf="pendingTasks.totalTalentRequest">
                <div class="button-danger-rounded" (click)="onClickPendingTaskButton('corporate/talent-requests')">
                  <div class="button">See Requests
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </app-todo-card>
          </ng-container>
        </div>

      </div>

    </div>

    <!-- ========== Start Job List ========== -->
    <div class="job-section-frame-1658 dasboard-container">
      <div class="job-section-frame-1660">
        <div class="job-section-job-post">
          Job Post
        </div>
        <button *ngIf="loginAs === 'BACK_OFFICE'" (click)="onClickPendingTaskButton('/corporate/details/job-post')"
          class="ec-btn ec-btn-link-primary ec-btn-rounded  mb-2 me-2">
          See Posts
          <i class="bi bi-arrow-right"></i>
        </button>
        <button *ngIf="loginAs === 'CORPORATE'" (click)="onClickPendingTaskButton('/corporate/job')"
          class="ec-btn ec-btn-link-primary ec-btn-rounded  mb-2 me-2">
          Manage Posts
          <i class="bi bi-arrow-right"></i>
        </button>
      </div>
      <div *ngIf="!isJobExist" class="job-not-exist">
        <div class="job-section-group-23">
          <svg class="job-section-group-20" width="74" height="93" viewBox="0 0 74 93" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.93964 0.40094L64.2116 6.75774L72.8018 16.3076L63.9172 92.1607L0.0678943 84.6821L9.93964 0.40094Z"
              fill="#84AFE2" />
            <path d="M64.4246 6.78293L72.792 16.3928L63.4274 15.296L64.4246 6.78293Z" fill="#FEFEFE" />
          </svg>
          <svg class="job-section-group-202" width="86" height="100" viewBox="0 0 86 100" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.6757 0.606812L76.1892 15.7126L83.1105 26.5334L61.9979 99.9287L0.217308 82.1572L23.6757 0.606812Z"
              fill="#EDF3FB" />
            <path d="M76.3956 15.7722L83.0872 26.6159L74.0261 24.0094L76.3956 15.7722Z" fill="#FEFEFE" />
          </svg>
        </div>
        <ng-container *ngIf="loginAs === 'CORPORATE'">
          <div class="job-section-title">
            <div class="job-section-you-have-not-post-anything-yet">
              This have not post anything, yet! </div>
            <div class="job-section-create-a-job-post">
              Create a job post </div>
          </div>
          <div class="button-group">
            <button type="button" (click)="onClickManageJob()"
              class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
              Create Job Post
              <i class="bi bi-arrow-right">
              </i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="loginAs === 'BACK_OFFICE'">
          <div class="job-bo-section-title">
            <div class="job-section-you-have-not-post-anything-yet">
              This company have not post anything, yet! </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="isJobExist && companyProfile" class="row" style="width: 100%; gap: 10px 0px;">
        <div *ngFor="let job of companyProfile.jobs.slice(0, 4);" class="col-sm-6 col-md-3 col-lg-3">
          <app-job-card [job]="job" [companyProfile]="companyProfile" (cardSelected)="selectJob(job)"></app-job-card>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- ========== End Job List ========== -->
  </div>
</div>
