// Angular modules
import { Injectable, Injector } from '@angular/core';
import { Endpoint } from '@enums/endpoint.enum';

import { BasicInfo, NotificationPreference,
  Portfolio, PreferencePayload, PrivacyPreference, ProfileCompletion,
  ProfileSetting, SkillAndResumePayload, Talent, UserProfile } from '@interfaces/talent.interface';

import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { LIMIT_GET_LIST } from '../constants';
import { APIBaseService } from './api-base.service';
import { StorageHelper } from '@helpers/storage.helper';

@Injectable()
export class TalentService extends APIBaseService {

  private readonly _availableTalentList = new BehaviorSubject<Array<Talent> | null>(null);
  private readonly _totalTalent = new BehaviorSubject<number>(0);
  private readonly _errorMessage = new BehaviorSubject<string>('');
  private readonly _showTalentAsGridList = new BehaviorSubject<boolean>(false);
  private readonly _searchParams = new BehaviorSubject<any>({ size: 10 });
  private readonly _offeredTalentId = new BehaviorSubject<string | undefined>('');
  private readonly _unreadNotification = new BehaviorSubject<number>(0);

  readonly availableTalentList$ = this._availableTalentList.asObservable();
  readonly totalTalent$ = this._totalTalent.asObservable();
  readonly errorMessage$ = this._errorMessage.asObservable();
  readonly showTalentAsGridList$ = this._showTalentAsGridList.asObservable();
  readonly searchParams$ = this._searchParams.asObservable();
  readonly offeredTalentId$ = this._offeredTalentId.asObservable();
  readonly unreadNotification$ = this._unreadNotification.asObservable();

  constructor(private injector: Injector) {
    super(injector);
  }

  public setAvailableTalentList(state: Array<Talent> | null): void {
    this._availableTalentList.next(state);
  }

  public setShowTalentAsGridList(state: boolean): void {
    this._showTalentAsGridList.next(state);
  }

  public setSearchParams(state: any): void {
    this._searchParams.next(state);
  }

  public setOfferedTalentId(state?: string): void {
    this._offeredTalentId.next(state);
  }

  public async getAvailableTalentList(params: any): Promise<Array<Talent>> {
    const url = Endpoint.TALENT_LIST;
    const updatedParams = _.pickBy(params)
    if (!updatedParams['size']) {
      updatedParams['size'] = LIMIT_GET_LIST;
    }
    const { data } = await this.baseAPI.get(`${url}`, { params: updatedParams });
    const result = data.data.talents;
    this.setAvailableTalentList(result)
    this._totalTalent.next(data.data.totalData)
    return result
  }

  public async getSimilarTalentList(params: any, talentId?: string): Promise<Array<Talent>> {
    const url = Endpoint.TALENT;
    const updatedParams = _.pickBy(params)
    if (!updatedParams['size']) {
      updatedParams['size'] = 4;
    }
    const { data } = await this.talentAPI.get(`${url}/${talentId}/similar`, { params: updatedParams });
    const result = data.data.talents;
    return result
  }

  public setErrorMessage(state: string): void {
    this._errorMessage.next(state);
  }

  public async getTalentById(id: string): Promise<UserProfile> {
    const url = Endpoint.TALENT;
    const { data } = await this.talentAPI.get(`${url}/${id}`);
    return data.data;
  }

  public async getProfile(): Promise<any> {
    const url = Endpoint.USER_PROFILE;
    const acccessToken = StorageHelper.getAccessToken();
    if (!acccessToken) {
      return {};
    }
    const { data } = await this.talentAPI.get(url);
    this.storeService.setUserProfile(data.data)
    return data.data;
  }

  public async getProfileCompletion(): Promise<ProfileCompletion> {
    const url = Endpoint.USER_PROFILE_COMPLETION;
    const { data } = await this.talentAPI.get(url);
    return data.data;
  }

  public async getNotifications(params: any): Promise<any> {
    const url = 'user/notification/list';
    const { data } = await this.talentAPI.get(url, { params });
    return data.data;
  }

  public async readNotification(notificationId: string): Promise<any> {
    const url = 'user/notification/read';
    const { data } = await this.talentAPI.post(url, { notificationId });
    return data.data;
  }

  public async getUnredNotificationCount(): Promise<number> {
    const url = 'user/notification/unread-count';
    const { data } = await this.talentAPI.get(url);
    const unreadCount = data.data.count;
    this._unreadNotification.next(unreadCount);
    return unreadCount;
  }

  public async createEducation(educationPayload: any, isRegistration = false): Promise<any> {
    const url = Endpoint.TALENT_EDUCATION;
    const { data } = await this.talentAPI.post(url, { ...educationPayload, isRegistration });
    return data.data;
  }

  public async editEducation(id: string, educationPayload: any): Promise<any> {
    const url = `${Endpoint.TALENT_EDUCATION}/${id}`;
    const { data } = await this.talentAPI.patch(url, educationPayload);
    return data.data;
  }

  public async removeEducation(id: string): Promise<any> {
    const url = `${Endpoint.TALENT_EDUCATION}/${id}`;
    const { data } = await this.talentAPI.delete(url);
    return data.data;
  }

  public async createWorkHistory(workHistoryPayload: any, isRegistration = false): Promise<any> {
    const url = Endpoint.TALENT_WORK_HISTORY;
    const { data } = await this.talentAPI.post(url, { ...workHistoryPayload, isRegistration });
    return data.data;
  }

  public async editWorkHistory(id: string, workHistoryPayload: any): Promise<any> {
    const url = `${Endpoint.TALENT_WORK_HISTORY}/${id}`;
    const { data } = await this.talentAPI.patch(url, workHistoryPayload);
    return data.data;
  }

  public async removeWorkHistory(id: string): Promise<any> {
    const url = `${Endpoint.TALENT_WORK_HISTORY}/${id}`;
    const { data } = await this.talentAPI.delete(url);
    return data.data;
  }

  public async createSkillAndPortofolio(skillAndPortofolioPayload: SkillAndResumePayload, isRegistration = false): Promise<any> {
    const url = Endpoint.TALENT_SKILL;
    const { data } = await this.talentAPI.post(url, { ...skillAndPortofolioPayload, isRegistration });
    return data.data;
  }

  public async updatePersonalWebsite(personalWebsite: string): Promise<any> {
    const url = Endpoint.TALENT_PERSONAL_WEBSITE;
    const { data } = await this.talentAPI.patch(url, { personalWebsite });
    return data.data;
  }

  public async createPreference(preferencePayload: PreferencePayload, isRegistration = false): Promise<any> {
    const url = Endpoint.TALENT_JOB_PREFERENCE;
    const { data } = await this.talentAPI.post(url, { ...preferencePayload, isRegistration });
    return data.data;
  }

  public async editPreference(preferencePayload: any): Promise<any> {
    const url = Endpoint.TALENT_PREFERENCE;
    const { data } = await this.talentAPI.patch(url, preferencePayload);
    return data.data;
  }

  public async activateEmail(token: string): Promise<any> {
    const url = `${Endpoint.ACTIVATE_EMAIL_ACTIVATION}?token=${token}`;
    const { data }: { data: any } = await this.talentAPI.get(url);
    return data.data;
  }

  public async uploadResume(file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = Endpoint.UPLOAD_RESUME;
      const { data } = await this.talentAPI.post(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async getResume(url: string) {
    const config = {
      withCredentials: false
    };
    const { data } = await this.talentAPI.get(url, config);
    return data
  }

  public async createPortfolio(portfolioPayload: Partial<Portfolio>): Promise<any> {
    const url = Endpoint.TALENT_PORTFOLIO;
    const { data } = await this.talentAPI.post(url, portfolioPayload);
    return data.data;
  }

  public async editPortfolio(id: string, portfolioPayload: Partial<Portfolio>): Promise<any> {
    const url = `${Endpoint.TALENT_PORTFOLIO}/${id}`;
    const { data } = await this.talentAPI.patch(url, portfolioPayload);
    return data.data;
  }

  public async removePortfolio(id: string): Promise<any> {
    const url = `${Endpoint.TALENT_PORTFOLIO}/${id}`;
    const { data } = await this.talentAPI.delete(url);
    return data.data;
  }

  public async editSkill(hardSkills: Array<string>, softSkills: Array<string>): Promise<any> {
    const url = Endpoint.TALENT_SKILL;
    const { data } = await this.talentAPI.patch(url, { hardSkills, softSkills });
    return data.data;
  }

  public async editLanguage(languages: Array<string>): Promise<any> {
    const url = Endpoint.TALENT_LANGUAGE;
    const { data } = await this.talentAPI.post(url, { languages });
    return data.data;
  }

  public async editAbout(newAbout: string): Promise<any> {
    const url = Endpoint.TALENT_ABOUT;
    const { data } = await this.talentAPI.patch(url, { about: newAbout });
    return data.data;
  }

  public async editBasicInfo(updatedBasicInfo: Partial<BasicInfo>): Promise<any> {
    const url = Endpoint.TALENT_BASIC_INFO;
    const { data } = await this.talentAPI.patch(url, updatedBasicInfo);
    return data.data;
  }

  public async editMyAccount(updatedMyAccount: Partial<BasicInfo>): Promise<any> {
    const url = Endpoint.TALENT_PERSONAL_INFO;
    const { data } = await this.talentAPI.patch(url, updatedMyAccount);
    return data.data;
  }

  public async editProfileNotification(updatedProfileNotification: NotificationPreference): Promise<any> {
    const url = Endpoint.TALENT_PROFILE_NOTIFICATION;
    const { data } = await this.talentAPI.patch(url, updatedProfileNotification);
    return data.data;
  }

  public async editProfilePrivacy(updatedProfilePrivacy: PrivacyPreference): Promise<any> {
    const url = Endpoint.TALENT_PROFILE_PRIVACY;
    const { data } = await this.talentAPI.patch(url, updatedProfilePrivacy);
    return data.data;
  }

  public async editProfileSetting(updatedProfileSetting: ProfileSetting): Promise<any> {
    const url = Endpoint.TALENT_PROFILE_SETTING;
    const { data } = await this.talentAPI.patch(url, updatedProfileSetting);
    return data.data;
  }

  public async editProfileCookie(updatedProfileNotification: NotificationPreference): Promise<any> {
    const url = Endpoint.TALENT_PROFILE_COOKIE;
    const { data } = await this.talentAPI.patch(url, updatedProfileNotification);
    return data.data;
  }

  public async updateProfilePicture(file: File | null): Promise<any> {
    if (file) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      const url = Endpoint.TALENT_PROFILE_PICTURE;
      const { data } = await this.talentAPI.patch(url, formData, config);
      return data.data;
    }
    return Promise.resolve()
  }

  public async removeProfilePicture(): Promise<any> {
    const url = Endpoint.TALENT_PROFILE_PICTURE;
    const { data } = await this.talentAPI.delete(`${url}/delete`);
    return data.data;
  }
}
