// Angular modules
import { Injectable, Injector } from '@angular/core';

import { Endpoint } from '@enums/endpoint.enum';
import { APIBaseService } from './api-base.service';
import { Reference } from '@interfaces/reference.interface';

@Injectable()
export class ReferenceService extends APIBaseService {


  // NOTE Controller
  private controller: AbortController = new AbortController();

  constructor(private injector: Injector) {
    super(injector);
  }

  public async getReferenceListByType(type: string): Promise<Array<Reference>> {
    const url = Endpoint.REFERENCE;
    const { data } = await this.referenceAPI.get(url, {
      params: {
        type
      }
    });
    return data.data;
  }
}