<!-- for corporate card -->
<div *ngIf="!jobInfo" class="jobcard" (click)="onClickDetailButton()">
  <div class="main">
    <div class="div">
      <div class="company-logo">
        <img *ngIf="!job.companyProfilePicture" src="./assets/img/project/default-company-logo.png"
          class="company-logo" />
        <img *ngIf="job.companyProfilePicture" [src]="job.companyProfilePicture" class="company-logo" />
      </div>

    </div>
    <div class="details">
      <div class="bca">
        {{ companyProfile.brand}} </div>
      <div class="front-end-developers-beginner">
        {{ job.postTitle}} </div>
    </div>
    <div class="tags">
      <div class="tag-jobtype">
        <div class="wfo">
          {{ job.workplaceType }} </div>
      </div>
      <div class="tag-joblevel">
        <div class="intermediate">
          {{ job.experienceLevel }} </div>
      </div>
    </div>
    <div class="_1-week-ago">
      {{ job.postedAt | timeAgo }} </div>
  </div>
  <div class="cta">
    <div class="jakarta-indonesia">
      {{ job.city }}, <br />{{ job.country }}
    </div>
    <button type="button" (click)="onClickDetailButton()"
      class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
      Details
      <i class="bi bi-arrow-right">
      </i>
    </button>
  </div>
</div>

<!-- for talent card -->
<div *ngIf="jobInfo" [style.background-color]="isSelected ? '#c6daf1' : '#e1e1e1'" class="jobcard"
  (click)="onClickDetailButton()">
  <div class="main">
    <div class="div">
      <img *ngIf="!jobInfo.companyProfilePicture" src="./assets/img/project/default-company-logo.png"
        class="company-logo" />
      <img *ngIf="jobInfo.companyProfilePicture" [src]="jobInfo.companyProfilePicture" class="company-logo" />
      <div class="save-job" *ngIf="showSavedButton">
        <svg *ngIf="jobInfo.isSaved" class="bookmark-fill-frame" width="9" height="12" viewBox="0 0 9 12" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 1.5V11.625C0 11.7574 0.0697671 11.8799 0.183576 11.9475C0.297385 12.015 0.438379 12.0176 0.55457 11.9542L4.5 9.80216L8.44543 11.9542C8.56162 12.0176 8.70262 12.015 8.81642 11.9475C8.93023 11.8799 9 11.7574 9 11.625V1.5C9 0.671573 8.32843 0 7.5 0H1.5C0.671573 0 0 0.671573 0 1.5Z"
            fill="#CB3A31" />
        </svg>
        <svg *ngIf="!jobInfo.isSaved" class="bookmark-frame" width="10" height="12" viewBox="0 0 10 12" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 1.5C0.5 0.671573 1.17157 0 2 0H8C8.82843 0 9.5 0.671573 9.5 1.5V11.625C9.5 11.7633 9.42388 11.8904 9.30195 11.9556C9.18001 12.0209 9.03206 12.0137 8.91699 11.937L5 9.82569L1.08301 11.937C0.967941 12.0137 0.819987 12.0209 0.698053 11.9556C0.576119 11.8904 0.5 11.7633 0.5 11.625V1.5ZM2 0.75C1.58579 0.75 1.25 1.08579 1.25 1.5V10.9243L4.79199 9.06298C4.91795 8.97901 5.08205 8.97901 5.20801 9.06298L8.75 10.9243V1.5C8.75 1.08579 8.41421 0.75 8 0.75H2Z"
            fill="#C2C2C2" />
        </svg>
      </div>
      <div class="button-alert-rounded" *ngIf="jobInfo.closedAt && jobListType !== 'corporate-find-job' && jobListType !== 'on-boarding-job'">
        <div class="button-alert-text">Closed</div>
      </div>
      <ng-container *ngIf="jobListType === 'job-template'">
        <div class="tag-job-status bg-orange-soft">
          <label>Draft / Template</label>
        </div>
      </ng-container>
      <ng-container *ngIf="jobListType === 'corporate-find-job'">
        <div class="tag-job-status bg-orange-soft" *ngIf="jobInfo.status === 0">
          <label>Drafted</label>
        </div>
        <div class="tag-job-status bg-warning-soft" *ngIf="jobInfo.status === 1">
          <label>On Review</label>
        </div>
        <div class="tag-job-status bg-success-soft" *ngIf="jobInfo.status === 2">
          <label>Active</label>
        </div>
        <div class="tag-job-status bg-black-soft" *ngIf="jobInfo.status === 3">
          <label>Closed</label>
        </div>
        <div class="tag-job-status bg-danger-soft" *ngIf="jobInfo.status === 4">
          <label>Declined</label>
        </div>
        <div class="tag-job-status bg-black-soft" *ngIf="jobInfo.status === 5">
          <label>Inactive</label>
        </div>
      </ng-container>
      <ng-container *ngIf="jobListType === 'applied-job'">
        <div class="tag-job-status bg-warning-soft" *ngIf="jobInfo.applicationStatus === 0">
          <label>Pending</label>
        </div>
        <div class="tag-job-status bg-success-soft" *ngIf="jobInfo.applicationStatus === 1">
          <label>On Process</label>
        </div>
        <div class="tag-job-status bg-danger-soft" *ngIf="jobInfo.applicationStatus === 2">
          <label>Declined</label>
        </div>
      </ng-container>
      <ng-container *ngIf="jobListType === 'on-boarding-job' && jobInfo.status === 1">
        <div class="tag-job-status bg-success-soft">
          <label *ngIf="jobInfo.lastStep !== 'nda'">{{jobInfo.lastStep | startCase }}</label>
          <label title="Non-Disclosure Agreement" *ngIf="jobInfo.lastStep === 'nda'">{{jobInfo.lastStep.toUpperCase() }}</label>
        </div>
      </ng-container>
      <ng-container *ngIf="jobListType === 'on-boarding-job' && jobInfo.status === 2">
        <div class="tag-job-status bg-primary-soft">
          <label>Congratulations</label>
        </div>
      </ng-container>
      <ng-container *ngIf="jobListType === 'on-boarding-job' && (jobInfo.status === 3 || jobInfo.status === 4)">
        <div class="tag-job-status bg-danger-soft">
          <label *ngIf="jobInfo.lastStep !== 'nda'">{{jobInfo.lastStep | startCase }}</label>
          <label title="Non-Disclosure Agreement" *ngIf="jobInfo.lastStep === 'nda'">{{jobInfo.lastStep.toUpperCase() }}</label>
        </div>
      </ng-container>
    </div>
    <div class="details">
      <div class="bca">
        {{ jobInfo.companyName}} </div>
      <div class="front-end-developers-beginner">
        {{ jobInfo.postTitle}} </div>
    </div>
    <div class="tags">
      <div class="tag-jobtype">
        <div class="wfo">
          {{ jobInfo.workplaceType }} </div>
      </div>
      <div class="tag-joblevel">
        <div class="intermediate">
          {{ jobInfo.experienceLevel }} </div>
      </div>
    </div>
    <div class="_1-week-ago">
      {{ jobInfo.postedAt | timeAgo }} </div>
  </div>
  <div class="cta">
    <div class="jakarta-indonesia">
      {{ jobInfo.city }}, <br />{{ jobInfo.country }}
    </div>
    <button type="button" (click)="onClickDetailButton()"
      class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
      Details
      <i class="bi bi-arrow-right">
      </i>
    </button>
  </div>
</div>
