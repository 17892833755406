import { Injectable, Injector } from '@angular/core';
import { StorageHelper } from '@helpers/storage.helper';
import { Endpoint } from '@enums/endpoint.enum';
import { AuthLayoutName, AuthResponse } from '@interfaces/authentication.interface';

import { LoginType } from '@enums/login.enum';
import { APIBaseService } from './api-base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService extends APIBaseService {

  private readonly _selectedLayoutName = new BehaviorSubject<AuthLayoutName>('LOGIN');

  readonly selectedLayoutName$ = this._selectedLayoutName.asObservable();


  constructor(private injector: Injector) {
    super(injector);
  }

  public setSelectedLayoutName(state: AuthLayoutName): void {
    this._selectedLayoutName.next(state);
  }

  public async authenticate(email: string, password: string): Promise<any> {
    StorageHelper.removeAccessToken();

    const url = Endpoint.AUTHENTICATE;
    const { data }: { data: AuthResponse } = await this.authAPI.post(url, { email, password });
    StorageHelper.setAccessToken(data.data?.accessToken, LoginType.SELF);
    StorageHelper.setRefreshToken(data.data?.refreshToken);
    return data.data;

  }

  public async loginByGoogle(idToken: string): Promise<any> {
    const url = Endpoint.LOGIN_BY_GOOGLE;
    const { data }: { data: AuthResponse } = await this.authAPI.post(url, { idToken });

    StorageHelper.setAccessToken(data.data.accessToken, LoginType.GOOGLE);
    StorageHelper.setRefreshToken(data.data.refreshToken);
    return data.data;
  }

  public async forgotPassword(email: string): Promise<boolean> {
    const url = Endpoint.FORGOT_PASSWORD;
    const { data } = await this.authAPI.post(url, { email });

    return !!data;
  }

  public async resetPassword(password: string, confirmationPassword: string, token: string): Promise<boolean> {
    const url = `${Endpoint.RESET_PASSWORD}?token=${token}`;
    const { data } = await this.authAPI.post(url, {
      newPassword: password,
      confirmPassword: confirmationPassword
    }, {
      headers: {
        token
      }
    });

    return !!data;
  }
}
