import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TalentService } from '@services/talent.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomEditor from 'ckeditor5-ng';
import { MAX_LENGTH } from 'src/app/shared/constants';
import { ToastManager } from '@blocks/toast/toast.manager';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements AfterViewInit {
  public oldAbout!: string;
  public isLoading = false;
  public about!: string;
  public errorMessage: string = '';
  public isAboutValid = true;
  public Editor = CustomEditor;

  constructor
    (
      private talentService: TalentService,
      private dialogRef: MatDialogRef<AboutComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    if (modalData?.data?.about) {
      this.about = modalData?.data?.about;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public onValueChange(newValue: string) {
    const words = newValue && newValue.trim().split(/\s+/);
    if (words.length > MAX_LENGTH.TEXT_AREA_SMALL) {
      this.isAboutValid = false;
      return;
    }
    if (words.length === 0) {
      this.isAboutValid = false;
      return;
    }
    this.isAboutValid = true;
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public getWordCount() {
    const about = this.about;
    const words = about && about.trim().split(/\s+/);
    return words?.length || 0;
  }

  public async editAbout() {
    try {
      this.isLoading = true;
      await this.talentService.editAbout(this.about);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('About saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
