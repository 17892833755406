import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External modules
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonDirective } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';

// Internal modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { StaticModule } from './static/static.module';

// Services
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { environment } from '@env/environment';

// Components
import { AppComponent } from './app.component';

// Factories
import { appInitFactory } from '@factories/app-init.factory';
import { TalentRoutingModule } from '@pages/talent/talent-routing.module';
import { RegisterService } from '@services/register.service';
import { TalentService } from '@services/talent.service';
import { ReferenceService } from '@services/reference.service';
import { SnackbarService } from '@services/snackbar.service';
import { CorporateService } from '@services/corporate.service';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { CorporateRoutingModule } from '@pages/corporate/corporate-routing.module';
import { ModalService } from '@services/modal.service';
import { MatDialogModule } from '@angular/material/dialog';
import { JobService } from '@services/job.service';
import { RegistrationTalentComponent } from './pages/registration-talent/registration-talent.component';
import { BackOfficeService } from '@services/back-office.service';
import { OnBoardingService } from '@services/onboarding.service';

import { provideLottieOptions } from 'ngx-lottie';

@NgModule({
  imports: [
    // Angular modules
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,

    // External modules
    TranslateModule.forRoot({
      loader:
      {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularSvgIconModule.forRoot(),
    SocialLoginModule,

    // Internal modules
    SharedModule,
    StaticModule,
    AppRoutingModule,
    TalentRoutingModule,
    CorporateRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatDialogModule,
  ],
  declarations: [
    AppComponent,
    RegistrationTalentComponent,
  ],
  providers: [
    // External modules
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            )
          },
        ],
        onError: (err) => {
          console.error('SocialAuthServiceConfig', err);
        }
      } as SocialAuthServiceConfig,
    },
    GoogleSigninButtonDirective,

    // Services
    AuthService,
    StoreService,
    RegisterService,
    TalentService,
    SnackbarService,
    ReferenceService,
    CorporateService,
    ModalService,
    JobService,
    BackOfficeService,
    OnBoardingService,

    // Pipes
    DatePipe,
    // Guards

    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
