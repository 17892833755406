import { Component, Input } from '@angular/core';

type TodoType = 'warning' | 'success' | 'danger';

@Component({
  selector: 'app-todo-card',
  templateUrl: './todo-card.component.html',
  styleUrls: ['./todo-card.component.scss']
})
export class TodoCardComponent {

  @Input() todoType: TodoType = 'warning';
}
